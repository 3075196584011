import React from 'react';

import loaderImage from '../../../assets/images/CoureMax.gif';

export default class Loader extends React.Component {
    render() {
        return(
            <div style={{
                width: this.props.width,
                position: this.props.position || 'relative',
                display: 'flex',
                justifyContent:'center',
                minHeight: '500px',
                alignItems:'center',
                backgroundColor: 'rgba(0,0,0,0.03)',
            }}>
                <img style={{
                    width: '200px'
                }}
                     src={loaderImage}
                     alt="loading..." />
            </div>
        );
    }

}