import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { fetchBills, cleanSearch } from "../../actions/billing";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import "../../../sass/activityfilters.scss";
import DatePicker from "react-datepicker";
import {
  BILLINGS_LISTING_COLUMNS,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import { formatDate } from "../../utils/date";

import CustomDropDown from "../organisms/CustomDropDown";
import BillsListingTable from "../atom/BillsListingTable";
import { utilityService } from "../../services/utility";
import { billingService } from "../../services/billing";
import ManageInvoicePopup from "../atom/ManageInvoicePopup";

class BillsDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    bills: PropTypes.array.isRequired,
    fetchBills: PropTypes.func.isRequired,
    cleanSearch: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    fromDate: "",
    toDate: "",
    facility: {
      value: "",
      label: "Select Facility",
    },
    patient: {
      value: "",
      label: "Select Patient",
    },
    practice: {
      value: "",
      label: "Select Practice",
    },
    has_invoice: null,
    events: [],
    checkedBills: [],
    checkedEventIds: [],
    allChecked: false,
    pageSize: RECORDS_PER_PAGE,
    manageInvoicePopup: false
  };

  componentDidMount() {
    this.props.cleanSearch({}).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
      has_invoice: this.state.has_invoice,
    };

    this.props.fetchBills({ params: this.addFilters(options) }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  addCheckedItem = (activity, event_ids) => {
    this.setState((prevState) => ({
      checkedBills: [...prevState.checkedBills, activity],
    }));
    this.setState((prevState) => ({
      checkedEventIds: [...prevState.checkedEventIds, event_ids],
    }));
  };

  addAllCheckedItem = () => {
    const { bills = [] } = this.props;
    this.setState({
      checkedBills: bills.map((activity) => activity.old_bill_id),
      checkedEventIds: bills.map((activity) => activity.event_id),
      allChecked: true,
    });
  };

  removeAllCheckedItem = () => {
    this.setState({
      checkedBills: [],
      checkedEventIds: [],
      allChecked: false,
    });
  };

  removeCheckedItem = (activityId, event_id) => {
    this.setState((prevState) => ({
      checkedBills: prevState.checkedBills.filter(
        (activity) => activity !== activityId
      ),
      checkedEventIds: prevState.checkedEventIds.filter(
        (event) => event !== event_id
      ),
    }));
    this.setState({ allChecked: false });
  };
  addFilters = (options) => {
    const { fromDate, toDate, facility, patient, practice, has_invoice } =
      this.state;

    if (fromDate) {
      options = { ...options, from_date: formatDate(fromDate) };
    }
    if (toDate) {
      options = { ...options, to_date: formatDate(toDate) };
    }
    if (facility.value) {
      options = { ...options, facility: facility.value };
    }
    if (patient.value) {
      options = { ...options, patient: patient.value };
    }
    if (practice.value) {
      options = { ...options, practice: practice.value };
    }
    if (has_invoice && has_invoice !== '') {
      options = { ...options, has_invoice: has_invoice };
    }
    options = { ...options, page_size: this.state.pageSize };
    return options;
  };

  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  search = () => {
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
    };
    this.props.fetchBills({ params: this.addFilters(options) }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  resetFilter = () => {
    this.setState({ fromDate: "" });
    this.setState({ endDate: "" });

    this.setState({
      facility: {
        value: "",
        label: "Select Facility",
      },
    });
    this.setState({
      facility: {
        value: "",
        label: "Select Facility",
      },
    });
    this.setState({
      patient: {
        value: "",
        label: "Select Patient",
      },
    });
    this.setState({
      practice: {
        value: "",
        label: "Select Practice",
      },
    });
    this.setState({
      has_invoice: "",
    });

    this.props.cleanSearch({}).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  list = () => {
    const { bills = [] } = this.props;
    const even = [];
    const list = bills.map((bill) => {
      let hcpcs_code = '';
      bill.service_lines.forEach(element => {
        if (element.cpt_code && element.cpt_code !== 'null')
          hcpcs_code += element.cpt_code + ", ";
      });
      if (hcpcs_code.length > 1)
        hcpcs_code = hcpcs_code.substring(0, hcpcs_code.length - 2);

      let insurance = '';
      if (bill.event_patient &&
        bill.event_patient.patient &&
        bill.event_patient.patient.patient_insurance_data &&
        bill.event_patient.patient.patient_insurance_data.primary)
        insurance += "P: " + bill.event_patient.patient.patient_insurance_data.primary.description + "<br>";

      if (bill.event_patient &&
        bill.event_patient.patient &&
        bill.event_patient.patient.patient_insurance_data &&
        bill.event_patient.patient.patient_insurance_data.secondary)
        insurance += "S: " + bill.event_patient.patient.patient_insurance_data.secondary.description;

      even.push(bill.event_id);
      return [
        bill.old_bill_id ? bill.old_bill_id : "",
        [
          bill.old_bill_id ? bill.old_bill_id : "",
          bill.event_id ? bill.event_id : "",
        ],
        bill.event_id ? bill.event_id : "",
        [
          bill.error_notes ? bill.error_notes : "",
        ],
        bill.event_patient ? bill.event_patient.patient_id : "",
        bill.event_patient && bill.event_patient.patient
          ? formatDate(bill.event_patient.patient.date_of_birth)
          : "",
        `${bill.event_patient && bill.event_patient.patient
          ? bill.event_patient.patient.first_name
          : ""
        } ${bill.event_patient && bill.event_patient.patient
          ? bill.event_patient.patient.last_name
          : ""
        }`,
        bill.service_date ? formatDate(bill.service_date) : "",
        bill.total_bill ? bill.total_bill : "0",
        bill.event_patient.billing_notes
          ? bill.event_patient.billing_notes
          : "",
        bill.event_patient && bill.event_patient.assistant
          ? `${bill.event_patient.assistant.name} ${bill.event_patient.assistant.last_name}`
          : "",

        hcpcs_code,
        insurance,
        bill.event_patient && bill.event_patient.surgeon
          ? `${bill.event_patient.surgeon.first_name} ${bill.event_patient.surgeon.last_name}`
          : "",
        bill.event_patient && bill.event_patient.facility
          ? bill.event_patient.facility.facility_name
          : "",
        bill.practice ? bill.practice.paytoname : "",
      ];
    });

    // eslint-disable-next-line
    this.state.events = even;
    return list;
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  goToPage = (page) => {
    this.setLoading(true);
    const options = {
      page: page < 0 ? 1 : page,
    };
    this.setCurrentPage(options.page);
    this.props
      .fetchBills({ params: page < 0 ? options : this.addFilters(options) })
      .then((res) => {
        this.setLoading(false);
      });
  };
  deleteBills = () => {
    const {
      checkedBills
    } = this.state;

    if (checkedBills.length > 0) {
      utilityService.showConfirmDialog().then(data => {
        billingService.deleteBills({ bill_ids: this.state.checkedBills }).then(data => {
          utilityService.showHTMLMessage('Bill Deletion', data.message);
          this.goToPage(1);
        })
      })
    }
  }

  createInvoice = () => {
    const {
      checkedBills
    } = this.state;

    if (checkedBills.length > 0) {
      let selectedBillsWithDetails = this.props.bills.filter(temp => checkedBills.findIndex(t => t === temp.old_bill_id) >= 0);
      this.setState({
        manageInvoicePopup: true,
        selectedBillsWithDetails: selectedBillsWithDetails
      });
    }
    else {
      utilityService.showHTMLMessage('Error', 'Please select at-least one bill to create invoice');
    }
  }

  setInvoicePopupOpen = (flag) => {
    this.setState({ manageInvoicePopup: flag });
  };

  render() {
    return (
      <div className="main_container">
        <div
          className="col-md-3 custom-sidebar-menu left_col"
          style={{
            minHeight: "100vh",
          }}
        >
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "830px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Bills
              </h3>
            </div>
          </div>
          <div className="custom-class">
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>Start Date</label>
              <DatePicker
                isClearable
                className="filter-input"
                selected={this.state.fromDate}
                onChange={(date) => this.setState({ fromDate: date })}
              />
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>End Date</label>
              <DatePicker
                isClearable
                className="filter-input"
                selected={this.state.toDate}
                onChange={(date) => this.setState({ toDate: date })}
              />
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <CustomDropDown
                type={'facilities'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.facility}
                onChange={(value) => this.setState({ facility: value })}>
              </CustomDropDown>
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <CustomDropDown
                type={'patients'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.patient}
                onChange={(value) => this.setState({ patient: value })}>
              </CustomDropDown>
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <CustomDropDown
                type={'practices'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.practice}
                onChange={(value) => this.setState({ practice: value })}>
              </CustomDropDown>
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>Invoice Options</label>
              <select
                value={this.state.has_invoice}
                className="code-input"
                onChange={(event) =>
                  this.setState({ has_invoice: event.target.value })
                }
              >
                <option value="">All Bills</option>
                <option value="Yes">Bill With Generated Invoice</option>
                <option value="No">Bill With Not Generated Invoice</option>
              </select>
            </div>

            <div className="filter-footer">

              <button
                className={
                  this.state.checkedBills.length <= 0
                    ? "filter-footer-disabled-button"
                    : "filter-button"
                }
                disabled={this.state.checkedBills.length <= 0 ? 'disabled' : ''}
                type="submit"
                onClick={this.createInvoice}
              >
                Create Invoice
              </button>
              <button
                type="submit"
                onClick={this.resetFilter}
                className="filter-footer-button"
              >
                Refresh
              </button>
              <button
                type="submit"
                onClick={this.search}
                className="filter-footer-button"
              >
                Search
              </button>

              <button
                className={
                  this.state.checkedBills.length <= 0
                    ? "ml-4 filter-footer-disabled-button"
                    : "ml-4 filter-button btn-danger"
                }
                disabled={this.state.checkedBills.length <= 0 ? 'disabled' : ''}
                type="submit"
                onClick={this.deleteBills}
              >
                Delete Selected Bills
              </button>
            </div>
            <div className="row">
              <div className="activities-header">
                <BillsListingTable
                  isLoading={this.state.loading}
                  checkedBills={this.state.checkedBills}
                  currentPage={this.state.currentPage}
                  allChecked={this.state.allChecked}
                  addCheckedItem={this.addCheckedItem}
                  removeCheckedItem={this.removeCheckedItem}
                  addAllCheckedItem={this.addAllCheckedItem}
                  removeAllCheckedItem={this.removeAllCheckedItem}
                  setPageSize={this.setPageSize}
                  pageSize={this.state.pageSize}
                  goToPage={this.goToPage}
                  headColumn={BILLINGS_LISTING_COLUMNS}
                  orignalObjects={this.props.bills}
                  listing={this.list()}
                  events={this.state.events}
                  totalRecords={this.props.totalRecords}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.manageInvoicePopup && (
          <ManageInvoicePopup
            title={'Manage Invoice'}
            isOpen={this.state.manageInvoicePopup}
            setModal={this.setInvoicePopupOpen}
            selectedBills={this.state.selectedBillsWithDetails}
            goToPage={this.goToPage}>
          </ManageInvoicePopup>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bills: state.billingsReducer.billings,
    totalRecords: state.billingsReducer.totalBillings,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBills,
      cleanSearch,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BillsDashboard));
