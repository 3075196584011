import React from "react";
import {
  APP_ROUTES,
  CLIENTS_LISTING_COLUMNS,
  NOTIFICATION_TYPES,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { fetchClients } from "../../actions/clients";
import ClientListingTable from "../atom/ClientListingTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clientService } from "../../services/clients";
import PropTypes from "prop-types";
import { authorizationService } from "../../services/authorization";

class ClientsDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    fetchClients: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedClients: [],
    searchedTotalRecords: 0,
    pageSize: RECORDS_PER_PAGE,
  };

  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };
    this.props.fetchClients({ params: options }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page,
      page_size: this.state.pageSize,
    };
    this.props.fetchClients({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_CLIENT);

  resetClientSearch = () => {
    this.setState({
      searchByName: "",
      searchedClients: [],
      searched: false,
      searchedTotalRecords: 0,
    });
  };

  searchClient = () => {
    const { searchByName } = this.state;
    let options = {};
    if (searchByName) {
      options = { ...options, search: searchByName };
    }
    options = { ...options, page_size: this.state.pageSize };

    this.setState({
      searched: true,
      loading: true,
    });
    this.setCurrentPage(1);
    clientService.getClients({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedClients: response.data.map((client) => {
          return [
            client.domain.domain,
            client.id,
            client.case_capacity,
            client.address,
            client.contact_phone,
            client.contact_fax,
            client.npi,
            client.status,
            client.id,
          ];
        }),
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  list = () => {
    const { clients = [] } = this.props;

    //var invoice_req = "";
    return clients.map((client) => {
      var c_status = "";
      if (client.status === 1) {
        c_status = "ACTIVE";
      } else {
        c_status = "NOT ACTIVE";
      }
      return [
        client.domain.domain,
        client.id,
        client.case_capacity,
        client.address,
        client.contact_phone,
        client.contact_fax,
        client.npi,
        c_status,
        client.id,
      ];
    });
  };

  showManageClient = (clientId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_CLIENT,
      state: {
        clientId,
      },
    });

  deleteClient = (client) => {
    clientService.deleteClient(client).then((response) => {
      createNotification(NOTIFICATION_TYPES.SUCCESS, response.message);
      this.goToPage(1);
    });
  };

  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };


  render() {
    const { searched, searchedClients, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />

        <div
          style={{
            minHeight: "760px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Clients/Tenants
              </h3>
            </div>
          </div>

          {authorizationService.isAddAccess() && (
            <div className="filter-footer-header">
              <button
                onClick={() => {
                  this.showAdd(true);
                }}
                className="filter-button"
              >
                Add New Client
              </button>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div
              style={{
                paddingLeft: "0",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Client Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                  marginRight: "10px",
                }}
                onClick={this.searchClient}
                className="filter-save-button"
              >
                Search
              </button>
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetClientSearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <ClientListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManageClient}
                delete={this.deleteClient}
                setPageSize={this.setPageSize}
                pageSize={this.state.pageSize}
                headColumn={CLIENTS_LISTING_COLUMNS}
                listing={
                  searched && searchedClients.length > 0
                    ? searchedClients
                    : this.list()
                }
                totalRecords={
                  searched && searchedTotalRecords > 0
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clientReducer.clients,
    totalRecords: state.clientReducer.totalClients,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchClients,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientsDashboard));
