import { Parser } from 'html-to-react'

function getClass(validationGroups, state, fieldkey, defaultClass = 'code-input', errorClass = 'error') {
    let rtnClass = defaultClass;
    let validationGroup = validationGroups['fields'][fieldkey];
    if (validationGroup) {
        if (!isValid(validationGroup, state[fieldkey]).total) {
            rtnClass += ' ' + errorClass;
        }
    }

    return rtnClass;
}

function getRequiredHTML(validationGroups, fieldkey) {
    let validationGroup = validationGroups['fields'][fieldkey];
    if (validationGroup)
        return Parser().parse(validationGroup.required ? '<span className="required">*</span>' : '');


    return '';
}

function isRequired(validationGroups, fieldkey) {
    let validationGroup = validationGroups['fields'][fieldkey];
    if (validationGroup)
        return true;

    return false;
}

function isFormValid(validationGroups, state) {
    let overAllValidation = true;
    let rtnMessage = validationGroups.message;
    let validationMessageArray = [];

    if (validationGroups.fields) {
        Object.keys(validationGroups.fields).forEach(tKey => {
            let validationResult = isValid(validationGroups.fields[tKey], state[tKey]);
            if (!validationResult.total) {
                overAllValidation = false;
                validationMessageArray.push('<br><b>' + validationGroups.fields[tKey].filedName + "</b><ul>");
                prepareMessageArray(validationGroups.fields[tKey], validationResult, validationMessageArray);
                validationMessageArray.push('</ul>');
            }
        })
    }

    if (validationMessageArray && validationMessageArray.length > 0) {
        rtnMessage += validationMessageArray.join('');
    }

    return { isValid: overAllValidation, message: rtnMessage };
}

function prepareMessageArray(validationGroup, validationResult, validationMessageArray) {
    if (!validationResult.required)
        validationMessageArray.push("<li>" + validationGroup.filedName + " is requried</li>");
    if (!validationResult.minLength)
        validationMessageArray.push("<li>Min length of " + validationGroup.filedName + " is " + validationGroup.minLength + "</li>");
    if (!validationResult.maxLength)
        validationMessageArray.push("<li>Max length of " + validationGroup.filedName + " is " + validationGroup.maxLength + "</li>");
    if (!validationResult.custom)
        validationMessageArray.push((!validationGroup.custom || validationGroup.custom === '') ? "<li>" + validationGroup.filedName + ' has some custom validation error <li>' : "<li>" + validationGroup.custom + "</li>");
}

function isValid(validationGroup, value) {
    let rtnValidationResult = { minLength: true, maxLength: true, required: true, custom: true };
    let totalLength = 0;
    if (typeof (value) != "object") {
        if (value)
            totalLength = value.toString().length;

        if (totalLength <= 0 && validationGroup.required)
            rtnValidationResult.required = false;

        if (validationGroup.maxLength && validationGroup.maxLength > 0 && totalLength > validationGroup.maxLength)
            rtnValidationResult.maxLength = false;

        if (validationGroup.minLength && validationGroup.minLength > 0 && totalLength < validationGroup.minLength)
            rtnValidationResult.minLength = false;
    }
    else {
        if (!value) {
            rtnValidationResult.required = false;
        }
        else if (value instanceof Date) {
            rtnValidationResult.required = true;
        }
        else if (value instanceof File) {
            if (!value.name)
                rtnValidationResult.required = false;
        }
        else if (Array.isArray(value) && validationGroup.required && value.length <= 0) {
            rtnValidationResult.required = false;
        }
        else {
            if (Object.keys(value).length === 0 && validationGroup.required)
                rtnValidationResult.required = false;

            if(Object.keys(value).length > 0 && validationGroup.required) {
                let valueExists = false;
                Object.keys(value).forEach(tKey => {
                    // eslint-disable-next-line
                    if(value[tKey] != '' && value[tKey] != "0")
                        valueExists = true;
                })

                if(!valueExists)
                    rtnValidationResult.required = false;
            }
        }
    }

    rtnValidationResult.total = rtnValidationResult.minLength
        && rtnValidationResult.maxLength
        && rtnValidationResult.required
        && rtnValidationResult.custom;

    //console.log(validationGroup, value, rtnValidationResult, totalLength);
    return rtnValidationResult;
}

export const FormValidatorService = {
    getClass,
    isFormValid,
    getRequiredHTML,
    isRequired
}