import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";
//import jsPDF from "jspdf";
//import { saveAs } from 'file-saver';
//import { utilityService } from '../services/utility';


function getInvoicing(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_INVOICINGV1, options)
        .then(handleResponse)
        .catch(handleError);
}

/*function submitInvoicing(payload) {
    return apiClient()
        .post(API_CONSTANTS.SUBMIT_INVOICING, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateInvoicing(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_INVOICING, payload)
        .then(handleResponse)
        .catch(handleError);
}

function addMultipleInvoicing(payload) {
    let invoices = { invoices: payload };
    return apiClient()
        .post(API_CONSTANTS.CREATE_MULTIPLE_INVOICING, invoices)
        .then(handleResponse)
        .catch(handleError);
}

function getActivityDetail(activity) {
    return apiClient()
        .get(API_CONSTANTS.ACTIVITY_DETAIL + activity)
        .then(handleResponse)
        .catch(handleError);
}
function getInvoiceDetail(activity) {
    return apiClient()
        .get(API_CONSTANTS.INVOICING_DETAIL + activity)
        .then(handleResponse)
        .catch(handleError);
}


function deleteInvoicing(activity) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_INVOICING + activity)
        .then(handleResponse)
        .catch(handleError);
}

function deleteActivityFaceSheet(faceSheetId) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ACTIVITY_FACESHEET + faceSheetId)
        .then(handleResponse)
        .catch(handleError);
}

function exportInvoicing(payload) {
    return apiClient()
        .post(API_CONSTANTS.EXPORT_INVOICING, payload)
        .then(async response => {
            const blob = utilityService.base64toBlob(response.data.data);
            saveAs(blob, response.data.name);
        })
        .catch(handleError);
}
function multiStatusUpdate(payload) {
    return apiClient()
        .post(API_CONSTANTS.MULTIPLE_STATUS_UPDATE, payload)
        .then(handleResponse)
        .catch(handleError);

}*/

function createInvoiceV1(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_INVOICE_V1, payload)
        .then(handleResponse)
        .catch(handleError);
}

function startInvoiceV1(payload) {
    return apiClient()
        .post(API_CONSTANTS.START_INVOICE_V1, payload)
        .then(handleResponse)
        .catch(handleError);
}

function sendInvoiceV1(payload) {
    return apiClient()
        .post(API_CONSTANTS.SEND_INVOICE_V1, payload)
        .then(handleResponse)
        .catch(handleError);
}

function submitClaimV1(payload) {
    return apiClient()
        .post(API_CONSTANTS.SUBMIT_CLAIM_V1, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const invoicingV1Service = {
    createInvoiceV1,
    getInvoicing,
    startInvoiceV1,
    sendInvoiceV1,
    submitClaimV1,
    /*submitInvoicing,
    updateInvoicing,
    addMultipleInvoicing,
    getActivityDetail,
    getInvoiceDetail,
    deleteInvoicing,
    deleteActivityFaceSheet,
    exportInvoicing,
    multiStatusUpdate,*/
}