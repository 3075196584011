import React from 'react';
import { bindActionCreators } from "redux";
import { fetchRegions } from "../../actions/regions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { APP_ROUTES, RECORDS_PER_PAGE, REGIONS } from "../../constants/common";
import { regionsService } from "../../services/regions";

import RegionListingTable from "../atom/RegionListingTable";
import { authorizationService } from "../../services/authorization";

class RegionsDashboard extends React.Component {

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searched: false,
        pageSize: RECORDS_PER_PAGE,
        searchedRegions: [],
        searchedTotalRecords: 0,
    };

    componentDidMount() {
        this.setState({ loading: true });
        const options = {
            page: this.state.currentPage,
            page_size: this.state.pageSize,
        };
        this.props.fetchRegions({ params: options })
            .then(res => {
                this.setState({ loading: false });
            });
    }

    setCurrentPage = (num) => {
        this.setState({ currentPage: num });
    };



    setLoading = (flag) => {
        this.setState({ loading: flag });
    };


    goToPage = (page) => {
        this.setLoading(true);
        this.setCurrentPage(page);
        const options = {
            page: page,
            page_size: this.state.pageSize,
        };

        this.props.fetchRegions({ params: options }).then((res) => {
            this.setLoading(false);
        });
    };

    showAdd = () => this.props.history.push({
        pathname: APP_ROUTES.ADD_REGION,
    });

    showAssistant = regionId => this.props.history.push({
        pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
        state: {
            regionId,
        }
    });

    resetRegionsSearch = () => {
        this.setState({
            searchByName: '',
            searchedRegions: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    edit = regionId => this.props.history.push({
        pathname: APP_ROUTES.UPDATE_REGION,
        state: {
            regionId,
        }
    });

    searchRegions = () => {
        const { searchByName } = this.state;
        let options = {};

        if (searchByName) {
            options = { ...options, search: searchByName };
        }

        options = { ...options, page_size: this.state.pageSize };

        this.setState({
            searched: true,
            loading: true
        });
        regionsService
            .getRegions({ params: options })
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedRegions: response.data.map(region => {
                        return [
                            region.id,
                            region.name,
                            region.id,
                        ];
                    })
                });
                this.setState({ loading: false });
            });
    }

    list = () => {
        const { regions = [] } = this.props;
        return regions.map(region => {
            return [
                region.id,
                region.name,
                region.id,
            ];
        });
    };

    setPageSize = (value) => {
        this.setState({ pageSize: value }, () => {
            this.goToPage(1);
        });
    };

    render() {
        const {
            searched,
            searchedRegions,
            searchedTotalRecords
        } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '755px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Regions</h3>
                        </div>
                    </div>
                    {authorizationService.isAddAccess() && (
                        <div className="filter-footer-header">
                            <button
                                onClick={() => { this.showAdd(true) }}
                                className="filter-button">
                                Add New Region
                            </button>
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        <div style={{
                            paddingLeft: '0',
                        }} className="col-md-3 mrgn-btm10">
                            <label>Region Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => {
                                    this.setState({ searchByName: event.target.value },
                                        () => {
                                            if (this.state.searchByName.length >= 3) {
                                                this.searchRegions();
                                            } else if (this.state.searchByName.length === 0) {
                                                this.resetRegionsSearch();
                                            }
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetRegionsSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <RegionListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                setPageSize={this.setPageSize}
                                pageSize={this.state.pageSize}
                                view={this.showAssistant}
                                edit={this.edit}
                                headColumn={REGIONS}
                                listing={searched ? searchedRegions : this.list()}
                                totalRecords={searched ? searchedTotalRecords : this.props.totalRecords}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        regions: state.regionReducer.regions,
        totalRecords: state.regionReducer.totalRegions,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchRegions,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(RegionsDashboard));