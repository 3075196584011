import React from "react";
import PropTypes from 'prop-types';

export default class ServiceLine extends React.Component {
    static propTypes = {
        lineNumber: PropTypes.string.isRequired,
        procedurename: PropTypes.string.isRequired,
        cpt: PropTypes.string.isRequired,
        cptdescription: PropTypes.string.isRequired,
        dxcode: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
        removeServiceLine: PropTypes.func.isRequired,
        setServiceLineAttribute: PropTypes.func.isRequired,
    };

    render() {
        const { lineNumber, setServiceLineAttribute } = this.props;
        return (
            <>
                <div className="col-md-4 mrgn-btm10">
                    <label>Procedure Name Code</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={this.props.procedurename}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>CPT Code</label>
                    <input
                        disabled
                        onChange={event => setServiceLineAttribute(lineNumber, 'cpt', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.cpt}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>CPT Description</label>
                    <input
                        disabled
                        onChange={event => setServiceLineAttribute(lineNumber, 'cptdescription', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.cptdescription}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>DX Code</label>
                    <input
                        disabled
                        onChange={event => setServiceLineAttribute(lineNumber, 'dxcode', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.dxcode}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Total</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={this.props.total}
                    />
                </div>
                {/* <div className="filter-footer">
                    <button
                        onClick={() => { this.props.removeServiceLine(lineNumber) }}
                        className="filter-cancel-button">
                        Remove (X)
                    </button>
                </div> */}
            </>
        );
    }
}