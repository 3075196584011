import {FACILITY} from '../constants/actions';

const initialState = {
    facilities: [],
    totalFacilities: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case FACILITY.FETCH_FACILITY_SUCCESS:
            return {
                ...state,
                facilities: action.data.data,
                totalFacilities: action.data.records,
            };
        case FACILITY.CREATE_FACILITY_SUCCESS:
            const facilities = [ ...state.facilities, action.data.data ];
            return {
                ...state,
                facilities: facilities,
            };
        case FACILITY.UPDATE_FACILITY_SUCCESS:
            const updatedFacilities = [ ...state.facilities, action.data.data ];
            return {
                ...state,
                facilities: updatedFacilities,
            }
        default:
            return state;
    }
}