import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getRevenueSchedulers(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_REVENUESCHEDULER, options)
        .then(handleResponse)
        .catch(handleError);
}

function getBillingDetail(billing) {
    return apiClient()
        .get(API_CONSTANTS.SCANFLOW_DETAIL + billing)
        .then(handleResponse)
        .catch(handleError);
}

function updateRevenueScheduler(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_REVENUESCHEDULER, payload)
        .then(handleResponse)
        .catch(handleError);
}


function uploadDocument(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPLOAD_DOCUMENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function exportBills(payload) {
    return apiClient()
        .get(API_CONSTANTS.EXPORT_BILLS, payload)
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'scanflowReport.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(handleError);
}


export const RevenueSchedulerService = {
    getRevenueSchedulers,
    getBillingDetail,
    updateRevenueScheduler,
    uploadDocument,
    exportBills,
};