import {CLIENT} from '../constants/actions';

const initialState = {
    clients: [],
    totalClients: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case CLIENT.FETCH_CLIENT_SUCCESS:
            return {
                ...state,
                clients: action.data.data,
                totalClients: action.data.records,
            };
        case CLIENT.CREATE_CLIENT_SUCCESS:
            const clients = [ ...state.clients, action.data.data ];
            return {
                ...state,
                clients: clients,
            };
        case CLIENT.UPDATE_CLIENT_SUCCESS:
            const updatedClients = [ ...state.clients, action.data.data ];
            return {
                ...state,
                clients: updatedClients,
            }
        default:
            return state;
    }
}