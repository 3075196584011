import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "../../../sass/activityfilters.scss";
import {
  LOCAL_STORAGE_KEYS,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import DatePicker from "react-datepicker";
import axios from "axios";
import { userService } from "../../services/users";
import CustomDropDown from "./CustomDropDown";

class LogsFilters extends React.Component {
  static propTypes = {
    setFromDate: PropTypes.func.isRequired,
    setToDate: PropTypes.func.isRequired,
    setAssistant: PropTypes.func.isRequired,
    setPatient: PropTypes.func.isRequired,
    setCreatedBy: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cancel = "";
  }

  loadUsers = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return userService
      .getUsers({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((user) => ({
          value: user.id,
          label: `${user.name} ${user.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  resetFilter = () => {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    this.props.setFromDate("");
    this.props.setToDate("");

    if (loggedInUser.type !== "assistant") {
      this.props.setAssistant({
        value: "",
        label: "Select Assistant",
      });
    }

    this.props.setPatient({
      value: "",
      label: "Select Patient",
    });
    this.props.setCreatedBy({
      value: "",
      label: "Select Updated By",
    });
  };
  render() {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    return (
      <>
        <div className="page-title">
          <div className="title_left">
            <h3
              style={{
                fontFamily:
                  "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "1.471",
              }}
            >
              Logs List
            </h3>
          </div>
        </div>

        <div className="custom-class">
          <div className="filters-column">
            <label>From Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.fromDate}
              onChange={(date) => this.props.setFromDate(date)}
            />
          </div>
          <div className="filters-column">
            <label>To Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.toDate}
              onChange={(date) => this.props.setToDate(date)}
            />
          </div>

          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                type={'patients'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.patient}
                onChange={(value) => this.props.setPatient(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                label={'Record Modified By'}
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.createdBy}
                onChange={(value) => this.props.setCreatedBy(value)}>
              </CustomDropDown>
            </div>
          )}

          <div className="filter-footer">
            <button
              type="submit"
              onClick={this.props.exportLogs}
              disabled={!this.props?.exportEnabled()}
              className={
                this.props.exportEnabled()
                  ? "filter-footer-button"
                  : "filter-footer-disabled-button"
              }
            >
              Export
            </button>
            <button
              type="submit"
              onClick={this.resetFilter}
              className="filter-footer-button"
            >
              Refresh
            </button>
            <button
              type="submit"
              onClick={this.props.search}
              className="filter-footer-button"
            >
              Search
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LogsFilters);
