import { INSURANCE } from '../constants/actions';

const initialState = {
    insurance: [],
    totalInsurance: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case INSURANCE.FETCH_INSURANCE_SUCCESS:
            return {
                ...state,
                insurance: action.data.data,
                totalInsurance: action.data.records,
            };
        case INSURANCE.CREATE_INSURANCE_SUCCESS:
            const createInsurance = [ ...state.insurance, action.data.data ];
            return {
                ...state,
                insurance: createInsurance,
            };
        case INSURANCE.UPDATE_INSURANCE_SUCCESS:
            const updateInsurance = [ ...state.insurance, action.data.data ];
            return {
                ...state,
                insurance: updateInsurance,
            }
        default:
            return state;
    }
}