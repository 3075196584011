import React from "react";
import { State } from 'country-state-city'
import { withRouter } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";

;

class StateSelection extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        isMulti: PropTypes.bool.isRequired,
        selectedStateCode: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        isRequired: PropTypes.bool.isRequired
    };

    state = {
        states: [],
    };

    selectedCountry = 'US';
    states = State.getStatesOfCountry(this.selectedCountry);
    convertedStates = null;

    constructor(props) {
        super(props);
        this.cancel = "";
    }

    componentDidMount() {
        const tState = State.getStateByCodeAndCountry(this.props.selectedStateCode, this.selectedCountry);
        if (tState) {
            this.setState({ selectedState: { label: tState.name, value: tState.isoCode } });
        }
    }

    loadOptions = (search, prevOptions) => {
        if (!this.convertedStates) {
            this.convertedStates = this.states.map((state) => ({
                'value': state.isoCode,
                'label': state.name
            }))
        }
        let filteredOptions;
        if (!search) {
            filteredOptions = this.convertedStates;
        } else {
            const searchLower = search.toLowerCase();

            filteredOptions = this.convertedStates.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }
        const hasMore = filteredOptions.length > prevOptions.length + 10;

        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );

        return {
            options: slicedOptions,
            hasMore
        };
    }

    render() {
        return (
            <>
                <label>{this.props.label} {this.props.isRequired && <span className="required">*</span>}</label>
                <AsyncPaginate
                    placeholder={"Select " + this.props.label}
                    loadOptions={this.loadOptions}
                    isMulti={this.props.isMulti}
                    defaultOptions={[
                        {
                            value: "",
                            label: "Select " + this.props.label,
                        },
                    ]}
                    value={this.state.selectedState}
                    onChange={(value) => this.props.onChange(value)}
                />
            </>
        )
    }
}


export default withRouter(StateSelection);
