import { SURGEON_ASSOCIATION } from '../constants/actions';

const initialState = {
    articles: [],
    total: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case SURGEON_ASSOCIATION.FETCH_SURGEON_ASSOCIATION_SUCCESS:
            return {
                ...state,
                surgeonAssociations: action.data.data,
                total: action.data.records,
            };
        case SURGEON_ASSOCIATION.CREATE_SURGEON_ASSOCATION_SUCCESS:
            const associated = [...state.surgeonAssociations, action.data.data];
            return {
                ...state,
                surgeonAssociations: associated,
            };
        default:
            return state;
    }
}