import {REGIONS} from '../constants/actions';

const initialState = {
    regions: [],
    totalRegions: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case REGIONS.FETCH_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.data.data,
                totalRegions: action.data.records,
            };
        case REGIONS.CREATE_REGION_SUCCESS:
            const regions = [ ...state.regions, action.data.data ];
            return {
                ...state,
                regions: regions,
            }
        case REGIONS.UPDATE_REGION_SUCCESS:
            const updatedRegions = [ ...state.regions, action.data.data ];
            return {
                ...state,
                regions: updatedRegions,
            }
        default:
            return state;
    }
}

