import * as React from "react";
import { Circle } from "rc-progress";
import "../../../sass/progressloading.scss";
class ProgressLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 9,
      color: "#85D262",
      isWaiting: true,
    };
    this.changeIncrease = this.changeIncrease.bind(this);
  }
  componentDidMount() {
    this.progressInterval = setInterval(this.changeIncrease, 100); // Change the interval as desired (e.g., 100ms)
  }

  componentWillUnmount() {
    clearInterval(this.progressInterval);
  }

  changeIncrease() {
    this.setState(({ percent, isWaiting }) => {
      if (percent === 30 && isWaiting) {
        // Set a wait condition at 30% progress
        setTimeout(() => {
          this.setState({ isWaiting: false });
        }, 3000); // 3000 milliseconds (3 seconds) wait time
        return {
          percent,
        };
      } else if (percent === 90 && !isWaiting) {
        // Set a wait condition at 90% progress
        setTimeout(() => {
          this.setState({ isWaiting: true });
        }, 3000); // 3000 milliseconds (3 seconds) wait time
        return {
          percent,
        };
      } else if (percent >= 100) {
        // Show success message at 100%
        setTimeout(() => {
          this.setState({ isWaiting: false, redirectToListing: true });
        }, 2000); // 2000 milliseconds (2 seconds) wait time for success message
        return {
          percent,
        };
      } else {
        return {
          percent: percent + 1,
        };
      }
    });
  }

  render() {
    const { percent, color, isWaiting, redirectToListing } = this.state;
    /*const containerStyle = {
      width: "250px",
    };*/
    const circleContainerStyle = {
      width: "250px",
      height: "250px",
      display: "inline-block",
      position: "relative",
    };
    const successClass = percent >= 100 && !isWaiting ? "success-text" : "";

    if (redirectToListing) {
      setTimeout(() => {
        // Simulate redirect after 10 seconds
        window.location.href = "/tenant-clients";
      }, 5000);
    }

    return (
      <div id="cover-spin">
        <div id="cover-spin-content">
          {percent <= 30 ? (
            <h3>
              Creating Database {percent}% {isWaiting && "(Creating...)"}
            </h3>
          ) : percent <= 45 ? (
            <h3>
              Creating Admin User {percent}% {isWaiting && "(Creating...)"}
            </h3>
          ) : percent <= 90 ? (
            <h3>Creating Client Front End {percent}%</h3>
          ) : (
            <h3 className={successClass}>Finishing Installation {percent}%</h3>
          )}
          {percent >= 100 && !isWaiting && (
            <h3 className={successClass}>Client Created Successfully</h3>
          )}
          {redirectToListing && <h3>Redirecting to Client Listing Page</h3>}
          <div style={circleContainerStyle}>
            <Circle
              percent={percent}
              strokeWidth={6}
              strokeLinecap="round"
              strokeColor={color}
            />
            <svg
              className="percentage-text"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="24"
                fontWeight="bold"
                fill="#333"
              >
                {percent}%
              </text>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressLoading;
