import { PROFILE } from '../constants/actions';
import { userProfileService } from '../services/profile';


export const fetchUserProfile = options => dispatch => {
    return new Promise((resolve, reject) => {
        userProfileService
            .getUsers(options)
            .then(response => {
                dispatch({
                    type: PROFILE.FETCH_USER_PROFILE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateUser = payload => dispatch => {
    return new Promise((resolve, reject) => {
        userProfileService
            .updateUser(payload)
            .then(response => {
                dispatch({
                    type: PROFILE.UPDATE_USER_PROFILE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

