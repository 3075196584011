import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createProcedure, updateProcedure } from "../../actions/procedure";
import { procedureService } from "../../services/procedures";
import { FormValidatorService } from "../../services/formvalidator";
import { utilityService } from "../../services/utility";
import CustomDropDown from "../organisms/CustomDropDown";

const validationGroup = {
  message: "There are some validation errors:",
  fields: {
    'name': { filedName: 'Procedure Name', required: true, minLength: 3, maxLength: 200 },
    'cptcode': { filedName: 'CPT Codes', required: true, minLength: 3, maxLength: 200 },
    'cptdescription': { filedName: 'CPT Description', required: true, minLength: 3, maxLength: 512 },
    'related_dx_code': { filedName: 'Related DX Code', required: true },
    'speciality': { filedName: 'Speciality', required: true },
  }
}

class ManageProcedure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      name: "",
      cptcode: "",
      price: "",
      speciality: {},
      related_dx_code: ''
    };
    this.cancel = "";
  }
  componentDidMount() {
    const procedureId = this.props.location.state
      ? this.props.location.state.procedureId
      : "";
    if (procedureId) {
      procedureService.getProcedureDetail(procedureId).then((response) => {
        this.setState({
          name: response.data.procedure_name,
          cptcode: response.data.cptcode,
          cptdescription: response.data.cptdescription,
          price: response.data.price,
          speciality: {
            label: response.data.specialty
              ? response.data.specialty.name
              : "",
            value: response.data.specialty
              ? parseInt(response.data.specialty.id)
              : "",
          },
          related_dx_code: response.data.related_dx_code,
        });
      });
    }
  }

  validateProcedure = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  submitProcedure = () => {
    if (!this.validateProcedure()) {
      return;
    }
    const { createProcedure, updateProcedure } = this.props;
    const { name, cptcode, related_dx_code, cptdescription } = this.state;

    const request = {
      procedure_name: name,
      cptcode: cptcode,
      cptdescription: cptdescription,
      related_dx_code: related_dx_code,
      specialty_id: this.state.speciality.value,
    };
    const procedureId = this.props.location.state
      ? this.props.location.state.procedureId
      : "";
    if (procedureId) {
      const payload = { ...request, id: procedureId };
      updateProcedure(payload)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
    } else {
      createProcedure(request)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            //this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          }
        })
    }
  };

  toggleCheckbox = (name, event) => {
    let obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  };

  cancel = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
    }
  };
  render() {
    const procedureId = this.props.location.state
      ? this.props.location.state.procedureId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {procedureId && (
                <h2 className="heading-custom">Edit Procedure</h2>
              )}
              {!procedureId && (
                <h2 className="heading-custom">Add Procedure</h2>
              )}
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>
                Name {FormValidatorService.getRequiredHTML(validationGroup, 'name')}
              </label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'name')}
                value={this.state.name}
                onChange={(event) => {
                  this.setState({ name: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomDropDown
                type={'procedure_specialities'}
                isRequired={true}
                isMulti={false}
                className={FormValidatorService.getClass(validationGroup, this.state, 'speciality', '')}
                selectedValue={this.state?.speciality}
                onChange={(value) => this.setState({ speciality: value })}>
              </CustomDropDown>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>
                CPT Code {FormValidatorService.getRequiredHTML(validationGroup, 'cptcode')}
              </label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'cptcode')}
                value={this.state.cptcode}
                onChange={(event) => {
                  this.setState({ cptcode: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>
                CPT Description {FormValidatorService.getRequiredHTML(validationGroup, 'cptdescription')}
              </label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'cptdescription')}
                value={this.state.cptdescription}
                onChange={(event) => {
                  this.setState({ cptdescription: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>
                Related Dx Code {FormValidatorService.getRequiredHTML(validationGroup, 'relateddxcode')}
              </label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'relateddxcode')}
                value={this.state.related_dx_code}
                onChange={(event) => {
                  this.setState({ related_dx_code: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="filter-footer">
            {procedureId && (
              <button
                onClick={this.submitProcedure}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!procedureId && (
              <button
                onClick={this.submitProcedure}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createProcedure,
      updateProcedure,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageProcedure));
