import React from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchSurgeons } from '../../actions/surgeons';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { SURGEON_LISTING_COLUMNS, APP_ROUTES, RECORDS_PER_PAGE, LOCAL_STORAGE_KEYS } from "../../constants/common";
import SurgeonListingTable from "../atom/SurgeonListingTable";
import { surgeonService } from "../../services/surgeons";
import { authorizationService } from "../../services/authorization";
import { utilityService } from '../../services/utility';
import CustomSelect from '../organisms/CustomSelect';

class SurgeonDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searched: false,
        searchedSurgeons: [],
        checkedSurgeon: [],
        pageSize: RECORDS_PER_PAGE,
        searchedTotalRecords: 0,
    };

    componentWillMount() {
        let tStorageItem = localStorage.getItem(LOCAL_STORAGE_KEYS.SURGEON_FILTERS);
        tStorageItem = tStorageItem ? JSON.parse(tStorageItem) : null;

        this.setState({ loading: true });
        if (tStorageItem) {
            // eslint-disable-next-line
            this.state.currentPage = tStorageItem.page;
            // eslint-disable-next-line
            this.state.pageSize = tStorageItem.page_size;
        }

        this.setState({ loading: true });
        const options = {
            page: this.state.currentPage,
            status: this.state.searchByStatus,
            page_size: this.state.pageSize,
        };
        this.props.fetchSurgeons({ params: options })
            .then(res => {
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    addCheckedItem = (surgeon) => {
        this.setState({},)
        this.setState((prevState) => ({
            checkedSurgeon: [...prevState.checkedSurgeon, surgeon],
        }));
    };

    removeCheckedItem = (surgeon) => {
        this.setState((prevState) => ({
            checkedSurgeon: prevState.checkedSurgeon.filter(
                (isurgeon) => isurgeon !== surgeon
            ),
        }));
    };

    addAllCheckedItem = () => {
        let tSurgeons = this.state.searched && this.state.searchedSurgeons.length > 0 ? this.state.searchedSurgeons
            : this.list()
        let tAllSurgeons = [];
        tSurgeons.forEach(temp => {
            tAllSurgeons.push(temp[0]);
        })

        this.setState((prevState) => ({
            checkedSurgeon: tAllSurgeons
        }));
    }

    removeAllCheckedItem = () => {
        this.setState((prevState) => ({
            checkedSurgeon: []
        }));
    }

    setLoading = (flag) => {
        this.setState({ loading: flag });
    };

    setCurrentPage = (num) => {
        this.setState({ currentPage: num });
    };

    goToPage = (page) => {
        this.setLoading(true);
        this.setCurrentPage(page);

        const options = {
            page: page,
            status: this.state.searchByStatus,
            page_size: this.state.pageSize,
        };
        localStorage.setItem(LOCAL_STORAGE_KEYS.SURGEON_FILTERS, JSON.stringify(options));
        this.props.fetchSurgeons({ params: options })
            .then(res => {
                this.setLoading(false);
            });
    };

    showAdd = () => this.props.history.push(APP_ROUTES.ADD_SURGEON);

    showManageSurgeon = surgeonId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_SURGEON,
        state: {
            surgeonId,
        }
    });

    deleteSurgeon = (surgeon) => {
        surgeonService
            .deleteSurgeon(surgeon)
            .then(response => {
                this.goToPage(1);
            });
    };

    view = surgeonId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_SURGEON,
        state: {
            onlyForView: true,
            surgeonId,
        }
    });

    activate = () => {
        let ids = { 'ids': this.state.checkedSurgeon };
        surgeonService.activateSurgeons(ids).then(temp => {
            utilityService.showHTMLMessage('Activate Surgeon', 'Activated all of the selected surgeon\'s successfully');
            this.goToPage(this.state.currentPage);
        })
    }

    deActivate = () => {
        let ids = { 'ids': this.state.checkedSurgeon };
        surgeonService.inActivateSurgeons(ids).then(temp => {
            utilityService.showHTMLMessage('De-Activate Surgeon', 'De-Activated all of the selected surgeon\'s successfully');
            this.goToPage(this.state.currentPage);
        })
    }

    list = () => {
        const { surgeons = [] } = this.props;
        return surgeons.map(surgeon => {
            let surgeonSuffix = surgeon.suffix ?? '';
            return [
                surgeon.id,
                surgeon.id,
                "".concat(`${surgeon.first_name ? surgeon.first_name : ''} ${surgeon.last_name ? surgeon.last_name : ''}`.toUpperCase(), surgeonSuffix !== '' ? ' - ' + surgeonSuffix + '' : ''),
                surgeon.children ? surgeon.children.license : '',
                surgeon.npi,
                surgeon.children ? surgeon.children.primary_taxonomy : '',
                surgeon.children ? "".concat(surgeon.children.address ? surgeon.children.address + ", " : '', surgeon.children.city ?? '', ' ', surgeon.children.state ?? '', ' ', surgeon.children.zip ?? '') : '',
                surgeon.children ? surgeon.children.fax : '',
                surgeon.status,
                surgeon.id,
            ];
        });
    };

    searchSurgeons = () => {
        const { searchByName } = this.state;
        let options = {};

        if (searchByName) {
            options = { ...options, search: searchByName };
        }
        options = { ...options, page_size: this.state.pageSize };

        this.setState({
            searched: true,
            loading: true
        });
        surgeonService
            .getSurgeons({ params: options })
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedSurgeons: response.data.map(surgeon => {
                        let surgeonSuffix = surgeon.suffix ?? '';
                        return [
                            surgeon.id,
                            surgeon.id,
                            "".concat(`${surgeon.first_name ? surgeon.first_name : ''} ${surgeon.last_name ? surgeon.last_name : ''}`.toUpperCase(), surgeonSuffix !== '' ? ' - ' + surgeonSuffix + '' : ''),
                            surgeon.children ? surgeon.children.license : '',
                            surgeon.npi,
                            surgeon.children ? surgeon.children.primary_taxonomy : '',
                            surgeon.children ? "".concat(surgeon.children.address ? surgeon.children.address + ", " : '', surgeon.children.city ?? '', ' ', surgeon.children.state ?? '', ' ', surgeon.children.zip ?? '') : '',
                            surgeon.children ? surgeon.children.fax : '',
                            surgeon.status,
                            surgeon.id,
                        ];
                    })
                });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    resetSurgeonSearch = () => {
        this.setState({
            searchByName: '',
            searchedSurgeons: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    setPageSize = (value) => {
        this.setState({ pageSize: value }, () => {
            this.goToPage(1);
        });
    };

    render() {
        const {
            searched,
            searchedSurgeons,
            searchedTotalRecords
        } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '755px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Surgeons</h3>
                        </div>
                    </div>
                    <div className="filter-footer-header">

                        {authorizationService.isAddAccess() && (
                            <button
                                onClick={() => { this.showAdd(true) }}
                                className="filter-button">
                                Add New Surgeon
                            </button>
                        )}
                        {authorizationService.isUpdateAccess() && (
                            <>
                                <button
                                    onClick={() => { this.activate() }}
                                    disabled={this.state.checkedSurgeon.length <= 0}
                                    className={this.state.checkedSurgeon.length <= 0 ? "filter-footer-disabled-button" : "filter-button"}>
                                    Active
                                </button>
                                <button
                                    onClick={() => { this.deActivate() }}
                                    disabled={this.state.checkedSurgeon.length <= 0}
                                    className={this.state.checkedSurgeon.length <= 0 ? "filter-footer-disabled-button" : "filter-button"}>
                                    In-Active
                                </button>
                            </>
                        )}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        <div style={{
                            paddingLeft: '0',
                        }} className="col-md-3 mrgn-btm10">
                            <label>Surgeon Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => this.setState({ searchByName: event.target.value })}
                            />
                        </div>
                        <div classname="col-md-3 mrgn-btm10">
                            <CustomSelect type='Active InActive Text'
                                label="Surgeon Status"
                                className="code-input"
                                value={this.state.searchByStatus}
                                onChange={(event) =>
                                    this.setState({ searchByStatus: event.target.value }, () => {
                                        this.goToPage(this.state.currentPage);
                                    })
                                }>
                            </CustomSelect>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                    marginRight: '10px',
                                }}
                                onClick={this.searchSurgeons}
                                className="filter-save-button">
                                Search
                            </button>
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetSurgeonSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <SurgeonListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                edit={this.showManageSurgeon}
                                setPageSize={this.setPageSize}
                                pageSize={this.state.pageSize}
                                delete={this.deleteSurgeon}
                                addCheckedItem={this.addCheckedItem}
                                removeCheckedItem={this.removeCheckedItem}
                                addAllCheckedItem={this.addAllCheckedItem}
                                removeAllCheckedItem={this.removeAllCheckedItem}
                                checkedSurgeon={this.state.checkedSurgeon}
                                view={this.view}
                                headColumn={SURGEON_LISTING_COLUMNS}
                                listing={searched ? searchedSurgeons : this.list()}
                                totalRecords={searched ? searchedTotalRecords : this.props.totalRecords}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        surgeons: state.surgeonReducer.surgeons,
        totalRecords: state.surgeonReducer.totalSurgeon,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchSurgeons,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(SurgeonDashboard));