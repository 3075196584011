import {organizationService} from "../services/organizations";
import {ORGANIZATION} from "../constants/actions";

export const fetchOrganization = options => dispatch => {
    return new Promise((resolve, reject) => {
        organizationService
            .getOrganizations(options)
            .then(response => {
                dispatch({
                    type: ORGANIZATION.FETCH_ORGANIZATION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createOrganization = payload => dispatch => {
    return new Promise((resolve, reject) => {
        organizationService
            .createOrganization(payload)
            .then(response => {
                dispatch({
                    type: ORGANIZATION.CREATE_ORGANIZATION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({message: 'Organization Email already exists'})
            });
    });
};

export const updateOrganization = payload => dispatch => {
    return new Promise((resolve, reject) => {
        organizationService
            .updateOrganization(payload)
            .then(response => {
                dispatch({
                    type: ORGANIZATION.UPDATE_ORGANIZATION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({message: 'Organization Email already exists'})
            });
    });
};