import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "../../../sass/activityfilters.scss";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
} from "../../constants/common";
import DatePicker from "react-datepicker";
import { authorizationService } from "../../services/authorization";
import CustomDropDown from "./CustomDropDown";

class ActivityFilters extends React.Component {
  static propTypes = {
    setFromDate: PropTypes.func.isRequired,
    setToDate: PropTypes.func.isRequired,
    setRegion: PropTypes.func.isRequired,
    setAssistant: PropTypes.func.isRequired,
    setSurgeon: PropTypes.func.isRequired,
    setFacility: PropTypes.func.isRequired,
    setPatient: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    setCreatedBy: PropTypes.func.isRequired,
    setBillOption: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    exportActivities: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cancel = "";
  }

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_ACTIVITY);

  resetFilter = () => {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    this.props.setFromDate("");
    this.props.setToDate("");
    if (loggedInUser.type !== "company") {
      this.props.setRegion({
        value: "",
        label: "Select Region",
      });
    }
    if (loggedInUser.type !== "assistant") {
      this.props.setAssistant({
        value: "",
        label: "Select Assistant",
      });
    }
    this.props.setSurgeon({
      value: "",
      label: "Select Surgeon",
    });
    this.props.setFacility({
      value: "",
      label: "Select Facility",
    });
    this.props.setPatient({
      value: "",
      label: "Select Patient",
    });
    this.props.setCreatedBy({
      value: "",
      label: "Select Created By",
    });
    this.props.setStatus(
      {
        value: "",
        label: "Select Status",
      },
      -1
    );
  };

  render() {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    return (
      <>
        <div className="page-title">
          <div className="title_left">
            <h3
              style={{
                fontFamily:
                  "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "1.471",
              }}
            >
              Activities
            </h3>
          </div>
        </div>
        {authorizationService.isAddAccess() && (
          <div className="filter-footer-header">
            <button
              onClick={() => {
                this.showAdd(true);
              }}
              className="filter-button"
            >
              Add New Activity
            </button>
          </div>
        )}
        <div className="custom-class">
          <div className="filters-column">
            <label>From Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.fromDate}
              onChange={(date) => this.props.setFromDate(date)}
            />
          </div>
          <div className="filters-column">
            <label>To Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.toDate}
              onChange={(date) => this.props.setToDate(date)}
            />
          </div>
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                type={'regions'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.region}
                onChange={(value) => this.props.setRegion(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (!this.props.flipAssistant || this.props.flipAssistant) && (
            <div className="filters-column">
              {this.props?.region?.value ? (
                <CustomDropDown
                  key={this.props.region?.value}
                  type={'regional_assistants'}
                  isRequired={false}
                  isMulti={false}
                  region={this.props.region?.value}
                  selectedValue={this.props?.assistant}
                  onChange={(value) => this.props.setAssistant(value)}>
                </CustomDropDown>
              ) : (
                <CustomDropDown
                  key={this.props.region?.value}
                  type={'assistants'}
                  isRequired={false}
                  isMulti={false}
                  selectedValue={this.props?.assistant}
                  onChange={(value) => this.props.setAssistant(value)}>
                </CustomDropDown>
              )}
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                type={'surgeons'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.surgeon}
                onChange={(value) => this.props.setSurgeon(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                type={'facilities'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.facility}
                onChange={(value) => this.props.setFacility(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                type={'patients'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.patient}
                onChange={(value) => this.props.setPatient(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.createdBy}
                onChange={(value) => this.props.setCreatedBy(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <CustomDropDown
                type={'activites_statuses'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.props?.status}
                onChange={(value) => this.props.setStatus(value)}>
              </CustomDropDown>
            </div>
          )}

          <div className="filters-column">
            <label>Bill Options</label>
            <select
              className="code-input"
              onChange={(event) =>
                this.props.setBillOption({ billOption: event.target.value })
              }
            >
              <option value="">All Activites</option>
              <option value="Billed">Activities With Generated Bills</option>
              <option value="UnBilled">Activities With Not Generated Bills</option>
            </select>
          </div>
          <div className="filter-footer">
            <button
              type="submit"
              onClick={this.props.exportActivities}
              disabled={!this.props?.exportEnabled()}
              className={
                this.props.exportEnabled()
                  ? "filter-footer-button"
                  : "filter-footer-disabled-button"
              }
            >
              Export
            </button>
            <button
              type="submit"
              onClick={this.resetFilter}
              className="filter-footer-button"
            >
              Refresh
            </button>
            <button
              type="submit"
              onClick={this.props.search}
              className="filter-footer-button"
            >
              Search
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ActivityFilters);
