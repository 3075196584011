import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createUser, updateUser } from "../../actions/users";
import { userService } from "../../services/users";
import { utilityService } from "../../services/utility";
import { FormValidatorService } from "../../services/formvalidator";
import CustomDropDown from "../organisms/CustomDropDown";
import CustomSelect from "../organisms/CustomSelect";

const validationGroup = {
  message: "There are some validation errors:",
  fields: {
    'firstName': { filedName: 'First Name', required: true, minLength: 3, maxLength: 200 },
    'lastName': { filedName: 'Last Name', required: true, minLength: 3, maxLength: 200 },
    'email': { filedName: 'Email', required: true, minLength: 3, maxLength: 200 },
    'type': { filedName: 'Type', required: true },
    'practice': { filedName: 'Practice', required: true },
    'groups': { filedName: 'Group', required: true },
    'is_active': { filedName: 'Is Active', required: true },
    'active_start': { filedName: 'Active Start', required: true },
  }
}

class ManageUsers extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    type: "",
    is_active: "",
    practice: [],
    groups: [],
    region: "",
    isAddAccess: false,
    isUpdateAccess: false,
    isDeleteAccess: false,
  };
  componentDidMount() {
    const userId = this.props.location.state
      ? this.props.location.state.userId
      : "";
    if (userId) {
      userService.getUserDetail(userId).then((response) => {
        this.setState({
          firstName: response.data.name,
          lastName: response.data.last_name,
          email: response.data.email,
          type: response.data.type,
          is_active: response.data.is_active,
          active_start: response.data.active_start,
          active_end: response.data.active_end,
          notes: response.data.notes,
          groups: response.data.groups_users.map((group) => {
            return {
              label: group.group_name,
              value: group.id,
            };
          }),
          practice: response.data.practice
            ? response.data.practice.split(",").map((value) => {
              let pname = "";
              // eslint-disable-next-line
              if (value == 1) {
                pname = "BLUE STAR SURGICAL ASSISTANTS LLC";
              }
              // eslint-disable-next-line
              if (value == 2) {
                pname = "UNIVERSAL SURGICAL PARTNERS";
              }
              // eslint-disable-next-line
              if (value == 5) {
                pname = "XCITE SURGICAL LLC";
              }
              // eslint-disable-next-line
              if (value == 7) {
                pname = "Universal Surgical MSO";
              }

              return {
                label: pname,
                value: value,
              };
            })
            : "",

          region: {
            label:
              response.data.region != null ? response.data.region.name : "",
            value:
              response.data.region != null
                ? parseInt(response.data.region.id)
                : "",
          }
        });
      });
    }
  }

  onChangeGroup = (value) => {
    this.setState({ groups: value });
  };

  onChangePractice = (value) => {
    this.setState({ practice: value });
  };

  onChangeRegion = (value) => {
    this.setState({ region: value });
  };

  validateActivity = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  submitUser = () => {
    if (!this.validateActivity()) {
      return;
    }

    const { createUser, updateUser } = this.props;
    const {
      firstName,
      lastName,
      email,
      type,
      practice,
      is_active,
      region,
      isAddAccess,
      isUpdateAccess,
      isDeleteAccess,
      groups,
      active_start,
      active_end,
      notes
    } = this.state;

    const request = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      type: type,
      practice: practice,
      is_active: is_active,
      region: region,
      isAddAccess: isAddAccess,
      isUpdateAccess: isUpdateAccess,
      isDeleteAccess: isDeleteAccess,
      groups: groups,
      active_start: active_start,
      active_end: active_end,
      notes: notes
    };

    const userId = this.props.location.state
      ? this.props.location.state.userId
      : "";
    request.practice = practice.map((practice) => practice.value).join();
    request.region = region.value;

    if (userId) {
      const payload = { ...request, id: userId };
      updateUser(payload)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
            //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          }
        })
    } else {
      createUser(request)
        .then((res) => {
          if (res.response.success) {
            this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
            //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          }
        })
    }
  };

  toggleCheckbox = (name, event) => {
    let obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  };

  render() {
    const userId = this.props.location.state
      ? this.props.location.state.userId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {userId && <h2 className="heading-custom">Edit User</h2>}
              {!userId && <h2 className="heading-custom">Add User</h2>}
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                First Name {FormValidatorService.getRequiredHTML(validationGroup, 'firstName')}
              </label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'firstName')}
                value={this.state.firstName}
                onChange={(event) => {
                  this.setState({ firstName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                Last Name {FormValidatorService.getRequiredHTML(validationGroup, 'lastName')}
              </label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'lastName')}
                value={this.state.lastName}
                onChange={(event) => {
                  this.setState({ lastName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                Email {FormValidatorService.getRequiredHTML(validationGroup, 'email')}
              </label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'email')}
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                User Type {FormValidatorService.getRequiredHTML(validationGroup, 'type')}
              </label>
              <select
                className={FormValidatorService.getClass(validationGroup, this.state, 'type')}
                onChange={(event) =>
                  this.setState({ type: event.target.value })
                }
                value={this.state.type}
              >
                <option value="">Please Select User Type</option>
                <option value="admin">Admin</option>
                <option value="assistant">Surgical Assistant</option>
                <option value="Physician Assistant">Physician Assistant</option>
                <option value="biller">Biller</option>
                <option value="data-entry">Data Entry</option>
                <option value="manager">Manager</option>
              </select>
            </div>

            {this.state.type === "assistant" && (
              <div className="col-md-4 mrgn-btm10">
                <CustomDropDown
                  type={'regions'}
                  isRequired={FormValidatorService.isRequired(validationGroup, 'region')}
                  isMulti={false}
                  selectedValue={this.state.region}
                  onChange={this.onChangeRegion}>
                </CustomDropDown>
              </div>
            )}

            <div className="col-md-4 mrgn-btm10">
              <CustomDropDown
                ref="practice"
                type={'practices'}
                isRequired={FormValidatorService.isRequired(validationGroup, 'practice')}
                isMulti={true}
                selectedValue={this.state.practice}
                onChange={this.onChangePractice}>
              </CustomDropDown>
            </div>

            <div className="col-md-4 mrgn-btm10">
              <CustomDropDown
                ref="groups"
                type={'groups'}
                isRequired={FormValidatorService.isRequired(validationGroup, 'groups')}
                isMulti={true}
                selectedValue={this.state.groups}
                onChange={this.onChangeGroup}>
              </CustomDropDown>
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>
                Active Start {FormValidatorService.getRequiredHTML(validationGroup, 'active_start')}
              </label>
              <input type="date"
                className={FormValidatorService.getClass(validationGroup, this.state, 'active_start')}
                onChange={(event) =>
                  this.setState({ active_start: event.target.value })
                }
                value={this.state.active_start}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>
                Active End {FormValidatorService.getRequiredHTML(validationGroup, 'active_end')}
              </label>
              <input type="date"
                className="code-input"
                onChange={(event) =>
                  this.setState({ active_end: event.target.value })
                }
                value={this.state.active_end}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <CustomSelect type='Status'
                value={this.state.is_active}
                isRequired={FormValidatorService.isRequired(validationGroup, 'is_active')}
                className={FormValidatorService.getClass(validationGroup, this.state, 'is_active')}
                onChange={(event) =>
                  this.setState({ is_active: event.target.value })
                }
              >
              </CustomSelect>
            </div>

            <div className="col-md-12 mrgn-btm10">
              <label>
                Notes {FormValidatorService.getRequiredHTML(validationGroup, 'notes')}
              </label>
              <textarea
                className="code-input"
                onChange={(event) =>
                  this.setState({ notes: event.target.value })
                }
                value={this.state.notes}
              />
            </div>



          </div>
          <div className="filter-footer">
            {userId && (
              <button onClick={this.submitUser} className="filter-save-button">
                Update
              </button>
            )}
            {!userId && (
              <button onClick={this.submitUser} className="filter-save-button">
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.USER_DASHBOARD);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createUser,
      updateUser,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageUsers));
