import {batchService} from "../services/batch";
import {BACTHES} from "../constants/actions";

export const fetchBatches = options => dispatch => {
    return new Promise((resolve, reject) => {
        batchService
            .getBatches(options)
            .then(response => {
                dispatch({
                    type: BACTHES.FETCH_BATCHES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createBatch = payload => dispatch => {
    return new Promise((resolve, reject) => {
        batchService
            .createBatch(payload)
            .then(response => {
                dispatch({
                    type: BACTHES.CREATE_BATCHES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateBatch = payload => dispatch => {
    return new Promise((resolve, reject) => {
        batchService
            .updateBatch(payload)
            .then(response => {
                dispatch({
                    type: BACTHES.UPDATE_BATCHES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createPosting = payload => dispatch => {
    return new Promise((resolve, reject) => {
        batchService
            .createPosting(payload)
            .then(response => {
                dispatch({
                    type: BACTHES.CREATE_POSTING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updatePosting = (payload, id) => dispatch => {
    return new Promise((resolve, reject) => {
        batchService
            .updatePosting(payload, id)
            .then(response => {
                dispatch({
                    type: BACTHES.UPDATE_POSTING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
