import {activityService} from "../services/activities";
import {DASHBOARD} from "../constants/actions";

export const fetchActivitiesForScheduling = options => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .getActivities(options)
            .then(response => {
                dispatch({
                    type: DASHBOARD.FETCH_EVENTS_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};