import { KNOWLEDGE_BASE_CATEGORY } from '../constants/actions';
import { knowledgeBaseCategoryService } from '../services/knowledgeBaseCategories';


export const fetchCategories = options => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseCategoryService
            .getCategories(options)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_CATEGORY.FETCH_CATEGORY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createCategory = payload => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseCategoryService
            .createCategory(payload)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_CATEGORY.CREATE_CATEGORY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateCategory = payload => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseCategoryService
            .updateCategory(payload)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_CATEGORY.UPDATE_CATEGORY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
