export function formatDate(date) {
    if (validDate(date)) {
        date = typeof date === 'string' ? date.replace(/-/g, '\/') : date;  //eslint-disable-line
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if (month === '01' && day === '01' && year === 1970)
            return '';

        return [month, day, year].join('/');
    }
    else {
        return "";
    }
}

export function validDate(date) {
    date = typeof date === 'string' ? date.replace(/-/g, '\/') : date;  //eslint-disable-line
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export function formatDateUI(date) {
    date = typeof date === 'string' ? date.replace(/-/g, '\/') : date;  //eslint-disable-line
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatAMPM(time) {
    if (time) {
        const separatedTime = time.split(':');
        let hours = separatedTime[0];
        let minutes = separatedTime[1];
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
    }
    return '';
}

export function format24Hours(time12h) {
    if (time12h) {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }
    return '';
}