import { CONTRACT } from '../constants/actions';
import { contractService } from '../services/contract';

export const fetchContracts = options => dispatch => {
    return new Promise((resolve, reject) => {
        contractService
            .getContracts(options)
            .then(response => {
                dispatch({
                    type: CONTRACT.FETCH_CONTRACT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createContract = payload => dispatch => {
    return new Promise((resolve, reject) => {
        contractService
            .createContract(payload)
            .then(response => {
                dispatch({
                    type: CONTRACT.CREATE_CONTRACT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateContract = payload => dispatch => {
    return new Promise((resolve, reject) => {
        contractService
            .updateContract(payload)
            .then(response => {
                dispatch({
                    type: CONTRACT.UPDATE_CONTRACT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};