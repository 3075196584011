import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

class FieldView extends React.Component {
    static propTypes = {
       value: PropTypes.string.isRequired
    };

    componentDidMount() {

    }


    render() {
        return (
            <>
                <div class="field-value">{this.props.value}</div>
            </>
        )
    }
}


export default withRouter(FieldView);
