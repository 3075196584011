import React from "react";
import PropTypes from "prop-types";
import {
  faFilePdf,
  faFileUpload,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import UploadDocument from "../atom/UploadDocument";
import "../../../sass/listingtable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePagination from "../organisms/TablePagination";

export default class ScanFlowListingTable extends React.Component {
  static propTypes = {
    checkedBillings: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    allChecked: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    addCheckedItem: PropTypes.func.isRequired,
    addAllCheckedItem: PropTypes.func.isRequired,
    removeAllCheckedItem: PropTypes.func.isRequired,
    removeCheckedItem: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
  };

  state = {
    isDialogOpen: false,
    title: "",
    eventId: "",
    type: "",
  };

  setDialogOpen = (flag) => {
    this.setState({ isDialogOpen: flag });
  };

  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column) => (
      <th
        className="listing-table-head-column"
        style={{
          minWidth: "135px",
        }}
      >
        {column}
      </th>
    ));

    const selectorElement = (
      <th className="listing-table-head-column">
        <input
          type="checkbox"
          id="check-all"
          checked={this.props.allChecked}
          onChange={(event) =>
            event.target.checked
              ? this.props.addAllCheckedItem()
              : this.props.removeAllCheckedItem()
          }
        />
      </th>
    );

    header.splice(0, 0, selectorElement);
    return header;
  };

  renderList = () => {
    const { listing = [] } = this.props;
    const { isDialogOpen } = this.state;
    return listing.map((listRow) => {
      const item = listRow.map((listItem, index) => {
        if (index === 0) {
          return (
            <td className="listing-table-column">
              <input
                type="checkbox"
                id="check-all"
                checked={this.props.checkedBillings.indexOf(listItem) > -1}
                onChange={(event) =>
                  event.target.checked
                    ? this.props.addCheckedItem(listItem)
                    : this.props.removeCheckedItem(listItem)
                }
              />
            </td>
          );
        } else if (index === 1) {
          if (listRow[10] && listRow[10].toLowerCase() === 'hospital contract' && listRow[31] <= 0)
            return (
              <td
                className="listing-table-column"
                style={{
                  minWidth: "135px",
                  padding: "10px",
                  lineHeight: "1.1",
                }}
              >
                <div>
                  <button style={{
                    fontSize: "12px",
                  }} type="button" class="btn btn-small btn-link" onClick={() => this.props.createSingleInvoice(listItem)}>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      size="2x"
                      color={listRow[1].file ? "green" : "green"}

                    />
                    <p> Create Invoice </p>
                  </button>
                </div>
              </td>
            );
          else
            return (
              <td
                className="listing-table-column"
                style={{
                  minWidth: "135px",
                  padding: "10px",
                  lineHeight: "1.1",
                }}
              >
              </td>
            );
        } else if (index === 3) {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              <div
                onClick={() =>
                  this.setState(
                    {
                      eventId: listItem.eventId,
                      type: "facesheet",
                      title: "Facesheet File",
                    },
                    () => {
                      this.setState({ isDialogOpen: !isDialogOpen });
                    }
                  )
                }
                style={{
                  paddingRight: "10px",
                }}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size="3x"
                  color={listRow[1].file ? "#D11516" : "#475f87"}
                />
              </div>
            </td>
          );
        } else if (index === 4) {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              <div
                onClick={() =>
                  this.setState(
                    {
                      eventId: listItem.eventId,
                      type: "op",
                      title: "OP File",
                    },
                    () => {
                      this.setState({ isDialogOpen: !isDialogOpen });
                    }
                  )
                }
              >
                {!listItem.file ? (
                  <FontAwesomeIcon
                    icon={faFileUpload}
                    size="3x"
                    color="#475f87"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    size="3x"
                    color={listItem.file ? "#D11516" : "#475f87"}
                  />
                )}
              </div>
            </td>
          );
        } else if (index === 5) {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              <div
                onClick={() =>
                  this.setState(
                    {
                      eventId: listItem.eventId,
                      type: "correspondence",
                      title: "Insurance File",
                    },
                    () => {
                      this.setState({ isDialogOpen: !isDialogOpen });
                    }
                  )
                }
              >
                {!listItem.file ? (
                  <FontAwesomeIcon
                    icon={faFileUpload}
                    size="3x"
                    color="#475f87"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    size="3x"
                    color={listItem.file ? "#D11516" : "#475f87"}
                  />
                )}
              </div>
            </td>
          );
        } else {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              {listItem}
            </td>
          );
        }
      });
      return <tr className="listing-table-rows">{item}</tr>;
    });
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div
            className="table-responsive"
          >
            {this.state.isDialogOpen && (
              <UploadDocument
                goToPage={this.props.goToPage}
                title={this.state.title}
                isOpen={this.state.isDialogOpen}
                setModal={this.setDialogOpen}
                eventId={this.state.eventId}
                type={this.state.type}
              />
            )}
            <table className="listing-table">
              <thead className="listing-table-head">
                <tr>{this.renderHeadColumn()}</tr>
              </thead>

              <tbody>{!this.props.isLoading && this.renderList()}</tbody>
            </table>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}
