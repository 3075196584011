import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { createBill, updateBill } from "../../actions/billing";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import DatePicker from "react-datepicker";
import { NOTIFICATION_TYPES } from "../../constants/common";
import { APP_ROUTES } from "../../constants/common";

import ServiceLine from "../atom/ServiceLine";
import { billingService } from "../../services/billing";
import { createNotification } from "../../utils/notificationManager";
import { activityService } from "../../services/activities";
import { utilityService } from "../../services/utility";
import CustomDropDown from "../organisms/CustomDropDown";
import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";

const validationGroup = {
  message: "There are some validation errors:",
  fields: {
    'serviceDate': { filedName: 'Service Start Date', required: true },
    'serviceDateEnd': { filedName: 'Service End Date', required: true },
    'surgeon': { filedName: 'Surgeon', required: true },
    'facility': { filedName: 'Facility', required: true },
    'assistant': { filedName: 'Surgical Assistant', required: true },
    'pos': { filedName: 'Place Of Service', required: true },
    'billPayTo': { filedName: 'Pay To', required: true },
  }
}
class ManageBilling extends React.Component {
  static propTypes = {
    createBill: PropTypes.func.isRequired,
    updateBill: PropTypes.func.isRequired,
  };

  state = {
    serviceDate: "",
    serviceDateEnd: "",
    facility: {},
    assistant: {},
    surgeon: {},
    pos: "21",
    billPayTo: "",
    addBillDiagnosis1: "",
    addBillDiagnosis2: "",
    addBillDiagnosis3: "",
    addBillDiagnosis4: "",
    addBillDiagnosis5: "",
    addBillDiagnosis6: "",
    addBillDiagnosis7: "",
    addBillDiagnosis8: "",
    addBillDiagnosis9: "",
    addBillDiagnosis10: "",
    addBillDiagnosis11: "",
    addBillDiagnosis12: "",
    totalBill: 0,
    serviceLines: [],
    loadingCPT: false,
  };

  componentDidMount() {
    let eventId;
    //let billId;
    if (this.props.location.state) {
      eventId = this.props.location.state.eventId;
      //billId = this.props.location.state.billId;
    } else {
      eventId = "";
      //billId = "";
    }
    if (this.props.eventId) {
      eventId = this.props.eventId;
      //billId = this.props.billId;
    }
    if (eventId) {
      activityService.getActivityDetailWithProcedureBillInformation(eventId).then((response) => {
        if (!response.data.event_end_date || response.data.event_end_date === '0000-00-00') {
          utilityService.showHTMLMessage("Event End Date", "Event End Date is not available, cannot generate bill", 'error');
          this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
        }
        else {
          this.setState({
            serviceDate: new Date(response.data.event_start_date),
            pos: response.data.pos_id,
            serviceDateEnd: response.data.event_end_date
              ? new Date(response.data.event_end_date)
              : "",
            facility: response.data.facility
              ? response.data.facility.facility_name
              : "",
            surgeon: response.data.surgeon
              ? `${response.data.surgeon.first_name} ${response.data.surgeon.last_name}`
              : "",
            assistant: response.data.assistant
              ? `${response.data.assistant.name} ${response.data.assistant.last_name}`
              : "",
            billPayTo: {
              label: response.data.billpayto
                ? response.data.billpayto.paytoname
                : "",
              value: response.data.billpayto ? response.data.billpayto.id : "",
            },
            serviceLines: response.data.line_items
          }, () => {
            this.calculateBill()
          });
        }
      });
    }
  }

  addServiceLine = () => {
    const serviceLine = {
      line_number: this.state.serviceLines.length + 1,
      line_start_date: this.state.serviceDate,
      line_end_date: this.state.serviceDateEnd,
      hcpcs_code: "",
      modifier1: "",
      modifier2: "",
      modifier3: "",
      modifier4: "",
      units: "",
      line_total: 0,
      line_discount: "",
      code_description: "",
      points_to_diagnosis: "",
      revenue_code: "",
    };
    this.setState(
      (prevState) => ({
        serviceLines: [...prevState.serviceLines, serviceLine],
      }),
      () => this.calculateBill()
    );
  };

  renderServiceLines = () => {
    const { serviceLines } = this.state;
    return serviceLines.map((serviceLine) => (
      <ServiceLine
        procedurename={serviceLine.procedure_name}
        lineNumber={serviceLine.line_number}
        cpt={serviceLine.cpt_code}
        cptdescription={serviceLine.cpt_description}
        dxcode={serviceLine.dx_code}
        total={serviceLine.rate}
        removeServiceLine={this.removeServiceLine}
        setServiceLineAttribute={this.setServiceLineAttribute}
      />
    ));
  };

  setServiceLineAttribute = (lineNumber, key, value) => {
    const { serviceLines } = this.state;

    const updatedServiceLines = serviceLines.map((serviceLine) => {
      if (serviceLine.line_number === lineNumber) {
        serviceLine[key] = value;
        if (key === "hcpcs_code" && value) {
          this.setState({ loadingCPT: true });
          billingService
            .getCPTFEE(value)
            .then((response) => {
              serviceLine["line_total"] = parseInt(
                response.data.fee_amount ? response.data.fee_amount : 0
              );
              this.setState({ loadingCPT: false }, () => this.calculateBill());
            })
            .catch((error) => {
              this.setState({ loadingCPT: false }, () => this.calculateBill());
            });
        }
      }
      return serviceLine;
    });

    this.setState({ serviceLines: updatedServiceLines }, () =>
      this.calculateBill()
    );
  };

  removeServiceLine = (lineNumber) => {
    const { serviceLines } = this.state;
    const filteredServiceLines = serviceLines.filter(
      (serviceLine) => serviceLine.line_number !== lineNumber
    );
    this.setState({ serviceLines: filteredServiceLines }, () =>
      this.calculateBill()
    );
  };

  calculateBill = () => {
    const { serviceLines } = this.state;
    let bill = 0;
    if (serviceLines != null) {
      serviceLines.map((serviceLine) => {                 //eslint-disable-line
        bill = bill + parseInt(serviceLine.rate);
      });
    }

    this.setState({ totalBill: bill });
  };

  validateBill = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  submit = () => {
    if (!this.validateBill()) {
      return;
    }

    let eventId;
    let billId;
    if (this.props.location.state) {
      eventId = this.props.location.state.eventId;
      billId = this.props.location.state.billId;
    } else {
      eventId = "";
      billId = "";
    }
    if (this.props.eventId) {
      eventId = this.props.eventId;
      billId = this.props.billId;
    }
    const { createBill, updateBill } = this.props;

    const {
      serviceDate,
      serviceDateEnd,
      pos,
      billPayTo,
      addBillDiagnosis1,
      addBillDiagnosis2,
      addBillDiagnosis3,
      addBillDiagnosis4,
      addBillDiagnosis5,
      addBillDiagnosis6,
      addBillDiagnosis7,
      addBillDiagnosis8,
      addBillDiagnosis9,
      addBillDiagnosis10,
      addBillDiagnosis11,
      addBillDiagnosis12,
      totalBill,
      serviceLines,
    } = this.state;

    const request = {
      event_id: eventId,
      service_date: serviceDate,
      service_date_end: serviceDateEnd,
      pos_select: pos,
      bill_pay_to_select: billPayTo.value,
      add_bill_diagnosis1: addBillDiagnosis1,
      add_bill_diagnosis2: addBillDiagnosis2,
      add_bill_diagnosis3: addBillDiagnosis3,
      add_bill_diagnosis4: addBillDiagnosis4,
      add_bill_diagnosis5: addBillDiagnosis5,
      add_bill_diagnosis6: addBillDiagnosis6,
      add_bill_diagnosis7: addBillDiagnosis7,
      add_bill_diagnosis8: addBillDiagnosis8,
      add_bill_diagnosis9: addBillDiagnosis9,
      add_bill_diagnosis10: addBillDiagnosis10,
      add_bill_diagnosis11: addBillDiagnosis11,
      add_bill_diagnosis12: addBillDiagnosis12,
      total_bill: totalBill,
      service_lines: serviceLines,
    };

    if (billId) {
      updateBill({ ...request, id: billId })
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
            }
          } else {
            createNotification(
              NOTIFICATION_TYPES.ERROR,
              `Unable to update bill ${res.response.message}`
            );
          }
        })
    } else {
      createBill(request)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
            }
          } else {
            createNotification(
              NOTIFICATION_TYPES.ERROR,
              `Unable to create bill ${res.response.message} ${JSON.stringify(
                res.response.data
              )}`
            );
          }
        })
    }
  };

  cancel = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
    }
  };

  render() {
    const billId = this.props.location.state
      ? this.props.location.state.billId
      : "";
    return (
      <div className="main_container">
        {this.props.PopupRequest === undefined && (
          <>
            <div
              className="col-md-3 custom-sidebar-menu left_col"
              style={{
                minHeight: "100vh",
              }}
            >
              <Sidebar activePage={this.props.activePage} />
            </div>
            <TopNavbar />
          </>
        )}
        <div
          className={
            this.props.PopupRequest === 1 ? "" : "manage-activity-right-col"
          }
        >
          <div className="activity-row">
            <div className="page-title">
              {billId && <h2 className="heading-custom">Edit Bill</h2>}
              {!billId && <h2 className="heading-custom">Add Bill</h2>}
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Service Date Start {FormValidatorService.getRequiredHTML(validationGroup, 'serviceDate')}</label>
              <br />
              <DatePicker
                isClearable
                selected={this.state.serviceDate}
                onChange={(date) => this.setState({ serviceDate: date })}
                className={FormValidatorService.getClass(validationGroup, this.state, 'serviceDate')}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Service Date End {FormValidatorService.getRequiredHTML(validationGroup, 'serviceDateEnd')}</label>
              <br />
              <DatePicker
                isClearable
                selected={this.state.serviceDateEnd}
                onChange={(date) => this.setState({ serviceDateEnd: date })}
                className={FormValidatorService.getClass(validationGroup, this.state, 'serviceDateEnd')}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Facility {FormValidatorService.getRequiredHTML(validationGroup, 'facility')}</label>
              <input
                disabled
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'facility')}
                value={this.state.facility}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Surgical Assistant {FormValidatorService.getRequiredHTML(validationGroup, 'assistant')}</label>
              <input
                disabled
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'assistant')}
                value={this.state.assistant}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Referring Provider {FormValidatorService.getRequiredHTML(validationGroup, 'surgeon')}</label>
              <input
                disabled
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, 'surgeon')}
                value={this.state.surgeon}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomSelect type='Place Of Service'
                isRequired={FormValidatorService.isRequired(validationGroup, 'pos')}
                value={this.state.pos}
                className={FormValidatorService.getClass(validationGroup, this.state, 'pos')}
                onChange={(event) => this.setState({ pos: event.target.value })}
              >
              </CustomSelect>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomDropDown
                label={'Pay to'}
                type={'practices'}
                isRequired={FormValidatorService.isRequired(validationGroup, 'billPayTo')}
                isMulti={false}
                selectedValue={this.state?.billPayTo}
                className={FormValidatorService.getClass(validationGroup, this.state, 'billPayTo', '')}
                onChange={(value) => this.setState({ billPayTo: value })}>
              </CustomDropDown>
            </div>
            <div className="page-title">
              <h2 className="heading-custom">Service Lines</h2>
            </div>
            {/* <div className="col-md-12 mrgn-btm10">
              <button
                onClick={this.addServiceLine}
                className="filter-save-button"
              >
                Add Service Line
              </button>
            </div> */}
            {this.state.serviceLines && this.renderServiceLines()}
            <div className="page-title">
              <h2 className="heading-custom">Total Bill</h2>
            </div>
            <div className="col-md-12 mrgn-btm10">
              <input
                disabled
                type="text"
                className="code-input"
                value={this.state.totalBill}
              />
            </div>
            <div className="filter-footer">
              <button onClick={this.submit} className="filter-save-button">
                Submit
              </button>
              {this.props.PopupRequest === undefined && (
                <button onClick={this.cancel} className="filter-cancel-button">
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createBill,
      updateBill,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageBilling));
