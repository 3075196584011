import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { APP_ROUTES } from "../../constants/common";
import loaderImage from "../../../assets/images/CoureMax.gif";
import LoadingOverlay from "react-loading-overlay";
import carrier from "../../../assets/images/carrier.png";
import patient from "../../../assets/images/patient.png";
import physician from "../../../assets/images/physician.png";
import qrCode from "../../../assets/images/qrcode.png";
import { activityService } from "../../services/activities";
import { utilityService } from "../../services/utility";

class ClaimForm extends React.Component {
  state = {
    width: "100%",
    insuredID: "",
    patientName: "",
    patientDOB: "",
    patientGender: "",
    insuredName: "",
    patientAddress: "",
    relationWithInsured: "",
    insuredAddress: "",
    patientCity: "",
    patientState: "",
    patientZip: "",
    patientPhone: "",
    insuredCity: "",
    insuredState: "",
    insuredZip: "",
    insuredPhone: "",
    otherInsuredNumber: "",
    otherInsuredName: "",
    insuredPolicyNumber: "",
    insuredPolicyName: "",
    insuredDOB: "",
    insuredGender: "",
    otherClaimId: "",
    claimCodes: [],
    patientCondition: "",
    injuryDate: "",
    hospitalizationDateStart: "",
    hospitalizationDateEnd: "",
    surgeon: "",
    npi: "",

    patientAccount: "",
    facility: "",
    facilityNPI: "",

    diagnosis1: "",
    diagnosis2: "",
    diagnosis3: "",
    diagnosis4: "",
    diagnosis5: "",
    diagnosis6: "",
    diagnosis7: "",
    diagnosis8: "",

    bill: {},
    totalBill: "",
    serviceLines: [],
    primaryInsuranceDesc: "",
  };

  componentDidMount() {
    const eventId = this.props.location.state
      ? this.props.location.state.eventId
      : "";
    const insuranceType = this.props.location.state
      ? this.props.location.state.insuranceType
      : "";
    if (eventId) {
      this.setState({ loading: true }, () => {
        activityService.getActivityDetail(eventId).then((response) => {
          let insurance, subscriber;
          if (insuranceType === "primary") {
            insurance =
              response.data &&
                response.data.patient &&
                response.data.patient.patient_insurance_data
                ? response.data.patient.patient_insurance_data.primary
                : {};
            subscriber =
              response.data &&
                response.data.patient &&
                response.data.patient.patient_insurance_data
                ? response.data.patient.patient_insurance_data
                  .primary_subscriber
                : {};
          } else if (insuranceType === "secondary") {
            insurance =
              response.data &&
                response.data.patient &&
                response.data.patient.patient_insurance_data
                ? response.data.patient.patient_insurance_data.secondary
                : {};
            subscriber =
              response.data &&
                response.data.patient &&
                response.data.patient.patient_insurance_data
                ? response.data.patient.patient_insurance_data
                  .secondary_subscriber
                : {};
          } else if (insuranceType === "tertiary") {
            insurance =
              response.data &&
                response.data.patient &&
                response.data.patient.patient_insurance_data
                ? response.data.patient.patient_insurance_data.tertiary
                : {};
            subscriber =
              response.data &&
                response.data.patient &&
                response.data.patient.patient_insurance_data
                ? response.data.patient.patient_insurance_data
                  .tertiary_subscriber
                : {};
          }
          this.setState(
            {
              patientName:
                response.data && response.data.patient
                  ? `${response.data.patient.last_name || ""}, ${response.data.patient.first_name || ""
                  } `
                  : "",
              patientDOB:
                response.data && response.data.patient
                  ? response.data.patient.date_of_birth
                  : "",
              patientGender:
                response.data && response.data.patient
                  ? response.data.patient.gender
                  : "",
              patientAddress:
                response.data && response.data.patient
                  ? `${response.data.patient.address1 || ""} ${response.data.patient.address2 || ""
                  }`
                  : "",
              patientCity:
                response.data && response.data.patient
                  ? response.data.patient.city || ""
                  : "",
              patientState:
                response.data && response.data.patient
                  ? response.data.patient.state || ""
                  : "",
              patientZip:
                response.data && response.data.patient
                  ? response.data.patient.zip || ""
                  : "",
              patientPhone:
                response.data && response.data.patient
                  ? response.data.patient.phone_number || ""
                  : "",
              insuredName: subscriber
                ? `${subscriber.subscriber_last_name || ""}, ${subscriber.subscriber_first_name || ""
                } `
                : "",
              insuredAddress: insurance ? `${insurance.address1 || ""}` : "",
              insuredCity: insurance ? `${insurance.city || ""}` : "",
              insuredState: insurance ? `${insurance.state || ""}` : "",
              insuredZip: insurance ? `${insurance.zip || ""}` : "",
              insuredPhone: insurance
                ? `${utilityService.formatPhone(insurance.phone) || ""}`
                : "",
              insuredDOB: subscriber ? subscriber.subscriber_dob || "" : "",
              insuredPolicyNumber: subscriber
                ? subscriber.subscriber_group_number || ""
                : "",
              insuredPolicyName: insurance
                ? `${insurance.description || ""}`
                : "",
              primaryInsuranceDesc:
                response.data &&
                  response.data.patient &&
                  response.data.patient.patient_insurance_data &&
                  response.data.patient.patient_insurance_data.primary
                  ? response.data.patient.patient_insurance_data.primary
                    .description
                  : "",
              patientCondition:
                response.data &&
                  response.data.patient &&
                  response.data.patient.patient_insurance_data &&
                  response.data.patient.patient_insurance_data.workers_comp
                  ? `${response.data.patient.patient_insurance_data.workers_comp
                    .patient_condition || ""
                  }`
                  : "",
              injuryDate:
                response.data &&
                  response.data.patient &&
                  response.data.patient.patient_insurance_data &&
                  response.data.patient.patient_insurance_data.workers_comp
                  ? `${response.data.patient.patient_insurance_data.workers_comp
                    .date_of_injury || ""
                  }`
                  : "",
              hospitalizationDateStart:
                response.data && response.data.admission_date
                  ? response.data.admission_date
                  : "",
              surgeon:
                response.data && response.data.surgeon
                  ? `${response.data.surgeon.first_name || ""} ${response.data.surgeon.last_name || ""
                  } `
                  : "",
              npi:
                response.data && response.data.surgeon
                  ? response.data.surgeon.npi || ""
                  : "",
              patientAccount:
                response.data && response.data.patient
                  ? response.data.patient.account_number || ""
                  : "",
              facility:
                response.data && response.data.facility
                  ? `${response.data.facility.facility_name || ""} ${response.data.facility.address || ""
                  } `
                  : "",
              facilityNPI:
                response.data && response.data.facility
                  ? response.data.facility.npi || ""
                  : "",
              diagnosis1:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis1 || ""
                  : "",
              diagnosis2:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis2 || ""
                  : "",
              diagnosis3:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis3 || ""
                  : "",
              diagnosis4:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis4 || ""
                  : "",
              diagnosis5:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis5 || ""
                  : "",
              diagnosis6:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis6 || ""
                  : "",
              diagnosis7:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis7 || ""
                  : "",
              diagnosis8:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis8 || ""
                  : "",
              diagnosis9:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis9 || ""
                  : "",
              diagnosis10:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis10 || ""
                  : "",
              diagnosis11:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis11 || ""
                  : "",
              diagnosis12:
                response.data && response.data.bill
                  ? response.data.bill.add_bill_diagnosis12 || ""
                  : "",
              totalBill:
                response.data && response.data.bill
                  ? response.data.bill.total_bill || ""
                  : "",
              serviceLines:
                response.data && response.data.bill
                  ? response.data.bill.service_lines || ""
                  : "",
              bill:
                response.data && response.data.bill
                  ? response.data.bill || ""
                  : "",
            }
          );
        }).finally(() => {
          this.setState({ loading: false });
        })
      });
    }
  }

  cancel = () => {
    this.props.history.push({
      pathname: APP_ROUTES.CLAIMS_AND_COLLECTION,
    });
  };

  send = () => {
    this.setState(
      {
        loading: true,
        width: "210mm",
      },
      () => {
        html2canvas(document.querySelector("#invoice")).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
          pdf.save("form.pdf");
          this.setState({
            width: "100%",
            loading: false,
          });
        });
      }
    );
  };

  render() {
    const {
      patientDOB,
      insuredDOB,
      injuryDate,
      hospitalizationDateStart,
      hospitalizationDateEnd,
      serviceLines,
      bill,
    } = this.state;
    const patDob = patientDOB.split("-");
    const insuredDob = insuredDOB.split("/");
    const injDate = injuryDate.split("/");
    const hospDateStart = hospitalizationDateStart.split("/");
    const hospDateEnd = hospitalizationDateEnd.split("-");
    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner={
          <img
            style={{
              width: "200px",
            }}
            src={loaderImage}
            alt="loading..."
          />
        }
      >
        <div className="main_container">
          <div
            className="col-md-3 custom-sidebar-menu left_col"
            style={{
              minHeight: "100vh",
            }}
          >
            <Sidebar activePage={this.props.activePage} />
          </div>
          <TopNavbar />
          <div
            style={{
              minHeight: "830px",
              WebkitBoxSizing: "border-box",
              boxSizing: "border-box",
              padding: 0,
              fontSize: "8px",
              textTransform: "uppercase",
              margin: 0,
              fontFamily: "Arial, Helvetica, sans-serif",
            }}
            className="right-col"
          >
            <div
              style={{
                borderBottom: "3px solid #4c638c",
              }}
              className="filter-footer-claim"
            >
              <button
                type="submit"
                onClick={this.send}
                className="filter-footer-button"
              >
                Export
              </button>
              <button type="submit" className="filter-footer-button">
                Send Claim
              </button>
              <button
                type="submit"
                onClick={this.cancel}
                className="filter-footer-button"
              >
                Cancel
              </button>
            </div>
            <div
              id="invoice"
              className="claim-form"
              style={{
                WebkitBoxSizing: "border-box",
                boxSizing: "border-box",
                padding: "20px",
                margin: 0,
                width: this.state.width,
                minHeight: "297mm",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              <div
                className="form-wrapper"
                style={{
                  WebkitBoxSizing: "border-box",
                  boxSizing: "border-box",
                  margin: 0,
                  padding: 0,
                }}
              >
                <div
                  className="form-header"
                  style={{
                    WebkitBoxSizing: "border-box",
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <div
                    className="flex jcsb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxPack: "justify",
                      msFlexPack: "justify",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <div
                        className="qr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          height: "48px",
                          width: "48px",
                          backgroundImage: `url(${qrCode})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          marginLeft: "4px",
                          marginBottom: "8px",
                        }}
                      />
                      <h1
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontSize: "0.7rem",
                        }}
                      >
                        Health Insurance Claim Form
                      </h1>
                      <div
                        className="gap"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          height: "4px",
                        }}
                      />
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Approved by national uniform claim committee (NUCC)
                        02/12
                      </p>
                      <div
                        className="gap"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          height: "4px",
                        }}
                      />
                    </div>
                    <div
                      className="mono pt pr pb"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        fontFamily: '"Courier New", Courier, monospace',
                        fontSize: "0.7rem",
                        paddingRight: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Ambetter
                      </p>
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        PO BOX 5010
                      </p>
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Farmington MO 53640-5010
                      </p>
                    </div>
                    <img
                      style={{
                        marginRight: "-17px",
                        marginBottom: "-15px",
                      }}
                      src={carrier}
                      alt="edit"
                    />
                  </div>
                  <div
                    className="flex jcsb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxPack: "justify",
                      msFlexPack: "justify",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                      }}
                    >
                      <div
                        className="squares small"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "block",
                            border: "2px solid #000",
                            height: "12px",
                            width: "12px",
                            borderWidth: "1px",
                          }}
                        />
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "block",
                            border: "2px solid #000",
                            height: "12px",
                            width: "12px",
                            borderWidth: "1px",
                            marginLeft: "-1px",
                          }}
                        />
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "block",
                            border: "2px solid #000",
                            height: "12px",
                            width: "12px",
                            borderWidth: "1px",
                            marginLeft: "-1px",
                          }}
                        />
                      </div>
                      <span
                        className="pl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingLeft: "4px",
                        }}
                      >
                        PICA
                      </span>
                    </div>
                    <div
                      className="flex"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                      }}
                    >
                      <span
                        className="pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                        }}
                      >
                        PICA
                      </span>
                      <div
                        className="squares small"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "block",
                            border: "2px solid #000",
                            height: "12px",
                            width: "12px",
                            borderWidth: "1px",
                          }}
                        />
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "block",
                            border: "2px solid #000",
                            height: "12px",
                            width: "12px",
                            borderWidth: "1px",
                            marginLeft: "-1px",
                          }}
                        />
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "block",
                            border: "2px solid #000",
                            height: "12px",
                            width: "12px",
                            borderWidth: "1px",
                            marginLeft: "-1px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="thicc"
                  style={{
                    WebkitBoxSizing: "border-box",
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                    borderBottom: "4px solid #000",
                  }}
                />
                <div
                  className="form-body"
                  style={{
                    WebkitBoxSizing: "border-box",
                    boxSizing: "border-box",
                    margin: 0,
                    padding: "0",
                    float: "left",
                    display: "grid",
                    msGridColumns: "(1fr)[32]",
                    border: "2px solid rgb(0, 0, 0)",
                    gridTemplateColumns: "repeat(32, 1fr)",
                  }}
                >
                  {/* ROW #1 */}
                  <div
                    className="left-half br bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: "3px",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="flex aife jcsb pt pr pl pb"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: "3px",
                        display: "flex",
                        WebkitBoxAlign: "end",
                        msFlexAlign: "end",
                        alignItems: "flex-end",
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <div
                        className="flex aife"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxAlign: "end",
                          msFlexAlign: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Medicare
                          </p>
                          <p
                            className="c i"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                              fontStyle: "italic",
                            }}
                          >
                            (Medicare#)
                          </p>
                        </div>
                      </div>
                      <div
                        className="flex pl aife"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxAlign: "end",
                          msFlexAlign: "end",
                          alignItems: "flex-end",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Medicaid
                          </p>
                          <p
                            className="c i"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                              fontStyle: "italic",
                            }}
                          >
                            (Medicaid#)
                          </p>
                        </div>
                      </div>
                      <div
                        className="flex pl aife"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxAlign: "end",
                          msFlexAlign: "end",
                          alignItems: "flex-end",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Tricars
                          </p>
                          <p
                            className="c i"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                              fontStyle: "italic",
                            }}
                          >
                            (ID#/DoD#)
                          </p>
                        </div>
                      </div>
                      <div
                        className="flex pl aife"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxAlign: "end",
                          msFlexAlign: "end",
                          alignItems: "flex-end",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Champva
                          </p>
                          <p
                            className="c i"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                              fontStyle: "italic",
                            }}
                          >
                            (Member ID#)
                          </p>
                        </div>
                      </div>
                      <div
                        className="flex pl aife"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxAlign: "end",
                          msFlexAlign: "end",
                          alignItems: "flex-end",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Group Health
                            <br
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                              }}
                            />
                            Plan
                          </p>
                          <p
                            className="c i"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                              fontStyle: "italic",
                            }}
                          >
                            (ID#)
                          </p>
                        </div>
                      </div>
                      <div
                        className="flex pl aife"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxAlign: "end",
                          msFlexAlign: "end",
                          alignItems: "flex-end",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            FECA
                            <br
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                              }}
                            />
                            BLK LUNG
                          </p>
                          <p
                            className="c i"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                              fontStyle: "italic",
                            }}
                          >
                            (ID#)
                          </p>
                        </div>
                      </div>
                      <div
                        className="flex pl aife"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxAlign: "end",
                          msFlexAlign: "end",
                          alignItems: "flex-end",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            OTHER
                          </p>
                          <p
                            className="c i"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                              fontStyle: "italic",
                            }}
                          >
                            (ID#)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: "3px",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pt pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <div
                        className="flex jcsb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxPack: "justify",
                          msFlexPack: "justify",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          1
                          <span
                            className="c"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            a
                          </span>
                          . Insured's I.D. Number
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          (For Program in Item 1)
                        </p>
                      </div>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                        }}
                      >
                        U9317758901
                      </p>
                    </div>
                  </div>
                  {/* ROW #2 */}
                  <div
                    className="left-half bb br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                      }}
                    >
                      <div
                        className="left-half pt pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          2. Patient's name{" "}
                          <span
                            className="c"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            (Last Name, First Name, Middle Initial)
                          </span>
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          {this.state.patientName}
                        </p>
                      </div>
                      <div
                        className="left-half-end flex pt pr pl bl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                        }}
                      >
                        <div
                          className="pr"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            3. Patient's Birth Date
                          </p>
                          <div
                            className="flex jcc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxPack: "center",
                              msFlexPack: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="pr center"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textAlign: "center",
                                paddingRight: "4px",
                              }}
                            >
                              <p
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                MM
                              </p>
                              <p
                                className="mono"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  fontFamily:
                                    '"Courier New", Courier, monospace',
                                  fontSize: "0.7rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {`${patDob[1]} |`}
                              </p>
                            </div>
                            <div
                              className="pr center pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textAlign: "center",
                                paddingRight: "4px",
                                paddingLeft: "4px",
                              }}
                            >
                              <p
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                DD
                              </p>
                              <p
                                className="mono"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  fontFamily:
                                    '"Courier New", Courier, monospace',
                                  fontSize: "0.7rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {`${patDob[2]} |`}
                              </p>
                            </div>
                            <div
                              className="pr center pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textAlign: "center",
                                paddingRight: "4px",
                                paddingLeft: "4px",
                              }}
                            >
                              <p
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                YYYY
                              </p>
                              <p
                                className="mono"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  fontFamily:
                                    '"Courier New", Courier, monospace',
                                  fontSize: "0.7rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {patDob[0]}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex fdc center pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxOrient: "vertical",
                            WebkitBoxDirection: "normal",
                            msFlexDirection: "column",
                            flexDirection: "column",
                            textAlign: "center",
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Sex
                          </p>
                          <div
                            className="flex"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              className="flex aic pt pr"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxAlign: "center",
                                msFlexAlign: "center",
                                alignItems: "center",
                                paddingRight: "4px",
                                paddingTop: "4px",
                              }}
                            >
                              <span
                                className="pr sm"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  paddingRight: "2px",
                                }}
                              >
                                M
                              </span>
                              <div
                                className="squares"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "flex",
                                  background:
                                    this.state.patientGender === "Male"
                                      ? "#000"
                                      : "#fff",
                                }}
                              >
                                <div
                                  style={{
                                    WebkitBoxSizing: "border-box",
                                    boxSizing: "border-box",
                                    margin: 0,
                                    padding: 0,
                                    display: "block",
                                    height: "14px",
                                    width: "14px",
                                    border: "2px solid #000",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="flex aic pt pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxAlign: "center",
                                msFlexAlign: "center",
                                alignItems: "center",
                                paddingLeft: "4px",
                                paddingTop: "4px",
                              }}
                            >
                              <span
                                className="pr sm"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  paddingRight: "2px",
                                }}
                              >
                                F
                              </span>
                              <div
                                className="squares"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "flex",
                                  background:
                                    this.state.patientGender === "Female"
                                      ? "#000"
                                      : "#fff",
                                }}
                              >
                                <div
                                  style={{
                                    WebkitBoxSizing: "border-box",
                                    boxSizing: "border-box",
                                    margin: 0,
                                    padding: 0,
                                    display: "block",
                                    height: "14px",
                                    width: "14px",
                                    border: "2px solid #000",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pt pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        4. Insured's name{" "}
                        <span
                          className="c"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          (Last Name, First Name, Middle Initial)
                        </span>
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                        }}
                      >
                        {this.state.insuredName}
                      </p>
                    </div>
                  </div>
                  {/* ROW #3 */}
                  <div
                    className="left-half bb br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                      }}
                    >
                      <div
                        className="left-half pt pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          5. Patient's Address{" "}
                          <span
                            className="c"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            (No., Street
                          </span>
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          {this.state.patientAddress}
                        </p>
                      </div>
                      <div
                        className="left-half-end flex pt pr pl bl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                        }}
                      >
                        <div
                          className="flex fdc center pl pb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxOrient: "vertical",
                            WebkitBoxDirection: "normal",
                            msFlexDirection: "column",
                            flexDirection: "column",
                            textAlign: "center",
                            paddingLeft: "4px",
                            paddingBottom: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            6. Patient's Relationship to insured
                          </p>
                          <div
                            className="flex"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              className="flex aic pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxAlign: "center",
                                msFlexAlign: "center",
                                alignItems: "center",
                                paddingTop: "4px",
                              }}
                            >
                              <span
                                className="pr sm c"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  paddingRight: "2px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Self
                              </span>
                              <div
                                className="squares"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    WebkitBoxSizing: "border-box",
                                    boxSizing: "border-box",
                                    margin: 0,
                                    padding: 0,
                                    display: "block",
                                    height: "14px",
                                    width: "14px",
                                    border: "2px solid #000",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="flex aic pt pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxAlign: "center",
                                msFlexAlign: "center",
                                alignItems: "center",
                                paddingLeft: "4px",
                                paddingTop: "4px",
                              }}
                            >
                              <span
                                className="pr sm c"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  paddingRight: "2px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Spouse
                              </span>
                              <div
                                className="squares"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    WebkitBoxSizing: "border-box",
                                    boxSizing: "border-box",
                                    margin: 0,
                                    padding: 0,
                                    display: "block",
                                    height: "14px",
                                    width: "14px",
                                    border: "2px solid #000",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="flex aic pt pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxAlign: "center",
                                msFlexAlign: "center",
                                alignItems: "center",
                                paddingLeft: "4px",
                                paddingTop: "4px",
                              }}
                            >
                              <span
                                className="pr sm c"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  paddingRight: "2px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Child
                              </span>
                              <div
                                className="squares"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    WebkitBoxSizing: "border-box",
                                    boxSizing: "border-box",
                                    margin: 0,
                                    padding: 0,
                                    display: "block",
                                    height: "14px",
                                    width: "14px",
                                    border: "2px solid #000",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="flex aic pt pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxAlign: "center",
                                msFlexAlign: "center",
                                alignItems: "center",
                                paddingLeft: "4px",
                                paddingTop: "4px",
                              }}
                            >
                              <span
                                className="pr sm c"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  paddingRight: "2px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Other
                              </span>
                              <div
                                className="squares"
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    WebkitBoxSizing: "border-box",
                                    boxSizing: "border-box",
                                    margin: 0,
                                    padding: 0,
                                    display: "block",
                                    height: "14px",
                                    width: "14px",
                                    border: "2px solid #000",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pt pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        7. Insured's Address{" "}
                        <span
                          className="c"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          (No., Street)
                        </span>
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                        }}
                      >
                        {this.state.insuredAddress}
                      </p>
                    </div>
                  </div>
                  {/* ROW #4 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                      }}
                    >
                      <div
                        className="left-half bb flex jcsb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxPack: "justify",
                          msFlexPack: "justify",
                          justifyContent: "space-between",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <div
                          className="pl pt pr"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            paddingTop: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            city
                          </p>
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {this.state.patientCity}
                          </p>
                        </div>
                        <div
                          className="bl pl pr pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            paddingTop: "4px",
                            borderLeft: "2px solid #000",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            state
                          </p>
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {this.state.patientState}
                          </p>
                        </div>
                      </div>
                      <div
                        className="left-half-end bl pl pt pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          8. reserved for nucc use
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb flex jcsb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxPack: "justify",
                      msFlexPack: "justify",
                      justifyContent: "space-between",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pl pt pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        city
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {this.state.insuredCity}
                      </p>
                    </div>
                    <div
                      className="bl pl pr pt"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        borderLeft: "2px solid #000",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        state
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {this.state.insuredState}
                      </p>
                    </div>
                  </div>
                  {/* ROW #5 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                      }}
                    >
                      <div
                        className="left-half bb flex jcsb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxPack: "justify",
                          msFlexPack: "justify",
                          justifyContent: "space-between",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <div
                          className="pl pt pr"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            paddingTop: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            zip code
                          </p>
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {this.state.patientZip}
                          </p>
                        </div>
                        <div
                          className="bl pl pr pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            paddingTop: "4px",
                            borderLeft: "2px solid #000",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            telephone{" "}
                            <span
                              className="c"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textTransform: "capitalize",
                              }}
                            >
                              (include area code)
                            </span>
                          </p>
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            ( ) {this.state.patientPhone}
                          </p>
                        </div>
                      </div>
                      <div
                        className="left-half-end bb bl pl pt pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb flex jcsb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxPack: "justify",
                      msFlexPack: "justify",
                      justifyContent: "space-between",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pl pt pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        zip code
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {this.state.insuredZip}
                      </p>
                    </div>
                    <div
                      className="bl pl pr pt"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        borderLeft: "2px solid #000",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        telephone{" "}
                        <span
                          className="c"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          (include area code)
                        </span>
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        ( ) {this.state.insuredPhone}
                      </p>
                    </div>
                  </div>
                  {/* ROW #6 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                      }}
                    >
                      <div
                        className="left-half bb pt pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          9. other insured's name{" "}
                          <span
                            className="c"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            (last name, first name, middle initial)
                          </span>
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          hussein, saqer, m
                        </p>
                      </div>
                      <div
                        className="left-half-end flex pt pr pl bl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          10. is patient's condition related to:
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pt pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        11. insured's policy group or feca number
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                        }}
                      >
                        {this.state.insuredPolicyNumber}
                      </p>
                    </div>
                  </div>
                  {/* ROW #7 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="left-half bb pt pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            a.
                          </span>{" "}
                          other insured's policy or group number
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          13301
                        </p>
                      </div>
                      <div
                        className="left-half-end pt pr pl bl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            a.
                          </span>{" "}
                          employment?{" "}
                          <span
                            className="c"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            (current or Previous)
                          </span>
                        </p>
                        <div
                          className="flex jcc pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "center",
                            msFlexPack: "center",
                            justifyContent: "center",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="flex aic pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingRight: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                background:
                                  this.state.patientCondition === "employment"
                                    ? "#000"
                                    : "#fff",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                              }}
                            >
                              yes
                            </span>
                          </div>
                          <div
                            className="flex aic pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingLeft: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                background:
                                  this.state.patientCondition !== "employment"
                                    ? "#000"
                                    : "#fff",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                              }}
                            >
                              no
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end flex jcsb pt pl pr bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxPack: "justify",
                      msFlexPack: "justify",
                      justifyContent: "space-between",
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span
                          className="lc"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "lowercase",
                          }}
                        >
                          a.
                        </span>{" "}
                        insured's date of birth
                      </p>
                      <div
                        className="flex jcc"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxPack: "center",
                          msFlexPack: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="pr center"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textAlign: "center",
                            paddingRight: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            MM
                          </p>
                          <p
                            className="mono"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {`${insuredDob[0]} |`}
                          </p>
                        </div>
                        <div
                          className="pr center pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textAlign: "center",
                            paddingRight: "4px",
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            DD
                          </p>
                          <p
                            className="mono"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {`${insuredDob[1]} |`}
                          </p>
                        </div>
                        <div
                          className="pr center pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textAlign: "center",
                            paddingRight: "4px",
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            YYYY
                          </p>
                          <p
                            className="mono"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {insuredDob[2]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex fdc center pl"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxOrient: "vertical",
                        WebkitBoxDirection: "normal",
                        msFlexDirection: "column",
                        flexDirection: "column",
                        textAlign: "center",
                        paddingLeft: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Sex
                      </p>
                      <div
                        className="flex"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                        }}
                      >
                        <div
                          className="flex aic pt pr"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxAlign: "center",
                            msFlexAlign: "center",
                            alignItems: "center",
                            paddingRight: "4px",
                            paddingTop: "4px",
                          }}
                        >
                          <span
                            className="pr sm"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "2px",
                            }}
                          >
                            M
                          </span>
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              background:
                                this.state.patientGender === "Male"
                                  ? "#000"
                                  : "#fff",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="flex aic pt pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxAlign: "center",
                            msFlexAlign: "center",
                            alignItems: "center",
                            paddingLeft: "4px",
                            paddingTop: "4px",
                          }}
                        >
                          <span
                            className="pr sm"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "2px",
                            }}
                          >
                            F
                          </span>
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              background:
                                this.state.patientGender === "Female"
                                  ? "#000"
                                  : "#fff",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ROW #8 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="left-half bb pt pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            b.
                          </span>{" "}
                          reserved for nucc use
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          some data
                        </p>
                      </div>
                      <div
                        className="left-half-end pt pr pl bl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                        }}
                      >
                        <div
                          className="flex jcsb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span
                              className="lc"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textTransform: "lowercase",
                              }}
                            >
                              b.
                            </span>{" "}
                            auto accident?
                          </p>
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            place{" "}
                            <span
                              className="c"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textTransform: "capitalize",
                              }}
                            >
                              (state)
                            </span>
                          </p>
                        </div>
                        <div
                          className="flex jcc pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "center",
                            msFlexPack: "center",
                            justifyContent: "center",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="flex aic pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingRight: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                background:
                                  this.state.patientCondition === "accident"
                                    ? "#000"
                                    : "#fff",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                              }}
                            >
                              yes
                            </span>
                          </div>
                          <div
                            className="flex aic pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingLeft: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                background:
                                  this.state.patientCondition !== "accident"
                                    ? "#000"
                                    : "#fff",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                              }}
                            >
                              no
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pt pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <span
                          className="lc"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "lowercase",
                          }}
                        >
                          b.
                        </span>{" "}
                        other claim id{" "}
                        <span
                          className="c"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          (Designated by NUCC)
                        </span>
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                        }}
                      >
                        some data
                      </p>
                    </div>
                  </div>
                  {/* ROW #9 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="left-half bb pt pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            c.
                          </span>{" "}
                          reserved for nucc use
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          some data
                        </p>
                      </div>
                      <div
                        className="left-half-end bb pt pr pl bl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <div
                          className="flex jcsb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span
                              className="lc"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textTransform: "lowercase",
                              }}
                            >
                              c.
                            </span>{" "}
                            other accident?
                          </p>
                        </div>
                        <div
                          className="flex jcc pt pb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "center",
                            msFlexPack: "center",
                            justifyContent: "center",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          }}
                        >
                          <div
                            className="flex aic pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingRight: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                background:
                                  this.state.patientCondition === "other"
                                    ? "#000"
                                    : "#fff",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                              }}
                            >
                              yes
                            </span>
                          </div>
                          <div
                            className="flex aic pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingLeft: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                background:
                                  this.state.patientCondition !== "other"
                                    ? "#000"
                                    : "#fff",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                              }}
                            >
                              no
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pt pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <span
                          className="lc"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "lowercase",
                          }}
                        >
                          c.
                        </span>{" "}
                        insurance plan name or program name
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                        }}
                      >
                        {this.state.insuredPolicyName}
                      </p>
                    </div>
                  </div>
                  {/* ROW #10 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="left-half bb pt pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 21,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            d.
                          </span>{" "}
                          insurance plan name or program name
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          {this.state.primaryInsuranceDesc}
                        </p>
                      </div>
                      <div
                        className="left-half-end bb pt pr pl bl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 21,
                          gridColumnStart: 21,
                          gridColumnEnd: 33,
                          borderLeft: "2px solid #000",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            10d.
                          </span>{" "}
                          claim codes
                          <span
                            className="c"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "capitalize",
                            }}
                          >
                            (Designated by NUCC)
                          </span>
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          13301
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb flex jcsb pl pr pt"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxPack: "justify",
                      msFlexPack: "justify",
                      justifyContent: "space-between",
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span
                          className="lc"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            textTransform: "lowercase",
                          }}
                        >
                          d.
                        </span>{" "}
                        is there another health benefit plan?
                      </p>
                      <div
                        className="flex fdc center pl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxOrient: "vertical",
                          WebkitBoxDirection: "normal",
                          msFlexDirection: "column",
                          flexDirection: "column",
                          textAlign: "center",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          className="flex aic"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxAlign: "center",
                            msFlexAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="flex aic pt pb pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingRight: "4px",
                              paddingTop: "4px",
                              paddingBottom: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl sm"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "2px",
                              }}
                            >
                              YES
                            </span>
                          </div>
                          <div
                            className="flex aic pt pb pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingRight: "4px",
                              paddingLeft: "4px",
                              paddingTop: "4px",
                              paddingBottom: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <span
                              className="pl sm"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "2px",
                              }}
                            >
                              NO
                            </span>
                          </div>
                          <p
                            className="pl lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingLeft: "4px",
                              whiteSpace: "nowrap",
                              textTransform: "lowercase",
                            }}
                          >
                            <strong
                              className="i"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontStyle: "italic",
                              }}
                            >
                              If yes,
                            </strong>
                            complete items 9, 9a, and 9d.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ROW #11 */}
                  <div
                    className="left-half br bb pt pl pr"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <p
                      className="b center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      read back of form before completing 7 signing this form.
                    </p>
                    <div
                      className="flex"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                      }}
                    >
                      <span
                        className="pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                        }}
                      >
                        12.
                      </span>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        patient's or authorized person's signature
                        <span
                          className="nc"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontSize: "10px",
                            textTransform: "none",
                          }}
                        >
                          I authorize the release of any medical or other
                          information necessary to process this claim. I also
                          request payment of government benefits either to
                          myself or to the party who accepts assignment below.
                        </span>
                        <div
                          className="pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="flex w100 pb"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              paddingBottom: "4px",
                              width: "100%",
                            }}
                          >
                            <div
                              className="flex pr fg2 aife"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxFlex: 2,
                                msFlexPositive: 2,
                                flexGrow: 2,
                                WebkitBoxAlign: "end",
                                msFlexAlign: "end",
                                alignItems: "flex-end",
                                paddingRight: "4px",
                              }}
                            >
                              <p
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                signed
                              </p>
                              <hr
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  width: "100%",
                                  height: "2px",
                                  marginLeft: "4px",
                                  backgroundColor: "#000",
                                }}
                              />
                            </div>
                            <div
                              className="flex pl fg aife"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxFlex: 1,
                                msFlexPositive: 1,
                                flexGrow: 1,
                                WebkitBoxAlign: "end",
                                msFlexAlign: "end",
                                alignItems: "flex-end",
                                paddingLeft: "4px",
                              }}
                            >
                              <p
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                date
                              </p>
                              <hr
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  width: "100%",
                                  height: "2px",
                                  marginLeft: "4px",
                                  backgroundColor: "#000",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end flex bb pt pl pr"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                      }}
                    >
                      <span
                        className="pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                        }}
                      >
                        13.
                      </span>
                      <div
                        className="flex fdc jcsb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxOrient: "vertical",
                          WebkitBoxDirection: "normal",
                          msFlexDirection: "column",
                          flexDirection: "column",
                          WebkitBoxPack: "justify",
                          msFlexPack: "justify",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          insured's or authorized person's signature
                          <span
                            className="nc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontSize: "10px",
                              textTransform: "none",
                            }}
                          >
                            I authorize payment of medical benefits to the
                            undersigned physician or supplier for servcices
                            described below.
                          </span>
                        </span>
                        <div
                          className="pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="flex w100 pb"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              paddingBottom: "4px",
                              width: "100%",
                            }}
                          >
                            <div
                              className="flex pr fg aife"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                WebkitBoxFlex: 1,
                                msFlexPositive: 1,
                                flexGrow: 1,
                                WebkitBoxAlign: "end",
                                msFlexAlign: "end",
                                alignItems: "flex-end",
                                paddingRight: "4px",
                              }}
                            >
                              <p
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                signed
                              </p>
                              <hr
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  width: "100%",
                                  height: "2px",
                                  marginLeft: "4px",
                                  backgroundColor: "#000",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  style={{
                    position: "absolute",
                  }}
                  src={patient}
                  alt="edit"
                />
                <div
                  className="thicc"
                  style={{
                    WebkitBoxSizing: "border-box",
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                    borderBottom: "4px solid #000",
                  }}
                />
                <div
                  className="form-body"
                  style={{
                    WebkitBoxSizing: "border-box",
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                    float: "left",
                    display: "grid",
                    msGridColumns: "(1fr)[32]",
                    border: "2px solid rgb(0, 0, 0)",
                    gridTemplateColumns: "repeat(32, 1fr)",
                  }}
                >
                  {/* ROW #12 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                      }}
                    >
                      <div
                        className="left-half-alt pt pl pr bb br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 19,
                          borderRight: "2px solid #000",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          14. date of current ilness, injury,{" "}
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            or
                          </span>{" "}
                          pregnancy (LMP)
                        </p>
                        <div
                          className="flex jcsb pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="brd pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              borderRight: "1px dashed #000",
                            }}
                          >
                            mm
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {injDate[0]}
                            </p>
                          </div>
                          <div
                            className="brd pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              paddingLeft: "4px",
                              borderRight: "1px dashed #000",
                            }}
                          >
                            dd
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {injDate[1]}
                            </p>
                          </div>
                          <div
                            className="brd pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              paddingLeft: "4px",
                              borderRight: "1px dashed #000",
                            }}
                          >
                            yyyy
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {injDate[2]}
                            </p>
                          </div>
                          <div
                            className="pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              paddingLeft: "4px",
                            }}
                          >
                            qual
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              some qual data
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="left-half-alt-end pt pl pr bb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 19,
                          gridColumnStart: 19,
                          gridColumnEnd: 33,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          15. other date
                        </p>
                        <div
                          className="flex jcsb pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="brd pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              borderRight: "1px dashed #000",
                            }}
                          >
                            qual
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              some qual data
                            </p>
                          </div>
                          <div
                            className="brd pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              paddingLeft: "4px",
                              borderRight: "1px dashed #000",
                            }}
                          >
                            mm
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              02
                            </p>
                          </div>
                          <div
                            className="brd pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              paddingLeft: "4px",
                              borderRight: "1px dashed #000",
                            }}
                          >
                            dd
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              29
                            </p>
                          </div>
                          <div
                            className="pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              paddingLeft: "4px",
                            }}
                          >
                            yyyy
                            <p
                              className="mono pt"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingTop: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              1992
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb pt pl pr"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <p
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      16. dates patient unable to work in current occupation
                    </p>
                    <div
                      className="flex jcsb aife pt"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxAlign: "end",
                        msFlexAlign: "end",
                        alignItems: "flex-end",
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingTop: "4px",
                      }}
                    >
                      <div
                        className="pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                        }}
                      >
                        from
                      </div>
                      <div
                        className="brd pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          borderRight: "1px dashed #000",
                        }}
                      >
                        mm
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          01
                        </p>
                      </div>
                      <div
                        className="brd pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          borderRight: "1px dashed #000",
                        }}
                      >
                        dd
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          02
                        </p>
                      </div>
                      <div
                        className="brd pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          borderRight: "1px dashed #000",
                        }}
                      >
                        yyyy
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          1992
                        </p>
                      </div>
                      <div
                        className="pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                        }}
                      >
                        to
                      </div>
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        mm
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          03
                        </p>
                      </div>
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        dd
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          26
                        </p>
                      </div>
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        yyyy
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          2021
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ROW #13 */}
                  <div
                    className="left-half br"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="grid-wrap h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "grid",
                        msGridColumns: "(1fr)[32]",
                        gridTemplateColumns: "repeat(32, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="left-half-alt pt pl pr bb br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          paddingTop: "4px",
                          msGridColumn: 1,
                          gridColumnStart: 1,
                          gridColumnEnd: 19,
                          borderRight: "2px solid #000",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          17. name of referring provider or other source
                        </p>
                        <div
                          className="flex pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="brd pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              borderRight: "1px dashed #000",
                            }}
                          >
                            <p
                              className="mono pb"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingBottom: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              DN
                            </p>
                          </div>
                          <div
                            className="pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingRight: "4px",
                              paddingLeft: "4px",
                            }}
                          >
                            <p
                              className="mono pb"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                fontFamily: '"Courier New", Courier, monospace',
                                fontSize: "0.7rem",
                                paddingBottom: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {this.state.surgeon}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="left-half-alt-end flex bb fdc"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxOrient: "vertical",
                          WebkitBoxDirection: "normal",
                          msFlexDirection: "column",
                          flexDirection: "column",
                          msGridColumn: 19,
                          gridColumnStart: 19,
                          gridColumnEnd: 33,
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <div
                          className="g221 bgl bbd fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            backgroundColor: "#eee",
                            display: "grid",
                            msGridColumns: "1fr 1fr 8fr",
                            gridTemplateColumns: "1fr 1fr 8fr",
                            borderBottom: "1px dashed #000",
                          }}
                        >
                          <div
                            className="p br"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: "4px",
                              borderRight: "2px solid #000",
                            }}
                          >
                            17a.
                          </div>
                          <div
                            className="p br"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: "4px",
                              borderRight: "2px solid #000",
                            }}
                          />
                          <div
                            className="p"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: "4px",
                            }}
                          />
                        </div>
                        <div
                          className="g221 fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            display: "grid",
                            msGridColumns: "1fr 1fr 8fr",
                            gridTemplateColumns: "1fr 1fr 8fr",
                          }}
                        >
                          <div
                            className="p br"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: "4px",
                              borderRight: "2px solid #000",
                            }}
                          >
                            17b.
                          </div>
                          <div
                            className="p br"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: "4px",
                              borderRight: "2px solid #000",
                            }}
                          >
                            NPI
                          </div>
                          <div
                            className="p mono"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              padding: "4px",
                            }}
                          >
                            {this.state.npi}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb pt pl pr"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <p
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      18. hospitalization dates related to current services
                    </p>
                    <div
                      className="flex jcsb aife pt"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxAlign: "end",
                        msFlexAlign: "end",
                        alignItems: "flex-end",
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingTop: "4px",
                      }}
                    >
                      <div
                        className="pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                        }}
                      >
                        from
                      </div>
                      <div
                        className="brd pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          borderRight: "1px dashed #000",
                        }}
                      >
                        mm
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hospDateStart[0]}
                        </p>
                      </div>
                      <div
                        className="brd pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          borderRight: "1px dashed #000",
                        }}
                      >
                        dd
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hospDateStart[1]}
                        </p>
                      </div>
                      <div
                        className="brd pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          borderRight: "1px dashed #000",
                        }}
                      >
                        yyyy
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hospDateStart[2]}
                        </p>
                      </div>
                      <div
                        className="pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                        }}
                      >
                        to
                      </div>
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        mm
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hospDateEnd[1]}
                        </p>
                      </div>
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        dd
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hospDateEnd[2]}
                        </p>
                      </div>
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        yyyy
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {hospDateEnd[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ROW #14 */}
                  <div
                    className="left-half br bb pl pt pr"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <p
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      19. additional claim information{" "}
                      <span
                        className="nc"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          textTransform: "none",
                        }}
                      >
                        (Designated by NUCC)
                      </span>
                    </p>
                    <p
                      className="mono pt"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        fontFamily: '"Courier New", Courier, monospace',
                        fontSize: "0.7rem",
                        paddingTop: "4px",
                      }}
                    >
                      zz246zs0410x
                    </p>
                  </div>
                  <div
                    className="left-half-end flex jcsb bb pt pl pr"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxPack: "justify",
                      msFlexPack: "justify",
                      justifyContent: "space-between",
                      paddingRight: "4px",
                      paddingLeft: "4px",
                      paddingTop: "4px",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        paddingRight: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        20. outside lab?
                      </p>
                      <div
                        className="flex pb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingBottom: "4px",
                        }}
                      >
                        <div
                          className="flex aic pt pr"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxAlign: "center",
                            msFlexAlign: "center",
                            alignItems: "center",
                            paddingRight: "4px",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                          <span
                            className="pl sm"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingLeft: "2px",
                            }}
                          >
                            YES
                          </span>
                        </div>
                        <div
                          className="flex aic pt pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxAlign: "center",
                            msFlexAlign: "center",
                            alignItems: "center",
                            paddingLeft: "4px",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="squares"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                          <span
                            className="pl sm"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingLeft: "2px",
                            }}
                          >
                            NO
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex fdc fg center pl"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxOrient: "vertical",
                        WebkitBoxDirection: "normal",
                        msFlexDirection: "column",
                        flexDirection: "column",
                        textAlign: "center",
                        paddingLeft: "4px",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        $ charges
                      </p>
                      <div
                        className="flex w100 h100 pt pb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div
                          className="bl br fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            borderRight: "2px solid #000",
                            borderLeft: "2px solid #000",
                          }}
                        />
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* ROW #15 */}
                  <div
                    className="left-half br bb pt flex fdc"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxOrient: "vertical",
                      WebkitBoxDirection: "normal",
                      msFlexDirection: "column",
                      flexDirection: "column",
                      paddingTop: "4px",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="fg bb pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        borderBottom: "2px solid #000",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        21. diagnosis or nature of illness or injury{" "}
                        <span
                          className="nc pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                            textTransform: "none",
                          }}
                        >
                          Relate A-L to service line below (24E)
                        </span>
                      </p>
                      <div
                        className="flex w100 pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingTop: "4px",
                          width: "100%",
                        }}
                      >
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          a.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis1}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          b.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis2}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          c.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis3}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          d.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis4}
                          </span>
                        </div>
                      </div>
                      <div
                        className="flex w100 pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingTop: "4px",
                          width: "100%",
                        }}
                      >
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          e.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis5}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          f.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis6}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          g.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis7}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          h.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis8}
                          </span>
                        </div>
                      </div>
                      <div
                        className="flex w100 pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingTop: "4px",
                          width: "100%",
                        }}
                      >
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          i.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis9}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          j.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis10}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          k.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis11}
                          </span>
                        </div>
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          l.{" "}
                          <span
                            className="mono td"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              textDecoration: "underline",
                            }}
                          >
                            {this.state.diagnosis12}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fg g611242 pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        display: "grid",
                        msGridColumns: "6fr 1fr 1fr 2fr 4fr 2fr",
                        gridTemplateColumns: "6fr 1fr 1fr 2fr 4fr 2fr",
                      }}
                    >
                      <div
                        className="pr br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          borderRight: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          24. a. date(s) of service
                        </p>
                        <div
                          className="flex aife w100"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxAlign: "end",
                            msFlexAlign: "end",
                            alignItems: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div
                            className="fg center"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              WebkitBoxFlex: 1,
                              msFlexPositive: 1,
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            MM
                          </div>
                          <div
                            className="fg center"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              WebkitBoxFlex: 1,
                              msFlexPositive: 1,
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            From
                            <br
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                              }}
                            />
                            DD
                          </div>
                          <div
                            className="fg center"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              WebkitBoxFlex: 1,
                              msFlexPositive: 1,
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            YYYY
                          </div>
                          <div
                            className="fg center"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              WebkitBoxFlex: 1,
                              msFlexPositive: 1,
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            MM
                          </div>
                          <div
                            className="fg center"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              WebkitBoxFlex: 1,
                              msFlexPositive: 1,
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            To
                            <br
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                              }}
                            />
                            DD
                          </div>
                          <div
                            className="fg center"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              WebkitBoxFlex: 1,
                              msFlexPositive: 1,
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            YYYY
                          </div>
                        </div>
                      </div>
                      <div
                        className="br center"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          textAlign: "center",
                          borderRight: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          b.
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          place of
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          service
                        </p>
                      </div>
                      <div
                        className="pr pl br center"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          textAlign: "center",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          borderRight: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          c.
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          emg
                        </p>
                      </div>
                      <div
                        className="pr pl br fourofg611242 flex fdc"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          WebkitBoxOrient: "vertical",
                          WebkitBoxDirection: "normal",
                          msFlexDirection: "column",
                          flexDirection: "column",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          msGridColumn: 4,
                          gridColumnStart: 4,
                          gridColumnEnd: 6,
                          borderRight: "2px solid #000",
                        }}
                      >
                        <div
                          className="fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            d. procedures, services, or supplies
                          </p>
                          <p
                            className="center c"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              textTransform: "capitalize",
                            }}
                          >
                            (explain unusual circumstances)
                          </p>
                        </div>
                        <div
                          className="g6 fg"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            display: "grid",
                            msGridColumns: "(1fr)[6]",
                            gridTemplateColumns: "repeat(6, 1fr)",
                          }}
                        >
                          <div
                            className="g2by6 center br"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textAlign: "center",
                              msGridColumn: 1,
                              gridColumnStart: 1,
                              gridColumnEnd: 3,
                              borderRight: "2px solid #000",
                            }}
                          >
                            cpt/hcpcs
                          </div>
                          <div
                            className="g4by6 center"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textAlign: "center",
                              msGridColumn: 3,
                              gridColumnStart: 3,
                              gridColumnEnd: 7,
                            }}
                          >
                            modifier
                          </div>
                        </div>
                      </div>
                      <div
                        className="pl pr center"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          textAlign: "center",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          e.
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          diagnosis
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          pointer
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb flex fdc"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      WebkitBoxOrient: "vertical",
                      WebkitBoxDirection: "normal",
                      msFlexDirection: "column",
                      flexDirection: "column",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="p bb fg"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        padding: "4px",
                        borderBottom: "2px solid #000",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        22. resubmission
                      </p>
                      <div
                        className="flex w100"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          className="fg pr pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            paddingRight: "4px",
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            className="pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              paddingLeft: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            code
                          </p>
                          <div
                            className="br p"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: "4px",
                              borderRight: "2px solid #000",
                            }}
                          >
                            <div
                              className="pt pb"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingTop: "4px",
                                paddingBottom: "4px",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="fg pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            original ref. no.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="p bb fg"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        padding: "4px",
                        borderBottom: "2px solid #000",
                      }}
                    >
                      <p
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          whiteSpace: "nowrap",
                        }}
                      >
                        23. prioir authorization number
                      </p>
                      <p
                        className="mono pt"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          fontFamily: '"Courier New", Courier, monospace',
                          fontSize: "0.7rem",
                          paddingTop: "4px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        090078601
                      </p>
                    </div>
                    <div
                      className="fg g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        f.
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          $ charges
                        </p>
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        g.
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          days
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          or
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          units
                        </p>
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        h.
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          epsdt
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          family
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          plan
                        </p>
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        i.
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          id.
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          qual.
                        </p>
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        j.
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          rendering
                          <br
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          />
                          provider id. #
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* ROW #16 */}
                  <div
                    className="left-half br bgl"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      backgroundColor: "#eee",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  />
                  <div
                    className="left-half-end br bgl mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      backgroundColor: "#eee",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        zz
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        246zs0410x
                      </div>
                    </div>
                  </div>
                  {/* ROW #17 */}
                  <div
                    className="left-half br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginInline: "-15px",
                        position: "absolute",
                        fontWeight: "600",
                        marginTop: "-10px",
                      }}
                    >
                      1
                    </span>
                    <div
                      className="g16 center pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        display: "grid",
                        msGridColumns: "(1fr)[16]",
                        gridTemplateColumns: "repeat(16, 1fr)",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          serviceLines[0].line_start_date
                            .split("T")[0]
                            .split("-")[1]}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          serviceLines[0].line_start_date
                            .split("T")[0]
                            .split("-")[2]}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          serviceLines[0].line_start_date
                            .split("T")[0]
                            .split("-")[0]}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          serviceLines[0].line_end_date
                            .split("T")[0]
                            .split("-")[1]}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          serviceLines[0].line_end_date
                            .split("T")[0]
                            .split("-")[2]}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          serviceLines[0].line_end_date
                            .split("T")[0]
                            .split("-")[0]}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 0 ? bill.pos : ""}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br gcs9 gce11"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 9,
                          gridColumnStart: 9,
                          gridColumnEnd: 11,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          (serviceLines[0].hcpcs_code || "")}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          (serviceLines[0].modifier1 || "")}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          (serviceLines[0].modifier2 || "")}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      {serviceLines.length > 0 &&
                        (serviceLines[0].modifier3 || "")}
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      {serviceLines.length > 0 &&
                        (serviceLines[0].modifier4 || "")}
                      <div
                        className="gcs15 gce17"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 15,
                          gridColumnStart: 15,
                          gridColumnEnd: 17,
                        }}
                      >
                        {serviceLines.length > 0 &&
                          (serviceLines[0].points_to_diagnosis || "")}
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          (serviceLines[0].line_total || "")}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 0 &&
                          (serviceLines[0].units || "")}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        1
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        npi
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        143421096
                      </div>
                    </div>
                  </div>
                  {/* ROW #18 */}
                  <div
                    className="left-half br bgl"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      backgroundColor: "#eee",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  />
                  <div
                    className="left-half-end br bgl mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      backgroundColor: "#eee",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        zz
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        246zs0410x
                      </div>
                    </div>
                  </div>
                  {/* ROW #19 */}
                  <div
                    className="left-half br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginInline: "-15px",
                        position: "absolute",
                        fontWeight: "600",
                        marginTop: "-10px",
                      }}
                    >
                      2
                    </span>
                    <div
                      className="g16 center pl pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        display: "grid",
                        msGridColumns: "(1fr)[16]",
                        gridTemplateColumns: "repeat(16, 1fr)",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          serviceLines[1].line_start_date
                            .split("T")[0]
                            .split("-")[1]}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          serviceLines[1].line_start_date
                            .split("T")[0]
                            .split("-")[2]}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          serviceLines[1].line_start_date
                            .split("T")[0]
                            .split("-")[0]}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          serviceLines[1].line_end_date
                            .split("T")[0]
                            .split("-")[1]}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          serviceLines[1].line_end_date
                            .split("T")[0]
                            .split("-")[2]}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          serviceLines[1].line_end_date
                            .split("T")[0]
                            .split("-")[0]}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 1 ? bill.pos : ""}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br gcs9 gce11"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 9,
                          gridColumnStart: 9,
                          gridColumnEnd: 11,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          (serviceLines[1].hcpcs_code || "")}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          (serviceLines[1].modifier1 || "")}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          (serviceLines[1].modifier2 || "")}
                      </div>
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          (serviceLines[1].modifier3 || "")}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      {serviceLines.length > 1 &&
                        (serviceLines[1].modifier4 || "")}
                      <div
                        className="gcs15 gce17"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 15,
                          gridColumnStart: 15,
                          gridColumnEnd: 17,
                        }}
                      >
                        {serviceLines.length > 1 &&
                          (serviceLines[1].points_to_diagnosis || "")}
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          (serviceLines[1].line_total || "")}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        {serviceLines.length > 1 &&
                          (serviceLines[1].units || "")}
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        1
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        npi
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        143421096
                      </div>
                    </div>
                  </div>
                  {/* ROW #20 */}
                  <div
                    className="left-half br bgl"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      backgroundColor: "#eee",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  />
                  <div
                    className="left-half-end br bgl mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      backgroundColor: "#eee",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        zz
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        246zs0410x
                      </div>
                    </div>
                  </div>
                  {/* ROW #21 */}
                  <div
                    className="left-half br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginInline: "-15px",
                        position: "absolute",
                        fontWeight: "600",
                        marginTop: "-10px",
                      }}
                    >
                      3
                    </span>
                    <div
                      className="g16 center pl pr h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        display: "grid",
                        msGridColumns: "(1fr)[16]",
                        gridTemplateColumns: "repeat(16, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />

                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br gcs9 gce11"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 9,
                          gridColumnStart: 9,
                          gridColumnEnd: 11,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="gcs15 gce17"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 15,
                          gridColumnStart: 15,
                          gridColumnEnd: 17,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="left-half-end br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        8682
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        50
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        1
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        npi
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        143421096
                      </div>
                    </div>
                  </div>
                  {/* ROW #22 */}
                  <div
                    className="left-half br bgl"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      backgroundColor: "#eee",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  />
                  <div
                    className="left-half-end br bgl mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      backgroundColor: "#eee",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        zz
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        246zs0410x
                      </div>
                    </div>
                  </div>
                  {/* ROW #23 */}
                  <div
                    className="left-half br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginInline: "-15px",
                        position: "absolute",
                        fontWeight: "600",
                        marginTop: "-10px",
                      }}
                    >
                      4
                    </span>
                    <div
                      className="g16 center pl pr h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        display: "grid",
                        msGridColumns: "(1fr)[16]",
                        gridTemplateColumns: "repeat(16, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br gcs9 gce11"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 9,
                          gridColumnStart: 9,
                          gridColumnEnd: 11,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="gcs15 gce17"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 15,
                          gridColumnStart: 15,
                          gridColumnEnd: 17,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="left-half-end br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        8682
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        50
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        1
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        npi
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        143421096
                      </div>
                    </div>
                  </div>
                  {/* ROW #24 */}
                  <div
                    className="left-half br bgl"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      backgroundColor: "#eee",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  />
                  <div
                    className="left-half-end br bgl mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      backgroundColor: "#eee",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        zz
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        246zs0410x
                      </div>
                    </div>
                  </div>
                  {/* ROW #25 */}
                  <div
                    className="left-half br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginInline: "-15px",
                        position: "absolute",
                        fontWeight: "600",
                        marginTop: "-10px",
                      }}
                    >
                      5
                    </span>
                    <div
                      className="g16 center pl pr h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        display: "grid",
                        msGridColumns: "(1fr)[16]",
                        gridTemplateColumns: "repeat(16, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br gcs9 gce11"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 9,
                          gridColumnStart: 9,
                          gridColumnEnd: 11,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="gcs15 gce17"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 15,
                          gridColumnStart: 15,
                          gridColumnEnd: 17,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="left-half-end br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        8682
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        50
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        1
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        npi
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        143421096
                      </div>
                    </div>
                  </div>
                  {/* ROW #26 */}
                  <div
                    className="left-half br bgl"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      backgroundColor: "#eee",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  />
                  <div
                    className="left-half-end br bgl mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      backgroundColor: "#eee",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        zz
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        246zs0410x
                      </div>
                    </div>
                  </div>
                  {/* ROW #27 */}
                  <div
                    className="left-half br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginInline: "-15px",
                        position: "absolute",
                        fontWeight: "600",
                        marginTop: "-10px",
                      }}
                    >
                      6
                    </span>
                    <div
                      className="g16 center pl pr h100"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        paddingRight: "4px",
                        paddingLeft: "4px",
                        display: "grid",
                        msGridColumns: "(1fr)[16]",
                        gridTemplateColumns: "repeat(16, 1fr)",
                        height: "100%",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="br gcs9 gce11"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 9,
                          gridColumnStart: 9,
                          gridColumnEnd: 11,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      />
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      />
                      <div
                        className="gcs15 gce17"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          msGridColumn: 15,
                          gridColumnStart: 15,
                          gridColumnEnd: 17,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="left-half-end br bb mono"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontFamily: '"Courier New", Courier, monospace',
                      fontSize: "0.7rem",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="g32124 center"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        display: "grid",
                        msGridColumns: "3fr 2fr 1fr 2fr 4fr",
                        gridTemplateColumns: "3fr 2fr 1fr 2fr 4fr",
                      }}
                    >
                      <div
                        className="brd"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "1px dashed #000",
                        }}
                      >
                        8682
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        50
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        1
                      </div>
                      <div
                        className="br"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          borderRight: "2px solid #000",
                        }}
                      >
                        npi
                      </div>
                      <div
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        143421096
                      </div>
                    </div>
                  </div>
                  {/* ROW #28 */}
                  <div
                    className="left-half br bb flex"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="flex brl fg2 jcsb pt pb pr"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 2,
                        msFlexPositive: 2,
                        flexGrow: 2,
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingRight: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        borderRight: "2px solid #ddd",
                      }}
                    >
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          25. federal tax i.d. number
                        </p>
                        <p
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          812431860
                        </p>
                      </div>
                      <div
                        className="flex pl pr center"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          textAlign: "center",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          className="pl pr"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            ssn
                          </p>
                          <div
                            className="squares pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              paddingTop: "4px",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="pl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingLeft: "4px",
                          }}
                        >
                          <p
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              whiteSpace: "nowrap",
                            }}
                          >
                            ein
                          </p>
                          <div
                            className="squares pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              paddingTop: "4px",
                            }}
                          >
                            <div
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "block",
                                height: "14px",
                                width: "14px",
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex fg jcsb brl pt pb pl"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        borderRight: "2px solid #ddd",
                      }}
                    >
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          26. patient's account no.
                        </p>
                        <div
                          className="flex jcsb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {this.state.patientAccount}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex fg jcsb pt pb pl"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          27. accept assignment?
                        </p>
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          <small
                            className="nc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "none",
                            }}
                          >
                            (For govt. claims, see back)
                          </small>
                        </p>
                        <div
                          className="flex pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            paddingTop: "4px",
                          }}
                        >
                          <div
                            className="flex aic pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingRight: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <p
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              yes
                            </p>
                          </div>
                          <div
                            className="flex aic pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              WebkitBoxAlign: "center",
                              msFlexAlign: "center",
                              alignItems: "center",
                              paddingLeft: "4px",
                            }}
                          >
                            <div
                              className="squares"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  WebkitBoxSizing: "border-box",
                                  boxSizing: "border-box",
                                  margin: 0,
                                  padding: 0,
                                  display: "block",
                                  height: "14px",
                                  width: "14px",
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                            <p
                              className="pl"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                paddingLeft: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              no
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end bb flex"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <div
                      className="flex fg jcsb brl pt pb pl"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        borderRight: "2px solid #ddd",
                      }}
                    >
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          28. total charge
                        </p>
                        <div
                          className="flex jcsb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            $
                          </p>
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {this.state.totalBill}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex fg jcsb brl pt pb pl"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        borderRight: "2px solid #ddd",
                      }}
                    >
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          29. amount paid
                        </p>
                        <div
                          className="flex jcsb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            $
                          </p>
                          <p
                            className="mono pt"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingTop: "4px",
                              whiteSpace: "nowrap",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex fg jcsb brl pt pb pl"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingLeft: "4px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        borderRight: "2px solid #ddd",
                      }}
                    >
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <p
                          className="nc"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                            textTransform: "none",
                          }}
                        >
                          30. Rsvd for NUCC Use
                        </p>
                        <div
                          className="flex jcsb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* ROW #29 */}
                  <div
                    className="left-half br flex"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      msGridColumn: 1,
                      gridColumnStart: 1,
                      gridColumnEnd: 21,
                      borderRight: "2px solid #000",
                    }}
                  >
                    <div
                      className="flex fdc fg jcsb brl pt pb"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxOrient: "vertical",
                        WebkitBoxDirection: "normal",
                        msFlexDirection: "column",
                        flexDirection: "column",
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        borderRight: "2px solid #ddd",
                      }}
                    >
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          className="flex"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            31.
                          </span>
                          <span
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            signature of physician or supplier including degrees
                            or credentials{" "}
                            <span
                              className="nc"
                              style={{
                                WebkitBoxSizing: "border-box",
                                boxSizing: "border-box",
                                margin: 0,
                                padding: 0,
                                textTransform: "none",
                                fontSize: "10px",
                              }}
                            >
                              (I certify that the statement on thr reverse apply
                              to this bill and are made a part thereof.)
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                        className="pl pr"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          className="mono pt"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                          }}
                        >
                          terry nicholas csa
                        </div>
                        <div
                          className="flex jcsb aife"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            WebkitBoxAlign: "end",
                            msFlexAlign: "end",
                            alignItems: "flex-end",
                            WebkitBoxPack: "justify",
                            msFlexPack: "justify",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            signed
                          </span>
                          <div
                            className="pl pr pt mono"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingRight: "4px",
                              paddingLeft: "4px",
                              paddingTop: "4px",
                            }}
                          >
                            11/01/16
                          </div>
                          <span
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            date
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex fdc fg jcsb pt"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxOrient: "vertical",
                        WebkitBoxDirection: "normal",
                        msFlexDirection: "column",
                        flexDirection: "column",
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingTop: "4px",
                      }}
                    >
                      <div
                        className="fg pl pr bb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          WebkitBoxFlex: 1,
                          msFlexPositive: 1,
                          flexGrow: 1,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <div
                          className="flex"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            32.
                          </span>
                          <span
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            service facility location information
                          </span>
                        </div>
                        <div
                          className="mono pt pb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          }}
                        >
                          {this.state.facility}
                        </div>
                      </div>
                      <div
                        className="flex pl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          className="br pr pt pb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            borderRight: "2px solid #000",
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            a.
                          </span>
                          <span
                            className="mono pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingRight: "4px",
                              paddingLeft: "4px",
                            }}
                          >
                            {this.state.facilityNPI}
                          </span>
                        </div>
                        <div
                          className="fg pl pt pb bgl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            paddingLeft: "4px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            backgroundColor: "#eee",
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            b.
                          </span>
                          <span
                            className="mono pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingLeft: "4px",
                            }}
                          >
                            {this.state.facilityNPI}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left-half-end"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      msGridColumn: 21,
                      gridColumnStart: 21,
                      gridColumnEnd: 33,
                    }}
                  >
                    <div
                      className="flex fdc fg jcsb pt"
                      style={{
                        WebkitBoxSizing: "border-box",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        WebkitBoxFlex: 1,
                        msFlexPositive: 1,
                        flexGrow: 1,
                        WebkitBoxOrient: "vertical",
                        WebkitBoxDirection: "normal",
                        msFlexDirection: "column",
                        flexDirection: "column",
                        WebkitBoxPack: "justify",
                        msFlexPack: "justify",
                        justifyContent: "space-between",
                        paddingTop: "4px",
                      }}
                    >
                      <div
                        className="fg pl pr bb"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          WebkitBoxFlex: 1,
                          msFlexPositive: 1,
                          flexGrow: 1,
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          borderBottom: "2px solid #000",
                        }}
                      >
                        <p
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            whiteSpace: "nowrap",
                          }}
                        >
                          33. billing provider info &amp; ph #{" "}
                          <span
                            className="mono pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingLeft: "4px",
                            }}
                          >
                            (832)6554141
                          </span>
                        </p>
                        <div
                          className="mono pt pb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            fontFamily: '"Courier New", Courier, monospace',
                            fontSize: "0.7rem",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          }}
                        >
                          b &amp; o surgical assistants llc 2100 w loop south
                          1200 houston tx 77027
                        </div>
                      </div>
                      <div
                        className="flex pl"
                        style={{
                          WebkitBoxSizing: "border-box",
                          boxSizing: "border-box",
                          margin: 0,
                          padding: 0,
                          display: "flex",
                          paddingLeft: "4px",
                        }}
                      >
                        <div
                          className="br pr pt pb"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            paddingRight: "4px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            borderRight: "2px solid #000",
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            a.
                          </span>
                          <span
                            className="mono pl pr"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingRight: "4px",
                              paddingLeft: "4px",
                            }}
                          >
                            1295788735
                          </span>
                        </div>
                        <div
                          className="fg pl pt pb bgl"
                          style={{
                            WebkitBoxSizing: "border-box",
                            boxSizing: "border-box",
                            margin: 0,
                            padding: 0,
                            WebkitBoxFlex: 1,
                            msFlexPositive: 1,
                            flexGrow: 1,
                            paddingLeft: "4px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            backgroundColor: "#eee",
                          }}
                        >
                          <span
                            className="lc"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              textTransform: "lowercase",
                            }}
                          >
                            b.
                          </span>
                          <span
                            className="mono pl"
                            style={{
                              WebkitBoxSizing: "border-box",
                              boxSizing: "border-box",
                              margin: 0,
                              padding: 0,
                              fontFamily: '"Courier New", Courier, monospace',
                              fontSize: "0.7rem",
                              paddingLeft: "4px",
                            }}
                          >
                            1295788735
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  style={{
                    position: "absolute",
                    bottom: "30px",
                  }}
                  src={physician}
                  alt="edit"
                />
                <div
                  className="thicc"
                  style={{
                    WebkitBoxSizing: "border-box",
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0,
                    borderBottom: "4px solid #000",
                  }}
                />
                <div
                  className="p flex jcsb"
                  style={{
                    WebkitBoxSizing: "border-box",
                    boxSizing: "border-box",
                    margin: 0,
                    display: "flex",
                    WebkitBoxPack: "justify",
                    msFlexPack: "justify",
                    justifyContent: "space-between",
                    padding: "4px",
                  }}
                >
                  <div
                    className="nc"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      textTransform: "none",
                    }}
                  >
                    NUCC Instruction Manual available at: www.nucc.org
                  </div>
                  <div
                    className="i"
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                      fontStyle: "italic",
                    }}
                  >
                    please print or type
                  </div>
                  <div
                    style={{
                      WebkitBoxSizing: "border-box",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    approved omb-0938-1157 form 1500 (02-12)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(ClaimForm);
