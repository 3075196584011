import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import { APP_ROUTES, NOTIFICATION_TYPES } from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createSurgeon, updateSurgeon } from '../../actions/surgeons';
import { surgeonService } from "../../services/surgeons";
import StateSelection from "../organisms/StateSelection";
import CitySelection from "../organisms/CitySelection";
import FieldView from "../organisms/FieldView";
import { formatDate } from "../../utils/date";
import { utilityService } from "../../services/utility";
import ZipCode from "../organisms/ZipCode";
import CustomDropDown from "../organisms/CustomDropDown";
import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";
import { FileStorageService } from "../../services/fileStorage";
import { faUpload, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validationGroup = {
    message: "There are some validation errors in Basic Information Tab:",
    fields: {
        'firstName': { filedName: 'First Name', required: true, minLength: 3, maxLength: 200 },
        'lastName': { filedName: 'Last Name', required: true, minLength: 3, maxLength: 200 },
        'npi': { filedName: 'NPI', required: true },
        'email': { filedName: 'Email', required: true },
    }
}

const validationGroupDegree = {
    message: "There are some validation errors in Degrees Tab:",
    fields: {
        'degree_degree': { filedName: 'Degree', required: true },
        'degree_status': { filedName: 'Degree Status', required: true },
        'degree_aquired_date': { filedName: 'Degree Aquired Date', required: true },
        'degree_state': { filedName: 'Degree State', required: true },
    }
}

class ManageSurgeons extends React.Component {
    state = {
        subSaveDisabled: false,
        errors: [],
        firstName: '',
        lastName: '',
        middleName: '',
        suffix: '',
        npi: '',
        designation: '',
        phone: '',
        city: '',
        address: '',
        state: '',
        email: '',
        zip: '',
        fax: '',
        additionalContactDetails: '',
        primaryTaxonomy: '',
        secondaryTaxonomy: '',
        financialClearance: '',
        license: '',
        primarySpeciality: {},
        secondarySpeciality: {},
        billingNotes: '',
        facility: [],
        degrees: [],
        degree_degree: '',
        degree_status: '',
        degree_aquired_date: '',
        degree_expired_date: '',
        degree_attachment: [],
        degree_state: {}
    };

    componentDidMount() {
        this.reLoadData();
    }

    reLoadData = () => {
        const surgeonId = this.props.location.state ? this.props.location.state.surgeonId : '';
        let viewOnly = false;
        if (this.props.location.state?.onlyForView || this.props.location.state?.onlyForView)
            viewOnly = this.props.location.state.onlyForView ? this.props.location.state.onlyForView : false;

        if (surgeonId) {
            surgeonService
                .getSurgeonDetail(surgeonId)
                .then(response => {
                    let facilities = [];
                    if (response.data.facilities) {
                        response.data.facilities.forEach(facility => {
                            facilities.push({ value: facility.id, label: facility.facility_name })
                        })
                    }


                    let tDegrees = [];
                    let tDegreeInstances = response.data.degrees;
                    if (tDegreeInstances) {
                        tDegreeInstances.forEach(tDeg => {
                            tDegrees.push({
                                date_aquired: formatDate(tDeg.date_aquired),
                                date_expired: formatDate(tDeg.date_expired),
                                degree: tDeg.degree,
                                state: tDeg.state,
                                status: tDeg.status,
                                id: tDeg.id,
                                attachments: tDeg.attachments ? JSON.parse(tDeg.attachments) : []
                            })
                        })
                    }
                    this.setState({
                        viewOnly: viewOnly,
                        degrees: tDegrees,
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        middleName: response.data.mi,
                        suffix: response.data.suffix,
                        designation: response.data.designation,
                        npi: response.data.npi,
                        phone: response.data.phone,
                        city: response.data.city,
                        email: response.data.email,
                        address: response.data.address,
                        state: response.data.state,
                        zip: response.data.zip,
                        fax: response.data.fax,
                        additionalContactDetails: response.data.additional_contact,
                        primaryTaxonomy: response.data.primary_taxonomy,
                        secondaryTaxonomy: response.data.secondry_taxonomy,
                        financialClearance: response.data.financial_clearance,
                        license: response.data.license,
                        facilities: facilities,
                        primarySpeciality: {
                            label: response.data.primary_speciality_name,
                            value: parseInt(response.data.primary_specialty),
                        },
                        secondarySpeciality: {
                            label: response.data.sub_speciality,
                            value: parseInt(response.data.sub_specialty),
                        },
                        billingNotes: response.data.billing_notes,
                    });
                })
        }
    }

    validateActivity = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    validateDegree = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroupDegree, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    submitDegree = () => {
        if (!this.validateDegree()) {
            return;
        }

        this.state.degrees.push({
            degree: this.state.degree_degree,
            date_aquired: formatDate(this.state.degree_aquired_date),
            date_expired: formatDate(this.state.degree_expired_date),
            state: this.state.degree_state,
            status: this.state.degree_status,
            attachments: this.state.degree_attachment
        });

        this.submitSurgeon(false);
    }

    clearDegreeForm = () => {
        this.setState({
            'degree_degree': '',
            'degree_status': '',
            'degree_aquired_date': '',
            'degree_expired_date': '',
            'degree_state': {}
        });
    }

    homeZipCodeChanged = (value) => {
        this.setState({ zip: value.zip_code })

        if (value.state) {
            this.setState({ state: value.state.code });
        }

        if (value.city) {
            this.setState({ city: value.city.name });
        }
    }

    downloadAttachment = (mainitem, item) => {
        FileStorageService.downloadFileFromObject(item).then(data => {
        })
    }

    deleteAttachment = (mainitem, item, type) => {
        utilityService.showConfirmDialog('Delete Attachment', 'Are you sure you want to delete this attachment', 'warning').then(data => {
            let pItem = {};
            pItem.type = type;
            pItem.id = mainitem.id;
            pItem.path = item.path;

            surgeonService.deleteDocument(pItem).then(tData => {
                this.reLoadData();
            })
        })
    }

    uploadAttachment = (mainitem, type) => {
        this.setState(
            {
                'current_type': type,
                'current_id': mainitem.id,
            }
        )
        const buttonElement = document.getElementById('generic-upload-' + type);
        if (buttonElement) {
            buttonElement.click();
        }
    }

    deleteDegree = (mainitem) => {
        utilityService.showConfirmDialog('Delete Degree', 'Are you sure you want to delete this degree', 'warning').then(data => {
            surgeonService.deleteDegree(mainitem.id).then(tData => {
                this.reLoadData();
            })
        })
    }

    uploadAttachmentProcess = (event) => {
        const formData = new FormData();
        formData.append("type", this.state.current_type);
        formData.append("file", event.target.files[0]);

        surgeonService.uploadFile(formData).then(tData => {
            let pItem = {};
            pItem.type = this.state.current_type;
            pItem.id = this.state.current_id;
            pItem.path = tData.data.path;
            pItem.name = tData.data.name;

            surgeonService.addDocument(pItem).then(tData => {
                this.reLoadData();
            })
        })
    }

    submitSurgeon = (redirectPage = true) => {
        if (!this.validateActivity()) {
            return;
        }

        const { createSurgeon, updateSurgeon } = this.props;
        const {
            firstName,
            lastName,
            middleName,
            suffix,
            primarySpeciality,
            secondarySpeciality,
            additionalContactDetails,
            address,
            city,
            state,
            zip,
            phone,
            fax,
            npi,
            primaryTaxonomy,
            secondaryTaxonomy,
            billingNotes,
            designation,
            license,
            financialClearance,
            facilities,
            degrees,
            email
        } = this.state;

        const request = {
            first_name: firstName,
            last_name: lastName,
            mi: middleName,
            suffix,
            primary_specialty: primarySpeciality.value,
            address,
            city,
            state,
            zip,
            phone,
            fax,
            npi,
            primary_taxonomy: primaryTaxonomy,
            secondry_taxonomy: secondaryTaxonomy,
            sub_specialty: secondarySpeciality.value,
            billing_notes: billingNotes,
            designation,
            license,
            financial_clearance: financialClearance,
            additional_contact: additionalContactDetails,
            facilities: facilities,
            degrees: degrees,
            email: email
        };

        const surgeonId = this.props.location.state ? this.props.location.state.surgeonId : '';
        if (surgeonId) {
            const payload = { ...request, 'id': surgeonId };
            updateSurgeon(payload)
                .then(res => {
                    if (res.response.success) {
                        if (redirectPage) {
                            createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                            this.props.history.push(APP_ROUTES.SURGEONS_DASHBOARD);
                        }
                        else {
                            this.clearDegreeForm();
                        }
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update surgeon ${res.response.message}`);
                    }
                })
        } else {
            createSurgeon(request)
                .then(res => {
                    if (res.response.success) {
                        if (redirectPage) {
                            createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                            this.props.history.push(APP_ROUTES.SURGEONS_DASHBOARD);
                        }
                        else {
                            this.clearDegreeForm();
                        }
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create surgeon ${res.response.message}`);
                    }
                })
        }
    };

    render() {
        const surgeonId = this.props.location.state ? this.props.location.state.surgeonId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {surgeonId && !this.state.viewOnly &&
                                <h2 className="heading-custom">Edit Surgeon</h2>
                            }
                            {!surgeonId && !this.state.viewOnly &&
                                <h2 className="heading-custom">Add Surgeon</h2>
                            }
                            {this.state.viewOnly &&
                                <h2 className="heading-custom">View Surgeon</h2>
                            }
                        </div>

                        <div class='col-md-12 p-0'>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="basic-info-tab" data-toggle="tab" href="#basic-info" role="tab">Basic Information</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="degree-tab" data-toggle="tab" href="#degree" role="tab">Degrees</a>
                                </li>
                            </ul>
                            <div class="tab-content mb-4" id="myTabContent">
                                <div class="tab-pane fade show active" id="basic-info" role="tabpanel">
                                    <div class='row'>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>First Name {FormValidatorService.getRequiredHTML(validationGroup, "firstName")}</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "firstName")}
                                                    value={this.state.firstName}
                                                    onChange={event => {
                                                        this.setState({ firstName: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.firstName} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Middle Name</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.middleName}
                                                    onChange={event => {
                                                        this.setState({ middleName: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.middleName} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Last Name {FormValidatorService.getRequiredHTML(validationGroup, "lastName")}</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "lastName")}
                                                    value={this.state.lastName}
                                                    onChange={event => {
                                                        this.setState({ lastName: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.lastName} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>
                                                Suffix <span className="required">*</span>
                                            </label>
                                            {!this.state.viewOnly &&
                                                <select
                                                    className="code-input"
                                                    onChange={(event) =>
                                                        this.setState({ suffix: event.target.value })
                                                    }
                                                    value={this.state.suffix}
                                                >
                                                    <option value="">Please Select Suffix</option>
                                                    <option value="MD">MD</option>
                                                    <option value="DO">DO</option>
                                                </select>
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.suffix} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Designation</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.designation}
                                                    onChange={event => {
                                                        this.setState({ designation: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.designation} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Email {FormValidatorService.getRequiredHTML(validationGroup, "email")}</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="email"
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "email")}
                                                    value={this.state.email}
                                                    onChange={event => {
                                                        this.setState({ email: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.email} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Phone</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={utilityService.formatPhone(this.state.phone)}
                                                    onChange={event => {
                                                        this.setState({ phone: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.phone} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Fax</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={utilityService.formatPhone(this.state.fax)}
                                                    onChange={event => {
                                                        this.setState({ fax: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.fax} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Additional Contact Details</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.additionalContactDetails}
                                                    onChange={event => {
                                                        this.setState({ additionalContactDetails: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.additionalContactDetails} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Address</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.address}
                                                    onChange={event => {
                                                        this.setState({ address: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.address} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <ZipCode title={'Zip'}
                                                    className='code-input'
                                                    value={this.state.zip}
                                                    onChange={(value) => {
                                                        this.setState({ 'zip': value });
                                                    }}
                                                    onBlur={(value) => {
                                                        this.homeZipCodeChanged(value);
                                                    }}>
                                                </ZipCode>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Zip</label>
                                                    <FieldView value={this.state.zip} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <StateSelection
                                                    key={this.state.state}
                                                    label={'State'}
                                                    isMulti={false}
                                                    selectedStateCode={this.state.state}
                                                    onChange={(value) => { this.setState({ state: value.value }) }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>State</label>
                                                    <FieldView value={this.state.state} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CitySelection
                                                    key={"".concat(this.state.state, this.state.city)}
                                                    label={'City'}
                                                    isMulti={false}
                                                    selectedStateCode={this.state.state}
                                                    selectedCity={this.state.city}
                                                    onChange={(value) => { console.log(value); this.setState({ city: value.value }) }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>City</label>
                                                    <FieldView value={this.state.city} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>NPI {FormValidatorService.getRequiredHTML(validationGroup, "npi")}</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "npi")}
                                                    value={this.state.npi}
                                                    onChange={event => {
                                                        this.setState({ npi: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.npi} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Primary Taxonomy</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.primaryTaxonomy}
                                                    onChange={event => {
                                                        this.setState({ primaryTaxonomy: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.primaryTaxonomy} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Secondary Taxonomy</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.secondaryTaxonomy}
                                                    onChange={event => {
                                                        this.setState({ secondaryTaxonomy: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.secondaryTaxonomy} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>License</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.license}
                                                    onChange={event => {
                                                        this.setState({ license: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.license} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Financial Clearance</label>
                                            {!this.state.viewOnly &&
                                                <select
                                                    value={this.state.financialClearance}
                                                    className="filter-input"
                                                    onChange={event => this.setState({ financialClearance: event.target.value })}>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.financialClearance} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CustomDropDown
                                                    label={'Primary Speciality'}
                                                    type={'surgeon_specialities'}
                                                    isRequired={false}
                                                    isMulti={false}
                                                    selectedValue={this.state.primarySpeciality}
                                                    onChange={value => { this.setState({ primarySpeciality: value }) }}>
                                                </CustomDropDown>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Primary Speciality</label><br></br>
                                                    <FieldView value={this.state.primarySpeciality?.label} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CustomDropDown
                                                    label={'Sub Speciality'}
                                                    type={'surgeon_specialities'}
                                                    isRequired={false}
                                                    isMulti={false}
                                                    selectedValue={this.state.secondarySpeciality}
                                                    onChange={value => { this.setState({ secondarySpeciality: value }) }}>
                                                </CustomDropDown>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Sub Speciality</label><br></br>
                                                    <FieldView value={this.state.secondarySpeciality?.label} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Special Billing Notes</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    type="text"
                                                    className="code-input"
                                                    value={this.state.billingNotes}
                                                    onChange={event => {
                                                        this.setState({ billingNotes: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.billingNotes} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CustomDropDown
                                                    label={'Facility Prefrence'}
                                                    type={'facilities'}
                                                    isRequired={false}
                                                    isMulti={false}
                                                    selectedValue={this.state.facilities}
                                                    onChange={(value) => this.setState({ facilities: value })}>
                                                </CustomDropDown>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Facility Prefrence</label> <br></br>
                                                    <FieldView value={this.state.facilities.map(tFacility => tFacility.label + ' ||| ')} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="degree" role="tabpanel">
                                    {!this.state.viewOnly &&
                                        <div class="row">
                                            <div class='col-md-12'>
                                                <div className="page-title">
                                                    <h2 className="heading-custom">Add New Entry</h2>
                                                </div>
                                                <div class='row'>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <CustomSelect type='Degree'
                                                            isRequired={FormValidatorService.isRequired(validationGroupDegree, "degree_degree")}
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_degree")}
                                                            value={this.state.degree_degree}
                                                            onChange={event => this.setState({ degree_degree: event.target.value })}
                                                        >
                                                        </CustomSelect>
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Date Aquired {FormValidatorService.getRequiredHTML(validationGroupDegree, "degree_aquired_date")}</label>
                                                        <input
                                                            type="date"
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_aquired_date")}
                                                            value={this.state.degree_aquired_date}
                                                            onChange={event => this.setState({ degree_aquired_date: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Date Expired - If Applicable {FormValidatorService.getRequiredHTML(validationGroupDegree, "degree_expired_date")}</label>
                                                        <input
                                                            type="date"
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_expired_date")}
                                                            value={this.state.degree_expired_date}
                                                            onChange={event => this.setState({ degree_expired_date: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <StateSelection
                                                            key={this.state.degree_state}
                                                            label={'State'}
                                                            isMulti={false}
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, 'degree_state')}
                                                            isRequired={FormValidatorService.isRequired(validationGroupDegree, 'degree_state')}
                                                            selectedStateCode={this.state.degree_state}
                                                            onChange={(value) => { this.setState({ degree_state: value.value }) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <CustomSelect type='Degree Status'
                                                            isRequired={FormValidatorService.isRequired(validationGroupDegree, "degree_status")}
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_status")}
                                                            value={this.state.degree_status}
                                                            onChange={event => this.setState({ degree_status: event.target.value })}>
                                                        </CustomSelect>
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Attachment {FormValidatorService.getRequiredHTML(validationGroupDegree, "degree_attachment")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_attachment")}
                                                            type="file"
                                                            onChange={(event) => {
                                                                this.setState({ 'subSaveDisabled': true });
                                                                const formData = new FormData();
                                                                formData.append("type", 'degree');
                                                                formData.append("file", event.target.files[0]);

                                                                surgeonService.uploadFile(formData).then(tData => {
                                                                    let previousFileObject = this.state.degree_attachment;
                                                                    previousFileObject.push(tData.data);

                                                                    this.setState({ 'degree_attachment': previousFileObject });
                                                                }).finally(() => {
                                                                    this.setState({ 'subSaveDisabled': false });
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-right">
                                                    <button
                                                        onClick={this.submitDegree}
                                                        disabled={this.state.subSaveDisabled}
                                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                                        Save Degree Entry
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div className="page-title">
                                                <h2 className="heading-custom">Current Degree List</h2>
                                            </div>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr >
                                                        <th>Degree</th>
                                                        <th>Date Aquired</th>
                                                        <th>Date Expired</th>
                                                        <th>State</th>
                                                        <th>Status</th>
                                                        <th>Attachments</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.degrees.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.degree}</td>
                                                                <td>{item.date_aquired}</td>
                                                                <td>{item.date_expired}</td>
                                                                <td>{item.state}</td>
                                                                <td>{item.status}</td>
                                                                <td>
                                                                    {item.attachments.map((tItem) => {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                {tItem.name}
                                                                                <FontAwesomeIcon className="ml-2" icon={faDownload} onClick={() => { this.downloadAttachment(item, tItem) }} />
                                                                                <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteAttachment(item, tItem, 'degree') }} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    <FontAwesomeIcon className="ml-2" icon={faUpload} onClick={() => { this.uploadAttachment(item, 'degree') }}></FontAwesomeIcon>
                                                                    <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteDegree(item) }}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter-footer">
                        {!this.state.viewOnly &&
                            <>
                                {surgeonId &&
                                    <button
                                        onClick={this.submitSurgeon}
                                        disabled={this.state.subSaveDisabled}
                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                        Update
                                    </button>
                                }
                                {!surgeonId &&
                                    <button
                                        onClick={this.submitSurgeon}
                                        disabled={this.state.subSaveDisabled}
                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                        Submit
                                    </button>
                                }
                                <button
                                    onClick={() => { this.props.history.push(APP_ROUTES.SURGEONS_DASHBOARD); }}
                                    className="filter-cancel-button">
                                    Cancel
                                </button>
                            </>
                        }
                        {this.state.viewOnly &&
                            <button
                                onClick={() => { this.props.history.push(APP_ROUTES.SURGEONS_DASHBOARD); }}
                                className="filter-cancel-button">
                                Surgeons Listing Page
                            </button>
                        }
                    </div>
                </div>
                <input type="file" id="generic-upload-degree" style={{ display: "none" }}
                    onChange={(event) => {
                        this.uploadAttachmentProcess(event);
                    }}></input>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createSurgeon,
            updateSurgeon,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageSurgeons));