import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function assignSurgeonAssociation(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_SURGEON_ASSOCIATION, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteSurgeonAssociation(id) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_SURGEON_ASSOCIATION + "/" + id)
        .then(handleResponse)
        .catch(handleError);
}

function getSurgeonAssociations(payload) {
    return apiClient()
        .get(API_CONSTANTS.LIST_SURGEON_ASSOCIATION, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const surgeonAssociationService = {
    assignSurgeonAssociation,
    deleteSurgeonAssociation,
    getSurgeonAssociations,
}