import {CLIENT} from '../constants/actions';
import { clientService } from '../services/clients';

export const fetchClients = options => dispatch => {
    return new Promise((resolve, reject) => {
        clientService
            .getClients(options)
            .then(response => {
                dispatch({
                    type: CLIENT.FETCH_CLIENT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createClient = payload => dispatch => {
    return new Promise((resolve, reject) => {
        clientService
            .createClient(payload)
            .then(response => {
                dispatch({
                    type: CLIENT.CREATE_CLIENT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateClient = payload => dispatch => {
    return new Promise((resolve, reject) => {
        clientService
            .updateClient(payload)
            .then(response => {
                dispatch({
                    type: CLIENT.UPDATE_CLIENT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};