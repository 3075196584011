import { DIVISION } from '../constants/actions';
import { divisionService } from '../services/division';

export const fetchDivision = options => dispatch => {
    return new Promise((resolve, reject) => {
        divisionService
            .getDivisions(options)
            .then(response => {
                dispatch({
                    type: DIVISION.FETCH_DIVISION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createDivision = payload => dispatch => {
    return new Promise((resolve, reject) => {
        divisionService
            .createDivision(payload)
            .then(response => {
                dispatch({
                    type: DIVISION.SUBMIT_DIVISION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateDivision = payload => dispatch => {
    return new Promise((resolve, reject) => {
        divisionService
            .updateDivision(payload)
            .then(response => {
                dispatch({
                    type: DIVISION.UPDATE_DIVISION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};


export const deleteDivision = options => dispatch => {
    return new Promise((resolve, reject) => {
        divisionService
            .deleteDivision(options)
            .then(response => {
                dispatch({
                    type: DIVISION.DELETE_DIVISION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};