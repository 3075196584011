import { GROUP } from '../constants/actions';
import { groupService } from '../services/groups';


export const fetchGroups = options => dispatch => {
    return new Promise((resolve, reject) => {
        groupService
            .getGroups(options)
            .then(response => {
                dispatch({
                    type: GROUP.FETCH_GROUP_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createGroup = payload => dispatch => {
    return new Promise((resolve, reject) => {
        groupService
            .createGroup(payload)
            .then(response => {
                dispatch({
                    type: GROUP.CREATE_GROUP_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateGroup = payload => dispatch => {
    return new Promise((resolve, reject) => {
        groupService
            .updateGroup(payload)
            .then(response => {
                dispatch({
                    type: GROUP.UPDATE_GROUP_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
