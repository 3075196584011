import React from 'react';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import EventCalendar from "../molecules/EventCalendar";
import { bindActionCreators } from "redux";
import { fetchActivitiesForScheduling } from "../../actions/dashboard";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from "prop-types";
import { formatDate, validDate } from "../../utils/date";
import DatePicker from "react-datepicker";

import '../../../sass/scheduling.scss';
import loaderImage from "../../../assets/images/CoureMax.gif";
import CustomDropDown from '../organisms/CustomDropDown';
import CustomSelect from '../organisms/CustomSelect';

class Dashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
        activities: PropTypes.array.isRequired,
        fetchActivitiesForScheduling: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            loading: false,
            currentPage: 1,
            fromDate: '',
            toDate: '',
            region: {
                value: '',
                label: 'Select Region',
            },
            assistant: {
                value: '',
                label: 'Select Assistant',
            },
            type: {
                value: '',
                label: 'Select Type',
            },
            eventType: 'month',
        };
        this.cancel = '';
    }

    componentDidMount() {
        this.loadEvents();
    }

    loadEvents = () => {
        this.setState({ loading: true });
        const options = {
            type: this.state.eventType,
            calendar: true,
        };
        this.props.fetchActivitiesForScheduling({ params: options })
            .then(res => {
                let tEvents = res.response.data.map(event => {
                    let startDT = formatDate(event.event_start_date);
                    if (event.event_start_time && event.event_start_time !== '')
                        startDT += ' ' + event.event_start_time;
                    startDT = new Date(startDT);

                    let endDT = null;
                    if (validDate(event.event_end_date)) {
                        endDT = formatDate(event.event_end_date);
                        if (event.event_end_time && event.event_end_time !== '') {
                            endDT += ' ' + event.event_end_time;
                            endDT = new Date(endDT);
                        }
                    }
                    else {
                        endDT = startDT;
                    }

                    let title = event.assistant ? `${event.assistant.name} ${event.assistant.last_name}` : '';
                    title += ' (' + startDT.toLocaleTimeString('en-US')
                    try {
                        if (endDT)
                            title += ' - ' + endDT?.toLocaleTimeString('en-US');
                    }
                    catch (ex) { }

                    title += ')';

                    return {
                        start: startDT,
                        end: endDT,
                        title: title,
                        id: event.old_event_id,
                        status: event.status,
                    }
                });

                console.log("*******", tEvents);

                this.setState({
                    events: tEvents
                });
                this.setState({ loading: false });
            })
    };

    addFilters = (options) => {
        const {
            fromDate,
            toDate,
            region,
            assistant,
            type,
        } = this.state;
        if (fromDate) {
            options = { ...options, from_date: formatDate(fromDate) };
        }
        if (toDate) {
            options = { ...options, to_date: formatDate(toDate) };
        }
        if (region.value) {
            options = { ...options, company_id: region.value };
        }
        if (assistant.value) {
            options = { ...options, assistant_id: assistant.value };
        }
        if (type.value) {
            options = { ...options, type: type.value };
        }
        return options;
    };

    search = () => {
        this.setState({ loading: true });
        const options = {
            type: this.state.eventType,
            calendar: true,
        };
        this.props.fetchActivitiesForScheduling({ params: this.addFilters(options) })
            .then(res => {
                this.setState({
                    events: res.response.data.map(event => {
                        let startDT = formatDate(event.event_start_date);
                        if (event.event_start_time && event.event_start_time !== '')
                            startDT += ' ' + event.event_start_time;
                        startDT = new Date(startDT);

                        let endDT = null;
                        if (validDate(event.event_end_date))
                            endDT = formatDate(event.event_end_date);
                        if (event.event_end_time && event.event_end_time !== '')
                            endDT += ' ' + event.event_end_time;
                        endDT = new Date(endDT);

                        let title = event.assistant ? `${event.assistant.name} ${event.assistant.last_name}` : '';
                        title += ' (' + startDT.toLocaleTimeString('en-US') + ' - ' + endDT.toLocaleTimeString('en-US') + ')';

                        return {
                            start: startDT,
                            end: endDT,
                            title: title,
                            id: event.old_event_id,
                            status: event.status,
                        }
                    })
                });
            }).finally(() => {
                this.setState({ loading: false });
            });
    };

    setFromDate = (date) => {
        this.setState({ fromDate: date });
    };

    setToDate = (date) => {
        this.setState({ toDate: date });
    };

    setRegion = (value) => {
        this.setState({ region: value });
    };

    setAssistant = (value) => {
        this.setState({ assistant: value });
    };

    setType = (value) => {
        this.setState({ type: value });
    };

    resetFilter = () => {
        this.setFromDate('');
        this.setToDate('');
        this.setRegion({
            value: '',
            label: 'Select Region',
        });
        this.setAssistant({
            value: '',
            label: 'Select Assistant',
        });
        this.setType({
            value: '',
            label: 'Select Type',
        });
        this.loadEvents();
    };

    render() {
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                    style={{
                        minHeight: '132vh',
                    }}>
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div className="right-col">
                    <div className="custom-class">
                        <div className="scheduling-filters-column">
                            <label>From Date</label>
                            <DatePicker
                                isClearable
                                popperPlacement='right-start'
                                className="filter-input"
                                selected={this.state.fromDate}
                                onChange={date => this.setFromDate(date)}
                            />
                        </div>
                        <div className="scheduling-filters-column">
                            <label>To Date</label>
                            <DatePicker
                                isClearable
                                className="filter-input"
                                popperPlacement='right-start'
                                selected={this.state.toDate}
                                onChange={date => this.setToDate(date)}
                            />
                        </div>
                        <div className="scheduling-filters-column">
                            <CustomDropDown
                                type={'regions'}
                                isRequired={false}
                                isMulti={false}
                                selectedValue={this.state?.region}
                                onChange={value => this.setRegion(value)}>
                            </CustomDropDown>
                        </div>
                        <div className="scheduling-filters-column">
                            {this.state?.region?.value ? (
                                <CustomDropDown
                                    key={this.state.region?.value}
                                    type={'regional_assistants'}
                                    isRequired={false}
                                    isMulti={false}
                                    region={this.state.region.value}
                                    selectedValue={this.state?.assistant}
                                    onChange={value => this.setAssistant(value)}>
                                </CustomDropDown>
                            ) : (
                                <CustomDropDown
                                    key={this.state.region?.value}
                                    type={'assistants'}
                                    isRequired={false}
                                    isMulti={false}
                                    selectedValue={this.state?.assistant}
                                    onChange={value => this.setAssistant(value)}>
                                </CustomDropDown>
                            )}
                        </div>
                        <div className="scheduling-filters-column">
                            <CustomDropDown
                                key={this.state.region?.value}
                                type={'event_types'}
                                isRequired={false}
                                isMulti={false}
                                selectedValue={this.state?.type}
                                onChange={value => this.setType(value)}>
                            </CustomDropDown>
                        </div>
                        <div className="filter-footer">
                            <button
                                type="submit"
                                onClick={this.resetFilter}
                                className="scheduling-filter-button">
                                Refresh
                            </button>
                            <button
                                type="submit"
                                onClick={this.search}
                                className="scheduling-filter-button">
                                Search
                            </button>
                        </div>
                    </div>
                    <br />
                    <div style={{
                        maxWidth: '20%',
                    }}>
                        <CustomSelect type='Event Type'
                            className="filter-input"
                            value={this.state.eventType}
                            onChange={event => this.setState({ eventType: event.target.value }, () => this.loadEvents())}>
                        </CustomSelect>
                    </div>
                    <div className="bottom-spacing">
                        <LoadingOverlay
                            active={this.state.loading}
                            className='z-index'
                            spinner={<img
                                style={{
                                    width: '200px',
                                }}
                                src={loaderImage}
                                alt="loading..."
                            />}>
                            <EventCalendar
                                events={this.state.events}
                            />
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activities: state.dashboardReducer.activities,
        totalRecords: state.dashboardReducer.totalActivities,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchActivitiesForScheduling,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Dashboard));