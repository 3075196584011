import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import {
    APP_ROUTES,
    NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createGroup, updateGroup } from "../../actions/groups";
import { groupService } from "../../services/groups";
import { FormValidatorService } from "../../services/formvalidator";
import { utilityService } from "../../services/utility";
import CustomDropDown from "../organisms/CustomDropDown";
import CustomSelect from "../organisms/CustomSelect";

const validationGroup = {
    message: "There are some validation errors:",
    fields: {
        'groupName': { filedName: 'Group Name', required: true, minLength: 3, maxLength: 50 }
    }
}

class ManageGroups extends React.Component {
    state = {
        groupName: "",
        facilitylistingoption: "",
        surgeonlistingoption: "",
        patientlistingoption: "",
        prefrencecardlistingoption: "",
        modules: [],
        appModules: [],
        selectedCategory: [],
        isAddAccess: false,
        isUpdateAccess: false,
        isDeleteAccess: false,
    };
    constructor() {
        super();
        this.onAddAccessChanged = this.onAddAccessChanged.bind(this);
        this.onListAccessChanged = this.onListAccessChanged.bind(this);
        this.onUpdateAccessChanged = this.onUpdateAccessChanged.bind(this);
        this.onDeleteAccessChanged = this.onDeleteAccessChanged.bind(this);

        this.onAppAddAccessChanged = this.onAppAddAccessChanged.bind(this);
        this.onAppListAccessChanged = this.onAppListAccessChanged.bind(this);
        this.onAppUpdateAccessChanged = this.onAppUpdateAccessChanged.bind(this);
        this.onAppDeleteAccessChanged = this.onAppDeleteAccessChanged.bind(this);
    }
    componentDidMount() {
        const groupId = this.props.location.state
            ? this.props.location.state.groupId
            : "";
        if (groupId) {
            groupService.getGroupDetail(groupId).then((response) => {
                this.setState({
                    groupName: response.data.group_name,
                    patientlistingoption: response.data.patientlistingoption,
                    surgeonlistingoption: response.data.surgeonlistingoption,
                    facilitylistingoption: response.data.facilitylistingoption,
                    prefrencecardlistingoption: response.data.prefrencecardlistingoption,
                    selectedCategory: response.data.categories,
                    modules: response.data.modules_groups.map((module) => {
                        return {
                            label: module.module_name,
                            value: module.id,
                            isAddAccess: module.pivot.isAddAccess,
                            isUpdateAccess: module.pivot.isUpdateAccess,
                            isDeleteAccess: module.pivot.isDeleteAccess,
                            isListAccess: module.pivot.isListAccess
                        };
                    }),
                    appModules: response.data.app_modules_groups.map((module) => {
                        return {
                            label: module.module_name,
                            value: module.id,
                            isAddAccess: module.pivot.isAddAccess,
                            isUpdateAccess: module.pivot.isUpdateAccess,
                            isDeleteAccess: module.pivot.isDeleteAccess,
                            isListAccess: module.pivot.isListAccess
                        };
                    }),
                });
            });
        }
    }

    onAppAddAccessChanged(e) {
        // eslint-disable-next-line
        this.state.appModules[e.target.name].isAddAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }
    onAppListAccessChanged(e) {
        // eslint-disable-next-line
        this.state.appModules[e.target.name].isListAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }
    onAppUpdateAccessChanged(e) {
        // eslint-disable-next-line
        this.state.appModules[e.target.name].isUpdateAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }
    onAppDeleteAccessChanged(e) {
        // eslint-disable-next-line
        this.state.appModules[e.target.name].isDeleteAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }
    onAddAccessChanged(e) {
        // eslint-disable-next-line
        this.state.modules[e.target.name].isAddAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }
    onListAccessChanged(e) {
        // eslint-disable-next-line
        this.state.modules[e.target.name].isListAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }
    onUpdateAccessChanged(e) {
        // eslint-disable-next-line
        this.state.modules[e.target.name].isUpdateAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }
    onDeleteAccessChanged(e) {
        // eslint-disable-next-line
        this.state.modules[e.target.name].isDeleteAccess = e.target.checked;
        this.setState({
            modules: this.state.modules
        })
    }

    onChangeCategory = (item, value) => {
        this.setState({ selectedCategory: item });
    }

    onChangeModule = (value) => {
        this.setState({ modules: value });
    };

    onChangeAppModule = (value) => {
        this.setState({ appModules: value });
    };

    onChangePractice = (value) => {
        this.setState({ practice: value });
    };

    onChangeRegion = (value) => {
        this.setState({ region: value });
    };

    validateActivity = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    submitUser = () => {
        if (!this.validateActivity()) {
            return;
        }

        const { createGroup, updateGroup } = this.props;
        const {
            groupName,
            prefrencecardlistingoption,
            patientlistingoption,
            surgeonlistingoption,
            facilitylistingoption,
            modules,
            appModules,
            isAddAccess,
            isUpdateAccess,
            isDeleteAccess,
            selectedCategory
        } = this.state;

        const request = {
            group_name: groupName,
            prefrencecardlistingoption: prefrencecardlistingoption,
            patientlistingoption: patientlistingoption,
            surgeonlistingoption: surgeonlistingoption,
            facilitylistingoption: facilitylistingoption,
            modules: modules,
            app_modules: appModules,
            isAddAccess: isAddAccess,
            isUpdateAccess: isUpdateAccess,
            isDeleteAccess: isDeleteAccess,
        };

        const groupId = this.props.location.state
            ? this.props.location.state.groupId
            : "";
        request.modules = modules;
        request.app_modules = appModules;
        request.articles = selectedCategory;

        if (groupId) {
            const payload = { ...request, id: groupId };
            updateGroup(payload)
                .then((res) => {
                    if (res.response.success) {
                        createNotification(
                            NOTIFICATION_TYPES.SUCCESS,
                            res.response.message
                        );
                        this.props.history.push(APP_ROUTES.GROUP_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                    }
                })
        } else {
            createGroup(request)
                .then((res) => {
                    if (res.response.success) {
                        this.props.history.push(APP_ROUTES.GROUP_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                    }
                })
        }
    };

    toggleCheckbox = (name, event) => {
        let obj = {};
        obj[name] = !this.state[name];
        this.setState(obj);
    };

    render() {
        const groupId = this.props.location.state
            ? this.props.location.state.groupId
            : "";
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {groupId && <h2 className="heading-custom">Edit Group</h2>}
                            {!groupId && <h2 className="heading-custom">Add Group</h2>}
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>
                                Group Name <span className="required">*</span>
                            </label>
                            <input
                                type="text"
                                className={FormValidatorService.getClass(validationGroup, this.state, 'groupName')}
                                value={this.state.groupName}
                                onChange={(event) => {
                                    this.setState({ groupName: event.target.value });
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <CustomSelect type='Patient Listing Option'
                                className="filter-input"
                                value={this.state.patientlistingoption}
                                onChange={(event) => this.setState({ patientlistingoption: event.target.value })}>
                            </CustomSelect>
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <CustomSelect type='Facility Listing Option'
                                className="filter-input"
                                value={this.state.facilitylistingoption}
                                onChange={(event) => this.setState({ facilitylistingoption: event.target.value })}>
                            </CustomSelect>
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <CustomSelect type='Surgeon Listing Option'
                                className="filter-input"
                                value={this.state.surgeonlistingoption}
                                onChange={(event) => this.setState({ surgeonlistingoption: event.target.value })}>
                            </CustomSelect>
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <CustomSelect type='Prefrence Card Listing Option'
                                className="filter-input"
                                value={this.state.prefrencecardlistingoption}
                                onChange={(event) => this.setState({ prefrencecardlistingoption: event.target.value })}>
                            </CustomSelect>
                        </div>

                        <div className="col-md-3" style={{ color: "black", float: "right" }}>
                            <CustomDropDown
                                type={'knowledgebase_categories_with_sub_categories'}
                                isRequired={false}
                                isMulti={true}
                                selectedValue={this.state?.selectedCategory}
                                onChange={this.onChangeCategory}>
                            </CustomDropDown>
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <CustomDropDown
                                type={'modules'}
                                isRequired={false}
                                isMulti={true}
                                selectedValue={this.state?.modules}
                                onChange={this.onChangeModule}>
                            </CustomDropDown>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <CustomDropDown
                                type={'app_modules'}
                                isRequired={false}
                                isMulti={true}
                                selectedValue={this.state?.appModules}
                                onChange={this.onChangeAppModule}>
                            </CustomDropDown>
                        </div>

                        <div className="col-md-6 mrgn-btm10">
                            <h5 style={{ textAlign: "center" }}>Modules</h5>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Module</th>
                                        <th>List</th>
                                        <th>Add</th>
                                        <th>Update</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.modules.map((val, index) => {
                                            return (
                                                <tr>
                                                    <td>{val.label}</td>
                                                    <td><input type="checkbox" name={index} checked={val.isListAccess} onChange={this.onListAccessChanged} /></td>
                                                    <td><input type="checkbox" name={index} checked={val.isAddAccess} onChange={this.onAddAccessChanged} ></input></td>
                                                    <td><input type="checkbox" name={index} checked={val.isUpdateAccess} onChange={this.onUpdateAccessChanged}></input></td>
                                                    <td><input type="checkbox" name={index} checked={val.isDeleteAccess} onChange={this.onDeleteAccessChanged}></input></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <h5 style={{ textAlign: "center" }}>App Modules</h5>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Module</th>
                                        <th>List</th>
                                        <th>Add</th>
                                        <th>Update</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.appModules.map((val, index) => {
                                            return (
                                                <tr>
                                                    <td>{val.label}</td>
                                                    <td><input type="checkbox" name={index} checked={val.isListAccess} onChange={this.onAppListAccessChanged} /></td>
                                                    <td><input type="checkbox" name={index} checked={val.isAddAccess} onChange={this.onAppAddAccessChanged} ></input></td>
                                                    <td><input type="checkbox" name={index} checked={val.isUpdateAccess} onChange={this.onAppUpdateAccessChanged}></input></td>
                                                    <td><input type="checkbox" name={index} checked={val.isDeleteAccess} onChange={this.onAppDeleteAccessChanged}></input></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="filter-footer">
                        {groupId && (
                            <button onClick={this.submitUser} className="filter-save-button">
                                Update
                            </button>
                        )}
                        {!groupId && (
                            <button onClick={this.submitUser} className="filter-save-button">
                                Submit
                            </button>
                        )}
                        <button
                            onClick={() => {
                                this.props.history.push(APP_ROUTES.GROUP_DASHBOARD);
                            }}
                            className="filter-cancel-button"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createGroup,
            updateGroup,
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageGroups));
