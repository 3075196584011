import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { submitInvoicing, updateInvoicing, addMultipleInvoicing } from "../../actions/invoicing";

import "rc-time-picker/assets/index.css";
import ReactTooltip from "react-tooltip";

import LoadingOverlay from "react-loading-overlay";
import logo from "../../../assets/images/universal-logo.png";
import { invoicingService } from "../../services/invoicing";

import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { formatDate, formatAMPM, formatDateUI } from "../../utils/date";

import "react-datepicker/dist/react-datepicker.css";
import "../../../sass/manageactivities.scss";
import { createNotification } from "../../utils/notificationManager";
import deleteIcon from "../../../assets/images/delete.png";
import viewIcon from "../../../assets/images/view.png";
import loaderImage from "../../../assets/images/CoureMax.gif";
import { utilityService } from "../../services/utility";
import CustomDropDown from "../organisms/CustomDropDown";

const REQUIRED_FIELDS = {
  PATIENT: "patient",
  EVENT_TYPE: "eventType",
  SELECTED_PROCEDURES: "selectedProcedures",
  SURGEON: "surgeon",
  FACILITY: "facility",
  ASSISTANT: "assistant",
  ADMISSION_DATE: "admissionDate",
  START_TIME: "startTime",
  PRICE: "0",
  account_number: "",
};

class ManageInvoicing extends React.Component {
  static propTypes = {
    submitInvoicing: PropTypes.func.isRequired,
    updateInvoicing: PropTypes.func.isRequired,
    addMultipleInvoicing: PropTypes.func.isRequired,
    screen: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      invoicelistdetail: {},
      showAddPatient: false,
      showAddProcedure: false,
      facilityInvoiceRequirements: "noneed",
      patient: {},
      file: null,
      fileType: "",
      allSheets: [],
      selectedProcedures: [],
      surgeon: {},
      facility: {},
      assistant: {},
      startDate: Date.now(),
      admissionDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      eventType: {},
      eventFlags: [],
      diagnosisCodes: "",
      cptCodes: "",
      status: {},
      notes: "",
      modifiedBy: "",
      createdBy: "",
      createdUser: "",
      fields: {},
      loading: false,
      errors: [],
      price: 0,
      account_number: 0,
    };
    this.cancel = "";
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  getStatusName = (id) => {
    if (id === "Draft") {
      return "Select Status";
    } else if (id === "started") {
      return "Surgery In Progress";
    } else if (id === "completed") {
      return "Surgery Completed";
    } else if (id === "cancelled") {
      return "Cancelled Surgery";
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.location.state)
      props.location.state = {};
    if (!props.location.state.listItem)
      props.location.state.listItem = [];

    if (props.calledFromModal) {
      if (!props.selectedProcedures) {
        props.selectedinvoices.forEach(tempValue => {
          tempValue.selectedProcedures = [];

          tempValue.procedures.forEach(tempProc => {
            tempValue.selectedProcedures.push({
              label: tempProc.procedure_name,
              value: tempProc.id,
              cptcode: tempProc.cptcode,
              procedure_price: tempProc.price,
            })
          })
        });
      }

      props.location.state.listItem = props.selectedinvoices;
    }

    state.loading = false;
  }

  componentDidMount() {
    if (!this.props.calledFromModal) {
      const activityId = this.props.location.state
        ? this.props.location.state.listItem[0]
        : "";

      if (activityId) {
        this.setLoading(true);
        invoicingService.getActivityDetail(activityId).then((response) => {
          if (!response.data)
            return;
          this.setState({
            facilityInvoiceRequirements: response.data.facility.invoice_req,
            account_number: response.data.patient.account_number
              ? response.data.patient.account_number
              : "0",
          });
          this.setState(
            {
              eventType: {
                label: response.data.event_type
                  ? response.data.event_type.event_type_name
                  : "",
                value: response.data.event_type
                  ? parseInt(response.data.event_type.id)
                  : "",
              },
              selectedProcedures: response.data.procedures.map((procedure) => {
                return {
                  label: procedure.procedure_name,
                  value: procedure.id,
                  cptcode: procedure.cptcode,
                  procedure_price: procedure.price,
                };
              }),
              patient: {
                label: response.data.patient
                  ? `${response.data.patient.first_name} ${response.data.patient.last_name}`
                  : "",
                value: response.data.patient
                  ? parseInt(response.data.patient.id)
                  : "",
                date_of_birth: response.data.patient
                  ? response.data.patient.date_of_birth
                  : "",
              },
              surgeon: {
                label: response.data.surgeon
                  ? `${response.data.surgeon.first_name} ${response.data.surgeon.last_name}`
                  : "",
                value: response.data.surgeon
                  ? parseInt(response.data.surgeon.id)
                  : "",
              },
              facility: {
                label: response.data.facility
                  ? response.data.facility.facility_name
                  : "",
                value: response.data.facility
                  ? parseInt(response.data.facility.id)
                  : "",
                price: response.data.facility
                  ? parseInt(response.data.facility.price)
                  : "",
              },
              assistant: {
                label: response.data.assistant
                  ? `${response.data.assistant.name} ${response.data.assistant.last_name}`
                  : "",
                value: response.data.assistant
                  ? parseInt(response.data.assistant.id)
                  : "",
              },
              startDate: Date.parse(formatDate(response.data.event_start_date)),
              admissionDate: Date.parse(formatDate(response.data.admission_date)),
              startTime: moment(response.data.event_start_time, "hh:mm A"),
              endDate: response.data.event_end_date
                ? Date.parse(formatDate(response.data.event_end_date))
                : "",
              endTime:
                response.data.event_end_time !== "" &&
                  response.data.event_end_time !== "null" &&
                  response.data.event_end_time !== null
                  ? moment(response.data.event_end_time, "hh:mm A")
                  : "",
              diagnosisCodes: response.data.diagnosis_codes || "",
              cptCodes: response.data.cpt_codes || "",
              status: {
                label: "Select Status",
                value: "NOT STARTED",
              },
              notes: response.data.event_notes || "",
              eventFlags:
                response.data.event_flag &&
                response.data.event_flag.map((event_flag) => {
                  return {
                    label: event_flag.schedule_flag,
                    value: event_flag.id,
                  };
                }),
              allSheets:
                response.data.all_sheets.length > 0
                  ? response.data.all_sheets
                  : [],
              modifiedBy: response.data.modified_by
                ? `${response.data.modified_by.name} ${response.data.modified_by.last_name}`
                : "",
              createdBy: response.data.created_by
                ? `${response.data.created_by.name} ${response.data.created_by.last_name}`
                : "",
              createdUser: response.data.created_by
                ? response.data.created_by.id
                : "",
            },
            () => {
              this.setLoading(false);
            }
          );
        });
      }
    }
  }

  loadInvoiceData = (search) => {
    return invoicingService.getInvoiceDetail(search).then((response) => {
      // this.setState({ invoicelistdetail: response });
      return response.data;
    });
  };

  onChangeProcedureMultiple(item, value) {
    item.selectedProcedures = value;
    this.resetErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES);
    this.setState({ selectedProcedures: value });
  }

  onChangeProcedure = (value) => {
    this.resetErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES);
    this.setState({ selectedProcedures: value });
  };

  onChangeSurgeon = (value) => {
    this.resetErrors(REQUIRED_FIELDS.SURGEON);
    this.setState({ surgeon: value });
  };

  onChangeFacility = (value) => {
    this.resetErrors(REQUIRED_FIELDS.FACILITY);
    this.setState({ facility: value });
  };

  onChangeAssistant = (value) => {
    this.resetErrors(REQUIRED_FIELDS.ASSISTANT);
    this.setState({ assistant: value });
  };

  setStartDate = (value) => {
    this.setState({ startDate: value });
  };

  setAdmissionDate = (value) => {
    this.resetErrors(REQUIRED_FIELDS.ADMISSION_DATE);
    this.setState({ admissionDate: value });
  };

  setEndDate = (value) => {
    this.setState({ endDate: value });
  };

  setStartTime = (value) => {
    this.setState({ startTime: value });
  };

  onChangeEventType = (value) => {
    this.resetErrors(REQUIRED_FIELDS.EVENT_TYPE);
    this.setState({ eventType: value });
  };

  onChangePatient = (value) => {
    this.resetErrors(REQUIRED_FIELDS.PATIENT);
    this.setState({ patient: value });
  };

  onChangeEventFlags = (value) => {
    this.setState({ eventFlags: value });
  };

  onChangeStatus = (value) => {
    this.setState({ status: value });
  };

  validateActivity = () => {
    const {
      patient,
      eventType,
      //selectedProcedures,
      surgeon,
      facility,
      assistant,
      admissionDate,
      startTime,
      errors,
      account_number,
      price,
    } = this.state;

    let isValid = true;
    let validation_error = "";
    if (Object.keys(patient).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.PATIENT] });
      isValid = false;
      validation_error = "Patient is Required";
    } else if (Object.keys(eventType).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.EVENT_TYPE] });
      isValid = false;
    }
    // else if (selectedProcedures.length === 0) {
    //   this.setState({
    //     errors: [...errors, REQUIRED_FIELDS.SELECTED_PROCEDURES],
    //   });
    //   isValid = false;
    // }
    else if (Object.keys(surgeon).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.SURGEON] });
      isValid = false;
      validation_error = "Surgeon is Required";
    } else if (Object.keys(facility).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.FACILITY] });
      isValid = false;
      validation_error = "Facility is Required";
    } else if (Object.keys(assistant).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.ASSISTANT] });
      isValid = false;
      validation_error = "Assistant is Required";
    } else if (admissionDate.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.ADMISSION_DATE] });
      isValid = false;
      validation_error = "Admission Date is Required";
    } else if (startTime.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.START_TIME] });
      isValid = false;
      validation_error = "Start Time is Required";
    } else if (account_number.length === 0 || account_number === "0") {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.account_number] });
      isValid = false;
      validation_error = "Account Number is Required";
    } else if (price.length === 0 || price === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.price] });
      isValid = false;
      validation_error = "Fee is Required";
    }

    if (!isValid) {
      createNotification(NOTIFICATION_TYPES.ERROR, validation_error);
    }

    return isValid;
  };

  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };

  submitInvoicing = (event) => {
    const { submitInvoicing, updateInvoicing, addMultipleInvoicing } = this.props;
    const {
      patient,
      //file,
      //fileType,
      selectedProcedures,
      surgeon,
      facility,
      assistant,
      startDate,
      endDate,
      startTime,
      endTime,
      eventType,
      eventFlags,
      diagnosisCodes,
      cptCodes,
      notes,
      admissionDate,
      status,
      createdUser,
      price,
      account_number,
    } = this.state;
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );

    if (this.props.calledFromModal) {
      let postData = [];
      this.props.location.state.listItem.forEach(tItem => {
        let tempItem = {};
        tempItem.account_number = tItem.account_number;
        tempItem.dos = tItem.event_start_date;
        tempItem.patient_name = tItem.patient.fullname;
        tempItem.patient_id = tItem.patient.id;
        tempItem.dob = tItem.patient.date_of_birth;
        tempItem.insurance = tItem.patient.patient_insurance_data?.primary?.description;
        tempItem.assistant_id = tItem.assistant?.id;
        tempItem.surgeon_id = tItem.surgeon?.id;
        tempItem.facility_id = tItem.facility?.id;
        tempItem.fee = tItem.price;
        tempItem.scan_flow_id = tItem.activity_id;
        tempItem.modified_by = loggedInUser.id;
        tempItem.status = status.value ?? '';
        tempItem.procedure_ids = tItem.selectedProcedures.map((procedure) => procedure.value).join()
        tempItem.procedure_names = tItem.selectedProcedures.map((procedure) => procedure.label).join()

        postData.push(tempItem);
      })

      this.setLoading(true);
      addMultipleInvoicing(postData)
        .then((res) => {
          createNotification(
            NOTIFICATION_TYPES.SUCCESS,
            res.response.message
          );

          this.props.search();
          this.props.closePopup();
        })
        .finally(() => {
          this.setLoading(false);
        })
    }
    else {
      if (!this.validateActivity()) {
        return;
      }

      const scan_flow_id = this.props.location.state.listItem[0];
      const formData = new FormData();
      formData.append("user_id", createdUser || loggedInUser.id);
      formData.append("scan_flow_id", scan_flow_id);
      formData.append("price", price);
      formData.append("account_number", account_number);
      formData.append("date_of_birth", patient.date_of_birth);
      formData.append("dos", this.props.location.state.listItem[2]);
      formData.append("event_surgeon", surgeon.value);
      formData.append("patient_id", patient.value);
      formData.append("modified_by", loggedInUser.id);
      formData.append("insurance", this.props.location.state.listItem[11]);
      formData.append("patient_name", patient.label);
      formData.append(
        "procedure_id",
        selectedProcedures.map((procedure) => procedure.value).join()
      );
      formData.append(
        "procedure_name",
        selectedProcedures.map((procedure) => procedure.label).join()
      );
      formData.append("event_facility", facility.value);
      formData.append("event_surgical_assitant", assistant.value);
      formData.append("cpt_codes", cptCodes);
      formData.append("cpt_codes_notes", "");
      formData.append("diagnosis_codes", diagnosisCodes);
      formData.append("event_start_date", formatDate(startDate));
      formData.append("event_end_date", endDate !== "" ? formatDate(endDate) : "");
      formData.append(
        "event_start_time",
        formatAMPM(`${startTime.hours()}:${startTime.minutes()}`)
      );
      formData.append(
        "event_end_time",
        endTime !== ""
          ? formatAMPM(`${endTime.hours()}:${endTime.minutes()}`)
          : ""
      );
      formData.append("event_type", eventType.value);
      formData.append(
        "event_flags",
        eventFlags.map((eventFlag) => eventFlag.value).join()
      );
      formData.append("event_notes", notes);
      formData.append("admission_date", formatDate(admissionDate));
      formData.append("status", status.value);
      const activityId = this.props.location.state
        ? this.props.location.state.activityId
        : "";
      /*const screen = this.props.location.state
        ? this.props.location.state.screen
        : "";*/
      this.setLoading(true);
      if (activityId) {
        formData.append("id", activityId);
        formData.append("status", status.value);
        updateInvoicing(formData)
          .then((res) => {
            if (res.response.data.success) {
              this.props.history.push({
                pathname: APP_ROUTES.INVOICING_DASHBOARD,
                state: {
                  currentPage: this.props.location.state.currentPage,
                  pageSize: this.props.location.state.pageSize,
                },
              });
            } else {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );

              this.props.history.push({
                pathname: APP_ROUTES.INVOICING_DASHBOARD,
                state: {
                  scanflowfiltersoptions:
                    this.props.location.state.scanflowfiltersoptions,
                  currentPage: this.props.location.state.currentPage,
                  pageSize: this.props.location.state.pageSize,
                },
              });
            }
            this.setLoading(false);
          })
      } else {
        submitInvoicing(formData)
          .then((res) => {
            if (res.response.data.success) {
              this.props.history.push(APP_ROUTES.INVOICING);
            } else {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );

              this.props.history.push({
                pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
                state: {
                  scanflowfiltersoptions:
                    this.props.location.state.scanflowfiltersoptions,
                  currentPage: this.props.location.state.currentPage,
                  pageSize: this.props.location.state.pageSize,
                },
              });
            }
            this.setLoading(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    }
  };

  toggleAddPatient = (flag) => {
    this.setState({ showAddPatient: flag });
  };

  toggleAddProcedure = (flag) => {
    this.setState({ showAddProcedure: flag });
  };

  setPatient = (value) => {
    this.resetErrors(REQUIRED_FIELDS.PATIENT);
    this.setState({ patient: value });
  };

  setProcedure = (value) => {
    this.resetErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES);
    this.setState((prevstate) => ({
      selectedProcedures: [...prevstate.selectedProcedures, value],
    }));
  };

  onFileChangeHandler = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  cancelNav = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push({
        screen,
        state: {
          scanflowfiltersoptions:
            this.props.location.state.scanflowfiltersoptions,
          currentPage: this.props.location.state.currentPage,
          pageSize: this.props.location.state.pageSize,
        },
      });
    } else {
      this.props.history.push({
        pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
        state: {
          scanflowfiltersoptions:
            this.props.location.state.scanflowfiltersoptions,
          currentPage: this.props.location.state.currentPage,
          pageSize: this.props.location.state.pageSize,
        },
      });
    }
  };

  renderHeadColumn = () => {
    const headColumn = ["File Name", "Type", "Action"];

    return headColumn.map((column) => (
      <th className="listing-table-head-column">{column}</th>
    ));
  };

  deleteFile = (faceSheetId) => {
    utilityService.showConfirmDialog().then(data => {
      // invoicingService
      //   .deleteActivityFaceSheet(faceSheetId)
      //   .then((response) => {
      //     this.resetErrors();
      //   });
    })
  };

  renderSheetList = () => {
    const { allSheets } = this.state;
    return allSheets.map((sheet) => {
      const facesheetFile = sheet.facesheet_file.split("/");
      const fileName =
        facesheetFile.length > 0 ? facesheetFile[facesheetFile.length - 1] : "";
      const sheetDetails = [fileName, sheet.type, sheet.id];
      const item = sheetDetails.map((item, index) => {
        if (index === 2) {
          return (
            <td className="listing-table-column">
              <a href={sheet.facesheet_file} target="_blank" rel="noreferrer">
                <img src={viewIcon} alt="view" />
              </a>
              <img
                src={deleteIcon}
                onClick={() => {
                  this.deleteFile(item);
                }}
                alt="view"
              />
            </td>
          );
        } else {
          return <td className="listing-table-column">{item}</td>;
        }
      });
      return <tr className="listing-table-rows">{item}</tr>;
    });
  };

  render() {
    const activityId = this.props.location.state
      ? this.props.location.state.activityId
      : "";

    let invoiceProcedure;
    let procedure_heading;
    let procedure_value;
    let procedure_price;
    let account_number;
    account_number = (
      <div className="col-md-10 ">
        <input
          type="text"
          style={{ width: "150px" }}
          className={
            this.hasErrors(REQUIRED_FIELDS.account_number)
              ? "error code-input"
              : "code-input"
          }
          value={this.state.account_number}
          onChange={(event) => {
            this.resetErrors(REQUIRED_FIELDS.account_number);
            this.setState({ account_number: event.target.value });
          }}
        />
      </div>
    );
    if (this.state.facility.price === 0) {
      procedure_price = (
        <div className="col-md-20 ">
          <input
            type="text"
            style={{ width: "80px" }}
            className={
              this.hasErrors(REQUIRED_FIELDS.price)
                ? "error code-input"
                : "code-input"
            }
            value={this.state.price}
            onChange={(event) => {
              this.resetErrors(REQUIRED_FIELDS.price);
              this.setState({ price: event.target.value });
            }}
          />
        </div>
      );

      // procedure_price = this.state.selectedProcedures.map(function (
      //   item,
      //   index
      // ) {
      //   if (
      //     item.procedure_price !== undefined &&
      //     item.procedure_price !== null &&
      //     item.procedure_price.length !== 0
      //   ) {
      //     console.log(item.procedure_price);
      //     return item.procedure_price;
      //   } else {
      //     return "";
      //   }
      // });
    } else {
      procedure_price = this.state.facility.price;
      procedure_price = (
        <div className="col-md-20 ">
          <input
            type="text"
            style={{ width: "80px" }}
            className={
              this.hasErrors(REQUIRED_FIELDS.price)
                ? "error code-input"
                : "code-input"
            }
            value={this.state.price}
            onChange={(event) => {
              this.resetErrors(REQUIRED_FIELDS.price);
              this.setState({ price: event.target.value });
            }}
          />
        </div>
      );
    }

    if (this.state.facilityInvoiceRequirements === "noneed") {
      invoiceProcedure = <div className="col-md-8"></div>;
    } else if (this.state.facilityInvoiceRequirements === "procedurename") {
      procedure_value = this.state.selectedProcedures.map(function (
        item,
        index
      ) {
        return (index ? ", " : "") + item.label;
      });
      procedure_heading = "Procedures : ";
      invoiceProcedure = (
        <div className="col-md-8" style={{ color: "black", float: "right" }}>
          <CustomDropDown
            ref="procedure"
            type={'procedures'}
            isRequired={true}
            isMulti={true}
            className={this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""}
            selectedValue={this.state.selectedProcedures}
            onChange={this.onChangeProcedure}>
          </CustomDropDown>
        </div>
      );
    } else if (this.state.facilityInvoiceRequirements === "cptcode") {
      procedure_value = this.state.selectedProcedures.map(function (
        item,
        index
      ) {
        return (index ? ", " : "") + item.value;
      });
      procedure_heading = "CPT Code a: ";
      invoiceProcedure = (
        <div className="col-md-8" style={{ color: "black", float: "right" }}>
          <CustomDropDown
            ref="procedure"
            type={'procedures'}
            isRequired={true}
            isMulti={true}
            className={this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""}
            selectedValue={this.state.selectedProcedures}
            onChange={this.onChangeProcedure}>
          </CustomDropDown>
        </div>
      );
    } else if (this.state.facilityInvoiceRequirements === "both") {
      procedure_value = this.state.selectedProcedures.map(function (
        item,
        index
      ) {
        return (index ? ", " : "") + item.label + item.value;
      });
      procedure_heading = "Procedure & CPT Code: ";
      invoiceProcedure = (
        <div className="col-md-8" style={{ color: "black", float: "right" }}>
          <CustomDropDown
            ref="procedure"
            type={'procedures'}
            isRequired={true}
            isMulti={true}
            className={this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""}
            selectedValue={this.state.selectedProcedures}
            onChange={this.onChangeProcedure}>
          </CustomDropDown>
        </div>
      );
    }
    // if (activityId) {
    //   let response;

    //   response = this.loadInvoiceData(activityId);
    //   const getresponse = () => {
    //     response.then((a) => {
    //       // console.log(a);
    //       this.setState({ invoicelistdetail: a });
    //     });
    //   };

    //   getresponse();
    //   console.log("------state ", this.state.invoicelistdetail);
    // }

    if (this.props.calledFromModal) {
      return (
        <div className="main_container">
          <div
            style={{
              minHeight: "830px",
              margin: "20px"
            }}
          >
            <table
              style={{
                width: "100%",
                border: "0",
                margin: "margin: 0 auto",
              }}
            >
              <div className="filter-footer">
                <p
                  style={{
                    width: "100%",
                    border: "0",
                    margin: "margin: 0 auto",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  Please Check Details CareFully and Click Invoice Button.
                </p>
                {!activityId && (
                  <button
                    onClick={this.submitInvoicing}
                    className="filter-save-button"
                  >
                    Save To Invoice
                  </button>
                )}
                {!this.props.calledFromModal && (
                  <button onClick={this.cancelNav} className="filter-cancel-button">
                    Cancel
                  </button>
                )}
                {this.props.calledFromModal && (
                  <button onClick={this.props.closePopup} className="filter-cancel-button">
                    Close
                  </button>
                )}
              </div>
              <tr>
                <td
                  style={{
                    height: "90px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <img src={logo} width="200" height="68" alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            width: "3%",
                          }}
                        >
                          <strong>Address:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            width: "39.5%",
                          }}
                        >
                          1 Sugar Creek Center Blvd. Suite 618 Sugar Land, Texas
                          77478
                        </td>

                        <td>
                          <div
                            className="col-md-4"
                            style={{ color: "black", float: "right" }}
                          >
                            <CustomDropDown
                              hideLabel={true}
                              type={'invoice_statuses'}
                              isRequired={false}
                              isMulti={false}
                              selectedValue={this.state?.status}
                              onChange={this.onChangeStatus}>
                            </CustomDropDown>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          <strong>Bill To:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          {this.props.location.state.listItem[0].facility.address}
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "right",
                          }}
                        >
                          <strong>Date:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          {formatDate(new Date())}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          <strong>Payable to:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          Universal Surgical Assistants
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <strong>Email:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          accounting@uspartnersinc.com
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "right",
                          }}
                        >
                          <strong>Phone:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          832-655-4141
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          colSpan="5"
                          style={{
                            background: "#4c638c",
                            color: "#fff",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "22px",
                            fontWeight: "300",
                            height: "45px",
                            verticalAlign: "middle",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;Invoice
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Account #</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>DOS</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Patient</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>DOB</strong>
                        </th>

                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Insurance</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Assistant</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Surgeon</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Facility</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Total Bill</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.location.state.listItem.map(li => (
                        <>
                          <tr>
                            <td>
                              <input
                                type="text"
                                style={{ width: "150px" }}
                                className={
                                  this.hasErrors(REQUIRED_FIELDS.price)
                                    ? "error code-input"
                                    : "code-input"
                                }
                                value={li.patient.account_number ?? '0'}
                                onChange={(event) => {
                                  this.resetErrors(REQUIRED_FIELDS.price);
                                  li.patient.account_number = event.target.value
                                }}
                              />
                            </td>
                            <td>{formatDateUI(li.event_start_date)}</td>
                            <td data-tip={li.patient.fullname}
                              id={`TooltipExample`}
                            >
                              {li.patient.fullname?.substring(0, 11) +
                                "..." || "NULL"}
                              <ReactTooltip
                                place="bottom"
                                type="light"
                                effect="float"
                              />
                            </td>
                            <td>{formatDateUI(li.patient.date_of_birth)}</td>
                            <td
                              data-tip={li.patient?.patient_insurance_data?.primary?.description ?? ''}
                              id={`TooltipExample`}
                            >
                              {((li.patient?.patient_insurance_data?.primary?.description) ?? '').substring(0, 8) +
                                "..." || "NULL"}
                              <ReactTooltip
                                place="bottom"
                                type="light"
                                effect="float"
                              />
                            </td>
                            <td
                              data-tip={li.assistant?.fullname}
                              id={`TooltipExample`}
                            >
                              {li.assistant?.fullname.substring(0, 8) +
                                "..." || "NULL"}
                              <ReactTooltip
                                place="bottom"
                                type="light"
                                effect="float"
                              />
                            </td>
                            <td
                              data-tip={li.surgeon?.fullname}
                              id={`TooltipExample`}
                            >
                              {li.surgeon?.fullname.substring(0, 8) +
                                "..." || "NULL"}
                              <ReactTooltip
                                place="bottom"
                                type="light"
                                effect="float"
                              />
                            </td>
                            <td
                              data-tip={li.facility?.facility_name}
                              id={`TooltipExample`}
                            >
                              {li.facility?.facility_name.substring(0, 8) +
                                "..." || "NULL"}
                              <ReactTooltip
                                place="bottom"
                                type="light"
                                effect="float"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                style={{ width: "80px" }}
                                className={
                                  this.hasErrors(REQUIRED_FIELDS.price)
                                    ? "error code-input"
                                    : "code-input"
                                }
                                value={li.price}
                                onChange={(event) => {
                                  this.resetErrors(REQUIRED_FIELDS.price);
                                  li.price = event.target.value
                                }}
                              />
                            </td>
                          </tr>
                          {li.facility.invoice_req !== 'noneed' && (
                            <tr>
                              <td colspan="9">
                                <table style={{ 'width': '100%' }}>
                                  <tbody
                                    style={{
                                      borderTop: "2px solid #cfcfcf",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <tr>
                                      <th
                                        style={{
                                          color: "#6f6f6f",
                                          fontFamily: "Segoe, Segoe UI, Verdana",
                                          fontSize: "15px",
                                          fontWeight: "500",
                                          height: "30px",
                                          verticalAlign: "middle",
                                          textAlign: "left",
                                          borderBottom: "1px solid #cfcfcf",
                                        }}
                                      >
                                        <strong>
                                          {li.facility.invoice_req === 'procedurename' && 'Procedures : '}
                                          {li.facility.invoice_req === 'cptcode' && 'CPT Code : '}
                                          {li.facility.invoice_req === 'both' && ' Procedure & CPT Code : '}
                                        </strong>
                                        {li.facility.invoice_req === 'procedurename' && (
                                          <div className="col-md-8" style={{ color: "black", float: "right" }}>
                                            <CustomDropDown
                                              ref="procedure"
                                              type={'procedures'}
                                              isRequired={true}
                                              isMulti={true}
                                              className={this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""}
                                              selectedValue={li.selectedProcedures}
                                              onChange={(value) => this.onChangeProcedureMultiple(li, value)}>
                                            </CustomDropDown>
                                          </div>
                                        )}

                                        {li.facility.invoice_req === 'cptcode' && (
                                          <div className="col-md-8" style={{ color: "black", float: "right" }}>
                                            <CustomDropDown
                                              ref="procedure"
                                              type={'procedures'}
                                              isRequired={true}
                                              isMulti={true}
                                              className={this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""}
                                              selectedValue={li.selectedProcedures}
                                              onChange={(value) => this.onChangeProcedureMultiple(li, value)}>
                                            </CustomDropDown>
                                          </div>
                                        )}

                                        {li.facility.invoice_req === 'both' && (
                                          <div className="col-md-8" style={{ color: "black", float: "right" }}>
                                            <CustomDropDown
                                              ref="procedure"
                                              type={'procedures'}
                                              isRequired={true}
                                              isMulti={true}
                                              className={this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""}
                                              selectedValue={li.selectedProcedures}
                                              onChange={(value) => this.onChangeProcedureMultiple(li, value)}>
                                            </CustomDropDown>
                                          </div>
                                        )}

                                        <div className="col-md-12">
                                          <strong>
                                            {li.facility.invoice_req === 'procedurename' && (
                                              li.selectedProcedures.map(tElement => tElement.label).join(' --- ')
                                            )}

                                            {li.facility.invoice_req === 'cptcode' && (
                                              li.selectedProcedures.map(tElement => tElement.value).join(' --- ')
                                            )}

                                            {li.facility.invoice_req === 'both' && (
                                              li.selectedProcedures.map(tElement => tElement.label + " (" + tElement.value + ")").join(' --- ')
                                            )}
                                          </strong>
                                        </div>
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}

                        </>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#4c638c",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "30px",
                            fontWeight: "700",
                            height: "50px",
                            verticalAlign: "middle",
                            textAlign: "right",
                          }}
                        >

                          Total : ${this.props.location.state.listItem.reduce((sum, item) => sum + parseFloat(!item.price || item.price === '' ? 0 : item.price), 0)}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#4c638c",
                    fontFamily: "Segoe, Segoe UI, Verdana",
                    fontSize: "20px",
                    fontWeight: "700",
                    height: "50px",
                    verticalAlign: "bottom",
                    textAlign: "center",
                  }}
                >
                  Thank you for your Business
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: "100px",
                    textAlign: "center",
                    verticalAlign: "bottom",
                  }}
                >
                  <img src={logo} width="200" height="68" alt="" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#6f6f6f",
                    fontFamily: "Segoe, Segoe UI, Verdana",
                    fontSize: "13px",
                    fontWeight: "400",
                    height: "50px",
                    textAlign: "center",
                  }}
                >
                </td>
              </tr>
            </table>
          </div>

          <LoadingOverlay
            active={this.state.loading}
            spinner={
              <img
                style={{
                  width: "200px",
                }}
                src={loaderImage}
                alt="loading..."
              />
            }
          >
          </LoadingOverlay>
        </div>
      );
    }
    else {
      return (
        <div className="main_container">
          <div
            className="col-md-3 custom-sidebar-menu left_col"
            style={{
              minHeight: "145vh",
            }}
          >
            <Sidebar activePage={this.props.activePage} />
          </div>
          <TopNavbar />

          <div
            style={{
              minHeight: "830px",
            }}
            className="right-col"
          >
            <table
              style={{
                width: "100%",
                border: "0",
                margin: "margin: 0 auto",
              }}
            >
              <div className="filter-footer">
                <p
                  style={{
                    width: "100%",
                    border: "0",
                    margin: "margin: 0 auto",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  Please Check Details CareFully and Click Invoice Button.
                </p>
                {/* <button
                type="submit"
                onClick={this.sendInvoice}
                className="filter-footer-button"
              >
                Send Invoice
              </button> */}
                {!activityId && (
                  <button
                    onClick={this.submitInvoicing}
                    className="filter-save-button"
                  >
                    Save To Invoice
                  </button>
                )}
                <button onClick={this.cancelNav} className="filter-cancel-button">
                  Cancel
                </button>
              </div>
              <tr>
                <td
                  style={{
                    height: "90px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <img src={logo} width="200" height="68" alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            width: "3%",
                          }}
                        >
                          <strong>Address:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            width: "39.5%",
                          }}
                        >
                          1 Sugar Creek Center Blvd. Suite 618 Sugar Land, Texas
                          77478
                        </td>

                        <td>
                          <div
                            className="col-md-4"
                            style={{ color: "black", float: "right" }}
                          >
                            <CustomDropDown
                              hideLabel={true}
                              type={'invoice_statuses'}
                              isRequired={false}
                              isMulti={false}
                              selectedValue={this.state?.status}
                              onChange={this.onChangeStatus}>
                            </CustomDropDown>
                          </div>
                          {invoiceProcedure}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          <strong>Bill To:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          {this.props.location.state.listItem[8]}
                        </td>
                        {/* <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        <strong>Invoice #:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        {this.props.location.state.listItem[0] || 1234}
                      </td> */}
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "right",
                          }}
                        >
                          <strong>Date:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          {formatDate(new Date())}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          <strong>Payable to:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          Universal Surgical Assistants
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <strong>Email:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                          }}
                        >
                          accounting@uspartnersinc.com
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "right",
                          }}
                        >
                          <strong>Phone:</strong>
                        </td>
                        <td
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "14px",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          832-655-4141
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          colSpan="5"
                          style={{
                            background: "#4c638c",
                            color: "#fff",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "22px",
                            fontWeight: "300",
                            height: "45px",
                            verticalAlign: "middle",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;Invoice
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid #cfcfcf",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Account #</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>DOS</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Patient</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>DOB</strong>
                        </th>

                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Insurance</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Assistant</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Surgeon</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Facility</strong>
                        </th>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>Total Bill</strong>
                        </th>
                      </tr>

                      <td>{account_number}</td>
                      <td>{this.props.location.state.listItem[2] || "NULL"}</td>
                      <td
                        data-tip={this.props.location.state.listItem[6]}
                        id={`TooltipExample`}
                      >
                        {this.props.location.state.listItem[6]?.substring(0, 11) +
                          "..." || "NULL"}
                        <ReactTooltip
                          place="bottom"
                          type="light"
                          effect="float"
                        />
                      </td>
                      <td>{this.state.patient.date_of_birth || "NULL"}</td>
                      <td
                        data-tip={this.props.location.state.listItem[11]}
                        id={`TooltipExample`}
                      >
                        {this.props.location.state.listItem[11]?.substring(0, 8) +
                          "..." || "NULL"}
                        <ReactTooltip
                          place="bottom"
                          type="light"
                          effect="float"
                        />
                      </td>
                      <td
                        data-tip={this.props.location.state.listItem[7]}
                        id={`TooltipExample`}
                      >
                        {this.props.location.state.listItem[7]?.substring(0, 8) +
                          "..." || "NULL"}
                        <ReactTooltip
                          place="bottom"
                          type="light"
                          effect="float"
                        />
                      </td>
                      <td>{this.state.surgeon["label"] || "NULL"}</td>
                      <td
                        data-tip={this.props.location.state.listItem[8]}
                        id={`TooltipExample`}
                      >
                        {this.props.location.state.listItem[8]?.substring(0, 11) +
                          "..." || "NULL"}
                        <ReactTooltip
                          place="bottom"
                          type="light"
                          effect="float"
                        />
                      </td>

                      <td>{procedure_price || 0}</td>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table
                    style={{
                      width: "100%",
                      margin: "0 auto",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#4c638c",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "30px",
                            fontWeight: "700",
                            height: "50px",
                            verticalAlign: "middle",
                            textAlign: "right",
                          }}
                        >
                          Total : ${this.state.price}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody
                      style={{
                        borderTop: "2px solid #cfcfcf",
                        paddingTop: "10px",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            color: "#6f6f6f",
                            fontFamily: "Segoe, Segoe UI, Verdana",
                            fontSize: "15px",
                            fontWeight: "500",
                            height: "30px",
                            verticalAlign: "middle",
                            textAlign: "left",
                            borderBottom: "1px solid #cfcfcf",
                          }}
                        >
                          <strong>{procedure_heading}</strong>
                        </th>
                        <td>{procedure_value}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#4c638c",
                    fontFamily: "Segoe, Segoe UI, Verdana",
                    fontSize: "20px",
                    fontWeight: "700",
                    height: "50px",
                    verticalAlign: "bottom",
                    textAlign: "center",
                  }}
                >
                  Thank you for your Business
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: "100px",
                    textAlign: "center",
                    verticalAlign: "bottom",
                  }}
                >
                  <img src={logo} width="200" height="68" alt="" />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "#6f6f6f",
                    fontFamily: "Segoe, Segoe UI, Verdana",
                    fontSize: "13px",
                    fontWeight: "400",
                    height: "50px",
                    textAlign: "center",
                  }}
                >
                </td>
              </tr>
            </table>
          </div>

          <LoadingOverlay
            active={this.state.loading}
            spinner={
              <img
                style={{
                  width: "200px",
                }}
                src={loaderImage}
                alt="loading..."
              />
            }
          >
          </LoadingOverlay>
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitInvoicing,
      updateInvoicing,
      addMultipleInvoicing
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageInvoicing));
