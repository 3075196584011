import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {register} from "../../actions/authentication";
import {APP_ROUTES} from "../../constants/common";

const REQUIRED_FIELDS = {
    NAME: 'name',
    PHONE: 'phone',
    HOME_ADDRESS: 'homeAddress',
    DRIVER_LICENSE: 'driverLicense',
    SSN: 'ssn',
    LICENSE: 'license',
    NPI: 'npi',
    DEA: 'dea',
    ADDRESS: 'address',
    BACKGROUND: 'background',
    DRUG: 'drug',
    CV: 'cv',
    SPECIALITY: 'speciality',
    ACLS: 'acls',
};
class Register extends React.Component {

    state = {
        name: '',
        phone: '',
        homeAddress: '',
        driverLicense: '',
        ssn: '',
        license: '',
        npi: '',
        dea: '',
        address: '',
        background: '',
        drug: '',
        cv: '',
        speciality: '',
        acls: '',
        errors: [],
    };

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    cancel = () => {
        this.props.history.push(APP_ROUTES.LOGIN);
    };

    createAssistant = () => {

    };

    render() {
        return (
            <div className="main_container">
                <div className="page-title">
                    <h2 className="heading-custom">Register Assistant</h2>
                </div>
                <div className="register-row">
                    <div className="col-md-6 mrgn-btm10">
                        <label>Assistant Name <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.NAME) ? 'error code-input' : 'code-input'}
                            value={this.state.name}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.NAME);
                                this.setState({ name: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>Cell phone Number <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.PHONE) ? 'error code-input' : 'code-input'}
                            value={this.state.phone}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.PHONE);
                                this.setState({ phone: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>Home Address <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.HOME_ADDRESS) ? 'error code-input' : 'code-input'}
                            value={this.state.homeAddress}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.HOME_ADDRESS);
                                this.setState({ homeAddress: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>Driver's License <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.DRIVER_LICENSE) ? 'error code-input' : 'code-input'}
                            value={this.state.driverLicense}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.DRIVER_LICENSE);
                                this.setState({ driverLicense: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>Social Security number <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.SSN) ? 'error code-input' : 'code-input'}
                            value={this.state.ssn}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.SSN);
                                this.setState({ ssn: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>License (Texas Medical Board)/ Board Certification (NCCPA) <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.LICENSE) ? 'error code-input' : 'code-input'}
                            value={this.state.license}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.LICENSE);
                                this.setState({ license: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>NPI number <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.NPI) ? 'error code-input' : 'code-input'}
                            value={this.state.npi}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.NPI);
                                this.setState({ npi: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>DEA <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.DEA) ? 'error code-input' : 'code-input'}
                            value={this.state.dea}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.DEA);
                                this.setState({ dea: event.target.value })
                            }}
                        />
                    </div>

                    <div className="col-md-6 mrgn-btm10">
                        <label>Practice Address <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.ADDRESS) ? 'error code-input' : 'code-input'}
                            value={this.state.address}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.ADDRESS);
                                this.setState({ address: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>Background check <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.BACKGROUND) ? 'error code-input' : 'code-input'}
                            value={this.state.background}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.BACKGROUND);
                                this.setState({ background: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>Drug test <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.DRUG) ? 'error code-input' : 'code-input'}
                            value={this.state.drug}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.DRUG);
                                this.setState({ drug: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>CV <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.CV) ? 'error code-input' : 'code-input'}
                            value={this.state.cv}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.CV);
                                this.setState({ cv: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>Current specialty/Preferred specialty <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.SPECIALITY) ? 'error code-input' : 'code-input'}
                            value={this.state.specialty}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.SPECIALITY);
                                this.setState({ specialty: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                        <label>ACLS/BLS certifications <span className="required">*</span></label>
                        <input
                            type="text"
                            className={this.hasErrors(REQUIRED_FIELDS.ACLS) ? 'error code-input' : 'code-input'}
                            value={this.state.acls}
                            onChange={event => {
                                this.resetErrors(REQUIRED_FIELDS.ACLS);
                                this.setState({ acls: event.target.value })
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        paddingRight: '10px',
                    }}
                    className="filter-footer">
                    <button
                        onClick={this.createAssistant}
                        className="filter-save-button">
                        Create
                    </button>
                    <button
                        onClick={this.cancel}
                        className="filter-cancel-button">
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            register,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(Register));
