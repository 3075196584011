import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import {API_CONSTANTS} from "../constants/api";

function getBatches(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_BATCH, options)
        .then(handleResponse)
        .catch(handleError);
}

function createBatch(payload) {
    return apiClient()
        .post(API_CONSTANTS.BATCH_ADD, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateBatch(payload) {
    return apiClient()
        .put(API_CONSTANTS.BATCH_UPDATE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteBatch(payload) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_BATCH + payload)
        .then(handleResponse)
        .catch(handleError);
}

function getBatchDetail(payload) {
    return apiClient()
        .get(API_CONSTANTS.BATCH_DETAIL + payload)
        .then(handleResponse)
        .catch(handleError);
}

function createPosting(payload) {
    return apiClient()
        .post(API_CONSTANTS.POSTING_CREATE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function getPostingDetail(payload) {
    return apiClient()
        .get(API_CONSTANTS.POSTING_DETAIL + payload)
        .then(handleResponse)
        .catch(handleError);
}

function updatePosting(payload, id) {
    return apiClient()
        .put(API_CONSTANTS.POSTING_UPDATE + id, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const batchService = {
    getBatches,
    createBatch,
    updateBatch,
    deleteBatch,
    getBatchDetail,
    createPosting,
    getPostingDetail,
    updatePosting,
}