import React from "react";
import Iframe from "react-iframe";
import { LOCAL_STORAGE_KEYS } from "../../constants/common";
import "../../../sass/file.scss";
import { FileStorageService } from "../../services/fileStorage";
export default class FileDisplay extends React.Component {
  tFile = null;
  tFileContent = null;
  state = {
    tFileContent: null
  };

  componentDidMount() {
    if (this.tFile == null) {
      this.tFile = localStorage.getItem(LOCAL_STORAGE_KEYS.FILE);
      FileStorageService.getFile(this.tFile).then(data => {
        let tFileURL = 'data:application/pdf;base64,' + data.data.content;
        this.setState({ tFileContent: tFileURL });
        this.tFileContent = data;
      });
    }
  }
  render() {
    return (
      <div id="wrapper">
        {!this.state.tFileContent ? (
          <div>
            <b>File Loading...</b>
          </div>
        ) : (
          <Iframe
            url={this.state.tFileContent}
            width="100%"
            height="1600px"
            id="myId"
            display="initial"
            position="relative"
          />
        )}
      </div>
    );
  }
}
