import React from "react";
import { bindActionCreators } from "redux";
import { createContract, updateContract } from "../../actions/contact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createNotification } from "../../utils/notificationManager";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { contractService } from "../../services/contract";
import { utilityService } from "../../services/utility";
import { authorizationService } from "../../services/authorization";
import deleteIcon from "../../../assets/images/delete.png";
import { FormValidatorService } from "../../services/formvalidator";
import CustomDropDown from "../organisms/CustomDropDown";

const validationGroup = {
  message: "There are some validation errors in basic information tab:",
  fields: {
    'type': { filedName: 'Type', required: true },
    'contact_person_name': { filedName: 'Contact Person Name', required: true },
    'contact_person_phone': { filedName: 'Contact Person Phone', required: true },
    'contact_person_email': { filedName: 'Contact Person Email', required: true },
  }
}

const validationGroupOnCall = {
  message: "There are some validation errors in on call tab:",
  fields: {
    'on_call_hours': { filedName: 'Number Of Hours', required: true },
    'on_call_rate': { filedName: 'Rate', required: true },
  }
}

const validationGroupProcedure = {
  message: "There are some validation errors in procedure tab:",
  fields: {
    'procedure_hour': { filedName: 'Hours', required: true },
    'procedure_price': { filedName: 'Price', required: true },
  }
}

class ManageContract extends React.Component {
  state = {
    errors: [],
    type: '',
    facility: {},
    patient: {},
    contact_person_name: '',
    contact_person_phone: '',
    contact_person_email: '',
    contract_procedures: [],
    contract_oncalls: [],
    on_call_hours: 0,
    on_call_rate: 0,
    procedure_speciality: {},
    procedure_procedure: {},
    procedure_hour: 0,
    procedure_price: 0
  };

  componentDidMount() {
    const contractId = this.props.location.state
      ? this.props.location.state.contractId
      : "";
    if (contractId) {
      contractService.getContractDetail(contractId).then((response) => {
        this.setUpStateObject(response);
      });
    }
  }

  reloadObject() {
    const contractId = this.props.location.state
      ? this.props.location.state.contractId
      : "";
    if (contractId) {
      contractService.getContractDetail(contractId).then((response) => {
        this.setUpStateObject(response);
      });
    }
  }


  setUpStateObject = (response) => {
    if (response.data.procedure) {
      response.data.procedure.forEach(proc => {
        if (proc.procedure_id) {
          if (proc.procedure_id === 0) {
            proc.procedure = {};
            proc.procedure.value = 0
            proc.procedure.label = 'All Procedures'
          }
          else {
            proc.procedure.value = proc.procedure.id
            proc.procedure.label = proc.procedure.procedure_name
          }
        }
        else {
          proc.procedure = {};
          proc.procedure.value = null
          proc.procedure.label = '-'
        }

        if (proc.speciality_id) {
          if (proc.speciality_id === 0) {
            proc.speciality = {};
            proc.speciality.value = 0
            proc.speciality.label = 'All Specialities'
          }
          else {
            proc.speciality.value = proc.speciality.id
            proc.speciality.label = proc.speciality.name
          }
        }
        else {
          proc.speciality = {};
          proc.speciality.value = null
          proc.speciality.label = '-'
        }
      })
    }
    this.setState({
      type: response.data.type,
      facility: response.data.facility ? { value: response.data.facility.id, label: response.data.facility.facility_name } : {},
      patient: response.data.patient ? { value: response.data.patient.id, label: `${response.data.patient.first_name} ${response.data.patient.last_name}` } : {},
      contact_person_name: response.data.contact_person_name,
      contact_person_phone: response.data.contact_person_phone,
      contact_person_email: response.data.contact_person_email,
      contract_oncalls: response.data.oncall ? response.data.oncall : [],
      contract_procedures: response.data.procedure ? response.data.procedure : [],
    });
  }

  validateContract = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  validateOnCallForm = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroupOnCall, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  validateProcedureForm = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroupProcedure, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };


  sumbitProcedureRates = () => {
    if (!this.validateProcedureForm()) {
      return;
    }

    const {
      procedure_speciality,
      procedure_procedure,
      procedure_hour,
      procedure_price,
    } = this.state;

    this.state.contract_procedures.push({
      'procedure': procedure_procedure,
      'procedure_id': procedure_procedure.value,
      'speciality': procedure_speciality,
      'speciality_id': procedure_speciality.value,
      'number_hour': procedure_hour,
      'price': procedure_price
    })

    this.submitContract(false);
  }

  sumbitOnCallRates = () => {
    if (!this.validateOnCallForm()) {
      return;
    }

    const {
      on_call_hours,
      on_call_rate,
    } = this.state;


    this.state.contract_oncalls.push({
      'number_hour': on_call_hours,
      'price': on_call_rate
    })

    this.submitContract(false);
  }

  clearOnCallRates = () => {
    this.setState({
      'on_call_hours': 0,
      'on_call_rate': 0,
    });
  }

  clearProcedureRates = () => {
    validationGroupProcedure.fields['procedure_procedure'] = { filedName: 'Procedure', required: true };
    this.setState({
      procedure_procedure: {},
      procedure_hour: 0,
      procedure_price: 0
    });
  }

  submitContract = (redirectPage = true) => {
    if (!this.validateContract()) {
      return;
    }

    const { createContract, updateContract } = this.props;
    const contractId = this.props.location.state
      ? this.props.location.state.contractId
      : "";

    const {
      type,
      facility,
      patient,
      contact_person_name,
      contact_person_phone,
      contact_person_email,
      contract_oncalls,
      contract_procedures
    } = this.state;

    const request = {
      type: type,
      facility_id: facility ? facility.value : null,
      patient_id: patient ? patient.value : null,
      contact_person_name: contact_person_name,
      contact_person_phone: contact_person_phone,
      contact_person_email: contact_person_email,
      contract_oncalls: contract_oncalls,
      contract_procedures: contract_procedures
    };

    if (contractId) {
      const payload = { ...request, id: contractId, api_type: "react" };

      updateContract(payload)
        .then((res) => {
          if (res.response.success) {
            if (redirectPage) {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              this.props.history.push(APP_ROUTES.CONTRACTS_DASHBOARD);
            }
            else {
              this.clearOnCallRates();
              this.clearProcedureRates();
              this.reloadObject();
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update contract ${res.response.message}`);
          }
        })
    } else {
      createContract(request)
        .then((res) => {
          if (res.response.success) {
            if (redirectPage) {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              this.props.history.push(APP_ROUTES.CONTRACTS_DASHBOARD);
            }
            else {
              this.clearOnCallRates();
              this.clearProcedureRates();
              this.reloadObject();
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update contract ${res.response.message}`);
          }
        })
    }
  };

  onChangePatient = (value) => {
    this.setState({ patient: value });
  };

  onChangeProcedure = (value) => {
    this.setState({ procedure_procedure: value });
  };

  onChangeFacility = (value) => {
    this.setState({ facility: value });
  };

  deleteOnCall(item) {
    if (!authorizationService.isDeleteAccess())
      return;

    utilityService.showConfirmDialog().then(data => {
      contractService.deleteOnCallRate(item).then(data => {
        createNotification(
          NOTIFICATION_TYPES.SUCCESS,
          data.data
        );
        this.reloadObject();
      })
    })
  }

  deleteProcedureRate(item) {
    if (!authorizationService.isDeleteAccess())
      return;

    utilityService.showConfirmDialog().then(data => {
      contractService.deleteProcedureRate(item).then(data => {
        createNotification(
          NOTIFICATION_TYPES.SUCCESS,
          data.data
        );

        this.reloadObject();
      })
    })
  }

  onTypeChanged(value) {
    if (value === 'Patient Based') {
      delete validationGroup.fields['facility'];
      validationGroup.fields['patient'] = { filedName: 'Patient', required: true };
    }

    if (value === 'Facility Based') {
      delete validationGroup.fields['patient'];
      validationGroup.fields['facility'] = { filedName: 'Facility', required: true };
    }

    if (value === 'Default') {
      delete validationGroup.fields['facility'];
      delete validationGroup.fields['patient'];
    }

    this.setState({ type: value });
  }

  render() {
    const contractId = this.props.location.state
      ? this.props.location.state.contractId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {contractId && <h2 className="heading-custom">Edit Contract</h2>}
              {!contractId && <h2 className="heading-custom">Add Contract</h2>}
            </div>


            <div class='col-md-12 p-0'>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="basic-info-tab" data-toggle="tab" href="#basic-info" role="tab">Basic Information</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="on-call-tab" data-toggle="tab" href="#on-call" role="tab">On Call</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="procedure-tab" data-toggle="tab" href="#procedure" role="tab">Procedure</a>
                </li>
              </ul>
              <div class="tab-content mb-4" id="myTabContent">
                <div class="tab-pane fade show active tab-default-style" id="basic-info" role="tabpanel">
                  <div className="row ">
                    <div className="col-md-6 ">
                      <label>Type {FormValidatorService.getRequiredHTML(validationGroup, 'type')}</label>
                      <select
                        className={FormValidatorService.getClass(validationGroup, this.state, 'type', 'filter-input')}
                        value={this.state.type}
                        onChange={(event) => { this.onTypeChanged(event.target.value) }}
                      >
                        <option value="">Contract Type</option>
                        <option value="Default">Default</option>
                        <option value="Patient Based">Patient Based</option>
                        <option value="Facility Based">Facility Based</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      {this.state.type === 'Patient Based' &&
                        <CustomDropDown
                          type={'patients'}
                          className={FormValidatorService.getClass(validationGroup, this.state, 'type', '')}
                          isRequired={FormValidatorService.isRequired(validationGroup, 'patient')}
                          isMulti={false}
                          selectedValue={this.state?.patient}
                          onChange={this.onChangePatient}>
                        </CustomDropDown>
                      }
                      {(this.state.type === 'Facility Based') &&
                        <CustomDropDown
                          type={'facilities'}
                          className={FormValidatorService.getClass(validationGroup, this.state, 'facility', '')}
                          isRequired={FormValidatorService.isRequired(validationGroup, 'facility')}
                          isMulti={false}
                          selectedValue={this.state?.facility}
                          onChange={this.onChangeFacility}>
                        </CustomDropDown>
                      }
                    </div>
                    <div className="col-md-4">
                      <label>Contact Person Name {FormValidatorService.getRequiredHTML(validationGroup, 'contact_person_name')}</label>
                      <input
                        type="text"
                        className={FormValidatorService.getClass(validationGroup, this.state, 'contact_person_name')}
                        value={this.state.contact_person_name}
                        onChange={(event) => { this.setState({ contact_person_name: event.target.value }) }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Contact Person Phone {FormValidatorService.getRequiredHTML(validationGroup, 'contact_person_phone')}</label>
                      <input
                        type="text"
                        className={FormValidatorService.getClass(validationGroup, this.state, 'contact_person_phone')}
                        value={this.state.contact_person_phone}
                        onChange={(event) => {
                          this.setState({ contact_person_phone: utilityService.formatPhone(event.target.value) })
                        }
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Contact Person Email {FormValidatorService.getRequiredHTML(validationGroup, 'contact_person_email')}</label>
                      <input
                        type="text"
                        className={FormValidatorService.getClass(validationGroup, this.state, 'contact_person_email')}
                        value={this.state.contact_person_email}
                        onChange={(event) => {
                          this.setState({ contact_person_email: event.target.value })
                        }
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show tab-default-style" id="on-call" role="tabpanel">
                  <div className="row mrgn-btm10">
                    <div class='col-md-12'>
                      <div className="page-title">
                        <h2 className="heading-custom">Add New Entry</h2>
                      </div>
                      <div class='row'>
                        <div className="col-md-6 mrgn-btm10">
                          <label>Number Hours {FormValidatorService.getRequiredHTML(validationGroupOnCall, 'contact_person_email')}</label>
                          <input
                            type="number"
                            className={FormValidatorService.getClass(validationGroupOnCall, this.state, 'on_call_hours')}
                            value={this.state.on_call_hours}
                            onChange={event => {
                              this.setState({ on_call_hours: event.target.value })
                            }}
                          />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                          <label>Rate {FormValidatorService.getRequiredHTML(validationGroupOnCall, 'on_call_rate')}</label>
                          <input
                            type="number"
                            className={FormValidatorService.getClass(validationGroupOnCall, this.state, 'on_call_rate')}
                            value={this.state.on_call_rate}
                            onChange={event => {
                              this.setState({ on_call_rate: event.target.value })
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-md-12 text-right">
                        <button
                          onClick={this.sumbitOnCallRates}
                          className="filter-save-button">
                          Save On Call Rate
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div className="page-title">
                        <h2 className="heading-custom">Current On Call Rate</h2>
                      </div>
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th>Number Hours</th>
                            <th>Rate</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.contract_oncalls.map((item, index) => {
                            return (
                              <tr>
                                <td>{item.number_hour}</td>
                                <td>{item.price}</td>
                                <td>
                                  {authorizationService.isDeleteAccess() && (
                                    <img
                                      src={deleteIcon}
                                      onClick={() => {
                                        this.deleteOnCall(item.id);
                                      }}
                                      alt="delete"
                                    />
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show tab-default-style" id="procedure" role="tabpanel">
                  <div className="row mrgn-btm10">
                    <div class='col-md-12'>
                      <div className="page-title">
                        <h2 className="heading-custom">Add New Entry</h2>
                      </div>
                      <div class='row'>
                        <div className="col-md-3 mrgn-btm10">
                          <CustomDropDown
                            type={'procedure_specialities'}
                            className={FormValidatorService.getClass(validationGroup, this.state, 'procedure_speciality', '')}
                            isRequired={FormValidatorService.isRequired(validationGroup, 'procedure_speciality')}
                            isMulti={false}
                            selectedValue={this.state?.procedure_speciality}
                            onChange={(value) => {
                              delete validationGroupProcedure.fields['procedure_procedure'];
                              this.setState({ procedure_speciality: value })
                            }}>
                          </CustomDropDown>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                          <CustomDropDown
                            type={'procedures'}
                            isRequired={true}
                            isMulti={false}
                            className={FormValidatorService.getClass(validationGroupProcedure, this.state, 'selectedProcedures', '')}
                            selectedValue={this.state.selectedProcedures}
                            onChange={this.onChangeProcedure}>
                          </CustomDropDown>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                          <label>Number Hours {FormValidatorService.getRequiredHTML(validationGroupProcedure, 'procedure_hour')}</label>
                          <input
                            type="number"
                            className={FormValidatorService.getClass(validationGroupProcedure, this.state, 'procedure_hour')}
                            value={this.state.procedure_hour}
                            onChange={event => {
                              this.setState({ procedure_hour: event.target.value })
                            }}
                          />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                          <label>Rate {FormValidatorService.getRequiredHTML(validationGroupProcedure, 'procedure_price')}</label>
                          <input
                            type="number"
                            className={FormValidatorService.getClass(validationGroupProcedure, this.state, 'procedure_price')}
                            value={this.state.procedure_price}
                            onChange={event => {
                              this.setState({ procedure_price: event.target.value })
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-md-12 text-right">
                        <button
                          onClick={this.sumbitProcedureRates}
                          className="filter-save-button">
                          Save On Call Rate
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div className="page-title">
                        <h2 className="heading-custom">Current Procedure Rate</h2>
                      </div>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Speciality</th>
                            <th>Procedure</th>
                            <th>Number Hours</th>
                            <th>Rate</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.contract_procedures.map((item, index) => {
                            return (
                              <tr>
                                <td>{item.speciality.label}</td>
                                <td>{item.procedure.label}</td>
                                <td>{item.number_hour}</td>
                                <td>{item.price}</td>
                                <td>
                                  {authorizationService.isDeleteAccess() && (
                                    <img
                                      src={deleteIcon}
                                      onClick={() => {
                                        this.deleteProcedureRate(item.id);
                                      }}
                                      alt="delete"
                                    />
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filter-footer">
            {contractId && (
              <button
                onClick={this.submitContract}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!contractId && (
              <button
                onClick={this.submitContract}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.CONTRACTS_DASHBOARD);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createContract,
      updateContract,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageContract));
