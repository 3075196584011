import React from "react";
import { bindActionCreators } from "redux";
import { fetchInvoices } from "../../actions/account";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {
  APP_ROUTES,
  ACCOUNTS_COLUMNS,
} from "../../constants/common";
import { accountService } from "../../services/accounts";

import AccountsListingTable from "../atom/AccountsListingTable";
import { formatDate } from "../../utils/date";

class AccountsDashboard extends React.Component {
  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedAccounts: [],
    searchedTotalRecords: 0,
  };
  componentDidMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
    };
    this.props.fetchInvoices({ params: options }).then((res) => {
      this.setState({ loading: false });
    });
  }
  showAdd = () =>
    this.props.history.push({
      pathname: APP_ROUTES.ADD_REGION,
    });

  resetAccountsSearch = () => {
    this.setState({
      searchByName: "",
      searchedAccounts: [],
      searched: false,
      searchedTotalRecords: 0,
    });
  };

  edit = (invoiceId) =>
    this.props.history.push({
      pathname: APP_ROUTES.ACCOUNT_INVOICE_UPDATE,
      state: {
        invoiceId,
      },
    });

  searchAccounts = () => {
    const { searchByName } = this.state;
    let options = {};

    if (searchByName) {
      options = { ...options, search: searchByName };
    }

    this.setState({
      searched: true,
      loading: true,
    });
    accountService.getAccounts({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedAccounts: response.data.map((region) => {
          return [region.id, region.name, region.id];
        }),
      });
      this.setState({ loading: false });
    });
  };

  list = () => {
    const { invoices = [] } = this.props;
    return invoices.map((invoice) => {
      return [
        invoice.id,
        invoice.facility ? invoice.facility.facility_name : "",
        invoice.total,
        invoice.amount_received,
        invoice.total - invoice.amount_received,
        invoice.id,
      ];
    });
  };

  showDetail = (invoiceId) => {
    if (invoiceId) {
      accountService.getInvoiceDetail(invoiceId).then((response) => {
        const facility =
          response.data.invoice_events &&
            response.data.invoice_events.length > 0
            ? response.data.invoice_events[0].facility.facility_name
            : "";
        const facilityId = response.data.facility_id;
        const practice =
          response.data.invoice_events &&
            response.data.invoice_events.length > 0
            ? response.data.invoice_events[0].practice_id
            : "";
        const patient =
          response.data.invoice_events &&
            response.data.invoice_events.length > 0
            ? response.data.invoice_events[0].patient_id
            : "";
        const startDate =
          response.data.invoice_events &&
            response.data.invoice_events.length > 0
            ? formatDate(response.data.invoice_events[0].event_start_date)
            : "";
        const endDate =
          response.data.invoice_events &&
            response.data.invoice_events.length > 0
            ? formatDate(response.data.invoice_events[0].event_end_date)
            : "";
        const even = [];
        const claims =
          response.data.invoice_events &&
            response.data.invoice_events.length > 0
            ? response.data.invoice_events.map((invoice) => {
              even.push(invoice.old_event_id);
              return {
                event_patient: {
                  patient: {
                    account_number: invoice.patient
                      ? invoice.patient.account_number
                      : "",
                    first_name: invoice.patient
                      ? invoice.patient.first_name
                      : "",
                    last_name: invoice.patient
                      ? invoice.patient.last_name
                      : "",
                    date_of_birth: invoice.patient
                      ? invoice.patient.date_of_birth
                      : "",
                    patient_insurance_data: {
                      primary: {
                        description:
                          invoice.patient &&
                            invoice.patient.patient_insurance_data &&
                            invoice.patient.patient_insurance_data.primary
                            ? invoice.patient.patient_insurance_data.primary
                              .description
                            : "",
                      },
                    },
                  },
                  assistant: invoice.assistant,
                  surgeon: invoice.surgeon,
                  procedures: invoice.procedures,
                  facility: invoice.facility,
                },
                service_date: invoice.event_start_date,
                total_bill: invoice.bill ? invoice.bill.total_bill : "",
              };
            })
            : [];
        this.props.history.push({
          pathname: APP_ROUTES.INVOICE_CLAIM_AND_COLLECTION,
          state: {
            claims,
            facility,
            facilityId,
            practice,
            patient,
            startDate,
            endDate,
            events: even,
            invoiceId,
            screen: APP_ROUTES.ACCOUNT_DASHBOARD,
          },
        });
      });
    }
  };

  render() {
    const { searched, searchedAccounts, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage}  />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "755px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Accounts
              </h3>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div
              style={{
                paddingLeft: "0",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Facility Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value }, () => {
                    if (this.state.searchByName.length >= 3) {
                      this.searchAccounts();
                    } else if (this.state.searchByName.length === 0) {
                      this.resetAccountsSearch();
                    }
                  });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetAccountsSearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <AccountsListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                view={this.showDetail}
                edit={this.edit}
                headColumn={ACCOUNTS_COLUMNS}
                listing={
                  searched && searchedAccounts.length > 0
                    ? searchedAccounts
                    : this.list()
                }
                totalRecords={
                  searched && searchedTotalRecords > 0
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: state.accountReducer.invoices,
    totalInvoices: state.accountReducer.totalInvoices,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoices,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsDashboard));
