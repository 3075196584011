import React from "react";
import PropTypes from "prop-types";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import Loader from "./Loader";
import ReactTooltip from "react-tooltip";

import loaderImage from "../../../assets/images/CoureMax.gif";
import LoadingOverlay from "react-loading-overlay";
import { authorizationService } from "../../services/authorization";
import { utilityService } from "../../services/utility";
import TablePagination from "../organisms/TablePagination";

export default class InvoicingListingTable extends React.Component {
  state = {
    fee_sum: 0,
    row_Color: "",
    row_Color_Text: "",
  };

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
    allChecked: PropTypes.bool.isRequired,
    addAllCheckedItem: PropTypes.func.isRequired,
    removeAllCheckedItem: PropTypes.func.isRequired,
  };
  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column, index) => {
      if (index === 1 || index === 2) {
        return (
          <td
            style={{
              textAlign: "center",
            }}
            className="listing-table-head-column"
          >
            {column}
          </td>
        );
      } else {
        return <th className="listing-table-head-column">{column}</th>;
      }
    });

    // const actionElement = (
    //   <th className="listing-table-head-column">{"Action"}</th>
    // );
    const selectorElement = (
      <th className="listing-table-head-column">
        <input
          type="checkbox"
          id="check-all"
          checked={this.props.allChecked}
          onChange={(event) =>
            event.target.checked
              ? this.props.addAllCheckedItem()
              : this.props.removeAllCheckedItem()
          }
        />
      </th>
    );
    // alert(headColumn.length + 1);
    // header.splice(0, 0, selectorElement);
    header.splice(0, 0, selectorElement);
    return header;
  };

  edit = (item) => {
    if (!authorizationService.isUpdateAccess())
      return;
    this.props.edit(item);
  }

  delete = (item) => {
    if (!authorizationService.isDeleteAccess())
      return;

    utilityService.showConfirmDialog().then(data => {
      this.props.delete(item);
    })
  };

  sumCalculate = (sum) => {
    // eslint-disable-next-line
    this.state.fee_sum = sum;
  };
  renderList = () => {
    const { listing = [] } = this.props;
    let sum = 0;
    return (
      listing.length > 0 &&
      listing.map((listRow) => {
        const item = listRow.map((listItem, index) => {
          if (index === 0) {
            return (
              <td className="listing-table-column">
                <input
                  type="checkbox"
                  id="check-all"
                  checked={this.props.checkedInvoices.indexOf(listItem) > -1}
                  onChange={(event) =>
                    event.target.checked
                      ? this.props.addCheckedItem(listItem)
                      : this.props.removeCheckedItem(listItem)
                  }
                />
              </td>
            );
          } else if (index === 1) {
            return (
              <td onClick={() => { this.edit(listRow[listRow.length - 1]) }}
                className="listing-table-column">
                {listItem}
              </td>
            );
          } else if (index < listRow.length - 1) {
            if (index === 2 || index === 3) {
              return (
                <td
                  onClick={() => {
                    this.edit(listRow[listRow.length - 1]);
                  }}
                  className="listing-table-column"
                >
                  {listItem}
                </td>
              );
            } else if (index === 10) {
              sum = sum + parseInt(listItem);
              this.sumCalculate(sum);
            }
            // if (index == 11) {

            // if (listItem == "NOT STARTED") {
            //   this.state.row_Color = "#6498";
            //   this.state.row_Color_Text = "white";
            // } else if (listItem == "SUBMITTED") {
            //   this.state.row_Color = "#9e7147";
            //   this.state.row_Color_Text = "black";
            // } else if (listItem == "CORRECTED CLAIM") {
            //   this.state.row_Color = "#17a2b8";
            //   this.state.row_Color_Text = "black";
            // } else if (listItem == "PAPER CLAIM") {
            //   this.state.row_Color = "#797775";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "RECONSIDERATION") {
            //   this.state.row_Color = "#0c474c";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "FOLLOW-UP") {
            //   this.state.row_Color = "#274c1c";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "APPEAL 1") {
            //   this.state.row_Color = "#03aaf4";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "APPEAL 2") {
            //   this.state.row_Color = "#03aaf4a6";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "HOLD TO BILL") {
            //   this.state.row_Color = "#c96b08";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "BILL PATIENT") {
            //   this.state.row_Color = "#08c97e";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "BILL HOSPITAL") {
            //   this.state.row_Color = "#08c97e";
            //   this.state.row_Color_Text = "black";
            // }
            // else if (listItem == "BILL PHYSICIAN") {
            //   this.state.row_Color = "#126243";
            //   this.state.row_Color_Text = "black";
            // }else if (listItem == "REVIEW ADJUSTMENT") {
            //   this.state.row_Color = "#1e498f";
            //   this.state.row_Color_Text = "black";
            // }else if (listItem == "CLOSED") {
            //   this.state.row_Color = "#84ff3fa6";
            //   this.state.row_Color_Text = "black";
            // }else if (listItem == "CLOSED NON-BILL") {
            //   this.state.row_Color = "#58b325a6";
            //   this.state.row_Color_Text = "black";
            // }else if (listItem == "CLOSED ADJUSTMENT") {
            //   this.state.row_Color = "#3f6828a6";
            //   this.state.row_Color_Text = "black";
            // }else if (listItem == "CLOSED HC-2nd Scrub") {
            //   this.state.row_Color = "#27540ea6";
            //   this.state.row_Color_Text = "black";
            // } else {
            //   this.state.row_Color = "black";
            //   this.state.row_Color_Text = "white";
            // }
            // } else {
            //   this.state.row_Color = "black";
            //   this.state.row_Color_Text = "white";
            // }

            return (
              <td
                className="listing-table-column"
                style={{
                  backgroundColor: this.state.row_Color,
                  color: this.state.row_Color_Text + "!important",
                  textAlign: "center",
                }}
                onClick={() => {
                  this.edit(listRow[listRow.length - 1]);
                }}
              >
                <p data-tip={listItem}>
                  <span id={`TooltipExample`}>
                    {index === 0 || (listItem && listItem.length < 12)
                      ? listItem
                      : listItem && listItem.substring(0, 9) + "..."}
                  </span>
                </p>
                <ReactTooltip place="bottom" type="light" effect="float" />
              </td>
            );
          } else {
            return (
              <td className="listing-table-column">
                {authorizationService.isUpdateAccess() && (
                  <img
                    src={editIcon}
                    onClick={() => {
                      this.edit(listItem);
                    }}
                    alt="edit"
                  />
                )}
                {authorizationService.isDeleteAccess() && (
                  <img
                    src={deleteIcon}
                    onClick={() => {
                      this.delete(listItem);
                    }}
                    alt="delete"
                  />
                )}
              </td>
            );
          }
        });

        return <tr style={{
          'cursor': 'pointer'
        }} className="listing-table-rows">{item}</tr>;
      })
    );
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive">
            <LoadingOverlay
              active={this.state.loading}
              spinner={
                <img
                  style={{
                    width: "200px",
                  }}
                  src={loaderImage}
                  alt="loading..."
                />
              }
            >
              <table className="listing-table">
                <thead className="listing-table-head">
                  <tr>{this.renderHeadColumn()}</tr>
                </thead>
                {this.props.isLoading && <Loader width="578%" />}
                {!this.props.isLoading && (
                  <tbody>
                    {this.renderList()}
                    {/* <tr
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      fontSize: 30,
                      textAlign: "center",
                    }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td>${this.state.fee_sum}</td>
                  </tr> */}
                  </tbody>
                )}
              </table>
            </LoadingOverlay>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}
