import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import "../../../sass/listingtable.scss";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
} from "../../constants/common";

import { billingService } from "../../services/billing";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../utils/date";
import { withRouter } from "react-router-dom";
import loaderImage from "../../../assets/images/CoureMax.gif";
import LoadingOverlay from "react-loading-overlay";
import { authorizationService } from "../../services/authorization";
import { utilityService } from "../../services/utility";
import TablePagination from "../organisms/TablePagination";

class ListingTable extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
    setPageSize: PropTypes.func,
    pageSize: PropTypes.number
  };

  state = {
    billingId: "",
    patient: {},
    bill: {},
    loading: false,
  };

  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column, index) => {
      if (index === 0) {
        return (
          <td
            className="listing-table-head-column"
          >
            {column}
          </td>
        );
      } else {
        return <th className="listing-table-head-column">{column}</th>;
      }
    });

    const actionElement = (
      <th className="listing-table-head-column">{"Action"}</th>
    );

    header.splice(headColumn.length + 1, 0, actionElement);

    return header;
  };

  edit = (item) => {
    if (!authorizationService.isUpdateAccess())
      return;
    this.props.edit(item);
  }

  delete = (item) => {
    if (!authorizationService.isDeleteAccess())
      return;

    utilityService.showConfirmDialog().then(data => {
      this.props.delete(item);
    })
  };

  getStatusColor = (status) => {
    switch (status) {
      case "new":
        return "#60A6D3";
      case "started":
        return "#9C5194";
      case "cancelled":
        return "#B4B14E";
      case "completed":
        return "#26A797";
      default:
        return "";
    }
  };

  toggleBillTable = (value) => {
    const { billingId } = this.state;
    this.setState({ loading: true });
    if (billingId === value) {
      this.setState({
        billingId: "",
        loading: false,
      });
    } else {
      this.setState({ billingId: value }, () => {
        const { billingId } = this.state;

        billingService.getEventBill(billingId).then((response) => {
          this.setState({
            bill: response.data.bill,
            patient: response.data.patient,
            loading: false,
          });
        });
      });
    }
  };

  editBill = (billId, eventId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_BILL,
      state: {
        billId,
        eventId,
      },
    });

  createBill = (eventId) =>
    this.props.history.push({
      pathname: APP_ROUTES.ADD_BILL,
      state: {
        eventId,
      },
    });

  renderBillList = () => {
    const { billingId, bill, patient } = this.state;
    if (bill) {
      return (
        <>
          <td
            style={{
              backgroundColor: "#475f87",
            }}
            className="listing-table-column"
          >
            <span
              style={{
                color: "white",
              }}
            >
              Event Bill
            </span>
          </td>
          <td className="listing-table-column">
            {bill ? bill.old_bill_id : ""}
          </td>
          <td className="listing-table-column">{billingId}</td>
          <td className="listing-table-column">{patient ? patient.id : ""}</td>
          <td className="listing-table-column">
            {patient ? patient.first_name : ""}{" "}
            {patient ? patient.last_name : ""}
          </td>
          <td className="listing-table-column">
            {bill ? formatDate(bill.service_date) : ""}
          </td>
          <td colSpan="2" className="listing-table-column">
            <button
              onClick={() => this.editBill(bill.old_bill_id, billingId)}
              className="filter-save-button"
            >
              Update Bill
            </button>
          </td>
        </>
      );
    } else {
      return (
        <td colSpan="8" className="listing-table-column">
          <button
            style={{
              width: "200px",
            }}
            onClick={() => this.createBill(billingId)}
            className="filter-save-button"
          >
            Create a Bill
          </button>
        </td>
      );
    }
  };

  renderBillingTable = () => (
    <>
      <td className="listing-table-head-column" />
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Bill Id
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Event Id
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Patient Id
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Patient
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        DOS
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
        colSpan="2"
      >
        Action
      </td>
    </>
  );

  getNonBilledClass(item) {
    if (item[10] && item[10].toLowerCase() === 'yes')
      return 'listing-table-rows';
    return 'listing-table-rows non-billed';
  }

  renderList = () => {
    const { listing = [] } = this.props;
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    return listing.map((listRow) => {
      const item = listRow.map((listItem, index) => {               //eslint-disable-line
        if (index === 0) {
          return (
            <td
              style={{
                textAlign: "left",
                cursor: "pointer",
              }}
              onClick={() => {
                this.edit(listItem);
              }}
              className="listing-table-column"
            >
              {listItem}
            </td>
          );
        } else if (index < listRow.length - 2) {
          return (
            <td
              className="listing-table-column textUpper"
              style={{
                borderLeft:
                  index === 0
                    ? `10px solid ${this.getStatusColor(
                      listRow[listRow.length - 1]
                    )}`
                    : "",
                textAlign: index === 0 ? "left" : "center",
              }}
            >
              <p data-tip={listItem}>
                <span id={`TooltipExample`}>
                  {index === 0 || (listItem && listItem.toString().length < 10)
                    ? listItem
                    : listItem && listItem.toString().substring(0, 10) + "..."}
                </span>
              </p>
              <ReactTooltip place="bottom" type="light" effect="float" />
            </td>
          );
        } else if (index < listRow.length - 1) {
          return (
            <td className="listing-table-column">
              {authorizationService.isUpdateAccess() && (
                <img
                  src={editIcon}
                  onClick={() => {
                    this.edit(listItem);
                  }}
                  alt="edit"
                />
              )}
              {authorizationService.isDeleteAccess() && (
                <img
                  src={deleteIcon}
                  onClick={() => {
                    this.delete(listItem);
                  }}
                  alt="delete"
                />
              )}
              {loggedInUser.type !== "assistant" && (
                <div
                  style={{
                    paddingTop: "2px",
                    display: "flex",
                    float: "left",
                  }}
                  onClick={() => this.toggleBillTable(listItem)}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    size="lg"
                    color="#475f87"
                  />
                </div>
              )}
            </td>
          );
        }
      });

      return (
        <>
          <tr className={this.getNonBilledClass(listRow)}>{item}</tr>

          {this.state.billingId === listRow[listRow.length - 2] && (
            <tr
              style={{
                backgroundColor: "#475f87",
              }}
              className="listing-table-rows"
            >
              {this.renderBillingTable()}
            </tr>
          )}
          {this.state.billingId === listRow[listRow.length - 2] && (
            <tr
              style={{
                backgroundColor: "#ffffff",
              }}
              className="listing-table-rows"
            >
              {this.renderBillList()}
            </tr>
          )}
        </>
      );
    });
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive">
            <LoadingOverlay
              active={this.state.loading}
              spinner={
                <img
                  style={{
                    width: "200px",
                  }}
                  src={loaderImage}
                  alt="loading..."
                />
              }
            >
              <table className="listing-table">
                <thead className="listing-table-head">
                  <tr>{this.renderHeadColumn()}</tr>
                </thead>
                <tbody>{this.renderList()}</tbody>
              </table>
            </LoadingOverlay>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}

export default withRouter(ListingTable);
