import { billingService } from "../services/billing";
import { BILL } from "../constants/actions";

export const fetchBills = options => dispatch => {
    return new Promise((resolve, reject) => {
        billingService
            .getEventBills(options)
            .then(response => {
                dispatch({
                    type: BILL.FETCH_BILLS_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createBill = payload => dispatch => {
    return new Promise((resolve, reject) => {
        billingService
            .createBill(payload)
            .then(response => {
                dispatch({
                    type: BILL.CREATE_BILL_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateBill = payload => dispatch => {
    return new Promise((resolve, reject) => {
        billingService
            .updateBill(payload)
            .then(response => {
                dispatch({
                    type: BILL.UPDATE_BILL_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const cleanSearch = options => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: BILL.CLEAN_SEARCH,
        });
        resolve({});
    });
};