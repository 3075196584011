import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getGroups(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_GROUPS, options)
        .then(handleResponse)
        .catch(handleError);
}

function createGroup(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_GROUP, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateGroup(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_GROUP, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteGroup(user) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_GROUP + user)
        .then(handleResponse)
        .catch(handleError);
}

function getGroupDetail(user) {
    return apiClient()
        .get(API_CONSTANTS.DETAIL_GROUP + user)
        .then(handleResponse)
        .catch(handleError);
}

export const groupService = {
    getGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    getGroupDetail,
}