import React from "react";
import { bindActionCreators } from "redux";
import { createClient, updateClient } from "../../actions/clients";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createNotification } from "../../utils/notificationManager";
import ProgressLoading from "../atom/ProgressLoading";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { clientService } from "../../services/clients";
import StateSelection from "../organisms/StateSelection";
import CitySelection from "../organisms/CitySelection";
import { utilityService } from "../../services/utility";
import ZipCode from "../organisms/ZipCode";


import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";
const validationGroup = {
  message: "There are some validation errors in Basic Information Tab:",
  fields: {
    'clientName': { filedName: 'Client Name', required: true, minLength: 3, maxLength: 200 },
    'clientEmail': { filedName: 'client Email', required: true },
    'domainName': { filedName: 'client Domain', required: true },
  }
}

class ManageClients extends React.Component {
  state = {
    errors: [],
    clientEmail: "",
    clientPassword: "",
    clientName: "",
    domainName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    contactName: "",
    contactPhone: "",
    contactFax: "",
    contactEmail: "",
    gpo: "",
    idn: "",
    npi: "",
    internalIdentifier: "",
    salesTax: "",
    caseCapacity: "",
    confirmationEmailAddresses: "",
    type: "",
    emrAccess: "NA",
    additionalContacts: "",
    status: "",
    progressloading: false,
  };

  componentDidMount() {
    const clientId = this.props.location.state
      ? this.props.location.state.clientId
      : "";
    if (clientId) {
      clientService.getClientDetail(clientId).then((response) => {
        console.log("responseresponse", response.data.contact_fax);
        this.setState({
          clientName: response.data.id,
          domainName: response.data.domain.domain,
          address1: response.data.address,
          address2: response.data.address2,
          city: response.data.city,
          state: response.data.state,
          zip: response.data.zip_code,
          contactName: response.data.contact_name,
          contactEmail: response.data.contact_email,
          contactPhone: response.data.contact_phone,
          contactFax: response.data.contact_fax,
          gpo: response.data.gpo,
          idn: response.data.idn,
          npi: response.data.npi,
          salesTax: response.data.tax_percent,
          caseCapacity: response.data.case_capacity,
          status: response.data.status,
        });
      });
    }
  }

  validateActivity = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  submitClient = () => {
    if (!this.validateActivity()) {
      return;
    }

    const { createClient, updateClient } = this.props;
    const clientId = this.props.location.state
      ? this.props.location.state.clientId
      : "";

    const {
      clientName,
      address1,
      address2,
      city,
      state,
      zip,
      contactName,
      contactPhone,
      contactFax,
      contactEmail,
      gpo,
      idn,
      npi,
      salesTax,
      caseCapacity,
      domainName,
      status,
      clientEmail,
    } = this.state;

    const request = {
      client_email: clientEmail,
      client_name: clientName,
      domain_name: domainName,
      address: address1,
      address2: address2,
      city: city,
      state: state,
      zip_code: zip,
      contact_name: contactName,
      contact_email: contactEmail,
      contact_phone: contactPhone,
      contact_fax: contactFax,
      gpo: gpo,
      idn: idn,
      npi: npi,
      tax_percent: salesTax,
      case_capacity: caseCapacity,
      status: status,
    };
    if (clientId) {
      const payload = { ...request, id: clientId, api_type: "react" };

      updateClient(payload)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.CLIENTS_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.CLIENTS_DASHBOARD);
          }
        })
    } else {
      this.setState({ progressloading: true });
      createClient(request)
        .then((res) => {
          if (res.response.data.success) {
            setTimeout(function () {
              this.props.history.push(APP_ROUTES.CLIENTS_DASHBOARD);
            }, 9000);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.CLIENTS_DASHBOARD);
          }
        })
    }
  };

  zipCodeChanged = (value) => {
    this.setState({ zip: value.zip_code })

    if (value.state) {
      this.setState({ state: value.state.code });
    }

    if (value.city) {
      this.setState({ city: value.city.name });
    }
  }

  render() {
    const clientId = this.props.location.state
      ? this.props.location.state.clientId
      : "";
    var isDisabled = false;
    var cover_spin = "";
    if (clientId) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }
    if (this.state.progressloading === true) {
      cover_spin = "cover-spin";
    }
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {clientId && <h2 className="heading-custom">Edit Client</h2>}
              {!clientId && <h2 className="heading-custom">Add Client</h2>}
            </div>
            <div id={cover_spin}>
              {this.state.progressloading && <ProgressLoading />}
            </div>
            {/* <div className="col-md-2 mrgn-btm10">
              <label>
                <span
                  className="required"
                  style={{ fontSize: 13, color: "green" }}
                >
                  Please Enter Domain name Only it will create following Doamin
                  eg xyz.coremaxcloud.com
                </span>
              </label>
            </div> */}
            <div className="col-md-4 mrgn-btm10">
              <label>Client Name {FormValidatorService.getRequiredHTML(validationGroup, "clientName")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "clientName")}
                disabled={isDisabled}
                value={this.state.clientName}
                onChange={(event) => {
                  this.setState({ clientName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Client Email {FormValidatorService.getRequiredHTML(validationGroup, "clientEmail")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "clientEmail")}
                disabled={isDisabled}
                value={this.state.clientEmail}
                onChange={(event) => {
                  this.setState({ clientEmail: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Client Domain {FormValidatorService.getRequiredHTML(validationGroup, "domainName")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "domainName")}
                placeholder=".coremaxcloud.com"
                value={this.state.domainName}
                onChange={(event) => {
                  this.setState({ domainName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Address Line 1</label>
              <input
                type="text"
                className="code-input"
                value={this.state.address1}
                onChange={(event) => {
                  this.setState({ address1: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Address Line 2</label>
              <input
                type="text"
                className="code-input"
                value={this.state.address2}
                onChange={(event) => {
                  this.setState({ address2: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <ZipCode title={'Zip Code(9 digit - No spaces)'}
                value={this.state.zip}
                onChange={(value) => {
                  this.setState({ 'zip': value });
                }}
                onBlur={(value) => {
                  this.zipCodeChanged(value);
                }}>
              </ZipCode>
            </div>
            <div className="col-md-4 mrgn-btm10">
              <StateSelection
                key={this.state.state}
                label={'State'}
                isMulti={false}
                selectedStateCode={this.state.state}
                onChange={(value) => { this.setState({ state: value.value }) }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <CitySelection
                key={"".concat(this.state.state, this.state.city)}
                label={'City'}
                isMulti={false}
                selectedStateCode={this.state.state}
                selectedCity={this.state.city}
                onChange={(value) => { console.log(value); this.setState({ city: value.value }) }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Main Contact Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.contactName}
                onChange={(event) => {
                  this.setState({ contactName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Main Contact Phone</label>
              <input
                type="text"
                className="code-input"
                value={utilityService.formatPhone(this.state.contactPhone)}
                onChange={(event) => {
                  this.setState({ contactPhone: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Main Contact Fax</label>
              <input
                type="text"
                className="code-input"
                value={utilityService.formatPhone(this.state.contactFax)}
                onChange={(event) => {
                  this.setState({ contactFax: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Main Contact Email</label>
              <input
                type="text"
                className="code-input"
                value={this.state.contactEmail}
                onChange={(event) => {
                  this.setState({ contactEmail: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>GPO</label>
              <input
                type="text"
                className="code-input"
                value={this.state.gpo}
                onChange={(event) => {
                  this.setState({ gpo: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>IDN</label>
              <input
                type="text"
                className="code-input"
                value={this.state.idn}
                onChange={(event) => {
                  this.setState({ idn: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Client NPI</label>
              <input
                type="text"
                className="code-input"
                value={this.state.npi}
                onChange={(event) => {
                  this.setState({ npi: event.target.value });
                }}
              />
            </div>

            <div className="col-md-3 mrgn-btm10">
              <label>Sales Tax Percent</label>
              <input
                type="text"
                className="code-input"
                value={this.state.salesTax}
                onChange={(event) => {
                  this.setState({ salesTax: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Case Capacity</label>
              <input
                type="text"
                className="code-input"
                value={this.state.caseCapacity}
                onChange={(event) => {
                  this.setState({ caseCapacity: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomSelect type='Status'
                className="code-input"
                onChange={(event) =>
                  this.setState({ status: event.target.value })
                }
                value={this.state.status}>
              </CustomSelect>
            </div>
          </div>

          <div className="filter-footer">
            {clientId && (
              <button
                onClick={this.submitClient}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!clientId && (
              <button
                onClick={this.submitClient}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.CLIENTS_DASHBOARD);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createClient,
      updateClient,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageClients));
