import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";
//import jsPDF from "jspdf";
import { saveAs } from 'file-saver';
import { utilityService } from '../services/utility';

function getInvoicing(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_INVOICING, options)
        .then(handleResponse)
        .catch(handleError);
}

function submitInvoicing(payload) {
    return apiClient()
        .post(API_CONSTANTS.SUBMIT_INVOICING, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateInvoicing(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_INVOICING, payload)
        .then(handleResponse)
        .catch(handleError);
}

function addMultipleInvoicing(payload) {
    let invoices = { invoices: payload };
    return apiClient()
        .post(API_CONSTANTS.CREATE_MULTIPLE_INVOICING, invoices)
        .then(handleResponse)
        .catch(handleError);
}

function getActivityDetail(activity) {
    return apiClient()
        .get(API_CONSTANTS.ACTIVITY_DETAIL + activity)
        .then(handleResponse)
        .catch(handleError);
}
function getInvoiceDetail(activity) {
    return apiClient()
        .get(API_CONSTANTS.INVOICING_DETAIL + activity)
        .then(handleResponse)
        .catch(handleError);
}


function deleteInvoicing(activity) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_INVOICING + activity)
        .then(handleResponse)
        .catch(handleError);
}

function deleteActivityFaceSheet(faceSheetId) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ACTIVITY_FACESHEET + faceSheetId)
        .then(handleResponse)
        .catch(handleError);
}

function exportInvoicing(payload) {
    return apiClient()
        .post(API_CONSTANTS.EXPORT_INVOICING, payload)
        .then(async response => {
            const blob = utilityService.base64toBlob(response.data.data);
            saveAs(blob, response.data.name);
            /*const url = window.URL.createObjectURL(new Blob([response.data]));

            if (payload['options']['facility_name'] === "SOUTH TEXAS SURGICAL HOSPITAL") {

                const doc = new jsPDF();

                doc.html(response.data, {
                    async callback(doc) {
                        doc.save('STSH-INVOICE.pdf');
                        // window.open(doc.output('bloburl')); // To debug.
                    },
                    html2canvas: { scale: 0.25 }
                });
            }
            else {
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', `${Date.now()}.xls`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }*/

        })
        .catch(handleError);
}
function multiStatusUpdate(payload) {
    return apiClient()
        .post(API_CONSTANTS.MULTIPLE_STATUS_UPDATE, payload)
        .then(handleResponse)
        .catch(handleError);

}

export const invoicingService = {
    getInvoicing,
    submitInvoicing,
    updateInvoicing,
    addMultipleInvoicing,
    getActivityDetail,
    getInvoiceDetail,
    deleteInvoicing,
    deleteActivityFaceSheet,
    exportInvoicing,
    multiStatusUpdate,
}