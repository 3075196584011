import { KNOWLEDGE_BASE_ARTICLES } from '../constants/actions';
import { knowledgeBaseArticleService } from '../services/knowledgeBaseArticles';


export const fetchArticles = options => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseArticleService
            .getArticles(options)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_ARTICLES.FETCH_ARTICLES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createArticle = payload => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseArticleService
            .createArticle(payload)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_ARTICLES.CREATE_ARTICLES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateArticle = payload => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseArticleService
            .updateArticle(payload)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_ARTICLES.UPDATE_ARTICLES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
