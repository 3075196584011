import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

class TablePagination extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        recordsPerPage: PropTypes.number.isRequired,
        goToPage: PropTypes.func.isRequired,
        isPageSelection: PropTypes.bool,
        setPageSize: PropTypes.func
    };

    render = () => {
        const { totalRecords, currentPage, recordsPerPage } = this.props;


        const totalPages = Math.ceil(totalRecords / recordsPerPage);

        const visiblePaginationPages = 6;

        const pageIndexes = [];

        const visiblePages = visiblePaginationPages / 2;
        if (totalPages > visiblePaginationPages + 2) {
            const firstPage = Math.min(
                currentPage,
                Math.max(totalPages - visiblePages * 2, 0)
            );
            for (let i = firstPage; i < firstPage + visiblePages; i++) {
                pageIndexes.push(i);
            }

            if (currentPage + visiblePages < totalPages - visiblePages) {
                pageIndexes.push(-1);
            }

            for (let i = totalPages - visiblePages; i < totalPages; i++) {
                pageIndexes.push(i);
            }
        } else {
            for (let i = 1; i <= totalPages; i++) {
                pageIndexes.push(i);
            }
        }

        const pageLinks = pageIndexes.map((i, index) => {
            const button = (
                <button
                    disabled={currentPage === i}
                    key={`pagination-button-${index}`}
                    onClick={() => {
                        return this.props.goToPage(i);
                    }}
                    className="listing-pagination-button"
                >
                    {i}
                </button>
            );

            const ellipsis = (
                <div
                    className="listing-pagination-button"
                    key={`pagination-ellipsis-${index}`}
                >
                    ...
                </div>
            );
            return -1 === i ? ellipsis : button;
        });
        return (
            <>
                {this.props.totalRecords > 0 &&
                    <div className="listing-pagination">
                        {this.props.isPageSelection &&
                            <>
                                <label style={{ marginTop: "5px", marginRight: "5px" }}>
                                    Page Size:
                                </label>
                                <select
                                    className="form-control"
                                    style={{ width: "80px" }}
                                    onChange={(event) => this.props.setPageSize(event.target.value)}
                                    value={this.props.recordsPerPage}
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="35">35</option>
                                    <option value="40">40</option>
                                </select>
                            </>
                        }
                        <div className="inner">
                            <button
                                onClick={() => {
                                    return this.props.goToPage(1);
                                }}
                                disabled={1 === currentPage}
                                className="listing-pagination-button"
                            >
                                &lt;&lt; First
                            </button>
                            <button
                                onClick={() => {
                                    return this.props.goToPage(currentPage - 1);
                                }}
                                disabled={1 === currentPage}
                                className="listing-pagination-button"
                            >
                                &lt; Previous
                            </button>
                            {pageLinks}
                            <button
                                onClick={() => {
                                    return this.props.goToPage(currentPage + 1);
                                }}
                                disabled={currentPage > totalPages - 1}
                                className="listing-pagination-button"
                            >
                                Next &gt;
                            </button>
                            <button
                                onClick={() => {
                                    return this.props.goToPage(totalPages - 1);
                                }}
                                disabled={currentPage > totalPages - 1}
                                className="listing-pagination-button"
                            >
                                Last &gt;&gt;
                            </button>
                        </div>
                    </div>
                }

                {this.props.totalRecords <= 0 &&
                    <div
                        style={{
                            width: "100%",
                            textAlign: "center",
                            paddingTop: "2px",
                        }}
                    >
                        {!this.props.isLoading && (
                            <span className="noRecord">No Records Found</span>
                        )}
                    </div>
                }
            </>
        );
    };
}

export default withRouter(TablePagination);