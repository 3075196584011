import { PRACTICE } from '../constants/actions';

const initialState = {
    practices: [],
    totalpractices: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case PRACTICE.FETCH_PRACTICE_SUCCESS:
            return {
                ...state,
                practices: action.data.data,
                totalpractices: action.data.records,
            };
        case PRACTICE.CREATE_PRACTICE_SUCCESS:
            const createPractice = [ ...state.practices, action.data.data ];
            return {
                ...state,
                practices: createPractice,
            };
        case PRACTICE.UPDATE_PRACTICE_SUCCESS:
            const updatePractice = [ ...state.practices, action.data.data ];
            return {
                ...state,
                practices: updatePractice,
            }
        default:
            return state;
    }
}