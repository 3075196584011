export const AUTH = {
    OFFICE_365_AUTHENTICATION: 'OFFICE_365_AUTHENTICATION_SUCCESS',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS'
};

export const ACTIVITY = {
    FETCH_ACTIVITIES_SUCCESS: 'FETCH_ACTIVITIES_SUCCESS',
    SUBMIT_ACTIVITY_SUCCESS: 'SUBMIT_ACTIVITY_SUCCESS',
    UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
    DELETE_ACTIVITY_SUCCESS: 'DELETE_ACTIVITY_SUCCESS',
};

export const PATIENT = {
    FETCH_PATIENT_SUCCESS: 'FETCH_PATIENT_SUCCESS',
    CREATE_PATIENT_SUCCESS: 'CREATE_PATIENT_SUCCESS',
    UPDATE_PATIENT_SUCCESS: 'UPDATE_PATIENT_SUCCESS',
};

export const SURGEON = {
    FETCH_SURGEON_SUCCESS: 'FETCH_SURGEON_SUCCESS',
    CREATE_SURGEON_SUCCESS: 'CREATE_SURGEON_SUCCESS',
    UPDATE_SURGEON_SUCCESS: 'UPDATE_SURGEON_SUCCESS',
};

export const PROCEDURE = {
    FETCH_PROCEDURES_SUCCESS: 'FETCH_PROCEDURES_SUCCESS',
    CREATE_PROCEDURE_SUCCESS: 'CREATE_PROCEDURE_SUCCESS',
    UPDATE_PROCEDURE_SUCCESS: 'UPDATE_PROCEDURE_SUCCESS',
};

export const FACILITY = {
    FETCH_FACILITY_SUCCESS: 'FETCH_FACILITY_SUCCESS',
    CREATE_FACILITY_SUCCESS: 'CREATE_FACILITY_SUCCESS',
    UPDATE_FACILITY_SUCCESS: 'UPDATE_FACILITY_SUCCESS',
};

export const CONTRACT = {
    FETCH_CONTRACT_SUCCESS: 'FETCH_CONTRACT_SUCCESS',
    CREATE_CONTRACT_SUCCESS: 'CREATE_CONTRACT_SUCCESS',
    UPDATE_CONTRACT_SUCCESS: 'UPDATE_CONTRACT_SUCCESS',
};

export const CLIENT = {
    FETCH_CLIENT_SUCCESS: 'FETCH_CLIENT_SUCCESS',
    CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
    UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
};

export const USER = {
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
};

export const KNOWLEDGE_BASE_CATEGORY = {
    FETCH_CATEGORY_SUCCESS: 'FETCH_CATEGORY_SUCCESS',
    CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
};


export const KNOWLEDGE_BASE_SUB_CATEGORY = {
    FETCH_SUB_CATEGORY_SUCCESS: 'FETCH_SUB_CATEGORY_SUCCESS',
    CREATE_SUB_CATEGORY_SUCCESS: 'CREATE_SUB_CATEGORY_SUCCESS',
    UPDATE_SUB_CATEGORY_SUCCESS: 'UPDATE_SUB_CATEGORY_SUCCESS',
};

export const KNOWLEDGE_BASE_ARTICLES = {
    FETCH_ARTICLES_SUCCESS: 'FETCH_ARTICLES_SUCCESS',
    CREATE_ARTICLES_SUCCESS: 'CREATE_ARTICLES_SUCCESS',
    UPDATE_ARTICLES_SUCCESS: 'UPDATE_ARTICLES_SUCCESS',
};

export const SURGEON_ASSOCIATION = {
    FETCH_SURGEON_ASSOCIATION_SUCCESS: 'FETCH_SURGEON_ASSOCATION_SUCCESS',
    CREATE_SURGEON_ASSOCATION_SUCCESS: 'CREATE_SURGEON_ASSOCATION_SUCCESS',
};

export const GROUP = {
    FETCH_GROUP_SUCCESS: 'FETCH_GROUP_SUCCESS',
    CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
    UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
};


export const PROFILE = {
    FETCH_USER_PROFILE_SUCCESS: 'FETCH_USER_PROFILE_SUCCESS',
    UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
};


export const CASElOG = {
    FETCH_CASElOG_SUCCESS: 'FETCH_CASElOG_SUCCESS',
    CREATE_CASElOG_SUCCESS: 'CREATE_CASElOG_SUCCESS',
    UPDATE_CASElOG_SUCCESS: 'UPDATE_CASElOG_SUCCESS',
};

export const ASSISTANT = {
    FETCH_ASSISTANT_SUCCESS: 'FETCH_ASSISTANT_SUCCESS',
    CREATE_ASSISTANT_SUCCESS: 'CREATE_ASSISTANT_SUCCESS',
    UPDATE_ASSISTANT_SUCCESS: 'UPDATE_ASSISTANT_SUCCESS',
    DELETE_ASSISTANT_SUCCESS: 'DELETE_ASSISTANT_SUCCESS',
};

export const PHYSICIAN_ASSISTANT = {
    FETCH_PHYSICIAN_ASSISTANT_SUCCESS: 'FETCH_PHYSICIAN_ASSISTANT_SUCCESS',
    CREATE_PHYSICIAN_ASSISTANT_SUCCESS: 'CREATE_PHYSICIAN_ASSISTANT_SUCCESS',
    UPDATE_PHYSICIAN_ASSISTANT_SUCCESS: 'UPDATE_PHYSICIAN_ASSISTANT_SUCCESS',
    DELETE_PHYSICIAN_ASSISTANT_SUCCESS: 'DELETE_PHYSICIAN_ASSISTANT_SUCCESS',
};

export const BILLING = {
    FETCH_BILLING_SUCCESS: 'FETCH_BILLING_SUCCESS',
    UPDATE_BILLING_SUCCESS: 'UPDATE_BILLING_SUCCESS',
};

export const BILL = {
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    FETCH_BILLS_SUCCESS: 'FETCH_BILLS_SUCCESS',
    CREATE_BILL_SUCCESS: 'CREATE_BILL_SUCCESS',
    UPDATE_BILL_SUCCESS: 'UPDATE_BILL_SUCCESS',
};

export const INSURANCE = {
    FETCH_INSURANCE_SUCCESS: 'FETCH_INSURANCE_SUCCESS',
    CREATE_INSURANCE_SUCCESS: 'CREATE_INSURANCE_SUCCESS',
    UPDATE_INSURANCE_SUCCESS: 'UPDATE_INSURANCE_SUCCESS',
};

export const PRACTICE = {
    FETCH_PRACTICE_SUCCESS: 'FETCH_PRACTICE_SUCCESS',
    CREATE_PRACTICE_SUCCESS: 'CREATE_PRACTICE_SUCCESS',
    UPDATE_PRACTICE_SUCCESS: 'UPDATE_PRACTICE_SUCCESS',
};

export const CLAIM = {
    FETCH_CLAIMS_SUCCESS: 'FETCH_CLAIMS_SUCCESS',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    UPDATE_CLAIMS_SUCCESS: 'UPDATE_CLAIMS_SUCCESS',
};

export const REGIONS = {
    FETCH_REGIONS_SUCCESS: 'FETCH_REGIONS_SUCCESS',
    CREATE_REGION_SUCCESS: 'CREATE_REGION_SUCCESS',
    UPDATE_REGION_SUCCESS: 'UPDATE_REGION_SUCCESS',
};

export const BACTHES = {
    FETCH_BATCHES_SUCCESS: 'FETCH_BATCHES_SUCCESS',
    CREATE_BATCHES_SUCCESS: 'CREATE_BATCHES_SUCCESS',
    UPDATE_BATCHES_SUCCESS: 'UPDATE_BATCHES_SUCCESS',
    CREATE_POSTING_SUCCESS: 'CREATE_POSTING_SUCCESS',
    UPDATE_POSTING_SUCCESS: 'UPDATE_POSTING_SUCCESS'
};

export const ACCOUNTS = {
    FETCH_ACCOUNTS_SUCCESS: 'FETCH_REGIONS_SUCCESS',
    UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
};

export const INVOICING = {
    FETCH_INVOICING_SUCCESS: 'FETCH_INVOICING_SUCCESS',
    SUBMIT_INVOICING_SUCCESS: 'SUBMIT_INVOICING_SUCCESS',
    UPDATE_INVOICING_SUCCESS: 'UPDATE_INVOICING_SUCCESS',
    DELETE_INVOICING_SUCCESS: 'DELETE_INVOICING_SUCCESS',
}

export const DIVISION = {
    FETCH_DIVISION_SUCCESS: 'FETCH_DIVISION_SUCCESS',
    SUBMIT_DIVISION_SUCCESS: 'SUBMIT_DIVISION_SUCCESS',
    UPDATE_DIVISION_SUCCESS: 'UPDATE_DIVISION_SUCCESS',
    DELETE_DIVISION_SUCCESS: 'DELETE_DIVISION_SUCCESS',
}


export const INVOICINGV1 = {
    FETCH_INVOICING_SUCCESS: 'FETCH_INVOICING_SUCCESS',
    SUBMIT_INVOICING_SUCCESS: 'SUBMIT_INVOICING_SUCCESS',
    UPDATE_INVOICING_SUCCESS: 'UPDATE_INVOICING_SUCCESS',
    DELETE_INVOICING_SUCCESS: 'DELETE_INVOICING_SUCCESS',
}

export const LOGS = {
    FETCH_LOGS_SUCCESS: 'FETCH_LOGS_SUCCESS',
    EXPORT_LOGS_SUCCESS: 'EXPORT_LOGS_SUCCESS',
}
export const DASHBOARD = {
    FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
};

export const ORGANIZATION = {
    FETCH_ORGANIZATION_SUCCESS: 'FETCH_ORGANIZATION_SUCCESS',
    CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
    UPDATE_ORGANIZATION_SUCCESS: 'UPDATE_ORGANIZATION_SUCCESS',
};

export const NOTIFICATION = {
    FETCH_NOTIFICATION_SUCCESS: 'FETCH_NOTIFICATION_SUCCESS',
};

export const FEEDBACK = {
    FETCH_FEEDBACK_SUCCESS: 'FETCH_FEEDBACK_SUCCESS',
};