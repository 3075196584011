import React from "react";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchProcedures } from "../../actions/procedure";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {
  PROCEDURE_LISTING_COLUMNS,
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import ProcedureListingTable from "../atom/ProcedureListingTable";
import { procedureService } from "../../services/procedures";
import { utilityService } from "../../services/utility";

class ProcedureDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedProcedures: [],
    searchedTotalRecords: 0,
    pageSize: RECORDS_PER_PAGE,
    checkedProcedures: [],
  };

  componentWillMount() {
    let tStorageItem = localStorage.getItem(LOCAL_STORAGE_KEYS.PROCEDURE_FILTERS);
    tStorageItem = tStorageItem ? JSON.parse(tStorageItem) : null;

    this.setState({ loading: true });
    if (tStorageItem) {
      // eslint-disable-next-line
      this.state.currentPage = tStorageItem.page;
      // eslint-disable-next-line
      this.state.pageSize = tStorageItem.page_size;
    }

    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
      api_type: "react",
    };
    this.props.fetchProcedures({ params: options }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);
    const options = {
      page: page,
      page_size: this.state.pageSize,
      api_type: "react",
    };
    localStorage.setItem(LOCAL_STORAGE_KEYS.PROCEDURE_FILTERS, JSON.stringify(options));

    this.props.fetchProcedures({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_PROCEDURE);

  showManageProcedure = (procedureId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_PROCEDURE,
      state: {
        procedureId,
      },
    });

  deleteProcedure = (procedure) => {
    procedureService.deleteProcedure(procedure).then((response) => {
      this.goToPage(1);
    });
  };

  list = () => {
    const { procedures = [] } = this.props;
    return procedures.map((procedure) => {
      return [
        procedure.id,
        procedure.cptcode,
        procedure.cptdescription,
        procedure.procedure_name,
        procedure.specialty?.name,
        procedure.related_dx_code,
        procedure.id,
      ];
    });
  };

  searchProcedure = () => {
    const { searchByName } = this.state;
    let options = {};

    if (searchByName) {
      options = { ...options, search: searchByName };
    }
    options = { ...options, page_size: this.state.pageSize };

    this.setState({
      searched: true,
      loading: true,
    });
    options = { ...options, api_type: "react" };
    procedureService.getProcedures({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedProcedures: response.data.map((procedure) => {
          return [
            procedure.id,
            procedure.cptcode,
            procedure.cptdescription,
            procedure.procedure_name,
            procedure.specialty?.name,
            procedure.related_dx_code,
            // procedure.price,
            procedure.id,
          ];
        }),
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  resetProcedureSearch = () => {
    this.setState({
      searchByName: "",
      searchedPractice: [],
      searched: false,
      searchedTotalRecords: 0,
    });
  };


  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };

  addCheckedItem = (patients) => {
    this.setState({},)
    this.setState((prevState) => ({
      checkedProcedures: [...prevState.checkedProcedures, patients],
    }));
  };

  removeCheckedItem = (patients) => {
    this.setState((prevState) => ({
      checkedProcedures: prevState.checkedProcedures.filter(
        (activity) => activity !== patients
      ),
    }));
  };

  deleteMultipleProcedures = () => {
    utilityService.showConfirmDialog().then(data => {
      let payload = { 'procedureIds': this.state.checkedProcedures };
      procedureService.deleteMultipleProcedures(payload).then((response) => {
        this.goToPage(1);
      });
    })
  }

  render() {
    const { searched, searchedProcedures, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "755px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Procedures
              </h3>
            </div>
          </div>
          <div className="filter-footer-header">
            <button
              onClick={() => {
                this.showAdd(true);
              }}
              className="filter-button"
            >
              Add New Procedure
            </button>

            <button
              style={{
                width: "109px",
              }}
              onClick={this.deleteMultipleProcedures}
              disabled={this.state.checkedProcedures.length < 1}
              className={
                this.state.checkedProcedures.length < 1
                  ? "filter-footer-disabled-button"
                  : "filter-button"
              }
            >
              Delete
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value }, () => {
                    if (this.state.searchByName.length >= 3) {
                      this.searchProcedure();
                    } else if (this.state.searchByName.length === 0) {
                      this.resetProcedureSearch();
                    }
                  });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetProcedureSearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <ProcedureListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManageProcedure}
                delete={this.deleteProcedure}
                headColumn={PROCEDURE_LISTING_COLUMNS}
                setPageSize={this.setPageSize}
                pageSize={this.state.pageSize}
                addCheckedItem={this.addCheckedItem}
                removeCheckedItem={this.removeCheckedItem}
                checkedProcedures={this.state.checkedProcedures}
                listing={
                  searched
                    ? searchedProcedures
                    : this.list()
                }
                totalRecords={
                  searched
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    procedures: state.procedureReducer.procedures,
    totalRecords: state.procedureReducer.totalProcedures,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProcedures,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProcedureDashboard));
