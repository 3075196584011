import React from "react";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { APP_ROUTES, NOTIFICATION_TYPES } from "../../constants/common";
import { accountService } from "../../services/accounts";
import { bindActionCreators } from "redux";
import { updateInvoice } from "../../actions/account";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createNotification } from "../../utils/notificationManager";

class ManageInvoice extends React.Component {
  state = {
    amountReceived: "",
  };

  componentDidMount() {
    const invoiceId = this.props.location.state
      ? this.props.location.state.invoiceId
      : "";
    if (invoiceId) {
      accountService.getInvoiceDetail(invoiceId).then((response) => {
        this.setState({
          amountReceived: response.data.amount_received,
        });
      });
    }
  }

  cancel = () => {
    this.props.history.push(APP_ROUTES.ACCOUNT_DASHBOARD);
  };

  submitInvoice = () => {
    const { updateInvoice } = this.props;
    const { amountReceived } = this.state;

    const invoiceId = this.props.location.state
      ? this.props.location.state.invoiceId
      : "";
    const request = {
      id: invoiceId,
      amount: amountReceived,
    };

    updateInvoice(request)
      .then((res) => {
        if (res.response.success) {
          createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
          this.props.history.push(APP_ROUTES.ACCOUNT_DASHBOARD);
        } else {
          createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
        }
      })
  };

  render() {
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage}  />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              <h2 className="heading-custom">Edit Invoice</h2>
            </div>
            <div className="col-md-12 mrgn-btm10">
              <label>Amount Received </label>
              <input
                type="text"
                className="code-input"
                value={this.state.amountReceived}
                onChange={(event) =>
                  this.setState({ amountReceived: event.target.value })
                }
              />
            </div>
          </div>
          <div className="filter-footer">
            <button onClick={this.submitInvoice} className="filter-save-button">
              Update
            </button>
            <button onClick={this.cancel} className="filter-cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateInvoice,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageInvoice));
