import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createInsurance, updateInsurance } from "../../actions/insurance";
import { insuranceService } from "../../services/insurance";
import StateSelection from "../organisms/StateSelection";
import CitySelection from "../organisms/CitySelection";
import deleteIcon from "../../../assets/images/delete.png";
import viewIcon from "../../../assets/images/view.png";
import { FileStorageService } from "../../services/fileStorage";
import { utilityService } from "../../services/utility";
import ZipCode from "../organisms/ZipCode";
import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";


const validationGroup = {
  message: "There are some validation errors in basic information tab:",
  fields: {
    'name': { filedName: 'Insurance Name', required: true, minLength: 3, maxLength: 200 },
  }
}

const validationGroupContactDetails = {
  message: "There are some validation errors in contact detail tab:",
  fields: {
    'contact_type': { filedName: 'Contact Type', required: true },
    'contact_information': { filedName: 'Contact Information', required: true },
  }
}

const validationGroupDocuments = {
  message: "There are some validation errors in document tab:",
  fields: {
    'documents_file': { filedName: 'Document File', required: true },
    'documents_description': { filedName: 'Document Description', required: true },
  }
}

class ManageInsurance extends React.Component {
  state = {
    errors: [],
    name: "",
    e_payer_id: "",
    m_payer_id: "",
    type_code: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    payer_type: "",
    contact_details: [],
    contact_type: '',
    contact_information: '',
    documents: [],
    documents_file: {},
    documents_description: ''
  };

  componentDidMount() {
    const insId = this.props.location.state
      ? this.props.location.state.insId
      : "";
    if (insId) {
      insuranceService.getInsuranceDetail(insId).then((response) => {
        let tContactDetails = [];
        let tContactDetailsInstances = response.data.contact_details;
        if (tContactDetailsInstances) {
          tContactDetailsInstances.forEach(tcd => {
            tContactDetails.push({
              type: tcd.type,
              information: tcd.information,
            })
          })
        }

        let tDocuments = [];
        let tDocumentsInstances = response.data.documents;
        if (tDocumentsInstances) {
          tDocumentsInstances.forEach(td => {
            tDocuments.push({
              file_name: td.file_name,
              description: td.description,
              id: td.id,
              path: td.path
            })
          })
        }

        this.setState({
          name: response.data.description,
          e_payer_id: response.data.id2,
          m_payer_id: response.data.payor_code,
          payer_type: response.data.category,
          type_code: response.data.insurance_type,
          address: response.data.address1,
          address2: response.data.address2,
          city: response.data.city,
          state: response.data.state,
          zip: response.data.zip,
          contact_details: tContactDetails,
          documents: tDocuments
        });
      });
    }
  }

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };

  validateActivity = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  validateContactDetails = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroupContactDetails, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  }

  validateDocuments = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroupDocuments, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  }

  submitDocuments = () => {
    if (!this.validateDocuments()) {
      return;
    }

    const {
      documents_file,
      documents_description,
      documents
    } = this.state;

    const insId = this.props.location.state
      ? this.props.location.state.insId
      : "";

    const formData = new FormData();
    formData.append("file", documents_file);
    formData.append("description", documents_description);
    formData.append("insuranceid", insId);

    insuranceService.uploadDocument(formData).then(tdata => {
      let tObject = {
        file_name: tdata.data.file_name,
        description: tdata.data.description,
        path: tdata.data.path,
        id: tdata.data.id
      }

      documents.push(tObject);
      this.setState({ documents: documents });
    })
  }

  submitContactDetails = () => {
    if (!this.validateContactDetails()) {
      return;
    }

    this.state.contact_details.push({
      type: this.state.contact_type,
      information: this.state.contact_information
    });

    this.submitInsurance(false);
  }

  clearContactDetails = () => {
    this.setState({
      'contact_type': '',
      'information': '',
    });
  }

  clearDocuments = () => {
    this.setState({
      documents_file: {},
      documents_description: ''
    })
  }

  deleteFile(index) {
    utilityService.showConfirmDialog().then(data => {
      let tDoc = this.state.documents[index];
      insuranceService.deleteDocument(tDoc.id).then(tempData => {
        this.state.documents.splice(index, 1);
        this.setState({
          document: this.state.documents
        })
      })
    })
  }

  downloadFile(index) {
    let tDoc = this.state.documents[index];
    insuranceService.downloadDocument(tDoc.id).then(response => {
      FileStorageService.downloadFileFromContentFileName(response.data.content, response.data.filename);
    })
  }

  deleteContact(index) {
    utilityService.showConfirmDialog().then(data => {
      this.state.contact_details.splice(index, 1);
      this.submitInsurance(false);

      this.setState({
        contact_details: this.state.contact_details
      })
    })
  }

  submitInsurance = (redirectPage = true) => {
    if (!this.validateActivity()) {
      return;
    }
    const { createInsurance, updateInsurance } = this.props;
    const {
      name,
      e_payer_id,
      m_payer_id,
      type_code,
      address,
      address2,
      city,
      zip,
      state,
      payer_type,
      contact_details
    } = this.state;

    const request = {
      description: name,
      payor_code: e_payer_id,
      id2: m_payer_id,
      insurance_type: type_code,
      category: payer_type,
      address1: address,
      address2: address2,
      city: city,
      state: state,
      zip: zip,
      contact_details: contact_details,
    };

    const insId = this.props.location.state
      ? this.props.location.state.insId
      : "";
    if (insId) {
      const payload = { ...request, id: insId };
      updateInsurance(payload)
        .then((res) => {
          if (res.response.success) {
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              if (redirectPage) {
                this.props.history.push(APP_ROUTES.INSURANCE_DASHBOARD);
              }
              else {
                this.clearContactDetails();
                this.clearDocuments();
              }
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
            //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          }
        })
    } else {
      createInsurance(request)
        .then((res) => {
          if (res.response.success) {
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              if (redirectPage) {
                this.props.history.push(APP_ROUTES.INSURANCE_DASHBOARD);
              }
              else {
                this.clearContactDetails();
                this.clearDocuments();
              }
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
            //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          }
        })
    }
  };

  toggleCheckbox = (name, event) => {
    let obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  };

  cancel = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.INSURANCE_DASHBOARD);
    }
  };

  zipCodeChanged = (value) => {
    this.setState({ zip: value.zip_code })

    if (value.state) {
      this.setState({ state: value.state.code });
    }

    if (value.city) {
      this.setState({ city: value.city.name });
    }
  }

  render() {
    const insId = this.props.location.state
      ? this.props.location.state.insId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {insId && <h2 className="heading-custom">Edit Insurance</h2>}
              {!insId && <h2 className="heading-custom">Add Insurance</h2>}
            </div>

            <div class='col-md-12 p-0'>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="basic-info-tab" data-toggle="tab" href="#basic-info" role="tab">Basic Information</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-details-tab" data-toggle="tab" href="#contact-details" role="tab">Contact Details</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="documents-tab" data-toggle="tab" href="#documents" role="tab">Documents</a>
                </li>
              </ul>
              <div class="tab-content mb-4" id="myTabContent">
                <div class="tab-pane fade show active" id="basic-info" role="tabpanel">
                  <div class="row">
                    <div className="col-md-3 mrgn-btm10">
                      <label>
                        Insurance Name {FormValidatorService.getRequiredHTML(validationGroup, 'name')}
                      </label>
                      <input
                        type="text"
                        className={FormValidatorService.getClass(validationGroup, this.state, 'name')}
                        value={this.state.name}
                        onChange={(event) => {
                          this.setState({ name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Electronic Payer ID {FormValidatorService.getRequiredHTML(validationGroup, 'e_payer_id')}</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.e_payer_id}
                        onChange={(event) => {
                          this.setState({ e_payer_id: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Master Payer ID {FormValidatorService.getRequiredHTML(validationGroup, 'm_payer_id')} </label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.m_payer_id}
                        onChange={(event) => {
                          this.setState({ m_payer_id: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Payer Type {FormValidatorService.getRequiredHTML(validationGroup, 'payer_type')}</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.payer_type}
                        onChange={(event) => {
                          this.setState({ payer_type: event.target.value });
                        }}
                      />
                    </div>

                    <div className="col-md-3 mrgn-btm10">
                      <label>Type Code {FormValidatorService.getRequiredHTML(validationGroup, 'type_code')}</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.type_code}
                        onChange={(event) => {
                          this.setState({ type_code: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Address {FormValidatorService.getRequiredHTML(validationGroup, 'address')}</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.address}
                        onChange={(event) => {
                          this.setState({ address: event.target.value });
                        }}
                      />
                    </div>

                    <div className="col-md-3 mrgn-btm10">
                      <label>Address 2 {FormValidatorService.getRequiredHTML(validationGroup, 'address2')}</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.address2}
                        onChange={(event) => {
                          this.setState({ address2: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <ZipCode title={'Zip Code ' + FormValidatorService.getRequiredHTML(validationGroup, 'zip')}
                        value={this.state.zip}
                        onChange={(value) => {
                          this.setState({ 'zip': value });
                        }}
                        onBlur={(value) => {
                          this.zipCodeChanged(value);
                        }}>
                      </ZipCode>
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <StateSelection
                        key={this.state.state}
                        label={'State ' + FormValidatorService.getRequiredHTML(validationGroup, 'state')}
                        isMulti={false}
                        selectedStateCode={this.state.state}
                        onChange={(value) => { this.setState({ state: value.value }) }}
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <CitySelection
                        key={"".concat(this.state.state, this.state.city)}
                        label={'City ' + FormValidatorService.getRequiredHTML(validationGroup, 'city')}
                        isMulti={false}
                        selectedStateCode={this.state.state}
                        selectedCity={this.state.city}
                        onChange={(value) => { console.log(value); this.setState({ city: value.value }) }}
                      />
                    </div>

                  </div>
                </div>
                <div class="tab-pane fade show" id="contact-details" role="tabpanel">
                  <div class="row">
                    <div class='col-md-12'>
                      <div className="page-title">
                        <h2 className="heading-custom">Add New Entry</h2>
                      </div>
                      <div class='row'>
                        <div className="col-md-6 mrgn-btm10">
                          <CustomSelect type='Contact Type'
                            isRequired={FormValidatorService.isRequired(validationGroupContactDetails, 'contact_type')}
                            className={FormValidatorService.getClass(validationGroupContactDetails, this.state, 'contact_type')}
                            value={this.state.contact_type}
                            onChange={event => {
                              this.setState({ contact_type: event.target.value });
                            }}
                          >
                          </CustomSelect>
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                          <label>Contact Information {FormValidatorService.getRequiredHTML(validationGroupContactDetails, 'contact_information')}</label>
                          {(this.state.contact_type === '') &&
                            <input
                              type="text"
                              class='code-input'
                              disabled="disabled"
                              placeholder="Please select contact type"
                            />
                          }
                          {(this.state.contact_type === 'Phone' || this.state.contact_type === 'Fax') &&
                            <input
                              type="text"
                              className={FormValidatorService.getClass(validationGroupContactDetails, this.state, 'contact_information')}
                              value={this.state.contact_information}
                              onChange={(event) =>
                                this.setState({ contact_information: utilityService.formatPhone(event.target.value) })
                              }
                            />
                          }
                          {this.state.contact_type === 'Email' &&
                            <input
                              type="email"
                              className={FormValidatorService.getClass(validationGroupContactDetails, this.state, 'contact_information')}
                              value={this.state.contact_information}
                              onChange={(event) =>
                                this.setState({ contact_information: event.target.value })
                              }
                            />
                          }
                        </div>
                      </div>
                      <div class="col-md-12 text-right">
                        <button
                          onClick={this.submitContactDetails}
                          className="filter-save-button">
                          Save Contact Entry
                        </button>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div className="page-title">
                            <h2 className="heading-custom">Current Contact Details</h2>
                          </div>
                          <table class="table table-striped">
                            <thead>
                              <tr >
                                <th>Type</th>
                                <th>Information</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.contact_details.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{item.type}</td>
                                    <td>{item.information}</td>
                                    <td>
                                      <img
                                        src={deleteIcon}
                                        onClick={() => { this.deleteContact(index) }}
                                        alt="delete"
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show" id="documents" role="tabpanel">
                  <div class="row">
                    <div class='col-md-12'>
                      <div className="page-title">
                        <h2 className="heading-custom">Add New Entry</h2>
                      </div>
                      <div class='row'>
                        <div className="col-md-6 mrgn-btm10">
                          <label>File {FormValidatorService.getRequiredHTML(validationGroup, 'documents_file')}</label><br></br>
                          <input
                            type="file"
                            name="file"
                            className={FormValidatorService.getClass(validationGroupContactDetails, this.state, 'documents_file', 'file-upload')}
                            onChange={(event) => { this.setState({ documents_file: event.target.files[0] }) }}
                          />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                          <label>Description {FormValidatorService.getRequiredHTML(validationGroup, 'documents_description')}</label>
                          <input
                            type="text"
                            className={FormValidatorService.getClass(validationGroupContactDetails, this.state, 'documents_description')}
                            value={this.state.documents_description}
                            onChange={(event) =>
                              this.setState({ documents_description: event.target.value })
                            }
                          />

                        </div>
                      </div>
                      <div class="col-md-12 text-right">
                        <button
                          onClick={this.submitDocuments}
                          className="filter-save-button">
                          Save File Entry
                        </button>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div className="page-title">
                            <h2 className="heading-custom">Current File Details</h2>
                          </div>
                          <table class="table table-striped">
                            <thead>
                              <tr >
                                <th>File Name</th>
                                <th>Description</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.documents.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{item.file_name}</td>
                                    <td>{item.description}</td>
                                    <td>
                                      <img
                                        src={viewIcon}
                                        onClick={() => { this.downloadFile(index) }}
                                        alt="download"
                                      />

                                      <img
                                        src={deleteIcon}
                                        onClick={() => { this.deleteFile(index) }}
                                        alt="delete"
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-footer">
            {insId && (
              <button
                onClick={this.submitInsurance}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!insId && (
              <button
                onClick={this.submitInsurance}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button onClick={this.cancel} className="filter-cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createInsurance,
      updateInsurance,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageInsurance));
