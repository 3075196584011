import React from "react";
import { bindActionCreators } from "redux";
import { createDivision, updateDivision } from "../../actions/division";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createNotification } from "../../utils/notificationManager";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { utilityService } from "../../services/utility";
import { FormValidatorService } from "../../services/formvalidator";
import CustomDropDown from "../organisms/CustomDropDown";
import { divisionService } from "../../services/division";

const validationGroup = {
  message: "There are some validation errors in basic information tab:",
  fields: {
    'name': { filedName: 'Division Name', required: true },
    'assistant': { filedName: 'Surgical Assistant', required: true },
  }
}

class ManageDivision extends React.Component {
  state = {
    errors: [],
    name: '',
    assistant: {},
  };

  componentDidMount() {
    const divisionId = this.props.location.state
      ? this.props.location.state.divisionId
      : "";
    if (divisionId) {
      divisionService.getDivisionDetail(divisionId).then((response) => {
        this.setUpStateObject(response);
      });
    }
  }

  reloadObject() {
    const divisionId = this.props.location.state
      ? this.props.location.state.divisionId
      : "";
    if (divisionId) {
      divisionService.getContractDetail(divisionId).then((response) => {
        this.setUpStateObject(response);
      });
    }
  }

  setUpStateObject = (response) => {
    this.setState({
      name: response.data.name,
      assistant: {
        value: response.data.user?.assistant?.id ,
        label: response.data.user?.fullname
      }
    });
  }

  validateDivision = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  submitDivision = (redirectPage = true) => {
    if (!this.validateDivision()) {
      return;
    }

    const { createDivision, updateDivision } = this.props;
    const divisionId = this.props.location.state
      ? this.props.location.state.divisionId
      : "";

    const {
      name,
      assistant
    } = this.state;

    const request = {
      name: name,
      assistant: assistant
    };

    if (divisionId) {
      const payload = { ...request, id: divisionId, api_type: "react" };

      updateDivision(payload)
        .then((res) => {
          if (res.response.success) {
            if (redirectPage) {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              this.props.history.push(APP_ROUTES.DIVISION);
            }
            else {
              this.reloadObject();
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update division ${res.response.message}`);
          }
        })
    } else {
      createDivision(request)
        .then((res) => {
          if (res.response.success) {
            if (redirectPage) {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              this.props.history.push(APP_ROUTES.DIVISION);
            }
            else {
              this.reloadObject();
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update division ${res.response.message}`);
          }
        })
    }
  };

  onChangeAssistant = (value) => {
    this.setState({ assistant: value });
  };


  render() {
    const divisionId = this.props.location.state
      ? this.props.location.state.divisionId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {divisionId && <h2 className="heading-custom">Edit Division</h2>}
              {!divisionId && <h2 className="heading-custom">Add Division</h2>}
            </div>


            <div class='col-md-12 p-0'>
              <div className="row ">
                <div className="col-md-6">
                  <label>Division Name {FormValidatorService.getRequiredHTML(validationGroup, 'name')}</label>
                  <input
                    type="text"
                    className={FormValidatorService.getClass(validationGroup, this.state, 'name')}
                    value={this.state.name}
                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                  />
                </div>
                <div className="col-md-6">
                  <CustomDropDown
                    label={'Surgical Assistant'}
                    type={'assistants'}
                    className={FormValidatorService.getClass(validationGroup, this.state, 'assistant', '')}
                    isRequired={FormValidatorService.isRequired(validationGroup, 'assistant')}
                    isMulti={false}
                    selectedValue={this.state?.assistant}
                    onChange={this.onChangeAssistant}>
                  </CustomDropDown>
                </div>
              </div>
            </div>
          </div>

          <div className="filter-footer">
            {divisionId && (
              <button
                onClick={this.submitDivision}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!divisionId && (
              <button
                onClick={this.submitDivision}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.DIVISION);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createDivision,
      updateDivision,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageDivision));
