import { KNOWLEDGE_BASE_ARTICLES } from '../constants/actions';

const initialState = {
    articles: [],
    total: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case KNOWLEDGE_BASE_ARTICLES.FETCH_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: action.data.data,
                total: action.data.records,
            };
        case KNOWLEDGE_BASE_ARTICLES.CREATE_ARTICLES_SUCCESS:
            const createUser = [...state.articles, action.data.data];
            return {
                ...state,
                articles: createUser,
            };
        case KNOWLEDGE_BASE_ARTICLES.UPDATE_ARTICLES_SUCCESS:
            const updateUser = [...state.articles, action.data.data];
            return {
                ...state,
                articles: updateUser,
            }
        default:
            return state;
    }
}