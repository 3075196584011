import { PHYSICIAN_ASSISTANT } from '../constants/actions';

const initialState = {
    physicianAssistants: [],
    totalPhysicianAssistants: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case PHYSICIAN_ASSISTANT.FETCH_PHYSICIAN_ASSISTANT_SUCCESS:
            return {
                ...state,
                physicianAssistants: action.data.data,
                totalPhysicianAssistants: action.data.records,
            };
        case PHYSICIAN_ASSISTANT.CREATE_PHYSICIAN_ASSISTANT_SUCCESS:
            const assistants = [...state.physicianAssistants, action.data.data];
            return {
                ...state,
                physicianAssistants: assistants,
            }
        case PHYSICIAN_ASSISTANT.UPDATE_PHYSICIAN_ASSISTANT_SUCCESS:
            const updatedAssistants = [...state.physicianAssistants, action.data.data];
            return {
                ...state,
                physicianAssistants: updatedAssistants,
            }
        case PHYSICIAN_ASSISTANT.DELETE_PHYSICIAN_ASSISTANT_SUCCESS.DELETE_ASSISTANT_SUCCESS:
            const deletedAssistants = [...state.physicianAssistants, action.data.data];
            return {
                ...state,
                physicianAssistants: deletedAssistants,
            }
        default:
            return state;
    }
}