import React from 'react';

import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {withRouter} from "react-router-dom";

import EventCard from "../atom/EventCard";

const localizer = momentLocalizer(moment);

class EventCalendar extends React.Component {

    render() {
        return (
            <Calendar
                popup
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={this.props.events}
                components={{
                    eventWrapper: EventCard,
                }}
                style={{
                    height: "100vh",
                }}
            />
        );
    }
}

export default withRouter(EventCalendar);

