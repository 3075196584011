import { FEEDBACK } from "../constants/actions";
import { feedbackService } from "../services/feedbakcs";

export const fetchFeedbacks = (options) => dispatch => {
    return new Promise((resolve, reject) => {
        feedbackService
            .getFeedbacks(options)
            .then(response => {
                dispatch({
                    type: FEEDBACK.FETCH_FEEDBACK_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => { });
    });
};