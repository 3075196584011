import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getArticles(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_KNOWLEDGE_BASE_ARTICLES, options)
        .then(handleResponse)
        .catch(handleError);
}

function createArticle(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_KNOWLEDGE_BASE_ARTICLE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateArticle(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_KNOWLEDGE_BASE_ARTICLE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteArticle(id) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_KNOWLEDGE_BASE_ARTICLE + id)
        .then(handleResponse)
        .catch(handleError);
}

function downloadArticle(id) {
    return apiClient()
        .get(API_CONSTANTS.DOWNLOAD_KNOWLEDGE_BASE_ARTICLE + id)
        .then(handleResponse)
        .catch(handleError);
}

function getArticleDetail(id) {
    return apiClient()
        .get(API_CONSTANTS.KNOWLEDGE_BASE_ARTICLE_DETAIL + id)
        .then(handleResponse)
        .catch(handleError);
}

export const knowledgeBaseArticleService = {
    getArticles,
    createArticle,
    updateArticle,
    deleteArticle,
    downloadArticle,
    getArticleDetail,
}