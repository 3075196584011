import { DIVISION } from '../constants/actions';

const initialState = {
    divisions: [],
    totalDivision: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case DIVISION.FETCH_DIVISION_SUCCESS:
            return {
                ...state,
                divisions: action.data.data,
                totalDivision: action.data.records,
            };
        case DIVISION.SUBMIT_DIVISION_SUCCESS:
            const divisions = [ ...state.divisions, action.data.data ];
            return {
                ...state,
                divisions: divisions,
            }
        case DIVISION.UPDATE_DIVISION_SUCCESS:
            const updateddivision = [ ...state.divisions, action.data.data ];
            return {
                ...state,
                divisions: updateddivision,
            }
        case DIVISION.DELETE_DIVISION_SUCCESS:
            const deleteddivision = [ ...state.divisions, action.data.data ];
            return {
                ...state,
                divisions: deleteddivision,
            }
        default:
            return state;
    }
}