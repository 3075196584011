import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { MENU_LIST } from "../../constants/common";

import "../../../sass/sidebar.scss";
import companyLogo from "../../../assets/images/coremaxlogo-trans.png";
import { isArray } from "lodash";

class Sidebar extends React.Component {
  static propTypes = {
    authInfo: PropTypes.object.isRequired,
    activePage: PropTypes.string.isRequired
  };

  state = {
    activePage: "",
    activeHead: ""
  };

  componentDidMount() {
    let tActiveHead = '';
    let tActivePage = '';
    let path = this.props.activePage;

    MENU_LIST.forEach(tMenu => {
      if (tMenu.slug === path) {
        tActivePage = tMenu.slug;
      }

      if (!tMenu.module_name) {
        Object.keys(tMenu).forEach(tKey => {
          tMenu[tKey].forEach(tSubMenu => {
            if (tSubMenu.slug === path) {
              tActiveHead = tKey;
              tActivePage = tSubMenu.slug;
            }
          })

        })
      }
    })


    this.setState({
      activePage: tActivePage,
      activeHead: tActiveHead
    });
  }
  getSubMenuCount = (nestedMenu) => {
    if (isArray(nestedMenu)) {
      return nestedMenu.filter((menu) => {
        return this.hasAccess(menu.slug);
      }).length
    }

    return 0;
  }

  renderNestedMenu = (nestedMenu, subMenuHead) => {
    return nestedMenu.map((menu, index) => {
      return (
        (this.hasAccess(menu.slug) || true) && index !== nestedMenu.length - 1 && (
          <Link
            style={{
              color: "#E7E7E7",
              textDecoration: "none",
            }}
            to={{
              pathname: menu.slug,
              state: {
                activePage: menu.slug,
                toggleMenu: true,
                subMenuHead,
              },
            }}
          >
            <li
              className={`sidebar-submenu-list-item ${menu.slug === this.state.activePage ? "active" : ""
                }`}
            >
              <div className="submenu-circle" />
              <div className="submenu" />
              <div
                style={{
                  fontSize: "12px",
                  paddingLeft: "35px",
                }}
              >
                {menu.module_name}
              </div>
            </li>
          </Link>
        )
      );
    });
  };

  handleRootMenuClick = (menu) => {
    this.setState(() => ({
      activePage: menu
    }));
  }

  handleClick = (menuHead) => {
    let tMenuHead = menuHead;
    if (this.state.activeHead === menuHead)
      tMenuHead = '';

    this.setState(() => ({
      activeHead: tMenuHead
    }));
  };

  hasAccess = (module) => {
    // const loggedInUser = JSON.parse(
    //   localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    // );
    // let menuExists = false;

    // if (loggedInUser.groups_users) {
    //   loggedInUser.groups_users.map(group => {          //eslint-disable-line
    //     if (group.modules_groups) {
    //       let tIndex = group.modules_groups.findIndex(t => t.slug === module)
    //       if (tIndex >= 0)
    //         menuExists = true;
    //     }
    //   });
    // }
    // return menuExists;
    return true;
  };

  isSubMenuActive = (menuItem) => {
    let activePage = this.state.activeHead;
    let tKey = Object.keys(menuItem)[0];

    let isActive = false;
    if (activePage === tKey)
      isActive = true;

    return isActive;
  }

  renderMenuListItem = () => {
    // const { toggleNestedMenu, menuName } = this.state;
    // const toggleMenu =
    //   this.props.toggleMenu || this.props.location.state
    //     ? this.props.location.state.toggleMenu
    //     : false;
    // const subMenuHead =
    //   this.props.subMenuHead || this.props.location.state
    //     ? this.props.location.state.subMenuHead
    //     : "";
    return MENU_LIST.map((menuItem) => {        //eslint-disable-line
      if (menuItem.module_name && this.hasAccess(menuItem.slug)) {
        return (
          <Link style={{ color: "#E7E7E7", textDecoration: "none", }} to={{ pathname: menuItem.slug, state: { activePage: menuItem.slug, }, }} onClick={() => this.handleRootMenuClick(menuItem.slug)}>
            <div className={`${menuItem.slug === this.state.activePage ? "active" : ""}`} >
              <div style={{ paddingLeft: "20px", display: "flex", alignItems: "center", }} >
                <FontAwesomeIcon icon={menuItem.icon} />
                <li style={{ paddingLeft: menuItem.padding, }} className="sidebar-menu-list-item" >
                  {menuItem.module_name}
                </li>
              </div>
            </div>
          </Link>
        );
      } else {
        if (this.getSubMenuCount(Object.values(menuItem)[0]) > 0) {
          const menuHead = Object.keys(menuItem)[0];
          const menuLength = Object.values(menuItem)[0].length;
          return (
            <>
              <div className={`${this.isSubMenuActive(menuItem) ? "has-submenu active" : "has-submenu"}`} >
                <div style={{ paddingLeft: "20px", display: "flex", alignItems: "center", }}>
                  <FontAwesomeIcon style={{ color: "#E7E7E7", }} icon={Object.values(menuItem)[0][menuLength - 1].icon} />
                  <li style={{ paddingLeft: Object.values(menuItem)[0][menuLength - 1].padding, color: "#E7E7E7", textDecoration: "none", }} className="sidebar-menu-list-item" onClick={() => { this.handleClick(menuHead); }}>
                    {menuHead}
                    <span className="main_menu">
                      <FontAwesomeIcon style={{ color: "#E7E7E7", }} icon={faChevronDown} />
                    </span>
                  </li>
                </div>
                {this.renderNestedMenu(Object.values(menuItem)[0], menuHead)}
              </div>
            </>
          );
        }
      }
    });
  };

  render() {
    return (
      <div className="sidebar-title">
        <a
          href="https://usp.coremaxcloud.com/login"
          className="sidebar-logo-link"
        >
          <img className="sidebar-logo" src={companyLogo} alt="coremax" />
        </a>
        <div className="sidebar-menu">
          <ul className="sidebar-menu-list">{this.renderMenuListItem()}</ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authInfo: state.authenticationReducer.authInfo,
  };
};

export default connect(mapStateToProps, null)(withRouter(Sidebar));
