import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { locationService } from "../../services/location";

class ZipCode extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
    };

    componentDidMount() {

    }


    render() {
        return (
            <>
                <label>{this.props.title ?? 'Default'}</label>
                <input
                    type="text"
                    className="code-input"
                    value={this.props.value}
                    onChange={(event) => {
                        this.props.onChange(event.target.value);
                    }}
                    onBlur={(event) => {
                        this.getZipDetails(event.target.value);
                    }}
                />
            </>
        )
    }

    getZipDetails(value) {
        locationService.getZipCodeDetails(value).then(data => {
            if (data.data) {
                this.props.onBlur(data.data);
            }
            else {
                this.props.onBlur({ 'zip_code': value });
            }
        })
    }
}


export default withRouter(ZipCode);
