import { scanFlowService } from "../services/scanFlow";
import { BILLING } from "../constants/actions";

export const fetchBilling = options => dispatch => {
    return new Promise((resolve, reject) => {
        scanFlowService
            .getBillings(options)
            .then(response => {
                dispatch({
                    type: BILLING.FETCH_BILLING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateBilling = payload => dispatch => {
    return new Promise((resolve, reject) => {
        scanFlowService
            .updateBilling(payload)
            .then(response => {
                dispatch({
                    type: BILLING.UPDATE_BILLING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};