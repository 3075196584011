import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import {API_CONSTANTS} from "../constants/api";

function getPractices(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_PRACTICE, options)
        .then(handleResponse)
        .catch(handleError);
}

function getPracticeDetail(practice) {
    return apiClient()
        .get(API_CONSTANTS.PRACTICE_DETAIL + practice)
        .then(handleResponse)
        .catch(handleError);
}

function createPractice(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_PRACTICE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updatePractice(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_PRACTICE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deletePractice(practice) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_PRACTICE + practice)
        .then(handleResponse)
        .catch(handleError);
}

export const practiceService = {
    getPractices,
    getPracticeDetail,
    createPractice,
    updatePractice,
    deletePractice
}