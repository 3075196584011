import React from "react";
import PropTypes from "prop-types";
import { APP_ROUTES } from "../../constants/common";
import Loader from "./Loader";
import { withRouter } from "react-router-dom";
import EventPopup from "./EventPopup";
import BillPopup from "./BillPopup";
import PatientPopup from "./PatientPopup";
import BillingNotes from "./BillingNotes";
import TablePagination from "../organisms/TablePagination";
var success = {
  div: {
    color: "green",
  },
};

class BillsListingTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
    addCheckedItem: PropTypes.func.isRequired,
    addAllCheckedItem: PropTypes.func.isRequired,
    removeAllCheckedItem: PropTypes.func.isRequired,
    removeCheckedItem: PropTypes.func.isRequired,
    checkedBills: PropTypes.array.isRequired,
    allChecked: PropTypes.bool.isRequired,
  };
  state = {
    eventPopupOpen: false,
    BillPopupOpen: false,
    patinetPopupOpen: false,
    billingNotesOpen: false,
    eventId: "",
    bill_Id: "",
    patientId: "",
    type: "",
    billing_notes: "",
    claim_notes: "",
    errors: "",
  };

  renderHeadColumn = () => {
    const { headColumn } = this.props;
    const header = headColumn.map((column) => (
      <th
        className="listing-table-head-column"
        style={{
          minWidth: "135px",
        }}
      >
        {column}
      </th>
    ));

    const selectorElement = (
      <th className="listing-table-head-column">
        <input
          type="checkbox"
          id="check-all"
          checked={this.props.allChecked}
          onChange={(event) =>
            event.target.checked
              ? this.props.addAllCheckedItem()
              : this.props.removeAllCheckedItem()
          }
        />
      </th>
    );

    header.splice(0, 0, selectorElement);
    return header;
  };

  seteventPopupOpen = (flag) => {
    this.setState({ eventPopupOpen: flag });
  };
  setbillingNotesOpen = (flag) => {
    this.setState({ billingNotesOpen: flag });
  };
  setBillPopupOpen = (flag) => {
    this.setState({ BillPopupOpen: flag });
  };
  setpatinetPopupOpen = (flag) => {
    this.setState({ patinetPopupOpen: flag });
  };
  setclaimPopupOpen = (flag) => {
    this.setState({ claimPopupOpen: flag });
  };

  navigateToEditBill = (billId, eventId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_BILL,
      state: {
        billId,
        eventId,
      },
    });

  getNonInvoicedClass(item) {
    if (item.event_patient && item.event_patient.is_invoice_created === "1")
      return 'listing-table-rows';

    return 'listing-table-rows non-invoiced';

  }

  renderList = () => {
    const { listing = [] } = this.props;
    const {
      billingNotesOpen,
      eventPopupOpen,
      BillPopupOpen,
      patinetPopupOpen,
    } = this.state;
    return listing.map((listRow, index) => {
      const item = listRow.map((listItem, index) => {
        if (index === 2) {
          var eventId = listItem;             //eslint-disable-line
        }
        if (index === 0) {
          return (
            <td className="listing-table-column">
              <input
                type="checkbox"
                id="check-all"
                checked={this.props.checkedBills.indexOf(listItem) > -1}
                onChange={(event) =>
                  event.target.checked
                    ? this.props.addCheckedItem(listItem, listRow[2])
                    : this.props.removeCheckedItem(listItem, listRow[2])
                }
              />
            </td>
          );
        } else if (index === 1) {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              {listItem[0] ? listItem[0] : "No Bill"}

              <button
                className="filter-save-button btn-sm mt-2"
                // onClick={() => this.toggleBillTable(listItem.id)}
                onClick={() => {
                  this.setState({
                    bill_Id: listItem[0],
                    eventId: listItem[1],
                    BillPopupOpen: !BillPopupOpen,
                    title: "Billing Detail",
                  });
                }}
              >
                Billing Details
              </button>
            </td>
          );
        } else if (index === 2) {
          return (
            <td className="listing-table-column">
              {listItem}
              <button
                className="filter-save-button btn-sm mt-2"
                onClick={() => {
                  this.setState({
                    eventId: listItem,
                    eventPopupOpen: !eventPopupOpen,
                    title: "Event Detail",
                  });
                }}
              >
                {"Event Details"}
              </button>
            </td>
          );
        } else if (index === 6) {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              {listItem}
              <button
                className="filter-save-button btn-sm mt-2"
                // onClick={() =>
                //   this.handlePatient(listItem.id, listItem.patient.id)
                // }
                onClick={() => {
                  this.setState({
                    eventId: listRow[2],
                    patientId: listRow[4],
                    patinetPopupOpen: !patinetPopupOpen,
                    title: "Patient Detail",
                  });
                }}
              >
                {"Patient Details"}
              </button>
            </td>
          );
        } else if (index === 8) {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              ${listItem}
            </td>
          );
        } else if (index === 9) {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              <div
                style={success.div}
                dangerouslySetInnerHTML={{
                  __html: listItem,
                }}
              />
              <button
                className="filter-save-button btn-sm mt-2"
                onClick={() => {
                  this.setState({
                    eventId: listRow[2],
                    billingNotesOpen: !billingNotesOpen,
                    title: "Add Billing Notes",
                    billing_notes: listItem,
                  });
                }}
              >
                {listItem.billing_notes != null ? (
                  <p>See Notes</p>
                ) : (
                  <p>Billing Notes</p>
                )}
                {/* Add Notes */}
              </button>
            </td>
          );
        } else {
          return (
            <td
              className="listing-table-column"
              style={{
                minWidth: "135px",
                padding: "10px",
                lineHeight: "1.1",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: listItem,
                }}
              />
            </td>
          );
        }
      });

      return <tr className={this.getNonInvoicedClass(this.props.orignalObjects[index])}>{item}</tr>;
    });
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive">
            <table className="listing-table">
              <thead className="listing-table-head">
                {this.state.billingNotesOpen && (
                  <BillingNotes
                    goToPage={this.props.goToPage}
                    title={this.state.title}
                    isOpen={this.state.billingNotesOpen}
                    setModal={this.setbillingNotesOpen}
                    eventId={this.state.eventId}
                    billingNotes={this.state.billing_notes}
                  />
                )}
                {this.state.patinetPopupOpen && (
                  <PatientPopup
                    goToPage={this.props.goToPage}
                    title={this.state.title}
                    isOpen={this.state.patinetPopupOpen}
                    setModal={this.setpatinetPopupOpen}
                    eventId={this.state.eventId}
                    patientId={this.state.patientId}
                  />
                )}
                {this.state.eventPopupOpen && (
                  <EventPopup
                    goToPage={this.props.goToPage}
                    title={this.state.title}
                    isOpen={this.state.eventPopupOpen}
                    setModal={this.seteventPopupOpen}
                    eventId={this.state.eventId}
                    patientId={this.state.patientId}
                  />
                )}
                {this.state.BillPopupOpen && (
                  <BillPopup
                    goToPage={this.props.goToPage}
                    title={this.state.title}
                    isOpen={this.state.BillPopupOpen}
                    setModal={this.setBillPopupOpen}
                    eventId={this.state.eventId}
                    billId={this.state.bill_Id}
                  />
                )}
                <tr>{this.renderHeadColumn()}</tr>
              </thead>
              {this.props.isLoading && <Loader width="280%" />}
              <tbody>{!this.props.isLoading && this.renderList()}</tbody>
            </table>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}

export default withRouter(BillsListingTable);
