import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { APP_ROUTES, LOCAL_STORAGE_KEYS } from "../../constants/common";
import DatePicker from "react-datepicker";
import CustomDropDown from "./CustomDropDown";
import CustomSelect from "./CustomSelect";

class ScanFlowFilters extends React.Component {
  static propTypes = {
    exportBills: PropTypes.func.isRequired,
    exportEnabled: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    serviceDate: PropTypes.string.isRequired,
    serviceEndDate: PropTypes.string.isRequired,
    setServiceDate: PropTypes.func.isRequired,
    setServiceEndDate: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    setPatient: PropTypes.func.isRequired,
    assistant: PropTypes.object.isRequired,
    setAssistant: PropTypes.func.isRequired,
    assignSorting: PropTypes.string.isRequired,
    setAssignSorting: PropTypes.func.isRequired,
    billStatus: PropTypes.string.isRequired,
    setBillStatus: PropTypes.func.isRequired,
    facility: PropTypes.object.isRequired,
    setFacility: PropTypes.func.isRequired,
    assignReg: PropTypes.string.isRequired,
    setAssignReg: PropTypes.func.isRequired,
    practice: PropTypes.object.isRequired,
    setPractice: PropTypes.func.isRequired,
    regStatus: PropTypes.string.isRequired,
    setRegStatus: PropTypes.func.isRequired,
    assignCode: PropTypes.string.isRequired,
    setAssignCode: PropTypes.func.isRequired,
    codingStatus: PropTypes.string.isRequired,
    setCodingStatus: PropTypes.func.isRequired,
    assignCharge: PropTypes.object.isRequired,
    setAssignCharge: PropTypes.func.isRequired,
    chargeStatus: PropTypes.string.isRequired,
    setChargeStatus: PropTypes.func.isRequired,
    assignClaim: PropTypes.object.isRequired,
    setAssignClaim: PropTypes.func.isRequired,
    claimStatus: PropTypes.object.isRequired,
    setClaimStatus: PropTypes.func.isRequired,
    hospContract: PropTypes.string.isRequired,
    setHospContract: PropTypes.func.isRequired,
    createdBy: PropTypes.string.isRequired,
    insurance: PropTypes.string.isRequired,
    setCreatedBy: PropTypes.func.isRequired,
    setInsurance: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cancel = "";
  }

  resetFilter = () => {
    this.props.setServiceDate("");
    this.props.setServiceEndDate("");
    this.props.setBillStatus("");
    this.props.setRegStatus("");
    this.props.setChargeStatus("");
    this.props.setCodingStatus("");
    this.props.setClaimStatus("");
    this.props.setHospContract("");
    this.props.setCreatedBy("");
    this.props.setAssistant({
      value: "",
      label: "Select Assistant",
    });
    this.props.setFacility({
      value: "",
      label: "Select Facility",
    });
    this.props.setPatient({
      value: "",
      label: "Select Patient",
    });
    this.props.setPractice({
      value: "",
      label: "Select Practice",
    });
    this.props.setAssignCharge({
      value: "",
      label: "Select Assign Charge",
    });
    this.props.setAssignSorting({
      value: "",
      label: "Select Assign Sorting",
    });
    this.props.setAssignReg({
      value: "",
      label: "Select Assign Reg",
    });
    this.props.setAssignCode({
      value: "",
      label: "Select Assign Code",
    });
    this.props.setCreatedBy({
      value: "",
      label: "Select Created By",
    });
    this.props.setInsurance({
      value: "",
      label: "Select Insurance",
    });
    this.props.setAssignClaim(
      {
        value: "",
        label: "Select Assign Claim",
      },
      -1
    );
  };

  render() {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    return (
      <>
        <div
          style={{
            minHeight: "286px",
          }}
          className="custom-class"
        >
          <div className="scan-flow-filters-column">
            <label>Service Start Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.serviceDate}
              onChange={(date) => this.props.setServiceDate(date)}
            />
          </div>

          <div className="scan-flow-filters-column">
            <label>Service End Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.serviceEndDate}
              onChange={(edate) => this.props.setServiceEndDate(edate)}
            />
          </div>
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomSelect type='Bill Status'
                className="filter-input"
                value={this.props.billStatus}
                onChange={(event) =>
                  this.props.setBillStatus(event.target.value)
                }>
              </CustomSelect>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Registration Status</label>
              <select
                className="filter-input"
                value={this.props.regStatus}
                onChange={(event) =>
                  this.props.setRegStatus(event.target.value)
                }
              >
                <option value="">Registration Status</option>
                <option value="not started">NOT STARTED</option>
                <option value="completed">COMPLETED</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Charge Status</label>
              <select
                value={this.props.chargeStatus}
                className="filter-input"
                onChange={(event) =>
                  this.props.setChargeStatus(event.target.value)
                }
              >
                <option value="">Charge Status</option>
                <option value="not started">NOT STARTED</option>
                <option value="ready to submit">READY TO SUBMIT</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomSelect type='Coding Status'
                className="filter-input"
                value={this.props.codingStatus}
                onChange={(event) =>
                  this.props.setCodingStatus(event.target.value)
                }
              >
              </CustomSelect>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomSelect type='Claim Status'
                value={this.props.claimStatus}
                className="filter-input"
                onChange={(event) =>
                  this.props.setClaimStatus(event.target.value)
                }
              >
              </CustomSelect>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                type={'patients'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.patient}
                onChange={(value) => this.props.setPatient(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                type={'assistants'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.assistant}
                onChange={(value) => this.props.setAssistant(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                type={'facilities'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.facility}
                onChange={(value) => this.props.setFacility(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                type={'practices'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.practice}
                onChange={(value) => this.props.setPractice(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                label='Assign Charge'
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.assignCharge}
                onChange={(value) => this.props.setAssignCharge(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                label='Assign Claim'
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.assignClaim}
                onChange={(value) => this.props.setAssignClaim(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                label='Assign Sorting'
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.assignSorting}
                onChange={(value) => this.props.setAssignSorting(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                label='Assign Registration'
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.assignReg}
                onChange={(value) => this.props.setAssignReg(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                label='Assign Coder'
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.assignCode}
                onChange={(value) => this.props.setAssignCode(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomSelect type='Hospital Contract'
                value={this.props.hospContract}
                className="filter-input"
                onChange={(event) =>
                  this.props.setHospContract(event.target.value)
                }
              >
              </CustomSelect>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                label='Created By'
                type={'users'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.createdBy}
                onChange={(value) => this.props.setCreatedBy(value)}>
              </CustomDropDown>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <CustomDropDown
                label='Insruance Company'
                type={'insurances'}
                isRequired={false}
                isMulti={false}
                selectedValue={this.state?.insurance}
                onChange={(value) => this.props.setInsurance(value)}>
              </CustomDropDown>
            </div>
          )}
        </div >
        <div className="d-flex" style={{ 'width': '100%' }}>
          <div className="col-md-6 p-0">
            <Link
              className={'btn filter-footer-button'}
              to={{
                pathname: APP_ROUTES.INVOICING_DASHBOARD,
              }}>
              Invoicing
            </Link>
          </div>
          <div className="col-md-6 p-0">
            <div className="filter-footer-claim">
              <button
                style={{
                  width: "72px",
                }}
                type="submit"
                onClick={this.props.exportBills}
                disabled={!this.props.exportEnabled()}
                className={
                  this.props.exportEnabled()
                    ? "filter-footer-button"
                    : "filter-footer-disabled-button"
                }
              >
                Export
              </button>
              <button
                style={{
                  width: "72px",
                }}
                type="submit"
                onClick={this.resetFilter}
                className="filter-footer-button"
              >
                Refresh
              </button>
              <button
                style={{
                  width: "72px",
                }}
                type="submit"
                onClick={this.props.search}
                className="filter-footer-button"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ScanFlowFilters);
