import { authorizationURLService } from './authorizationurl';

function isAddAccess() {
    let menuItems = authorizationURLService.getURLSlug();
    //console.log("Add ******* ", menuItems);
    let tPermission = false;
    menuItems.forEach(tItem => {
        if (tItem.pivot && tItem.pivot.isAddAccess)
            tPermission = tItem.pivot.isAddAccess;
    })
    return tPermission;
}

function isUpdateAccess() {
    let menuItems = authorizationURLService.getURLSlug();
    //console.log("Update ******* ", menuItems);
    let tPermission = false;
    menuItems.forEach(tItem => {
        if (tItem.pivot && tItem.pivot.isUpdateAccess)
            tPermission = tItem.pivot.isUpdateAccess;
    })
    return tPermission;

}

function isDeleteAccess() {
    let menuItems = authorizationURLService.getURLSlug();
    //console.log("Delete ******* ", menuItems);
    let tPermission = false;
    menuItems.forEach(tItem => {
        if (tItem.pivot && tItem.pivot.isDeleteAccess)
            tPermission = tItem.pivot.isDeleteAccess;
    })
    return tPermission;

}


export const authorizationService = {
    isAddAccess,
    isUpdateAccess,
    isDeleteAccess
};
