import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { assignSurgeonAssocation } from "../../actions/surgeonAssociation";
import { FormValidatorService } from "../../services/formvalidator";
import { utilityService } from "../../services/utility";
import CustomDropDown from "../organisms/CustomDropDown";

const validationGroup = {
  message: "There are some validation errors:",
  fields: {
    'surgeon': { filedName: 'Surgeon', required: true },
    'assistant': { filedName: 'Surgical Assistant', required: true }
  }
}


class ManageSurgeonAssociation extends React.Component {
  state = {
    errors: [],
    surgeon: {},
  };


  componentDidMount() {
  }

  validateAssociation = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  onChangeSurgeon = (value) => {
    this.setState({ surgeon: value });
  };

  onChangeAssistant = (value) => {
    this.setState({ assistant: value });
  };

  submitAssociation = () => {
    if (!this.validateAssociation()) {
      return;
    }

    const { assignSurgeonAssocation } = this.props;
    const {
      surgeon,
      assistant
    } = this.state;

    let payLoad = {
      surgeon_id: surgeon.value,
      assistant_id: assistant.value
    };

    assignSurgeonAssocation(payLoad)
      .then((res) => {
        if (res.response.success) {
          this.props.history.push(APP_ROUTES.SURGEON_ASSOCIATION);
        } else {
          createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
        }
      })
  };

  render() {
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              <h2 className="heading-custom">Add Surgeon Assocation</h2>
            </div>
            <div className="col-md-6" style={{ color: "black", float: "right" }}>
              <CustomDropDown
                type={'assistants'}
                isRequired={FormValidatorService.isRequired(validationGroup, this.state, 'assistant')}
                isMulti={false}
                all={true}
                className={FormValidatorService.getClass(validationGroup, this.state, 'assistant', '')}
                selectedValue={this.state.assistant}
                onChange={this.onChangeAssistant}>
              </CustomDropDown>
            </div>
            <div className="col-md-6" style={{ color: "black", float: "right" }}>
              <CustomDropDown
                type={'surgeons'}
                isRequired={FormValidatorService.isRequired(validationGroup, this.state, 'surgeon')}
                isMulti={false}
                all={true}
                className={FormValidatorService.getClass(validationGroup, this.state, 'surgeon', '')}
                selectedValue={this.state.surgeon}
                onChange={this.onChangeSurgeon}>
              </CustomDropDown>
            </div>
          </div>
          <div className="filter-footer">
            <button onClick={this.submitAssociation} className="filter-save-button">
              Submit
            </button>
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.SURGEON_ASSOCIATION);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignSurgeonAssocation,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageSurgeonAssociation));
