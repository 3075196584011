import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getAccounts(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_ACCOUNT, options)
        .then(handleResponse)
        .catch(handleError);
}

function getInvoiceDetail(invoice) {
    return apiClient()
        .get(API_CONSTANTS.INVOICE_DETAIL + invoice)
        .then(handleResponse)
        .catch(handleError);
}

function updateInvoice(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_INVOICE, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const accountService = {
    getAccounts,
    getInvoiceDetail,
    updateInvoice,
}