import { KNOWLEDGE_BASE_CATEGORY } from '../constants/actions';

const initialState = {
    categories: [],
    total: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case KNOWLEDGE_BASE_CATEGORY.FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.data.data,
                total: action.data.records,
            };
        case KNOWLEDGE_BASE_CATEGORY.CREATE_CATEGORY_SUCCESS:
            const createUser = [ ...state.categories, action.data.data ];
            return {
                ...state,
                categories: createUser,
            };
        case KNOWLEDGE_BASE_CATEGORY.UPDATE_CATEGORY_SUCCESS:
            const updateUser = [ ...state.categories, action.data.data ];
            return {
                ...state,
                categories: updateUser,
            }
        default:
            return state;
    }
}