import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createArticle, updateArticle } from "../../actions/knowledgeBaseArticles";
import { knowledgeBaseArticleService } from "../../services/knowledgeBaseArticles";
import uuid from 'react-uuid';
import CustomDropDown from "../organisms/CustomDropDown";

import { FormValidatorService } from "../../services/formvalidator";
import { utilityService } from "../../services/utility";

let validationGroup = {
  message: "There are some validation errors in Basic Information Tab:",
  fields: {
    'name': { filedName: 'Article Name', required: true, minLength: 3, maxLength: 200 },
    'description': { filedName: 'Article Description', required: true, minLength: 3, maxLength: 500 },
    'selectedCategory': { filedName: 'Category', required: true },
    'selectedSubCategory': { filedName: 'Sub Category', required: true },
  }
}

class ManageKnowledgeBaseArticle extends React.Component {
  state = {
    errors: [],
    name: "",
    description: "",
    file: null,
    selectedCategory: {},
    selectedSubCategory: {},
    categorySelectionChanged: false,
    subCategoryKey: uuid()
  };


  componentDidMount() {
    const articleId = this.props.location.state
      ? this.props.location.state.articleId
      : "";
    if (articleId) {
      knowledgeBaseArticleService.getArticleDetail(articleId).then((response) => {
        this.setState({
          name: response.data.name,
          description: response.data.description,
          selectedCategory: { id: response.data.category.id, label: response.data.category.category },
          selectedSubCategory: { id: response.data.sub_category.id, label: response.data.sub_category.sub_category }
        });
      });
    } else {
      validationGroup.fields['file'] = { filedName: 'File', required: true }
    }
  }

  validateKnowledgeBaseCategory = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  onChangeSubCategory = (item, value) => {
    this.setState({ selectedSubCategory: { id: item.value, label: item.label } });
  }

  onChangeCategory = (item, value) => {
    this.setState({
      selectedCategory: { id: item.value, label: item.label },
      subCategoryKey: uuid(),
      selectedSubCategory: {}
    });
  }

  submitArticle = () => {
    if (!this.validateKnowledgeBaseCategory()) {
      return;
    }

    const { createArticle, updateArticle } = this.props;
    const {
      selectedCategory,
      selectedSubCategory,
      name,
      description,
      file
    } = this.state;

    const formData = new FormData();
    formData.append('category_id', selectedCategory.id);
    formData.append('sub_category_id', selectedSubCategory.id);
    formData.append('file', file);
    formData.append('name', name);
    formData.append('description', description);

    const articleId = this.props.location.state
      ? this.props.location.state.articleId
      : "";

    if (articleId) {
      formData.append('id', articleId);
      updateArticle(formData)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_ARTICLES);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
    } else {
      createArticle(formData)
        .then((res) => {
          if (res.response.success) {
            this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_ARTICLES);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
    }
  };

  render() {
    const articleId = this.props.location.state
      ? this.props.location.state.articleId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {articleId && <h2 className="heading-custom">Edit Knowledge Base Sub Category</h2>}
              {!articleId && <h2 className="heading-custom">Add Knowledge Base Sub Category</h2>}
            </div>
            <div className="col-md-6" style={{ color: "black", float: "right" }}>
              <CustomDropDown
                type={'knowledgebase_categories'}
                className={FormValidatorService.getClass(validationGroup, this.state, "selectedCategory", '')}
                isRequired={FormValidatorService.isRequired(validationGroup, "selectedCategory")}
                isMulti={false}
                selectedValue={this.state?.selectedCategory}
                onChange={this.onChangeCategory}>
              </CustomDropDown>
            </div>
            <div className="col-md-6" style={{ color: "black", float: "right" }}>
              <CustomDropDown
                key={this.state.subCategoryKey}
                type={'knowledgebase_sub_categories'}
                selectedCategory={this.state?.selectedSubCategory}
                className={FormValidatorService.getClass(validationGroup, this.state, "selectedCategory", '')}
                isRequired={FormValidatorService.isRequired(validationGroup, "selectedSubCategory")}
                isMulti={false}
                selectedValue={this.state?.selectedSubCategory}
                onChange={this.onChangeSubCategory}>
              </CustomDropDown>
            </div>
            <div className="col-md-6 mrgn-btm10">
              <label>Name {FormValidatorService.getRequiredHTML(validationGroup, "name")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "name")}
                value={this.state.name}
                onChange={(event) => {
                  this.setState({ name: event.target.value });
                }}
              />
            </div>

            <div className="col-md-6 mrgn-btm10">
              <label>File {FormValidatorService.getRequiredHTML(validationGroup, "file")}</label>
              <input
                type="file"
                style={{
                  "padding-left": "5px",
                  "padding-top": "3px"
                }}
                className={FormValidatorService.getClass(validationGroup, this.state, "file")}
                onChange={(event) => {
                  this.setState({ file: event.target.files[0] });
                }}
              />
            </div>
            <div className="col-md-12 mrgn-btm10">
              <label>Description {FormValidatorService.getRequiredHTML(validationGroup, "description")}</label>
              <textarea
                className={FormValidatorService.getClass(validationGroup, this.state, "description")}
                onChange={(event) => {
                  this.setState({ description: event.target.value });
                }}
                value={this.state.description}
              >
              </textarea>
            </div>
          </div>
          <div className="filter-footer">
            {articleId && (
              <button onClick={this.submitArticle} className="filter-save-button">
                Update
              </button>
            )}
            {!articleId && (
              <button onClick={this.submitArticle} className="filter-save-button">
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_ARTICLES);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createArticle,
      updateArticle,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageKnowledgeBaseArticle));
