import React from 'react';
import {
    APP_ROUTES,
    ASSISTANTS,
    LOCAL_STORAGE_KEYS,
    RECORDS_PER_PAGE,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import AssistantListingTable from "../atom/AssistantListingTable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deleteAssistant, getRegionAssistants } from "../../actions/assistant";
import { assistantService } from "../../services/assistants";
import { authorizationService } from "../../services/authorization";
import { utilityService } from '../../services/utility';
import CustomSelect from '../organisms/CustomSelect';

class AssistantDashboard extends React.Component {

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searched: false,
        searchedAssistants: [],
        pageSize: RECORDS_PER_PAGE,
        searchedTotalRecords: 0,
        checked: [],
    };

    componentDidMount() {
        this.setState({ loading: true });
        let tStorageItem = localStorage.getItem(LOCAL_STORAGE_KEYS.SURGICAL_ASSISTANT_FILTERS);
        tStorageItem = tStorageItem ? JSON.parse(tStorageItem) : null;

        if (tStorageItem) {
            // eslint-disable-next-line
            this.state.currentPage = tStorageItem.page;
            // eslint-disable-next-line
            this.state.pageSize = tStorageItem.page_size;
        }

        let regionId = this.props.regionId || this.props.location.state.regionId;
        // if(regionId != "undefined"){
        //     regionId = 0;
        //     // alert(regionId);
        // }else{
        //     // alert('1 Undefined');
        // }
        const options = {
            page: this.state.currentPage,
            status: this.state.searchByStatus,
            page_size: this.state.pageSize,
        };
        this.props.getRegionAssistants(regionId, { params: options })
            .then(res => {
            }).finally(() => {
                this.setState({ loading: false });
            })
    }

    goToPage = (page) => {
        const regionId = this.props.regionId || this.props.location.state.regionId;
        this.setState({ loading: true });
        const options = {
            page: page < 0 ? 1 : page,
            status: this.state.searchByStatus,
            page_size: this.state.pageSize,
        };
        localStorage.setItem(LOCAL_STORAGE_KEYS.SURGICAL_ASSISTANT_FILTERS, JSON.stringify(options));
        this.setCurrentPage(options.page);
        this.props.getRegionAssistants(regionId, { params: options })
            .then(res => {
            }).finally(() => {
                this.setState({ loading: false });
            })

    };

    addCheckedItem = (surgeon) => {
        this.setState({},)
        this.setState((prevState) => ({
            checked: [...prevState.checked, surgeon],
        }));
    };

    removeCheckedItem = (entity) => {
        this.setState((prevState) => ({
            checked: prevState.checked.filter(
                (ientity) => ientity !== entity
            ),
        }));
    };

    addAllCheckedItem = () => {
        let tEntities = this.state.searched && this.state.searchedAssistants.length > 0 ? this.state.searchedAssistants
            : this.list()
        let tAllEntities = [];
        tEntities.forEach(temp => {
            tAllEntities.push(temp[0]);
        })

        this.setState((prevState) => ({
            checked: tAllEntities
        }));
    }

    removeAllCheckedItem = () => {
        this.setState((prevState) => ({
            checked: []
        }));
    }

    setCurrentPage = (num) => {
        this.setState({ currentPage: num });
    };

    activate = () => {
        let ids = { 'ids': this.state.checked };
        assistantService.activateAssistants(ids).then(temp => {
            utilityService.showHTMLMessage('Activate Surgical Assistant', 'Activated all of the selected surgical assistant\'s successfully');
            this.goToPage(this.state.currentPage);
        })
    }

    moveToPhysicianAssistant() {
        let ids = { 'ids': this.state.checked };
        assistantService.moveToPhysicianAssistant(ids).then(temp => {
            utilityService.showHTMLMessage('Successfully moved to physician assistant');
            this.goToPage(this.state.currentPage);
        })
    }

    deActivate = () => {
        let ids = { 'ids': this.state.checked };
        assistantService.inActivateAssistants(ids).then(temp => {
            utilityService.showHTMLMessage('De-Activate Surgical Assistant', 'De-Activated all of the selected surgical assistant\'s successfully');
            this.goToPage(this.state.currentPage);
        })
    }

    showAdd = () => this.props.history.push({
        pathname: APP_ROUTES.ADD_ASSISTANT,
        state: {
            regionId: this.props.regionId || this.props.location.state.regionId,
        }
    });

    resetAssistantSearch = () => {
        this.setState({
            searchByName: '',
            searchedAssistants: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    edit = assistantId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_ASSISTANT,
        state: {
            assistantId,
            regionId: this.props.regionId || this.props.location.state.regionId,
        }
    });

    view = assistantId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_ASSISTANT,
        state: {
            onlyForView: true,
            assistantId,
            regionId: this.props.regionId || this.props.location.state.regionId,
        }
    });

    setPageSize = (value) => {
        this.setState({ pageSize: value }, () => {
            this.goToPage(1);
        });
    };

    searchAssistants = () => {
        const { searchByName } = this.state;
        let options = {};
        const regionId = this.props.regionId || this.props.location.state.regionId;

        if (searchByName) {
            options = { ...options, search: searchByName };
        }

        options = { ...options, page_size: this.state.pageSize };
        options = { ...options, status: this.state.searchByStatus };

        this.setState({
            searched: true,
            loading: true
        });
        assistantService
            .getRegionAssistants(regionId, { params: options })
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedAssistants: response.data.map(assistant => {
                        return [
                            assistant.id,
                            assistant.id,
                            `${assistant.name} ${assistant.last_name}`,
                            assistant.assistant?.certificate_number,
                            assistant.assistant?.npi,
                            assistant.assistant?.primary_taxonomy,
                            "".concat(assistant.assistant?.practice1_address1 ? assistant.assistant?.practice1_address1 + ", " : '', assistant.assistant?.practice1_city ?? '', ' ', assistant.assistant?.practice1_state ?? '', ' ', assistant.assistant?.practice1_zip ?? ''),
                            assistant.assistant?.personal_cell_phone,
                            assistant.assistant?.division?.name,
                            assistant.assistant?.status,
                            assistant.id,
                        ];
                    })
                });
            }).finally(() => {
                this.setState({ loading: false });
            })
    }

    list = () => {
        const { assistants = [] } = this.props;
        return assistants.map(assistant => {
            return [
                assistant.id,
                assistant.id,
                `${assistant.name} ${assistant.last_name}`,
                assistant.assistant?.certificate_number,
                assistant.assistant?.npi,
                assistant.assistant?.primary_taxonomy,
                "".concat(assistant.assistant?.practice1_address1 ? assistant.assistant?.practice1_address1 + ", " : '', assistant.assistant?.practice1_city ?? '', ' ', assistant.assistant?.practice1_state ?? '', ' ', assistant.assistant?.practice1_zip ?? ''),
                assistant.assistant?.personal_cell_phone,
                assistant.assistant?.division?.name,
                assistant.assistant?.status,
                assistant.id,
            ];
        });
    };

    delete = (assistant) => {
        this.props
            .deleteAssistant(assistant)
            .then(response => {
                this.goToPage(1);
            });
    };

    render() {
        const {
            searched,
            searchedAssistants,
            searchedTotalRecords
        } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '755px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Manage Surgical Assistants</h3>
                        </div>
                    </div>
                    <div className="filter-footer-header">

                        {authorizationService.isAddAccess() && (
                            <button
                                onClick={() => { this.showAdd(true) }}
                                className="filter-button">
                                Add New Surgical Assistant
                            </button>
                        )}

                        {authorizationService.isUpdateAccess() && (
                            <>
                                <button
                                    onClick={() => { this.moveToPhysicianAssistant() }}
                                    disabled={this.state.checked.length <= 0}
                                    className={this.state.checked.length <= 0 ? "filter-footer-disabled-button" : "filter-button"}>
                                    Move To Physician Assistant
                                </button>
                                <button
                                    onClick={() => { this.activate() }}
                                    disabled={this.state.checked.length <= 0}
                                    className={this.state.checked.length <= 0 ? "filter-footer-disabled-button" : "filter-button"}>
                                    Active
                                </button>
                                <button
                                    onClick={() => { this.deActivate() }}
                                    disabled={this.state.checked.length <= 0}
                                    className={this.state.checked.length <= 0 ? "filter-footer-disabled-button" : "filter-button"}>
                                    In-Active
                                </button>
                            </>
                        )}
                    </div>

                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        <div style={{
                            paddingLeft: '0',
                        }} className="col-md-3 mrgn-btm10">
                            <label>User Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => {
                                    this.setState({ searchByName: event.target.value },
                                        () => {
                                            if (this.state.searchByName.length >= 3) {
                                                this.searchAssistants();
                                            } else if (this.state.searchByName.length === 0) {
                                                this.resetAssistantSearch();
                                            }
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div classname="col-md-3 mrgn-btm10">
                            <CustomSelect type='Active InActive Text'
                                label="Surgical Assistant Status"
                                className="code-input"
                                value={this.state.searchByStatus}
                                onChange={(event) =>
                                    this.setState({ searchByStatus: event.target.value }, () => {
                                        this.goToPage(this.state.currentPage);
                                    })
                                }>
                            </CustomSelect>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetAssistantSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <AssistantListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                delete={this.delete}
                                edit={this.edit}
                                view={this.view}
                                setPageSize={this.setPageSize}
                                pageSize={this.state.pageSize}
                                addCheckedItem={this.addCheckedItem}
                                removeCheckedItem={this.removeCheckedItem}
                                addAllCheckedItem={this.addAllCheckedItem}
                                removeAllCheckedItem={this.removeAllCheckedItem}
                                checked={this.state.checked}
                                headColumn={ASSISTANTS}
                                listing={searched ? searchedAssistants : this.list()}
                                totalRecords={searched ? searchedTotalRecords : this.props.totalRecords}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        assistants: state.assistantReducer.assistants,
        totalRecords: state.assistantReducer.totalAssistants,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRegionAssistants,
            deleteAssistant,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AssistantDashboard));
