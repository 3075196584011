import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { submitActivity, updateActivity } from "../../actions/activity";

import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";

import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";

import { activityService } from "../../services/activities";
import { FileStorageService } from "../../services/fileStorage";

import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import AddPatient from "../atom/AddPatient";
import AddProcedure from "../atom/AddProcedure";
//import { formatDate, formatAMPM, format24Hours } from "../../utils/date";
import { formatDate, formatAMPM } from "../../utils/date";

import "react-datepicker/dist/react-datepicker.css";
import "../../../sass/manageactivities.scss";
import { createNotification } from "../../utils/notificationManager";
import deleteIcon from "../../../assets/images/delete.png";
import viewIcon from "../../../assets/images/view.png";
import loaderImage from "../../../assets/images/CoureMax.gif";
import { userService } from "../../services/users";
import { utilityService } from "../../services/utility";
import CustomDropDown from "../organisms/CustomDropDown";
import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";

const validationGroup = {
  message: "There are some validation errors:",
  fields: {
    //'patient': { filedName: 'Patient', required: true },
    'eventType': { filedName: 'Event Type', required: true },
    //'selectedProcedures': { filedName: 'Procedure' },
    'surgeon': { filedName: 'Surgeon', required: true },
    'facility': { filedName: 'Facility', required: true },
    'assistant': { filedName: 'Surgical Assitant', required: true },
    //'admissionDate': { filedName: 'Admission Date', required: true },
    'startDate': { filedName: 'Start Date', required: true },
    'startTime': { filedName: 'Start Time', required: true },
    //'accountNumber': { filedName: 'Account Number', required: true },
    //'placeOfService': { filedName: 'Place Of Service', required: true },
    //'billPayTo': { filedName: 'Pay To', required: true },
  }
}
const format = "H:mm";

const now = moment().hour(0).minute(0);

class ManageActivity extends React.Component {
  static propTypes = {
    submitActivity: PropTypes.func.isRequired,
    updateActivity: PropTypes.func.isRequired,
    createProcedure: PropTypes.func.isRequired,
    screen: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      placeOfService: "",
      showAddPatient: false,
      showAddProcedure: false,
      patient: {},
      file: null,
      fileType: "",
      allSheets: [],
      selectedProcedures: [],
      surgeon: {},
      facility: {},
      assistant: {},
      startDate: Date.now(),
      admissionDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      eventType: {},
      eventFlags: [],
      billPayTo: {},
      diagnosisCodes: "",
      cptCodes: "",
      status: {},
      notes: "",
      modifiedBy: "",
      createdBy: "",
      createdUser: "",
      fields: {},
      loading: false,
      errors: [],
      accountNumber: "",
    };
    this.cancel = "";
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  getStatusName = (id) => {
    if (id === "new") {
      return "New Schedule";
    } else if (id === "started") {
      return "Surgery In Progress";
    } else if (id === "completed") {
      return "Surgery Completed";
    } else if (id === "cancelled") {
      return "Cancelled Surgery";
    }
  };

  componentDidMount() {
    let activityId;
    if (this.props.location.state) {
      activityId = this.props.location.state.activityId;
    } else {
      activityId = "";
    }
    if (this.props.activityId) {
      activityId = this.props.activityId;
    }
    if (activityId) {
      this.setLoading(true);
      activityService.getActivityDetail(activityId).then((response) => {
        this.setState(
          {
            eventType: {
              label: response.data.event_type
                ? response.data.event_type.event_type_name
                : "",
              value: response.data.event_type
                ? parseInt(response.data.event_type.id)
                : "",
            },
            selectedProcedures: response.data.procedures.map((procedure) => {
              return {
                label: procedure.procedure_name,
                value: procedure.id,
              };
            }),
            patient: {
              label: response.data.patient
                ? `${response.data.patient.first_name} ${response.data.patient.last_name}`
                : "",
              value: response.data.patient
                ? parseInt(response.data.patient.id)
                : "",
            },
            surgeon: {
              label: response.data.surgeon
                ? `${response.data.surgeon.first_name} ${response.data.surgeon.last_name}`
                : "",
              value: response.data.surgeon
                ? parseInt(response.data.surgeon.id)
                : "",
            },
            facility: {
              label: response.data.facility
                ? response.data.facility.facility_name
                : "",
              value: response.data.facility
                ? parseInt(response.data.facility.id)
                : "",
            },
            assistant: {
              label: response.data.assistant
                ? `${response.data.assistant.name} ${response.data.assistant.last_name}`
                : "",
              value: response.data.assistant
                ? parseInt(response.data.assistant.id)
                : "",
            },
            billPayTo: {
              label: response.data.billpayto
                ? `${response.data.billpayto.paytoname}`
                : "",
              value: response.data.billpayto
                ? parseInt(response.data.billpayto.id)
                : "",
            },
            startDate: Date.parse(formatDate(response.data.event_start_date)),
            admissionDate: Date.parse(formatDate(response.data.admission_date)),
            startTime: moment(response.data.event_start_time, "hh:mm A"),
            endDate: response.data.event_end_date
              ? Date.parse(formatDate(response.data.event_end_date))
              : "",
            endTime:
              response.data.event_end_time !== "" &&
                response.data.event_end_time !== "null" &&
                response.data.event_end_time !== null
                ? moment(response.data.event_end_time, "hh:mm A")
                : "",
            diagnosisCodes: response.data.diagnosis_codes || "",
            cptCodes: response.data.cpt_codes || "",
            status: {
              label: this.getStatusName(response.data.status),
              value: response.data.status,
            },
            notes: response.data.event_notes || "",
            eventFlags:
              response.data.event_flag &&
              response.data.event_flag.map((event_flag) => {
                return {
                  label: event_flag.schedule_flag,
                  value: event_flag.id,
                };
              }),
            allSheets:
              response.data.all_sheets.length > 0
                ? response.data.all_sheets
                : [],
            modifiedBy: response.data.modified_by
              ? `${response.data.modified_by.name} ${response.data.modified_by.last_name}`
              : "",
            createdBy: response.data.created_by
              ? `${response.data.created_by.name} ${response.data.created_by.last_name}`
              : "",
            accountNumber: response.data.account_number,
            createdUser: response.data.created_by
              ? response.data.created_by.id
              : "",
            placeOfService: response.data.pos_id,
          },
          () => {
            this.setLoading(false);
          }
        );
      });
    }
  }

  onChangeProcedure = (value) => {
    this.setState({ selectedProcedures: value });
  };

  onChangeSurgeon = (value) => {
    this.setState({ surgeon: value });
  };

  onChangeFacility = (value) => {
    this.setState({ facility: value });
  };

  onChangeAssistant = (value) => {
    this.setState({ assistant: value });
  };

  setStartDate = (value) => {
    this.setState({ startDate: value });
  };

  setAdmissionDate = (value) => {
    this.setState({ admissionDate: value });
  };

  setEndDate = (value) => {
    this.setState({ endDate: value });
  };

  setStartTime = (value) => {
    this.setState({ startTime: value });
  };

  onChangeEventType = (value) => {
    this.setState({ eventType: value });
  };

  onChangePatient = (value) => {
    this.setState({ patient: value });
    this.setState({ accountNumber: value.account_number });
  };

  onChangeEventFlags = (value) => {
    this.setState({ eventFlags: value });
  };

  onChangeStatus = (value) => {
    this.setState({ status: value });
  };

  validateActivity = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  submitActivity = () => {
    if (!this.validateActivity()) {
      return;
    }

    const { submitActivity, updateActivity } = this.props;
    const {
      patient,
      file,
      fileType,
      selectedProcedures,
      surgeon,
      facility,
      assistant,
      startDate,
      endDate,
      startTime,
      endTime,
      eventType,
      eventFlags,
      diagnosisCodes,
      cptCodes,
      accountNumber,
      placeOfService,
      notes,
      admissionDate,
      status,
      createdUser,
      billPayTo
    } = this.state;

    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );

    const formData = new FormData();
    formData.append("user_id", createdUser || loggedInUser.id);
    formData.append("event_surgeon", surgeon.value);
    formData.append("file", file);
    formData.append("file_type", fileType);
    formData.append("patient_id", patient.value);
    formData.append("paytoadmin_id", billPayTo.value);
    formData.append("modified_by", loggedInUser.id);
    formData.append("patient_name", patient.label);
    formData.append(
      "procedure_id",
      selectedProcedures.map((procedure) => procedure.value).join()
    );
    formData.append("event_facility", facility.value);
    formData.append("event_surgical_assitant", assistant.value);
    formData.append("cpt_codes", cptCodes);
    formData.append("account_number", accountNumber);
    formData.append("placeOfService", placeOfService);
    formData.append("cpt_codes_notes", "");
    formData.append("diagnosis_codes", diagnosisCodes);
    formData.append("event_start_date", formatDate(startDate));
    formData.append("event_end_date", endDate !== "" ? formatDate(endDate) : "");
    if (startTime)
      formData.append(
        "event_start_time",
        formatAMPM(`${startTime.hours()}:${startTime.minutes()}`)
      );

    if (endTime)
      formData.append(
        "event_end_time",
        endTime !== ""
          ? formatAMPM(`${endTime.hours()}:${endTime.minutes()}`)
          : ""
      );
      
    formData.append("event_type", eventType.value);
    formData.append(
      "event_flags",
      eventFlags.map((eventFlag) => eventFlag.value).join()
    );
    formData.append("event_notes", notes);
    formData.append("admission_date", formatDate(admissionDate));

    // const activityId = this.props.location.state
    //   ? this.props.location.state.activityId
    //   : "";
    let activityId;
    if (this.props.location.state) {
      activityId = this.props.location.state.activityId;
    } else {
      activityId = "";
    }
    if (this.props.activityId) {
      activityId = this.props.activityId;
    }
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    this.setLoading(true);
    if (activityId) {
      formData.append("id", activityId);
      formData.append("status", status.value);
      updateActivity(formData)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            if (screen) {
              this.props.history.push(screen);
            } else {
              if (this.props.PopupRequest === undefined) {
                this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
              }
            }
          }
          this.setLoading(false);
        })
        .finally(() => {
          this.setLoading(false);
        });
    } else {
      submitActivity(formData)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
          }
        }).finally(() => {
          this.setLoading(false);
        })
    }
  };

  toggleAddPatient = (flag) => {
    this.setState({ showAddPatient: flag });
  };

  toggleAddProcedure = (flag) => {
    this.setState({ showAddProcedure: flag });
  };

  setPatient = (value) => {
    this.setState({ patient: value });
  };

  setProcedure = (value) => {
    this.setState((prevstate) => ({
      selectedProcedures: [...prevstate.selectedProcedures, value],
    }));
  };

  onFileChangeHandler = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  cancelNav = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
    }
  };

  renderHeadColumn = () => {
    const headColumn = ["File Name", "Type", "Action"];

    return headColumn.map((column) => (
      <th className="listing-table-head-column">{column}</th>
    ));
  };

  downloadFaceSheet(facesheet) {
    FileStorageService.downloadFile(facesheet).then(data => {
      console.log(data);
    })
  }

  deleteFile = (faceSheetId) => {
    utilityService.showConfirmDialog().then(data => {
      activityService
        .deleteActivityFaceSheet(faceSheetId)
        .then((response) => {
          this.resetErrors();
        });
    })
  };

  renderSheetList = () => {
    const { allSheets } = this.state;
    return allSheets.map((sheet) => {
      const facesheetFile = sheet.facesheet_file.split("/");
      const fileName =
        facesheetFile.length > 0 ? facesheetFile[facesheetFile.length - 1] : "";
      const sheetDetails = [fileName, sheet.type, sheet.id];
      const item = sheetDetails.map((item, index) => {
        if (index === 2) {
          return (
            <td className="listing-table-column">
              <button class="link" onClick={() => {
                this.downloadFaceSheet(item);
              }} href="#" rel="noreferrer">
                <img src={viewIcon} alt="view" />
              </button>
              <img
                src={deleteIcon}
                onClick={() => {
                  this.deleteFile(item);
                }}
                alt="view"
              />
            </td>
          );
        } else {
          return <td className="listing-table-column">{item}</td>;
        }
      });
      return <tr className="listing-table-rows">{item}</tr>;
    });
  };

  loadUsers = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return userService
      .getUsers({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((user) => ({
          value: user.id,
          label: `${user.name} ${user.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  render() {
    let activityId;
    if (this.props.location.state) {
      activityId = this.props.location.state.activityId;
    } else {
      activityId = "";
    }
    if (this.props.activityId) {
      activityId = this.props.activityId;
    }
    return (
      <div className="main_container">
        {this.props.PopupRequest === undefined && (
          <>
            <div
              className="col-md-3 custom-sidebar-menu left_col"
              style={{
                minHeight: "145vh",
              }}
            >
              <Sidebar activePage={this.props.activePage} />
            </div>
            <TopNavbar />
          </>
        )}
        <LoadingOverlay
          active={this.state.loading}
          spinner={
            <img
              style={{
                width: "200px",
              }}
              src={loaderImage}
              alt="loading..."
            />
          }
        >
          <div
            className={
              this.props.PopupRequest === 1 ? "" : "manage-activity-right-col"
            }
          >
            <div className="activity-row">
              <div className="page-title">
                {activityId && (
                  <h2 className="heading-custom">Edit Activity</h2>
                )}
                {!activityId && (
                  <h2 className="heading-custom">Add Activity</h2>
                )}
              </div>
              <div className="col-md-3 mrgn-btm10">

                {!this.state.showAddPatient && (
                  <CustomDropDown
                    type={'patients'}
                    isRequired={FormValidatorService.isRequired(validationGroup, 'patient')}
                    isMulti={false}
                    className={FormValidatorService.getClass(validationGroup, this.state, 'patient', '')}
                    selectedValue={this.state.patient}
                    onChange={this.onChangePatient}>
                  </CustomDropDown>
                )}
                {!this.state.showAddPatient && (
                  <div style={{ paddingTop: "10px" }}>
                    <button
                      onClick={() => this.toggleAddPatient(true)}
                      className="filter-save-button add-new-button"
                    >
                      Add New Patient
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CustomDropDown
                  type={'event_types'}
                  isRequired={FormValidatorService.isRequired(validationGroup, "eventType")}
                  isMulti={false}
                  className={FormValidatorService.getClass(validationGroup, this.state, "eventType", '')}
                  selectedValue={this.state.eventType}
                  onChange={this.onChangeEventType}>
                </CustomDropDown>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Upload File</label>
                <br />
                <input
                  type="file"
                  name="file"
                  className="file-upload"
                  onChange={this.onFileChangeHandler}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CustomSelect type='File Type'
                  className="code-input"
                  onChange={(event) =>
                    this.setState({ fileType: event.target.value })
                  }
                  value={this.state.fileType}
                >
                </CustomSelect>
              </div>

              <div class="col-md-6"></div>
              <div class="col-md-6">
                <div className="top-padding">
                  <div className="table-responsive">
                    <table className="listing-table">
                      <thead className="listing-table-head">
                        <tr>{this.renderHeadColumn()}</tr>
                      </thead>
                      <tbody>{this.renderSheetList()}</tbody>
                    </table>
                  </div>
                </div>
              </div>

              {this.state.showAddPatient && (
                <div class="col-md-12">
                  <div class="row">
                    <AddPatient
                      toggle={this.toggleAddPatient}
                      setPatient={this.setPatient}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-12 mt-5">
                {!this.state.showAddProcedure && (
                  <CustomDropDown
                    ref="procedure"
                    type={'procedures'}
                    isRequired={FormValidatorService.isRequired(validationGroup, "selectedProcedures")}
                    isMulti={true}
                    className={FormValidatorService.getClass(validationGroup, this.state, "selectedProcedures", '')}
                    selectedValue={this.state.selectedProcedures}
                    onChange={this.onChangeProcedure}>
                  </CustomDropDown>
                )}
                {!this.state.showAddProcedure && (
                  <div style={{ paddingTop: "10px" }}>
                    <button
                      onClick={() => this.toggleAddProcedure(true)}
                      className="filter-save-button add-new-button"
                    >
                      Add New Procedure
                    </button>
                  </div>
                )}
              </div>
              {this.state.showAddProcedure && (
                <AddProcedure
                  toggle={this.toggleAddProcedure}
                  setProcedure={this.setProcedure}
                />
              )}
              <div className="col-md-3 mrgn-btm10">
                <CustomDropDown
                  label={'Pay To'}
                  type={'practices'}
                  isRequired={FormValidatorService.isRequired(validationGroup, "billPayTo")}
                  className={FormValidatorService.getClass(validationGroup, this.state, "billPayTo", '')}
                  isMulti={false}
                  selectedValue={this.state.billPayTo}
                  onChange={(value) => this.setState({ billPayTo: value })}>
                </CustomDropDown>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>
                  Account Number {FormValidatorService.getRequiredHTML(validationGroup, "accountNumber")}
                </label>
                <input
                  type="text"
                  className={FormValidatorService.getClass(validationGroup, this.state, "accountNumber")}
                  value={this.state.accountNumber}
                  onChange={(event) => {
                    this.setState({ accountNumber: event.target.value });
                  }}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CustomDropDown
                  type={'surgeons'}
                  isRequired={FormValidatorService.isRequired(validationGroup, "surgeon")}
                  isMulti={false}
                  className={FormValidatorService.getClass(validationGroup, this.state, "surgeon", '')}
                  selectedValue={this.state.surgeon}
                  onChange={this.onChangeSurgeon}>
                </CustomDropDown>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CustomDropDown
                  type={'facilities'}
                  isRequired={FormValidatorService.isRequired(validationGroup, "facility")}
                  isMulti={false}
                  className={FormValidatorService.getClass(validationGroup, this.state, "facility", '')}
                  selectedValue={this.state.facility}
                  onChange={this.onChangeFacility}>
                </CustomDropDown>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CustomDropDown
                  type={'assistants'}
                  isRequired={FormValidatorService.isRequired(validationGroup, "assistant")}
                  isMulti={false}
                  className={FormValidatorService.getClass(validationGroup, this.state, "assistant", '')}
                  selectedValue={this.state.assistant}
                  onChange={this.onChangeAssistant}>
                </CustomDropDown>
              </div>

              <div className="col-md-3 mrgn-btm10">
                <label>
                  Admission Date {FormValidatorService.getRequiredHTML(validationGroup, "admissionDate")}
                </label>
                <br />
                <DatePicker
                  isClearable
                  selected={this.state.admissionDate}
                  onChange={(date) => this.setAdmissionDate(date)}
                  className={FormValidatorService.getClass(validationGroup, this.state, "admissionDate")}
                />
              </div>

              <div className="col-md-3 mrgn-btm10">
                <label>
                  Start Date {FormValidatorService.getRequiredHTML(validationGroup, "startDate")}
                </label>
                <br />
                <DatePicker
                  isClearable
                  selected={this.state.startDate}
                  onChange={(date) => this.setStartDate(date)}
                  className={FormValidatorService.getClass(validationGroup, this.state, "startDate")}
                />
              </div>

              <div className="col-md-3 mrgn-btm10">
                <label>
                  Start Time {FormValidatorService.getRequiredHTML(validationGroup, "startTime")}
                </label>
                <TimePicker
                  name={'test'}
                  showSecond={false}
                  defaultValue={now}
                  value={this.state.startTime}
                  className={FormValidatorService.getClass(validationGroup, this.state, "startTime")}
                  onChange={(value) => this.setState({ startTime: value })}
                  format={format}
                  use12Hours={false}
                  inputReadOnly={false}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>End Date</label>
                <br />
                <DatePicker
                  isClearable
                  selected={this.state.endDate}
                  onChange={(date) => this.setEndDate(date)}
                  className="date-picker"
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>End Time</label>
                <TimePicker
                  showSecond={false}
                  defaultValue={now}
                  value={this.state.endTime}
                  className="time-input"
                  onChange={(value) => this.setState({ endTime: value })}
                  format={format}
                  use12Hours={false}
                  inputReadOnly={false}
                />
              </div>

              <div className="col-md-3 mrgn-btm10">
                <CustomSelect type='Place Of Service'
                  isRequired={FormValidatorService.isRequired(validationGroup, "placeOfService")}
                  value={this.state.placeOfService}
                  className={FormValidatorService.getClass(validationGroup, this.state, "placeOfService", 'filter-input')}
                  onChange={(event) =>
                    this.setState({ placeOfService: event.target.value })
                  }
                >
                </CustomSelect>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CustomDropDown
                  type={'event_flags'}
                  isRequired={false}
                  isMulti={true}
                  selectedValue={this.state.eventFlags}
                  onChange={this.onChangeEventFlags}>
                </CustomDropDown>
              </div>
              {!activityId && (
                <div className="col-md-3">
                  <label>Add Notes</label>
                  <input
                    type="text"
                    value={this.state.notes}
                    className="code-input"
                    onChange={(event) =>
                      this.setState({ notes: event.target.value })
                    }
                  />
                </div>
              )}
              {activityId && (
                <div className="col-md-3">
                  <CustomDropDown
                    type={'status'}
                    isRequired={false}
                    isMulti={false}
                    selectedValue={this.state.status}
                    onChange={this.onChangeStatus}>
                  </CustomDropDown>
                </div>
              )}
              {activityId && (
                <div className="col-md-3">
                  <label>Add Notes</label>
                  <input
                    type="text"
                    value={this.state.notes}
                    className="code-input"
                    onChange={(event) =>
                      this.setState({ notes: event.target.value })
                    }
                  />
                </div>
              )}
              <div className="col-md-12 mt-4">
                <input type="checkbox" checked={this.state.endDate === ""} />
                <label style={{ paddingLeft: "5px" }}>
                  End Time not confirmed (Click checkbox only if you don't know
                  the end time)
                </label>
              </div>
              <div className="filter-footer">
                <div style={{ 'float': 'left', 'padding-top': '10px' }}>
                  Created By: <strong>{this.state.createdBy}</strong>, Modified By: <strong>{this.state.modifiedBy}</strong>
                </div>
                {activityId && (
                  <button
                    onClick={this.submitActivity}
                    className="filter-save-button"
                  >
                    Update
                  </button>
                )}
                {!activityId && (
                  <button
                    onClick={this.submitActivity}
                    className="filter-save-button"
                  >
                    Submit
                  </button>
                )}
                {this.props.PopupRequest === undefined && (
                  <button
                    onClick={this.cancelNav}
                    className="filter-cancel-button"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </LoadingOverlay >
      </div >
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitActivity,
      updateActivity,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageActivity));
