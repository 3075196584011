import React from "react";
import {bindActionCreators} from "redux";
import {createPosting} from "../../actions/batches";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import DatePicker from "react-datepicker";
import {formatDate} from "../../utils/date";
import {patientService} from "../../services/patients";
import {billingService} from "../../services/billing";
import loaderImage from "../../../assets/images/CoureMax.gif";
import {APP_ROUTES, NOTIFICATION_TYPES} from "../../constants/common";
import {createNotification} from "../../utils/notificationManager";

class ManagePaymentPosting extends React.Component {

    state = {
        unapplied: '',
        patient: '',
        dob: '',
        searchType: 'name',
        selectedPatient: '',
        patients: [],
        events: [],
        loading: false,
        bills: [],
        cpt: [],
    };

    componentDidMount() {
        const batch = this.props.location.state ? this.props.location.state.batch : '';
        this.setState({unapplied: batch[3]});
    }

    search = () => {
        const { patient, dob, searchType } = this.state;
        if (patient || dob) {
            let options = {};
            if (searchType === 'name') {
                options = {...options, name: patient };
            }
            if (searchType === 'dob') {
                options = {...options, dob: formatDate(dob) };
            }
            this.setState({loading: true});
            patientService
                .searchPatient({params: options})
                .then(response => {
                    this.setState({
                        patients: response.data.map(patient => {
                            return [
                                patient.old_id,
                                `${patient.first_name ? patient.first_name : ''} ${patient.last_name ? patient.last_name : ''}`.toUpperCase(),
                                formatDate(patient.date_of_birth),
                                patient.patient_insurance_data && patient.patient_insurance_data.primary
                                    ? patient.patient_insurance_data.primary.description : '',
                                patient.patient_insurance_data && patient.patient_insurance_data.secondary
                                    ? patient.patient_insurance_data.secondary.description: '',
                            ];
                        })
                    });
                    this.setState({loading: false});
                });
        }
    };

    showEvents = (patientId) => {
        this.setState({
            loading: true,
            selectedPatient: patientId,
        });
        this.setState({events: []}, () => {
            patientService
                .getPatientActivity(patientId)
                .then(response => {
                    return response.data.map(activity => {  //eslint-disable-line
                        billingService
                            .getEventBill(activity.old_event_id)
                            .then(response => {
                                const event = [
                                    activity.old_event_id,
                                    formatDate(activity.event_start_date),
                                    `${activity.assistant ? activity.assistant.name : ''} ${activity.assistant ? activity.assistant.last_name : ''}`.toUpperCase(),
                                    response.data.bill ? response.data.bill.total_bill : 0,
                                ];
                                this.setState(previousState => ({
                                    events: [...previousState.events, event]
                                }));
                                this.setState({loading: false});
                            });
                    });
                });
        });
    };

    showBills = (eventId) => {
        this.setState({loading: true});
        this.setState({bills: []}, () => {
            const options = {
                batch_id: this.props.location.state.batch[5],
            };
            billingService
                .getEventBill(eventId, { params: options })
                .then(response => {
                    const serviceLines = response.data.bill && response.data.bill.service_lines;
                    const bills = serviceLines && serviceLines.map((serviceLine, index) => {
                        const postings = response.data && response.data.paymentPosting;
                        const cpt = {
                            allowed: postings.length > 0 && postings[index].allowed || 0,                       //eslint-disable-line
                            payment: postings.length > 0 && postings[index].paid || 0,                          //eslint-disable-line
                            adjustment: postings.length > 0 && postings[index].adjustment || 0,                 //eslint-disable-line
                            balance: postings.length > 0 && postings[index].balance || 0,                       //eslint-disable-line
                            ptResponsibility: postings.length > 0 && postings[index].pt_responsibility || 0,    //eslint-disable-line
                            description: postings.length > 0 && postings[index].description || 0,               //eslint-disable-line
                            postingId: postings.length > 0 && postings[index].id || 0,                          //eslint-disable-line
                        }
                        this.setState(previousState => ({
                            cpt: [...previousState.cpt, cpt]
                        }));
                        return [
                            response.data.bill ? formatDate(response.data.bill.service_date) : '',
                            serviceLine.hcpcs_code,
                            serviceLine.line_total,
                            eventId,
                            'payment',
                            'adjustment',
                            'balance',
                            'ptResponsibility',
                            'description',
                        ];
                    });
                    this.setState({bills: bills || []});
                    this.setState({loading: false});
                });
        });
    };

    reset = () => {
      this.setState({
          patient: '',
          dob: '',
          searchType: 'name',
          patients: [],
          events: [],
          bills: [],
          cpt: [],
      });
    };

    renderHeadColumn = (headColumn) => {
        return headColumn.map(column => {
            return (
                <th style={{
                    position: 'sticky',
                    top: '0',
                    background: '#475f87',
                }} className="listing-table-head-column">
                    {column}
                </th>
            );
        });
    };

    renderList = (list) => {
        return list.map(listRow => (
            <tr
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => this.showEvents(listRow[0])}
                className="listing-table-rows">
                {listRow.map(column => (
                    <td style={{
                        lineHeight: '1.1',
                    }}
                    className="listing-table-column">
                        {column}
                    </td>
                ))}
            </tr>
        ));
    };

    renderEventsList = (list) => {
        return list.map(listRow => (
            <tr
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => this.showBills(listRow[0])}
                className="listing-table-rows">
                {listRow.map(column => (
                    <td style={{
                        lineHeight: '1.1',
                    }}
                        className="listing-table-column">
                        {column}
                    </td>
                ))}
            </tr>
        ));
    };

    handleBillChange = (attribute, value, index) => {
        let cpt = [ ...this.state.cpt ];
        cpt[index] = {...cpt[index], [attribute]: value};
        this.setState({ cpt }, () => {
            const paymentSum = this.state.cpt.reduce((a, b) => parseInt(a) + parseInt(b['payment'] || 0), 0);
            const batch = this.props.location.state ? this.props.location.state.batch : '';
            this.setState({unapplied: batch[3] - paymentSum});
        });
    };

    renderBillList = (list) => {
        return list.map((listRow, ind) => {
            const item = listRow.map((listItem, index) => {
                if (index === 3) {
                    return (
                        <td style={{
                            lineHeight: '1.1',
                        }}
                            className="listing-table-column">
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.cpt[ind].allowed}
                                onChange={event => this.handleBillChange('allowed', event.target.value, ind)}
                            />
                        </td>
                    )
                } else if (index === 4) {
                    return (
                        <td style={{
                            lineHeight: '1.1',
                        }}
                            className="listing-table-column">
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.cpt[ind].payment}
                                onChange={event => this.handleBillChange('payment', event.target.value, ind)}
                            />
                        </td>
                    )
                } else if (index === 5) {
                    return (
                        <td style={{
                            lineHeight: '1.1',
                        }}
                            className="listing-table-column">
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.cpt[ind].adjustment}
                                onChange={event => this.handleBillChange('adjustment', event.target.value, ind)}
                            />
                        </td>
                    )
                } else if (index === 6) {
                    return (
                        <td style={{
                            lineHeight: '1.1',
                        }}
                            className="listing-table-column">
                            <input
                                type="text"
                                className="code-input"
                                value={listRow[2] - (parseInt(this.state.cpt[ind].adjustment) + parseInt(this.state.cpt[ind].payment))}
                                disabled
                            />
                        </td>
                    )
                } else if (index === 7) {
                    return (
                        <td style={{
                            lineHeight: '1.1',
                        }}
                            className="listing-table-column">
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.cpt[ind].ptResponsibility}
                                onChange={event => this.handleBillChange('ptResponsibility', event.target.value, ind)}
                            />
                        </td>
                    )
                } else if (index === 8) {
                    return (
                        <td style={{
                            lineHeight: '1.1',
                        }}
                            className="listing-table-column">
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.cpt[ind].description}
                                onChange={event => this.handleBillChange('description', event.target.value, ind)}
                            />
                        </td>
                    )
                } else {
                    return (
                        <td style={{
                            lineHeight: '1.1',
                        }}
                            className="listing-table-column">
                            {listItem}
                        </td>
                    )
                }
            });

            return (
                <tr className="listing-table-rows">
                    {item}
                </tr>
            );
        });
    };

    renderBills = () => {
        const headerList = [
            'Date From',
            'Procedure',
            'Charge',
            'Allowed',
            'Payment',
            'Adjustment',
            'Balance',
            'PT Responsibility',
            'Description',
        ];
        return (
            <div className="x_panel">
                <div className="page-title">
                    <h2 className="heading-custom">CPT</h2>
                </div>
                <div className="x_content">
                    <div style={{
                        overflow: 'auto',
                        maxHeight: '227px',
                    }}
                         className="table-responsive">
                        <table className="listing-table">
                            <thead className="listing-table-head">
                            <tr>
                                {this.renderHeadColumn(headerList)}
                            </tr>
                            </thead>
                            <tbody>
                                {this.renderBillList(this.state.bills)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    renderPatients = () => {
        const headerList = [
            'ID',
            'Patient Name',
            'DOB',
            'Primary Insurance',
            'Secondary Insurance',
        ];
        return (
            <div className="x_panel">
                <div className="page-title">
                    <h2 className="heading-custom">Patients</h2>
                </div>
                <div className="x_content">
                    <div style={{
                        overflow: 'auto',
                        maxHeight: '227px',
                    }}
                         className="table-responsive">
                        <table className="listing-table">
                            <thead className="listing-table-head">
                                <tr>
                                    {this.renderHeadColumn(headerList)}
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderList(this.state.patients)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    renderEvents = () => {
        const headerList = [
            'ID',
            'Date of Service',
            'Surgical Assistant',
            'Amount',
        ];
        return (
            <div className="x_panel">
                <div className="page-title">
                    <h2 className="heading-custom">Events</h2>
                </div>
                <div className="x_content">
                    <div style={{
                        overflow: 'auto',
                        maxHeight: '227px',
                    }}
                         className="table-responsive">
                        <table className="listing-table">
                            <thead className="listing-table-head">
                            <tr>
                                {this.renderHeadColumn(headerList)}
                            </tr>
                            </thead>
                            <tbody>
                                {this.renderEventsList(this.state.events)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    submitPosting = () => {
        const { bills, cpt } = this.state;
        const { createPosting } = this.props;
        this.setState({loading: true});
        const batch = this.props.location.state ? this.props.location.state.batch : '';
        const postings = bills.map((bill, index) => {
            const allowed = cpt[index].allowed;
            const payment = cpt[index].payment;
            const adjustment = cpt[index].adjustment;
            const ptResponsibility = cpt[index].ptResponsibility;
            const description = cpt[index].description;
            const postingId = cpt[index].postingId;
            return {
                batch_id: batch[5],
                event_id: bill[3],
                patient_id: this.state.selectedPatient,
                cpt: bill[1],
                cpt_charge: bill[2],
                allowed: allowed,
                paid: payment,
                adjustment: adjustment,
                pt_responsibility: ptResponsibility,
                description: description,
                posting_id: postingId,
            }
        });

        const request = {
            batch_id: batch[5],
            event_id: bills.length > 0 && bills[0][3],
            postings,
        }

        createPosting(request)
            .then(res => {
                if (res.response.success) {
                    createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                    this.props.history.push(APP_ROUTES.BATCH_DASHBOARD);
                }
                this.setState({loading: false});
            })
            .finally(err => {
                this.setState({loading: false});
            });
    };

    cancel = () => {
        this.props.history.push(APP_ROUTES.BATCH_DASHBOARD);
    };

    render() {
        const batch = this.props.location.state ? this.props.location.state.batch : '';
        return (
                <div className="main_container">
                    <div className="col-md-3 custom-sidebar-menu left_col">
                        <Sidebar activePage={this.props.activePage}  />
                    </div>
                    <TopNavbar />
                    <LoadingOverlay
                        active={this.state.loading}
                        spinner={<img
                            style={{
                                width: '200px',
                            }}
                            src={loaderImage}
                            alt="loading..."
                        />}>
                        <div className="manage-activity-right-col">
                                <div className="activity-row">
                                    <div className="page-title">
                                        <h2 className="heading-custom">Manage Payment Posting</h2>
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <h3 style={{
                                            fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '1.471',
                                        }}>Batch Name:</h3>
                                        <label>{batch[0]}</label>
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <h3 style={{
                                            fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '1.471',
                                        }}>TRN:</h3>
                                        <label>{batch[1]}</label>
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <h3 style={{
                                            fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '1.471',
                                        }}>Payment:</h3>
                                        <label>{batch[4]}</label>
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <h3 style={{
                                            fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '1.471',
                                        }}>Unapplied:</h3>
                                        <label>{this.state.unapplied}</label>
                                    </div>

                                    { this.state.searchType === 'name' && (
                                        <div className="col-md-6 mrgn-btm10">
                                            <label>Patient Name</label>
                                            <input
                                                type="text"
                                                className="code-input"
                                                value={this.state.patient}
                                                onChange={event => this.setState({ patient: event.target.value })}
                                            />
                                        </div>
                                    )}
                                    { this.state.searchType === 'dob' && (
                                        <div className="col-md-6 mrgn-btm10">
                                            <label>Date of Birth</label>
                                            <br/>
                                            <DatePicker
                                                isClearable
                                                selected={this.state.dob}
                                                onChange={date => this.setState({ dob: date })}
                                                className="date-picker"
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Search By</label>
                                        <select
                                            className='code-input'
                                            onChange={event => this.setState({ searchType: event.target.value })}
                                            value={this.state.searchType}>
                                            <option value="name"> Name</option>
                                            <option value="dob"> Date of Birth</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <button
                                            style={{
                                                marginTop: '34px',
                                            }}
                                            onClick={this.search}
                                            className="filter-save-button">
                                            Search
                                        </button>
                                        <button
                                            style={{
                                                marginLeft: '5px',
                                                marginTop: '34px',
                                            }}
                                            onClick={this.reset}
                                            className="filter-save-button">
                                            Reset
                                        </button>
                                    </div>
                                    {this.state.patients.length > 0 && this.renderPatients()}
                                    {this.state.events.length > 0 && this.renderEvents()}
                                    {this.state.bills.length > 0 && this.renderBills()}
                                    <div className="filter-footer">
                                        {this.state.cpt.length > 0 && (
                                            <button
                                                onClick={this.submitPosting}
                                                className="filter-save-button">
                                                Submit
                                            </button>
                                        )}
                                        <button
                                            onClick={this.cancel}
                                            className="filter-cancel-button">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </LoadingOverlay>
                </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createPosting,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManagePaymentPosting));