import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getActivities(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_ACTIVITIES, options)
        .then(handleResponse)
        .catch(handleError);
}

function submitActivity(payload) {
    return apiClient()
        .post(API_CONSTANTS.SUBMIT_ACTIVITIES, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateActivity(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_ACTIVITY, payload)
        .then(handleResponse)
        .catch(handleError);
}

function getActivityDetail(activity) {
    return apiClient()
        .get(API_CONSTANTS.ACTIVITY_DETAIL + activity)
        .then(handleResponse)
        .catch(handleError);
}

function getActivityDetailWithProcedureBillInformation(activity) {
    return apiClient()
        .get(API_CONSTANTS.ACTIVITY_DETAIL_WITH_BILL_INFO + activity)
        .then(handleResponse)
        .catch(handleError);
}

function deleteActivity(activity) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ACTIVITY + activity)
        .then(handleResponse)
        .catch(handleError);
}

function deleteActivityFaceSheet(faceSheetId) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ACTIVITY_FACESHEET + faceSheetId)
        .then(handleResponse)
        .catch(handleError);
}

function exportActivities(payload) {
    return apiClient()
        .get(API_CONSTANTS.EXPORT_ACTIVITIES, payload)
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'activityReport.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(handleError);
}

export const activityService = {
    getActivities,
    submitActivity,
    updateActivity,
    getActivityDetail,
    deleteActivity,
    deleteActivityFaceSheet,
    exportActivities,
    getActivityDetailWithProcedureBillInformation,
}