import { PROCEDURE } from '../constants/actions';

const initialState = {
    procedures: [],
    totalProcedures: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case PROCEDURE.FETCH_PROCEDURES_SUCCESS:
            return {
                ...state,
                procedures: action.data.data,
                totalProcedures: action.data.records,
            };
        case PROCEDURE.CREATE_PROCEDURE_SUCCESS:
            const procedures = [...state.procedures, action.data.data];
            return {
                ...state,
                procedures: procedures,
                totalProcedures: action.data.records,
            };
        case PROCEDURE.UPDATE_PROCEDURE_SUCCESS:
            const updateProcedures = [...state.procedures, action.data.data];
            return {
                ...state,
                procedures: updateProcedures,
            }
        default:
            return state;
    }
}