import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getCaseLogs(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_CASELOG, options)
        .then(handleResponse)
        .catch(handleError);
}

function createCaseLog(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_CASELOG, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateCaseLog(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_CASELOG, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteCaseLog(user) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_CASELOG + user)
        .then(handleResponse)
        .catch(handleError);
}

function getCaseLogDetail(user) {
    return apiClient()
        .get(API_CONSTANTS.CASELOG_DETAIL + user)
        .then(handleResponse)
        .catch(handleError);
}

export const caseLogService = {
    getCaseLogs,
    createCaseLog,
    updateCaseLog,
    deleteCaseLog,
    getCaseLogDetail,
}