import {PATIENT} from '../constants/actions';

const initialState = {
    patients: [],
    totalPatients: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case PATIENT.FETCH_PATIENT_SUCCESS:
            return {
                ...state,
                patients: action.data.data,
                totalPatients: action.data.records,
            };
        case PATIENT.CREATE_PATIENT_SUCCESS:
            const patients = [ ...state.patients, action.data.data ];
            return {
                ...state,
                patients: patients,
            };
        case PATIENT.UPDATE_PATIENT_SUCCESS:
            const updatedPatients = [ ...state.patients, action.data.data ];
            return {
                ...state,
                patients: updatedPatients,
            }
        default:
            return state;
    }
}