import React from "react";
import {
  APP_ROUTES,
  DIVISION_LISTING_COLUMNS,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { fetchDivision } from "../../actions/division";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { divisionService } from "../../services/division";
import PropTypes from "prop-types";
import DivisionFilters from "../organisms/DivisionFilters";
import DivisionListingTable from "../atom/DivisionListingTable";
import { authorizationService } from "../../services/authorization";

class DivisionDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    fetchDivision: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    filter: "",
    pageSize: RECORDS_PER_PAGE
  };

  componentDidMount() {
    this.setState({ loading: true });
  }
  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };
    this.props.fetchDivision({ params: options }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  }
  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page < 0 ? 1 : page,
      page_size: this.state.pageSize,
    };
    this.props.fetchDivision({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_DIVISION);

  resetFacilitySearch = () => {
    this.setState({
      filter: "",
    });
  };

  list = () => {
    let { division = [] } = this.props;
    if (division.length > 0) {
      const lastIndex = division.length - 1;
      const lastElementLength = division[lastIndex].length;
      if (lastElementLength > 0) {
        division = division.slice(0, -1);
      }
    }

    return division.map((temp) => {
      return [
        temp.id,
        temp.name,
        temp.user?.fullname,
        temp.user?.email,
        temp.id,
      ];
    });
  };

  showManageDivsion = (divisionId) =>
    this.props.history.push({
      pathname: APP_ROUTES.ADD_DIVISION,
      state: {
        divisionId,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
      },
    });

  deleteDivision = (invoice) => {
    divisionService.deleteDivision(invoice).then((response) => {
      this.goToPage(1);
    });
  };

  setFilter = (value) => {
    this.setState({ filter: value });
  };


  addFilters = (options) => {
    const {
      filter,
    } = this.state;
    if (filter) {
      options = { ...options, search: filter };
    }
    return options;
  };

  search = () => {
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };

    this.props
      .fetchDivision({ params: this.addFilters(options) })
      .then((res) => {
      }).finally(() => {
        this.setState({ loading: false });
      });
  };
  render() {
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />

        <div
          style={{
            minHeight: "760px",
          }}
          className="right-col"
        >
          {authorizationService.isAddAccess() && (
            <div className="filter-footer-header">
              <button
                onClick={() => {
                  this.showAdd(true);
                }}
                className="patients-filter-button"
              >
                Add New Division
              </button>
            </div>
          )}
          <DivisionFilters
            goToPage={this.goToPage}
            setFilter={this.setFilter}
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          ></div>
          <div className="row">
            <div className="activities-header">
              <DivisionListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManageDivsion}
                delete={this.deleteDivision}
                headColumn={DIVISION_LISTING_COLUMNS}
                listing={this.list()}
                totalRecords={this.props.totalRecords}
                setPageSize={this.setPageSize}
                pageSize={this.state.pageSize}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    division: state.divisionReducer.divisions,
    totalRecords: state.divisionReducer.totalDivision,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDivision,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DivisionDashboard));
