import {accountService} from "../services/accounts";
import {ACCOUNTS} from "../constants/actions";

export const fetchInvoices = options => dispatch => {
    return new Promise((resolve, reject) => {
        accountService
            .getAccounts(options)
            .then(response => {
                dispatch({
                    type: ACCOUNTS.FETCH_ACCOUNTS_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateInvoice = payload => dispatch => {
    return new Promise((resolve, reject) => {
        accountService
            .updateInvoice(payload)
            .then(response => {
                dispatch({
                    type: ACCOUNTS.UPDATE_INVOICE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
