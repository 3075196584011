import React from "react";
import { bindActionCreators } from "redux";
import { createRegion, updateRegion } from "../../actions/regions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { APP_ROUTES, NOTIFICATION_TYPES } from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import { regionsService } from "../../services/regions";

import { FormValidatorService } from "../../services/formvalidator";
import { utilityService } from "../../services/utility";

const validationGroup = {
    message: "There are some validation errors in Basic Information Tab:",
    fields: {
        'name': { filedName: 'Region Name', required: true, minLength: 3, maxLength: 200 },
        'email': { filedName: 'Email', required: true },
    }
}

class ManageRegions extends React.Component {
    state = {
        name: '',
        email: '',
        errors: [],
    };

    componentDidMount() {
        const regionId = this.props.location.state ? this.props.location.state.regionId : '';
        if (regionId) {
            regionsService
                .getRegions()
                .then(response => {
                    response.data.map(region => {           //eslint-disable-line
                        if (region.id === regionId) {
                            this.setState({
                                name: region.name,
                                email: region.email,
                            });
                        }
                    })
                });
        }
    }

    submitRegion = () => {
        if (!this.validateRegion()) {
            return;
        }
        const { createRegion, updateRegion } = this.props;
        const {
            name,
            email,
        } = this.state;

        const request = {
            name,
            email,
            type: 'company',
        };

        const regionId = this.props.location.state ? this.props.location.state.regionId : '';

        if (regionId) {
            updateRegion({ ...request, id: regionId })
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.REGIONS);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.REGIONS);
                    }
                })
        } else {
            createRegion(request)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.REGIONS);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.REGIONS);
                    }
                })
        }
    };

    validateRegion = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    render() {
        const regionId = this.props.location.state ? this.props.location.state.regionId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {regionId &&
                                <h2 className="heading-custom">Edit Region</h2>
                            }
                            {!regionId &&
                                <h2 className="heading-custom">Add Region</h2>
                            }
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Region Name {FormValidatorService.getRequiredHTML(validationGroup, "name")}</label>
                            <input
                                className={FormValidatorService.getClass(validationGroup, this.state, "name")}
                                type="text"
                                value={this.state.name}
                                onChange={event => {
                                    this.setState({ name: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Region Email {FormValidatorService.getRequiredHTML(validationGroup, "email")}</label>
                            <input
                                className={FormValidatorService.getClass(validationGroup, this.state, "email")}
                                type="text"
                                value={this.state.email}
                                onChange={event => {
                                    this.setState({ email: event.target.value })
                                }}
                            />
                        </div>
                        <div className="filter-footer">
                            <button
                                onClick={this.submitRegion}
                                className="filter-save-button">
                                Submit
                            </button>
                            <button
                                onClick={() => { this.props.history.push(APP_ROUTES.REGIONS); }}
                                className="filter-cancel-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createRegion,
            updateRegion,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageRegions));