import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getCategories(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_KNOWLEDGE_BASE_CATEGORIES, options)
        .then(handleResponse)
        .catch(handleError);
}

function createCategory(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_KNOWLEDGE_BASE_CATEGORIES, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateCategory(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_KNOWLEDGE_BASE_CATEGORIES, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteCategory(user) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_KNOWLEDGE_BASE_CATEGORIES + user)
        .then(handleResponse)
        .catch(handleError);
}

function getCategoryDetail(user) {
    return apiClient()
        .get(API_CONSTANTS.KNOWLEDGE_BASE_CATEGORIES_DETAIL + user)
        .then(handleResponse)
        .catch(handleError);
}

export const knowledgeBaseCategoryService = {
    getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    getCategoryDetail,
}