import { INVOICINGV1 } from '../constants/actions';

const initialState = {
    invoicingv1: [],
    totalInvoicingv1: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case INVOICINGV1.FETCH_INVOICING_SUCCESS:
            return {
                ...state,
                invoicingv1: action.data.data,
                totalInvoicingv1: action.data.records,
            };
        case INVOICINGV1.SUBMIT_INVOICING_SUCCESS:
            const invoicing = [ ...state.invoicingv1, action.data.data ];
            return {
                ...state,
                invoicingv1: invoicing,
            }
        case INVOICINGV1.UPDATE_INVOICING_SUCCESS:
            const updatedinvoicing = [ ...state.invoicingv1, action.data.data ];
            return {
                ...state,
                invoicingv1: updatedinvoicing,
            }
        case INVOICINGV1.DELETE_INVOICING_SUCCESS:
            const deletedinvoicing = [ ...state.invoicingv1, action.data.data ];
            return {
                ...state,
                invoicingv1: deletedinvoicing,
            }
        default:
            return state;
    }
}