import React from "react";

import "../../../sass/dashboard.scss";
import "../../../sass/patients.scss";

import { formatDate } from "../../utils/date";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { fetchPatients } from "../../actions/patients";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import PatientListingTable from "../atom/PatientListingTable";
import {
  APP_ROUTES,
  PATIENT_LISTING_COLUMNS,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import PropTypes from "prop-types";
import { patientService } from "../../services/patients";
import { authorizationService } from "../../services/authorization";

class PatientsDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searchByDob: "",
    searched: false,
    searchedPatients: [],
    checkedPatients: [],
    searchedTotalRecords: 0,
    pageSize: RECORDS_PER_PAGE
  };

  componentDidMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };
    this.props.fetchPatients({ params: options }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  showManagePatient = (patientId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_PATIENT,
      state: {
        patientId,
      },
    });

  deletePatient = (patient) => {
    patientService.deletePatient(patient).then((response) => {
      this.goToPage(1);
    });
  };

  deleteMultiplePatients = () => {
    let payload = { 'ids': this.state.checkedPatients };
    patientService.deleteMultiplePatient(payload).then((response) => {
      this.goToPage(1);
    });
  }

  resetPatientSearch = () => {
    this.setState(
      {
        searchByName: "",
        searchByDob: "",
        searchedPatients: [],
        searched: false,
        searchedTotalRecords: 0,
      },
      () => this.goToPage(1)
    );
  };

  searchPatient = () => {
    const { searchByName, searchByDob } = this.state;
    let options = {};
    if (searchByName) {
      options = { ...options, name: searchByName };
    }
    if (searchByDob) {
      options = { ...options, dob: formatDate(searchByDob) };
    }
    options = { ...options, api_type: "react" };
    options = { ...options, pageSize: this.state.pageSize };

    this.setState({
      searched: true,
      loading: true,
    });
    this.setCurrentPage(1);
    patientService.searchPatient({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedPatients: response.data.map((patient) => {
          return [
            patient.id,
            patient.id,
            `${patient.first_name ? patient.first_name : ""} ${patient.last_name ? patient.last_name : ""
              }`.toUpperCase(),
            formatDate(patient.date_of_birth),
            new Date().getFullYear() -
            parseInt(patient.date_of_birth.split("-")[0]),
            patient.gender ? patient.gender : "",
            this.getInsuranceDetail(patient),
            patient.old_id,
          ];
        }),
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  addCheckedItem = (patients) => {
    this.setState({},)
    this.setState((prevState) => ({
      checkedPatients: [...prevState.checkedPatients, patients],
    }));
  };

  removeCheckedItem = (patients) => {
    this.setState((prevState) => ({
      checkedPatients: prevState.checkedPatients.filter(
        (activity) => activity !== patients
      ),
    }));
  };

  addAllCheckedItem = () => {
    let tPatients = this.state.searched && this.state.searchedPatients.length > 0 ? this.state.searchedPatients
      : this.list()
    let tAllPatients = [];
    tPatients.forEach(temp => {
      tAllPatients.push(temp[0]);
    })

    this.setState((prevState) => ({
      checkedPatients: tAllPatients
    }));
  }
  
  removeAllCheckedItem = () => {
    this.setState((prevState) => ({
      checkedPatients: []
    }));
  }

  goToPage = (page) => {
    const { searched, searchByName, searchByDob } = this.state;

    this.setLoading(true);
    this.setCurrentPage(page);

    let options = {
      page: page,
      page_size: this.state.pageSize,
      api_type: "react",
    };

    if (searched) {
      if (searchByName) {
        options = { ...options, name: searchByName };
      }
      if (searchByDob) {
        options = { ...options, dob: formatDate(searchByDob) };
      }

      patientService.searchPatient({ params: options }).then((res) => {
        this.setState({
          searchedTotalRecords: parseInt(res.records),
          searchedPatients: res.data.map((patient) => {
            return [
              patient.id,
              patient.id,
              `${patient.first_name ? patient.first_name : ""} ${patient.last_name ? patient.last_name : ""
                }`.toUpperCase(),
              formatDate(patient.date_of_birth),
              new Date().getFullYear() -
              parseInt(patient.date_of_birth.split("-")[0]),
              patient.gender ? patient.gender : "",
              this.getInsuranceDetail(patient),
              patient.old_id,
            ];
          }),
        });
      }).finally(() => {
        this.setState({ loading: false });
      });
    } else {
      this.props.fetchPatients({ params: options }).then((res) => {
        this.setLoading(false);
      });
    }
  };

  list = () => {
    const { patients = [] } = this.props;
    return patients.map((patient) => {
      return [
        patient.id,
        patient.id,
        `${patient.first_name ? patient.first_name : ""} ${patient.last_name ? patient.last_name : ""
          }`.toUpperCase(),
        formatDate(patient.date_of_birth),
        new Date().getFullYear() -
        parseInt(patient.date_of_birth.split("-")[0]),
        patient.gender ? patient.gender : "",
        this.getInsuranceDetail(patient),
        patient.old_id,
      ];
    });
  };

  getInsuranceDetail(patient) {
    let tArray = [];
    let primaryInsurance = '';
    if (patient && patient.patient_insurance_data && patient.patient_insurance_data.primary) {
      let tInsurance = patient.patient_insurance_data.primary;
      tArray.push(tInsurance.description);
      primaryInsurance = tArray.join(', ');
    }

    return primaryInsurance;
  }
  showAdd = () => this.props.history.push(APP_ROUTES.ADD_PATIENT);

  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };

  render() {
    const { searched, searchedPatients, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "760px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Patients
              </h3>
            </div>
          </div>
          {authorizationService.isAddAccess() && (
            <div className="filter-footer-header">
              <button
                onClick={() => {
                  this.showAdd(true);
                }}
                className="patients-filter-button"
              >
                Add New Patient
              </button>

              <button
                style={{
                  width: "109px",
                }}
                onClick={this.deleteMultiplePatients}
                disabled={this.state.checkedPatients.length < 1}
                className={
                  this.state.checkedPatients.length < 1
                    ? "filter-footer-disabled-button"
                    : "filter-button"
                }
              >
                Delete
              </button>
            </div>


          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div
              style={{
                paddingLeft: "0",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Patient Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>DOB</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByDob}
                onChange={(event) =>
                  this.setState({ searchByDob: event.target.value })
                }
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                  marginRight: "10px",
                }}
                onClick={this.searchPatient}
                className="filter-save-button"
              >
                Search
              </button>
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetPatientSearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <PatientListingTable
                pageSize={this.state.pageSize}
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManagePatient}
                delete={this.deletePatient}
                headColumn={PATIENT_LISTING_COLUMNS}
                addCheckedItem={this.addCheckedItem}
                removeCheckedItem={this.removeCheckedItem}
                addAllCheckedItem={this.addAllCheckedItem}
                removeAllCheckedItem={this.removeAllCheckedItem}
                checkedPatients={this.state.checkedPatients}
                setPageSize={this.setPageSize}
                listing={
                  searched && searchedPatients.length > 0
                    ? searchedPatients
                    : this.list()
                }
                totalRecords={
                  searched && searchedTotalRecords > 0
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patientReducer.patients,
    totalRecords: state.patientReducer.totalPatients,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPatients,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PatientsDashboard));
