import React from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchCaseLogs } from '../../actions/caseLog';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import Loader from "../atom/Loader";
import {CASELOG_LISTING_COLUMNS, APP_ROUTES} from "../../constants/common";
import CaseLogListingTable from "../atom/CaseLogListingTable";
import { caseLogService } from "../../services/caseLog";
import { activityService } from "../../services/activities";

class CaseLogDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searched: false,
        searchedCaseLogs: [],
        searchedTotalRecords: 0,
    };

    componentWillMount() {
        this.setState({loading: true});
        const options = {
            page: this.state.currentPage,
        };
        this.props.fetchCaseLogs({ params: options })
            .then(res => {
                this.setState({loading: false});
            });
    }

    setLoading = (flag) => {
        this.setState({loading: flag});
    };

    setCurrentPage = (num) => {
        this.setState({currentPage: num});
    };

    goToPage = (page) => {
        this.setLoading(true);
        this.setCurrentPage(page);

        const options = {
            page: page,
        };
        this.props.fetchCaseLogs({ params: options })
            .then(res => {
                this.setLoading(false);
            });
    };

    showAdd = () => this.props.history.push(APP_ROUTES.ADD_CASELOG);

    showManageCaseLog = caseId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_CASELOG,
        state: {
            caseId,
        }
    });

    deleteCaseLog = (user) => {
        activityService
            .deleteActivity(user)
            .then(response => {
                this.goToPage(1);
            });
    };

    list = () => {
        const { caseLogs = [] } = this.props;
        return caseLogs.map(caseLog => {
            return [
                `${caseLog.patient && caseLog.patient.first_name ? caseLog.patient.first_name.toUpperCase() : ''} ${caseLog.patient && caseLog.patient.last_name ? caseLog.patient.last_name.toUpperCase() : ''}`,
                caseLog.patient ? caseLog.patient.date_of_birth : '',
                `${caseLog.patient && caseLog.patient.gender ? caseLog.patient.gender.toUpperCase() : ''}`,
                `${caseLog.patient 
                && caseLog.patient.patient_insurance_data 
                && caseLog.patient.patient_insurance_data.primary 
                    ? caseLog.patient.patient_insurance_data.primary.description 
                    : ''
                }`,
                `${caseLog.surgeon.first_name ? caseLog.surgeon.first_name : ''} ${caseLog.surgeon.last_name ? caseLog.surgeon.last_name : ''}`.toUpperCase(),
                `${caseLog.facility.facility_name ? caseLog.facility.facility_name : ''}`.toUpperCase(),
                caseLog.event_start_date ? caseLog.event_start_date  : '',
                caseLog.old_event_id,
            ];
        });
    };

    searchUsers = () => {
        const { searchByName } = this.state;
        let options = {};

        if (searchByName) {
            options = {...options, search: searchByName };
        }

        this.setState({
            searched: true,
            loading: true
        });
        caseLogService
            .getCaseLogs({params: options})
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedCaseLogs: response.data.map(caseLog => {
                        return [
                            `${caseLog.patient.first_name ? caseLog.patient.first_name : ''} ${caseLog.patient.last_name ? caseLog.patient.last_name : ''}`.toUpperCase(),
                            caseLog.patient.date_of_birth,
                            `${caseLog.patient.gender ? caseLog.patient.gender : ''}`.toUpperCase(),
                            caseLog.activity_id
                                `${caseLog.surgeon.first_name ? caseLog.surgeon.first_name : ''} ${caseLog.surgeon.last_name ? caseLog.surgeon.last_name : ''}`.toUpperCase(),
                            `${caseLog.facility.facility_name ? caseLog.facility.facility_name : ''}`.toUpperCase(),
                            caseLog.event_start_date ? caseLog.event_start_date  : '',
                            caseLog.activity_id,
                        ];
                    })
                });
                this.setState({loading: false});
            });
    }

    resetUserSearch = () => {
        this.setState({
            searchByName: '',
            searchedCaseLogs: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    render() {
        const {
            searched,
            searchedCaseLogs,
            searchedTotalRecords
        } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage}  />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '755px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>CaseLogs</h3>
                        </div>
                    </div>
                    <div className="filter-footer-header">
                        <button
                            onClick={() => { this.showAdd(true) }}
                            className="filter-button">
                            Add New CaseLog
                        </button>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>
                        <div style={{
                            paddingLeft: '10px',
                        }} className="col-md-3 mrgn-btm10">
                            <label>Case ID</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => {
                                    this.setState({ searchByName: event.target.value },
                                        () => {
                                            if (this.state.searchByName.length >= 3) {
                                                this.searchUsers();
                                            } else if (this.state.searchByName.length === 0) {
                                                this.resetUserSearch();
                                            }
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetUserSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            {this.state.loading && (
                                <Loader width='100%' />
                            )}
                            {!this.state.loading && (
                                <CaseLogListingTable
                                    isLoading={this.state.loading}
                                    currentPage={this.state.currentPage}
                                    goToPage={this.goToPage}
                                    edit={this.showManageCaseLog}
                                    delete={this.deleteCaseLog}
                                    headColumn={CASELOG_LISTING_COLUMNS}
                                    listing={searched ? searchedCaseLogs : this.list()}
                                    totalRecords={searched ? searchedTotalRecords : this.props.totalRecords}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        caseLogs: state.caseLogReducer.caseLogs,
        totalRecords: state.caseLogReducer.totalCaseLogs,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchCaseLogs,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(CaseLogDashboard));