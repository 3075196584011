import {ASSISTANT} from '../constants/actions';
import { assistantService } from '../services/assistants';

export const fetchAssistants = options => dispatch => {
    return new Promise((resolve, reject) => {
        assistantService
            .getAssistants(options)
            .then(response => {
                dispatch({
                    type: ASSISTANT.FETCH_ASSISTANT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const getRegionAssistants = (regionId, options) => dispatch => {
    return new Promise((resolve, reject) => {
        assistantService
            .getRegionAssistants(regionId, options)
            .then(response => {
                dispatch({
                    type: ASSISTANT.FETCH_ASSISTANT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createAssistant = payload => dispatch => {
    return new Promise((resolve, reject) => {
        assistantService
            .createAssistant(payload)
            .then(response => {
                dispatch({
                    type: ASSISTANT.CREATE_ASSISTANT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateAssistant = payload => dispatch => {
    return new Promise((resolve, reject) => {
        assistantService
            .updateAssistant(payload)
            .then(response => {
                dispatch({
                    type: ASSISTANT.UPDATE_ASSISTANT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const deleteAssistant = options => dispatch => {
    return new Promise((resolve, reject) => {
        assistantService
            .deleteAssistant(options)
            .then(response => {
                dispatch({
                    type: ASSISTANT.DELETE_ASSISTANT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};