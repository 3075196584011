import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getEventFlags(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_EVENT_FLAGS, options)
        .then(handleResponse)
        .catch(handleError);
}

export const eventFlagService = {
    getEventFlags,
}