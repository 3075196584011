import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router-dom";
class ViewInvoicePopup extends React.Component {
  static propTypes = {
    selectedBills: PropTypes.array.isRequired,
    saveChanges: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
  };

  state = {
    selectedInvoice: {},
  };

  componentDidMount() {
    if (this.props) {
      let tSelectedInvoice = this.props.selectedInvoice;
      tSelectedInvoice.details.forEach(bill => {

      })

      this.setState({
        selectedInvoice: tSelectedInvoice
      })

      console.log(this.props.selectedInvoice);
    }
  }

  render() {
    const { title, isOpen, setModal } = this.props;
    const { selectedInvoice } = this.state;

    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => setModal(!isOpen)}
          style={{
            maxWidth: "90%",
          }}
        >
          <ModalHeader
            toggle={() => setModal(!isOpen)}
            style={{ backgroundColor: "#1b252e", color: "white" }}
          >
            {title}
          </ModalHeader>
          <ModalBody
            style={{
              backgroundColor: "#1b252e",
            }}
          >
            <React.Fragment>
              <div className="row">
                <div className="col-md-12 bordered p-0">
                  <table className="listing-table">
                    <thead className="listing-table-head">
                      <tr>
                        <th className="listing-table-head-column">Bill ID</th>
                        <th className="listing-table-head-column">Patient</th>
                        <th className="listing-table-head-column">Facility</th>
                        <th className="listing-table-head-column">Surgeon</th>
                        <th className="listing-table-head-column">Provider</th>
                        <th className="listing-table-head-column">Insurance</th>
                        <th className="listing-table-head-column">CPT Codes</th>
                        <th className="listing-table-head-column">Amount</th>
                        <th className="listing-table-head-column">Discount %</th>
                        <th className="listing-table-head-column">Discount Value</th>
                        <th className="listing-table-head-column">Discount Amount</th>
                        <th className="listing-table-head-column">Net Amount</th>
                        <th className="listing-table-head-column">Payment Received</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedInvoice.details?.map((bill) => {
                        let event = bill.bills?.event_patient;

                        let hcpcs_code = '';
                        bill.bills.service_lines.forEach(element => {
                          if (element.cpt_code && element.cpt_code !== 'null')
                            hcpcs_code += element.cpt_code + ", ";
                        });
                        if (hcpcs_code.length > 1)
                          hcpcs_code = hcpcs_code.substring(0, hcpcs_code.length - 2);

                        let insurance = '';
                        if (event &&
                          event.patient &&
                          event.patient.patient_insurance_data &&
                          event.patient.patient_insurance_data.primary)
                          insurance += "P: " + event.patient.patient_insurance_data.primary.description + "<br>";

                        if (event &&
                          event.patient &&
                          event.patient.patient_insurance_data &&
                          event.patient.patient_insurance_data.secondary)
                          insurance += "S: " + event.patient.patient_insurance_data.secondary.description;

                        let headerColumnClass = 'listing-table-rows';
                        let due = 0;
                        let payment = 0;
                        bill.payments.forEach(p => {
                          due += parseFloat(p.amount_due);
                          payment += parseFloat(p.amount_received);
                        })

                        if (payment !== due)
                          headerColumnClass = 'listing-table-rows remaining-amount-row';

                        return (
                          <>
                            <tr className={bill.amount_received !== bill.net_amount ? 'listing-table-rows remaining-amount-row' : 'listing-table-rows'}>
                              <td className="listing-table-column">
                                {bill.bill_id}
                              </td>
                              <td className="listing-table-column">
                                {event?.patient?.first_name} {event?.patient?.last_name}
                              </td>
                              <td className="listing-table-column">
                                {event?.facility?.facility_name}
                              </td>
                              <td className="listing-table-column">
                                {event?.surgeon?.first_name} {event?.surgeon?.last_name}
                              </td>
                              <td className="listing-table-column">
                                {event?.assistant?.first_name} {event?.assistant?.last_name}
                              </td>
                              <td className="listing-table-column" dangerouslySetInnerHTML={{
                                __html: insurance,
                              }}>
                              </td>
                              <td className="listing-table-column">
                                {hcpcs_code}
                              </td>
                              <td className="listing-table-column">
                                ${bill.total_amount ? bill.total_amount : "0"}
                              </td>
                              <td className="listing-table-column">
                                {bill.discount_percentage}%
                              </td>
                              <td className="listing-table-column">
                                ${bill.discount_value}
                              </td>
                              <td className="listing-table-column">
                                ${bill.discount_amount ? bill.discount_amount : "0"}
                              </td>
                              <td className="listing-table-column">
                                ${bill.net_amount ? bill.net_amount : "0"}
                              </td>
                              <td className="listing-table-column">
                                ${bill.amount_received ? bill.amount_received : "0"}
                              </td>
                            </tr>
                            <tr className={headerColumnClass}>
                              <th colSpan={9} className="listing-table-column"></th>
                              <th className="listing-table-column"><strong>Type</strong></th>
                              <th className="listing-table-column"><strong>Label</strong></th>
                              <th className="listing-table-column"><strong>Amount Due</strong></th>
                              <th className="listing-table-column"><strong>Payment Received</strong></th>
                            </tr>
                            {
                              bill.payments.map((payment) => {
                                return (
                                  <tr className={bill.amount_due !== bill.amount_received ? 'listing-table-rows remaining-amount-row' : 'listing-table-rows'}>
                                    <td className="listing-table-column" colSpan={9} ></td>
                                    <td className="listing-table-column">{payment.type}</td>
                                    <td className="listing-table-column">{payment.type_label}</td>
                                    <td className="listing-table-column">${payment.amount_due}</td>
                                    <td className="listing-table-column">${payment.amount_received}</td>
                                  </tr>
                                );
                              })
                            }
                          </>
                        )
                      })}
                      <tr className={selectedInvoice.amount_received !== selectedInvoice.net_amount ? 'remaining-amount-row' : ''}>
                        <td className="listing-table-column">
                          <h6><strong>Grand Total</strong></h6>
                        </td>
                        <td className="listing-table-column" colSpan={6} ></td>
                        <td className="listing-table-column">
                          <p><strong>Total Amount</strong></p>
                          ${selectedInvoice.total_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Discount Amount</strong></p>
                          ${selectedInvoice.discount_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Net Amount</strong></p>
                          ${selectedInvoice.net_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Amount Assigned</strong></p>
                          ${selectedInvoice.assigned_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Remaining Amount</strong></p>
                          ${selectedInvoice.remaining_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Payment Received</strong></p>
                          ${selectedInvoice.amount_received}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </React.Fragment>
          </ModalBody>
          <ModalFooter
            style={{
              backgroundColor: "#1b252e",
            }}
          >
            <Button color="secondary" onClick={() => setModal(!isOpen)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div >
    );
  }
}

export default withRouter(ViewInvoicePopup);
