import { LOGS } from '../constants/actions';

const initialState = {
    logs: [],
    totalLogs: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case LOGS.FETCH_LOGS_SUCCESS:
            return {
                ...state,
                logs: action.data.data,
                totalLogs: action.data.records,
            };
        default:
            return state;
    }
}