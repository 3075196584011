import React from "react";
import PropTypes from "prop-types";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import Loader from "./Loader";
import { authorizationService } from "../../services/authorization";
import { utilityService } from "../../services/utility";
import TablePagination from "../organisms/TablePagination";

export default class FacilityListingTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
  };

  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column, index) => {
      if (index === 1 || index === 2) {
        return (
          <td
            style={{
              textAlign: "left",
            }}
            className="listing-table-head-column"
          >
            {column}
          </td>
        );
      } else {
        return <th className="listing-table-head-column">{column}</th>;
      }
    });

    const actionElement = (
      <th className="listing-table-head-column">{"Action"}</th>
    );

    header.splice(headColumn.length + 1, 0, actionElement);

    return header;
  };

  edit = (item) => {
    if (!authorizationService.isUpdateAccess())
      return;
    this.props.edit(item);
  }

  delete = (item) => {
    if (!authorizationService.isDeleteAccess())
      return;

    utilityService.showConfirmDialog().then(data => {
      this.props.delete(item);
    })
  };

  renderList = () => {
    const { listing = [] } = this.props;
    return (
      listing.length > 0 &&
      listing.map((listRow) => {
        const item = listRow.map((listItem, index) => {
          if (index === 1 || index === 2) {
            return (
              <td
                onClick={() => {
                  this.edit(listRow[listRow.length - 1]);
                }}
                className="listing-table-column"
              >
                {listItem}
              </td>
            );
          } else if (index < listRow.length - 1) {
            return <td onClick={() => {
              this.edit(listRow[listRow.length - 1]);
            }}
              className="listing-table-column">{listItem}</td>;
          } else {
            return (
              <td className="listing-table-column">
                {authorizationService.isUpdateAccess() && (
                  <img
                    src={editIcon}
                    onClick={() => {
                      this.edit(listItem);
                    }}
                    alt="edit"
                  />
                )}
                {authorizationService.isDeleteAccess() && (
                  <img
                    src={deleteIcon}
                    onClick={() => {
                      this.delete(listItem);
                    }}
                    alt="delete"
                  />
                )}
              </td>
            );
          }
        });

        return <tr style={{ 'cursor': 'pointer' }} className="listing-table-rows">{item}</tr>;
      })
    );
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive">
            <table className="listing-table">
              <thead className="listing-table-head">
                <tr>{this.renderHeadColumn()}</tr>
              </thead>
              {this.props.isLoading && <Loader width="178%" />}
              {!this.props.isLoading && <tbody>{this.renderList()}</tbody>}
            </table>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}
