import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "../../../sass/activityfilters.scss";
import {
  APP_ROUTES,
} from "../../constants/common";

class DivisionFilters extends React.Component {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cancel = "";
  }

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_DIVISION);

  resetFilter = () => {
  };

  render() {
    return (
      <>
        <div className="page-title">
          <div className="title_left">
            <h3
              style={{
                fontFamily:
                  "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "1.471",
              }}
            >
              Division List
            </h3>
          </div>
        </div>

        <div className="custom-class">
          <div className="filters-column">
            <label>Filter</label>
            <input
              type="text"
              className="code-input"
              onChange={(event) => {
                this.props.setFilter(event.target.value);
              }}
            />
          </div>
          <div className="filter-footer">
            <button
              type="submit"
              onClick={this.resetFilter}
              className="filter-footer-button"
            >
              Refresh
            </button>
            <button
              type="submit"
              onClick={this.props.search}
              className="filter-footer-button"
            >
              Search
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DivisionFilters);
