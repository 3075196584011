import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { utilityService } from "../../services/utility";

class CustomSelect extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        type: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        hideLabel: PropTypes.bool,
        isDisabled: PropTypes.bool,
        className: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired
    };

    typeProperties = [];
    state = {
        options: []
    }

    constructor(props) {
        super(props);
        this.cancel = "";

        if (this.props.type === 'Gender') {
            this.state.options = utilityService.dropDownOptionsSex();
        } else if (this.props.type === 'Event Type') {
            this.state.options = utilityService.dropDownEventType();
        } else if (this.props.type === 'Claim Status') {
            this.state.options = utilityService.dropDownOptionsClaimStatus();
        } else if (this.props.type === 'Place Of Service') {
            this.state.options = utilityService.dropDownOptionsPlaceOfService();
        } else if (this.props.type === 'Patient Listing Option') {
            this.state.options = utilityService.dropDownOptionsPatientListingOptions();
        } else if (this.props.type === 'Facility Listing Option') {
            this.state.options = utilityService.dropDownOptionsFacilityListingOptions();
        } else if (this.props.type === 'Surgeon Listing Option') {
            this.state.options = utilityService.dropDownOptionsSurgeonListingOptions();
        } else if (this.props.type === 'Prefrence Card Listing Option') {
            this.state.options = utilityService.dropDownOptionsPrefrenceCardListingOptions();
        } else if (this.props.type === 'File Type') {
            this.state.options = utilityService.dropDownOptionsFileTypes();
        } else if (this.props.type === 'Patient Condition Related To') {
            this.state.options = utilityService.dropDownOptionsPatientConditionRelatedto();
        } else if (this.props.type === 'Degree') {
            this.state.options = utilityService.dropDownOptionsDegrees();
        } else if (this.props.type === 'Bill Status') {
            this.state.options = utilityService.dropDownOptionsBillStatus();
        } else if (this.props.type === 'Coding Status') {
            this.state.options = utilityService.dropDownOptionsCodingStatus();
        } else if (this.props.type === 'Insurance Type') {
            this.state.options = utilityService.dropDownOptionsCodingStatus();
        } else if (this.props.type === 'Status') {
            this.state.options = utilityService.dropDownOptionsActiveInActive();
        } else if (this.props.type === 'Illness Indicator') {
            this.state.options = utilityService.dropDownOptionsIllnessIndicator();
        } else if (this.props.type === 'Degree Status') {
            this.state.options = utilityService.dropDownOptionsDegreeStatus();
        } else if (this.props.type === 'Contact Type') {
            this.state.options = utilityService.dropDownOptionsContactType();
        } else if (this.props.type === 'Hospital Contract') {
            this.state.options = utilityService.dropDownOptionsHospitalContract();
        } else if (this.props.type === 'EMR Access') {
            this.state.options = utilityService.dropDownOptionsEMRAccess();
        } else if (this.props.type === 'Facility Type') {
            this.state.options = utilityService.dropDownOptionsFacilityType();
        } else if (this.props.type === 'Active InActive Text') {
            this.state.options = utilityService.dropDownOptionsActiveInActiveText();
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                {!this.props.hideLabel &&
                    <label>
                        {(!this.props.label || this.props.label === '') ? this.props.type : this.props.label}
                        {this.props.isRequired &&
                            <span className="required"> *</span>
                        }
                    </label>
                }
                <select
                    className={this.props.className}
                    onChange={event => this.props.onChange(event)}
                    value={this.props.value}>
                    <option value="">Select {(!this.props.label || this.props.label === '') ? this.props.type : this.props.label}</option>
                    {this.state.options.map(tPlace => {
                        return (
                            <option value={tPlace.value}>{tPlace.label}</option>
                        )
                    })}
                </select>
            </>
        )
    }
}


export default withRouter(CustomSelect);
