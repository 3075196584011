import Swal from "sweetalert2";

function base64toBlob(base64Data, contentType) {
    const byteArray = Buffer.from(base64Data, "base64");
    return new Blob([byteArray])
}

function formatFrontEndDate(str) {
    let dt = new Date(str);

    let year = dt.getFullYear();
    let month = dt.getMonth() + 1;
    if (month <= 9)
        month = "0" + month;
    let day = dt.getDate();
    if (day <= 9)
        day = "0" + day;

    return year + "-" + month + "-" + day;
}

function formatValueInCurrency(number) {
    let tCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return tCurrency.format(number);
}

function formatPhone(str) {
    if (str) {
        let phone = "";
        str = str.replace(/\D/g, "").substring(0, 10);
        if (str.length < 3) {
            phone += str;
        } else if (str.length < 6) {
            phone += str.substring(0, 3) + "-";
            if (str.length > 3) {
                phone += str.substring(3, str.length);
            }
        } else {
            phone +=
                str.substring(0, 3) +
                "-" +
                str.substring(3, 6) +
                "-" +
                str.substring(6, 10);
        }
        return phone;
    }
    return str;
};

function showHTMLMessage(title, message, icon = 'success') {
    Swal.fire({
        title: title,
        html: message,
        icon: icon,
        toast: true,
        position: 'top-end',
        timer: 10000,
        showConfirmButton: false,
        timerProgressBar: true,
        showCloseButton: true,
    })
}

function showConfirmDialog(title = 'Are you sure?',
    text = "You won't be able to revert this!",
    icon = "error",
    showCancelButton = true,
    confirmButtonColor = '#3085d6',
    cancelButtonColor = '#d33',
    confirmButtonText = 'Yes, delete it!'
) {
    return new Promise(function (accept, reject) {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: showCancelButton,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: confirmButtonText,
        }).then((result) => {
            if (result.isConfirmed) {
                accept();
            }
            else {
                reject();
            }
        });
    });
}

function dropDownEventType() {
    return [
        { label: 'Past', value: 'past' },
        { label: 'Future', value: 'future' },
        { label: 'Month', value: 'month' },
    ]
}

function dropDownOptionsSex() {
    return [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
    ]
}

function dropDownOptionsClaimStatus() {
    return [
        { label: 'NOT STARTED', value: 'not started' },
        { label: 'SUBMITTED', value: 'submitted' },
        { label: 'CORRECTED CLAIM', value: 'corrected claim' },
        { label: 'PAPER CLAIM', value: 'paper claim' },
        { label: 'RECONSIDERATION', value: 'reconsideration' },
        { label: 'FOLLOW-UP', value: 'followup' },
        { label: 'APPEAL 1', value: 'appeal 1' },
        { label: 'APPEAL 2', value: 'appeal 2' },
        { label: 'HOLD TO BILL', value: 'hold to bill' },
        { label: 'BILL PATIENT', value: 'bill patient' },
        { label: 'BILL HOSPITAL', value: 'bill hospital' },
        { label: 'BILL PHYSICIAN', value: 'bill physician' },
        { label: 'REVIEW ADJUSTMENT', value: 'review adjustment' },
        { label: 'CLOSED', value: 'closed' },
        { label: 'CLOSED NON-BILL', value: 'closed non-bill' },
        { label: 'CLOSED ADJUSTMENT', value: 'closed adjustment' },
        { label: 'CLOSED HC-2nd Scrub', value: 'closed hc-2nd scrub' },
    ]
}

function dropDownOptionsPlaceOfService() {
    return [
        { label: 'No Default POS', value: 'nopos' },
        { label: '11 Office', value: '11' },
        { label: '21 Inpatient Hospital', value: '21' },
        { label: '22 Outpatient Hospital', value: '22' },
        { label: '23 Emergency Room - Hospital', value: '23' },
        { label: '24 Ambulatory Surgical Center', value: '24' }
    ]
}

function dropDownOptionsPatientListingOptions() {
    return [
        { label: 'All Patients', value: 'All Patients' },
        { label: 'Assigned Patients', value: 'Assigned Patients' },
    ]
}

function dropDownOptionsFacilityListingOptions() {
    return [
        { label: 'All Facilities', value: 'All Facilities' },
        { label: 'Assigned Facilities', value: 'Assigned Facilities' },
    ]
}

function dropDownOptionsSurgeonListingOptions() {
    return [
        { label: 'All Surgeons', value: 'All Surgeons' },
        { label: 'Assigned Surgeons', value: 'Assigned Surgeons' },
    ]
}

function dropDownOptionsPrefrenceCardListingOptions() {
    return [
        { label: 'All Prefrence Cards', value: 'All Prefrence Cards' },
        { label: 'Assigned Prefrence Cards', value: 'Assigned Surgeons Prefrence Cards' },
    ]
}

function dropDownOptionsFileTypes() {
    return [
        { label: 'Appeals', value: 'appeal' },
        { label: 'Authorization', value: 'authorization' },
        { label: 'EOB', value: 'eob' },
        { label: 'Facesheet', value: 'facesheet' },
        { label: 'OP Report', value: 'op' },
        { label: 'PN', value: 'pn' },
        { label: 'Check', value: 'check' },
        { label: 'Claim', value: 'claim' },
        { label: 'Correspondence', value: 'correspondence' },
    ]
}

function dropDownOptionsPatientConditionRelatedto() {
    return [
        { label: 'Auto Accident', value: 'accident' },
        { label: 'Employment', value: 'employment' },
        { label: 'Other Reason', value: 'other' },
    ]
}

function dropDownOptionsDegrees() {
    return [
        { label: 'Associate Degree', value: 'Associate Degree' },
        { label: 'Board Certification', value: 'Board Certification' },
        { label: 'Professional License', value: 'Professional License' },
    ]
}

function dropDownOptionsBillStatus() {
    return [
        { label: 'Billable', value: 'billable' },
        { label: 'Non Billable', value: 'non billable' },
        { label: 'Hospital Contract', value: 'hospital contract' },
        { label: 'Hospital Contract Second Scrub', value: 'hospital contract second scrub' },
        { label: 'Bill to Patient', value: 'bill to patient' },
        { label: 'Bill to Physician', value: 'bill to physician' },
    ]
}

function dropDownOptionsCodingStatus() {
    return [
        { label: 'NOT STARTED', value: 'not started' },
        { label: '3M', value: '3m' },
        { label: 'READY TO BILL', value: 'ready to bill' },
        { label: 'OP Request', value: 'op request' },
        { label: 'IN HOUSE CODING', value: 'in house coding' },
    ]
}

function dropDownOptionsInsurance() {
    return [
        { label: 'Primary', value: 'primary' },
        { label: 'Secondary', value: 'secondary' },
        { label: 'Tertiary', value: 'tertiary' },
    ]
}

function dropDownOptionsActiveInActive() {
    return [
        { label: 'Active', value: '1' },
        { label: 'In-Active', value: '0' },
    ]
}

function dropDownOptionsYesNo() {
    return [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ]
}

function dropDownOptionsIllnessIndicator() {
    return [
        { label: 'Illness', value: 'illness' },
        { label: 'Indicator', value: 'indicator' },
        { label: 'Injury', value: 'injury' },
    ]
}

function dropDownOptionsDegreeStatus() {
    return [
        { label: 'Not Verified', value: 'Not Verified' },
        { label: 'Verification In Progress', value: 'Verification In Progress' },
        { label: 'Verified', value: 'Verified' },
    ]
}

function dropDownOptionsContactType() {
    return [
        { label: 'Phone', value: 'Phone' },
        { label: 'Fax', value: 'Fax' },
        { label: 'Email', value: 'Email' },
    ]
}

function dropDownOptionsHospitalContract() {
    return [
        { label: 'Non Contracted', value: 'non-contracted' },
        { label: 'Billed Hospital', value: 'billed hospital' },
        { label: 'Payment Recived', value: 'payment received' },
    ]
}

function dropDownOptionsEMRAccess() {
    return [
        { label: 'NO EMR ACCESS', value: 'noemr' },
        { label: 'EMR ACCESS', value: 'emr' },
        { label: 'NO EMR ACCESS FAX REQ', value: 'noemrfax' },
        { label: 'NO ACCESS', value: 'noaccess' },
        { label: 'HL7 DATA FEED', value: 'hl7data' },
    ]
}

function dropDownOptionsFacilityType() {
    return [
        { label: 'Assisted Living', value: 'assistedliving' },
        { label: 'Home Health', value: 'homehealth' },
        { label: 'Hospice', value: 'hospice' },
        { label: 'Hospital', value: 'hospital' },
        { label: 'Nursing Home', value: 'nursinghome' },
        { label: 'Office', value: 'office' },
    ]
}

function dropDownOptionsActiveInActiveText() {
    return [
        { label: 'All', value: ' ' },
        { label: 'Active', value: 'Active' },
        { label: 'In-Active', value: 'In-Active' },
    ]
}

export const utilityService = {
    base64toBlob,
    formatPhone,
    showConfirmDialog,
    showHTMLMessage,
    dropDownOptionsPlaceOfService,
    dropDownOptionsPatientListingOptions,
    dropDownOptionsFacilityListingOptions,
    dropDownOptionsSurgeonListingOptions,
    dropDownOptionsPrefrenceCardListingOptions,
    formatFrontEndDate,
    formatValueInCurrency,
    dropDownOptionsClaimStatus,
    dropDownOptionsSex,
    dropDownEventType,
    dropDownOptionsFileTypes,
    dropDownOptionsPatientConditionRelatedto,
    dropDownOptionsDegrees,
    dropDownOptionsBillStatus,
    dropDownOptionsCodingStatus,
    dropDownOptionsInsurance,
    dropDownOptionsActiveInActive,
    dropDownOptionsIllnessIndicator,
    dropDownOptionsDegreeStatus,
    dropDownOptionsContactType,
    dropDownOptionsHospitalContract,
    dropDownOptionsYesNo,
    dropDownOptionsEMRAccess,
    dropDownOptionsFacilityType,
    dropDownOptionsActiveInActiveText
}