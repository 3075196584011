import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import DatePicker from "react-datepicker";
import viewIcon from "../../../assets/images/view.png";
import deleteIcon from "../../../assets/images/delete.png";
import LoadingOverlay from "react-loading-overlay";

import { createPatient, updatePatient } from "../../actions/patients";

import "../../../sass/managepatients.scss";
import { insuranceService } from "../../services/insurance";
import { createNotification } from "../../utils/notificationManager";
import { patientService } from "../../services/patients";
import { formatDate } from "../../utils/date";
import editIcon from "../../../assets/images/edit.png";
import { activityService } from "../../services/activities";
import loaderImage from "../../../assets/images/CoureMax.gif";
import { FileStorageService } from "../../services/fileStorage";
import StateSelection from "../organisms/StateSelection";
import CitySelection from "../organisms/CitySelection";
import { utilityService } from "../../services/utility";
import ZipCode from "../organisms/ZipCode";
import CustomDropDown from "../organisms/CustomDropDown";
import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";

const validationGroup = {
  message: "There are some validation errors:",
  fields: {
    'firstName': { filedName: 'First Name', required: true, minLength: 3, maxLength: 200 },
    'lastName': { filedName: 'Last Name', required: true, minLength: 3, maxLength: 200 },
    'dob': { filedName: 'Date Of Birth', required: true },
    'phoneNumber': { filedName: 'Phone Number', required: true },
    'cellPhone': { filedName: 'Cell Phone', required: true },
    'account': { filedName: 'Account Number', required: true },
    'gender': { filedName: 'Gender', required: true },
    'ssn': { filedName: 'Social Security Number', required: true },
  }
}

class ManagePatient extends React.Component {
  static propTypes = {
    createPatient: PropTypes.func.isRequired,
    updatePatient: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    errors: [],
    activities: [],
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    account: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
    additionalNumber: "",
    cellPhone: "",
    ssn: "",
    fax: "",
    email: "",
    gender: "",
    country: "usa",
    notes: "",
    toggleFirstInsurance: false,
    billable: "",
    primaryPayerId: "",
    primaryMasterPayerId: "",
    primaryInsurance: {},
    primaryTypeCode: "",
    primaryPhone: "",
    primaryFax: "",
    primaryAddress: "",
    primaryAdditionalInformation: "",
    primarySubscriberFirstName: "",
    primarySubscriberLastName: "",
    primaryMemberNumber: "",
    primaryGroupNumber: "",
    primaryDob: "",
    toggleGuarantor: false,
    guarantorId: "",
    guarantorFirstName: "",
    guarantorLastName: "",
    guarantorAddress1: "",
    guarantorAddress2: "",
    guarantorZip: "",
    guarantorCity: "",
    guarantorState: "",
    guarantorSpouseName: "",
    guarantorSpousePhone: "",
    guarantorRelationship: "",
    toggleSecondaryInsurance: false,
    secondaryInsurance: {},
    secondaryPayerId: "",
    secondaryMasterPayerId: "",
    secondaryTypeCode: "",
    secondaryPhone: "",
    secondaryFax: "",
    secondaryAddress: "",
    secondaryAdditionalInformation: "",
    secondarySubscriberFirstName: "",
    secondarySubscriberLastName: "",
    secondaryMemberNumber: "",
    secondaryGroupNumber: "",
    secondaryDob: "",
    toggleTertiaryInsurance: false,
    tertiaryInsurance: {},
    tertiaryPayerId: "",
    tertiaryMasterPayerId: "",
    tertiaryTypeCode: "",
    tertiaryPhone: "",
    tertiaryFax: "",
    tertiaryAddress: "",
    tertiaryAdditionalInformation: "",
    tertiarySubscriberFirstName: "",
    tertiarySubscriberLastName: "",
    tertiaryMemberNumber: "",
    tertiaryGroupNumber: "",
    tertiaryDob: "",
    toggleWorker: false,
    workerPatientCondition: "",
    employerName: "",
    dateOfInjury: "",
    illnessIndicator: "",
  };

  componentDidMount() {
    // const patientId = this.props.location.state
    //   ? this.props.location.state.patientId
    //   : "";
    let patientId;
    if (this.props.location.state) {
      patientId = this.props.location.state.patientId;
    } else {
      patientId = "";
    }
    if (this.props.patientId) {
      patientId = this.props.patientId;
    }

    if (patientId) {
      this.setLoading(true);
      patientService.getPatientDetail(patientId).then((response) => {
        this.setState(
          {
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            middleName: response.data.middle_name,
            dob: Date.parse(formatDate(response.data.date_of_birth)),
            account: response.data.account_number,
            addressLine1: response.data.address1,
            addressLine2: response.data.address2,
            state: response.data.state,
            city: response.data.city,
            zipCode: response.data.zip,
            phoneNumber: utilityService.formatPhone(response.data.phone_number),
            additionalNumber: utilityService.formatPhone(response.data.additional_number),
            cellPhone: response.data.cell_number,
            ssn: response.data.ssn,
            fax: response.data.fax,
            email: response.data.email,
            gender: response.data.gender,
            country: response.data.country,
            notes: response.data.notes,
            primaryInsurance: {
              label:
                response.data.patient_insurance_data &&
                  response.data.patient_insurance_data.primary
                  ? `${response.data.patient_insurance_data.primary.description} (${response.data.patient_insurance_data.primary.address1})`
                  : "",
              value:
                response.data.patient_insurance_data &&
                  response.data.patient_insurance_data.primary
                  ? parseInt(response.data.patient_insurance_data.primary.id)
                  : "",
            },
            primarySubscriberFirstName:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.primary_subscriber
                ? response.data.patient_insurance_data.primary_subscriber
                  .subscriber_first_name
                : "",
            primarySubscriberLastName:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.primary_subscriber
                ? response.data.patient_insurance_data.primary_subscriber
                  .subscriber_last_name
                : "",
            primaryMemberNumber:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.primary_subscriber
                ? response.data.patient_insurance_data.primary_subscriber
                  .subscriber_member_number
                : "",
            primaryGroupNumber:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.primary_subscriber
                ? response.data.patient_insurance_data.primary_subscriber
                  .subscriber_group_number
                : "",
            primaryDob:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.primary_subscriber
                ? Date.parse(
                  response.data.patient_insurance_data.primary_subscriber
                    .subscriber_dob
                )
                : "",
            guarantorId: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.id
              : "",
            guarantorFirstName: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_first_name
              : "",
            guarantorLastName: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_last_name
              : "",
            guarantorAddress1: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_address_1
              : "",
            guarantorAddress2: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_address_2
              : "",
            guarantorZip: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_zip
              : "",
            guarantorCity: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_city
              : "",
            guarantorState: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_state
              : "",
            guarantorSpouseName: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_spouse_name
              : "",
            guarantorSpousePhone: utilityService.formatPhone(
              response.data.patient_guarantor_data
                ? response.data.patient_guarantor_data.guarantor_spouse_phone
                : ""
            ),
            guarantorRelationship: response.data.patient_guarantor_data
              ? response.data.patient_guarantor_data.guarantor_relationship
              : "",
            secondaryInsurance: {
              label:
                response.data.patient_insurance_data &&
                  response.data.patient_insurance_data.secondary
                  ? `${response.data.patient_insurance_data.secondary.description} (${response.data.patient_insurance_data.secondary.address1})`
                  : "",
              value:
                response.data.patient_insurance_data &&
                  response.data.patient_insurance_data.secondary
                  ? parseInt(response.data.patient_insurance_data.secondary.id)
                  : "",
            },
            secondarySubscriberFirstName:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.secondary_subscriber
                ? response.data.patient_insurance_data.secondary_subscriber
                  .subscriber_first_name
                : "",
            secondarySubscriberLastName:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.secondary_subscriber
                ? response.data.patient_insurance_data.secondary_subscriber
                  .subscriber_last_name
                : "",
            secondaryMemberNumber:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.secondary_subscriber
                ? response.data.patient_insurance_data.secondary_subscriber
                  .subscriber_member_number
                : "",
            secondaryGroupNumber:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.secondary_subscriber
                ? response.data.patient_insurance_data.secondary_subscriber
                  .subscriber_group_number
                : "",
            secondaryDob:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.secondary_subscriber
                ? Date.parse(
                  response.data.patient_insurance_data.secondary_subscriber
                    .subscriber_dob
                )
                : "",
            tertiaryInsurance: {
              label:
                response.data.patient_insurance_data &&
                  response.data.patient_insurance_data.tertiary
                  ? `${response.data.patient_insurance_data.tertiary.description} (${response.data.patient_insurance_data.tertiary.address1})`
                  : "",
              value:
                response.data.patient_insurance_data &&
                  response.data.patient_insurance_data.tertiary
                  ? parseInt(response.data.patient_insurance_data.tertiary.id)
                  : "",
            },
            tertiarySubscriberFirstName:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.tertiary_subscriber
                ? response.data.patient_insurance_data.tertiary_subscriber
                  .subscriber_first_name
                : "",
            tertiarySubscriberLastName:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.tertiary_subscriber
                ? response.data.patient_insurance_data.tertiary_subscriber
                  .subscriber_last_name
                : "",
            tertiaryMemberNumber:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.tertiary_subscriber
                ? response.data.patient_insurance_data.tertiary_subscriber
                  .subscriber_member_number
                : "",
            tertiaryGroupNumber:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.tertiary_subscriber
                ? response.data.patient_insurance_data.tertiary_subscriber
                  .subscriber_group_number
                : "",
            tertiaryDob:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.tertiary_subscriber
                ? Date.parse(
                  response.data.patient_insurance_data.tertiary_subscriber
                    .subscriber_dob
                )
                : "",
            workerPatientCondition:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.workers_comp
                ? response.data.patient_insurance_data.workers_comp
                  .patient_condition
                : "",
            employerName:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.workers_comp
                ? response.data.patient_insurance_data.workers_comp
                  .employer_name
                : "",
            dateOfInjury:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.workers_comp
                ? response.data.patient_insurance_data.workers_comp
                  .date_of_injury
                : "",
            illnessIndicator:
              response.data.patient_insurance_data &&
                response.data.patient_insurance_data.workers_comp
                ? response.data.patient_insurance_data.workers_comp
                  .illness_indicator
                : "",
          },
          () => {
            this.loadPrimaryInsuranceDetails();
            this.loadSecondaryInsuranceDetails();
            this.loadTertiaryInsuranceDetails();
            this.loadActivities();
            this.setLoading(false);
          }
        );
      });
    }
  }

  loadActivities = () => {
    // const patientId = this.props.location.state
    //   ? this.props.location.state.patientId
    //   : "";
    let patientId;
    if (this.props.location.state) {
      patientId = this.props.location.state.patientId;
    } else {
      patientId = "";
    }
    if (this.props.patientId) {
      patientId = this.props.patientId;
    }
    // alert(patientId);
    patientService.getPatientActivity(patientId).then((response) => {
      const activitiesList = response.data.map((activity) => {
        const faceSheetFile =
          activity.facesheets.length > 0
            ? activity.facesheets[0].facesheet_file
            : "";
        return [
          activity.old_event_id,
          faceSheetFile,
          activity.facesheets.length > 0 ? activity.facesheets[0].type : "",
          activity.event_start_date
            ? formatDate(activity.event_start_date)
            : "",
          activity.event_end_date && activity.event_end_date !== "0000-00-00"
            ? formatDate(activity.event_end_date)
            : "",
          activity.facesheets.length > 0 ? activity.facesheets[0].id : "",
        ];
      });
      this.setState({ activities: activitiesList });
    });
  };

  submitPatient = () => {
    if (!this.validateActivity()) {
      return;
    }

    const { createPatient, updatePatient } = this.props;
    const {
      firstName,
      lastName,
      middleName,
      dob,
      account,
      addressLine1,
      addressLine2,
      state,
      city,
      zipCode,
      phoneNumber,
      additionalNumber,
      fax,
      email,
      gender,
      country,
      notes,
      primaryInsurance,
      primarySubscriberFirstName,
      primarySubscriberLastName,
      primaryMemberNumber,
      primaryGroupNumber,
      primaryDob,
      guarantorId,
      guarantorFirstName,
      guarantorLastName,
      guarantorAddress1,
      guarantorAddress2,
      guarantorZip,
      guarantorCity,
      guarantorState,
      guarantorSpouseName,
      guarantorSpousePhone,
      guarantorRelationship,
      secondaryInsurance,
      secondarySubscriberFirstName,
      secondarySubscriberLastName,
      secondaryMemberNumber,
      secondaryGroupNumber,
      secondaryDob,
      tertiaryInsurance,
      tertiarySubscriberFirstName,
      tertiarySubscriberLastName,
      tertiaryMemberNumber,
      tertiaryGroupNumber,
      tertiaryDob,
      workerPatientCondition,
      employerName,
      dateOfInjury,
      illnessIndicator,
      cellPhone,
      ssn
    } = this.state;

    const request = {
      cell_number: cellPhone,
      ssn: ssn,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      date_of_birth: formatDate(dob),
      account_number: account,
      email: email,
      gender: gender,
      phone_number: phoneNumber,
      fax,
      state,
      city,
      country,
      zip: zipCode,
      notes,
      address1: addressLine1,
      address2: addressLine2,
      additional_number: additionalNumber,
      responsible_first_name: guarantorFirstName,
      responsible_last_name: guarantorLastName,
      responsible_phone: guarantorSpousePhone,
      primary_insurance_id: primaryInsurance.value,
      primary: {
        subscriber_first_name: primarySubscriberFirstName,
        subscriber_last_name: primarySubscriberLastName,
        subscriber_dob: formatDate(primaryDob),
        subscriber_member_number: primaryMemberNumber,
        subscriber_group_number: primaryGroupNumber,
      },
      secondary_insurance_id: secondaryInsurance.value,
      secondary: {
        subscriber_first_name: secondarySubscriberFirstName,
        subscriber_last_name: secondarySubscriberLastName,
        subscriber_dob: formatDate(secondaryDob),
        subscriber_member_number: secondaryMemberNumber,
        subscriber_group_number: secondaryGroupNumber,
      },
      tertiary_insurance_id: tertiaryInsurance.value,
      tertiary: {
        subscriber_first_name: tertiarySubscriberFirstName,
        subscriber_last_name: tertiarySubscriberLastName,
        subscriber_dob: formatDate(tertiaryDob),
        subscriber_member_number: tertiaryMemberNumber,
        subscriber_group_number: tertiaryGroupNumber,
      },
      guarantor: {
        guarantor_id: guarantorId,
        guarantor_first_name: guarantorFirstName,
        guarantor_last_name: guarantorLastName,
        guarantor_address1: guarantorAddress1,
        guarantor_address2: guarantorAddress2,
        guarantor_zip: guarantorZip,
        guarantor_city: guarantorCity,
        guarantor_state: guarantorState,
        guarantor_relatioship: guarantorRelationship,
        guarantor_spouse: guarantorSpouseName,
        guarantor_phone: guarantorSpousePhone,
      },
      worker_comp: {
        patient_condition: workerPatientCondition,
        employer_name: employerName,
        date_of_injury: formatDate(dateOfInjury),
        illness_indicator: illnessIndicator,
      },
    };

    // const patientId = this.props.location.state
    //   ? this.props.location.state.patientId
    //   : "";
    let patientId;
    if (this.props.location.state) {
      patientId = this.props.location.state.patientId;
    } else {
      patientId = "";
    }
    if (this.props.patientId) {
      patientId = this.props.patientId;
    }
    this.setLoading(true);
    if (patientId) {
      const payload = { ...request, id: patientId };
      updatePatient(payload)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              if (this.props.PopupRequest === undefined) {
                this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
              }
            }
          }
          this.setLoading(false);
        })
        .finally(() => {
          this.setLoading(false);
        });
    } else {
      createPatient(request)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
            }
          }
          this.setLoading(false);
        })
        .finally(() => {
          this.setLoading(false);
        });
    }
  };

  toggleFirstInsurance = () => {
    this.setState((prevState) => ({
      toggleFirstInsurance: !prevState.toggleFirstInsurance,
    }));
  };

  toggleGuarantorInsurance = () => {
    this.setState((prevState) => ({
      toggleGuarantor: !prevState.toggleGuarantor,
    }));
  };

  toggleSecondaryInsurance = () => {
    this.setState((prevState) => ({
      toggleSecondaryInsurance: !prevState.toggleSecondaryInsurance,
    }));
  };

  toggleTertiaryInsurance = () => {
    this.setState((prevState) => ({
      toggleTertiaryInsurance: !prevState.toggleTertiaryInsurance,
    }));
  };

  toggleWorkerInsurance = () => {
    this.setState((prevState) => ({
      toggleWorker: !prevState.toggleWorker,
    }));
  };

  handlePhoneChange = (str) => {
    this.setState({ phoneNumber: utilityService.formatPhone(str) });
  };

  handlePrimaryPhoneChange = (str) => {
    this.setState({ primaryPhone: utilityService.formatPhone(str) });
  };

  handleGuarantorPhoneChange = (str) => {
    this.setState({ guarantorSpousePhone: utilityService.formatPhone(str) });
  };

  handleSecondaryPhoneChange = (str) => {
    this.setState({ secondaryPhone: utilityService.formatPhone(str) });
  };

  handleTertiaryPhoneChange = (str) => {
    this.setState({ tertiaryPhone: utilityService.formatPhone(str) });
  };

  validateActivity = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  primarySubscriber = () => {
    this.setState({
      primarySubscriberFirstName: this.state.firstName,
      primarySubscriberLastName: this.state.lastName,
      primaryDob: this.state.dob,
    });
  };

  secondarySubscriber = () => {
    this.setState({
      secondarySubscriberFirstName: this.state.firstName,
      secondarySubscriberLastName: this.state.lastName,
      secondaryDob: this.state.dob,
    });
  };

  tertiarySubscriber = () => {
    this.setState({
      tertiarySubscriberFirstName: this.state.firstName,
      tertiarySubscriberLastName: this.state.lastName,
      tertiaryDob: this.state.dob,
    });
  };

  guarantorDetails = () => {
    this.setState({
      guarantorFirstName: this.state.firstName,
      guarantorLastName: this.state.lastName,
      guarantorAddress1: this.state.addressLine1,
      guarantorAddress2: this.state.addressLine2,
      guarantorZip: this.state.zipCode,
      guarantorState: this.state.state,
      guarantorCity: this.state.city,
    });
  };

  loadPrimaryInsuranceDetails = () => {
    return insuranceService
      .getInsuranceDetail(this.state.primaryInsurance.value)
      .then((response) => {
        this.setState({
          primaryPayerId: response.data.payor_code,
          primaryMasterPayerId: response.data.id,
          primaryTypeCode: response.data.insurance_type,
          primaryPhone: response.data.phone,
          primaryFax: response.data.fax,
          primaryAddress: `${response.data.address1 ? response.data.address1 : ""
            } ${response.data.address2 ? response.data.address2 : ""} ${response.data.city ? response.data.city : ""
            } ${response.data.state ? response.data.state : ""} ${response.data.zip ? response.data.zip : ""
            }`,
        });
      });
  };

  loadSecondaryInsuranceDetails = () => {
    return insuranceService
      .getInsuranceDetail(this.state.secondaryInsurance.value)
      .then((response) => {
        this.setState({
          secondaryPayerId: response.data.payor_code,
          secondaryMasterPayerId: response.data.id,
          secondaryTypeCode: response.data.insurance_type,
          secondaryPhone: response.data.phone,
          secondaryFax: response.data.fax,
          secondaryAddress: `${response.data.address1 ? response.data.address1 : ""
            } ${response.data.address2 ? response.data.address2 : ""} ${response.data.city ? response.data.city : ""
            } ${response.data.state ? response.data.state : ""} ${response.data.zip ? response.data.zip : ""
            }`,
        });
      });
  };

  loadTertiaryInsuranceDetails = () => {
    return insuranceService
      .getInsuranceDetail(this.state.tertiaryInsurance.value)
      .then((response) => {
        this.setState({
          tertiaryPayerId: response.data.payor_code,
          tertiaryMasterPayerId: response.data.id,
          tertiaryTypeCode: response.data.insurance_type,
          tertiaryPhone: response.data.phone,
          tertiaryFax: response.data.fax,
          tertiaryAddress: `${response.data.address1 ? response.data.address1 : ""
            } ${response.data.address2 ? response.data.address2 : ""} ${response.data.city ? response.data.city : ""
            } ${response.data.state ? response.data.state : ""} ${response.data.zip ? response.data.zip : ""
            }`,
        });
      });
  };

  resetPrimary = () => {
    this.setState({
      billable: "",
      primaryPayerId: "",
      primaryMasterPayerId: "",
      primaryInsurance: {},
      primaryTypeCode: "",
      primaryPhone: "",
      primaryFax: "",
      primaryAddress: "",
      primaryAdditionalInformation: "",
      primarySubscriberFirstName: "",
      primarySubscriberLastName: "",
      primaryMemberNumber: "",
      primaryGroupNumber: "",
      primaryDob: "",
    });
  };

  resetSecondary = () => {
    this.setState({
      secondaryInsurance: {},
      secondaryPayerId: "",
      secondaryMasterPayerId: "",
      secondaryTypeCode: "",
      secondaryPhone: "",
      secondaryFax: "",
      secondaryAddress: "",
      secondaryAdditionalInformation: "",
      secondarySubscriberFirstName: "",
      secondarySubscriberLastName: "",
      secondaryMemberNumber: "",
      secondaryGroupNumber: "",
      secondaryDob: "",
    });
  };

  resetTertiary = () => {
    this.setState({
      tertiaryInsurance: {},
      tertiaryPayerId: "",
      tertiaryMasterPayerId: "",
      tertiaryTypeCode: "",
      tertiaryPhone: "",
      tertiaryFax: "",
      tertiaryAddress: "",
      tertiaryAdditionalInformation: "",
      tertiarySubscriberFirstName: "",
      tertiarySubscriberLastName: "",
      tertiaryMemberNumber: "",
      tertiaryGroupNumber: "",
      tertiaryDob: "",
    });
  };

  renderHeadColumn = () => {
    const headColumn = [
      "Event Id",
      "File Name",
      "Type",
      "Start Date",
      "End Date",
      "Action",
    ];

    return headColumn.map((column) => (
      <th className="listing-table-head-column">{column}</th>
    ));
  };

  edit = (activityId) => {
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_ACTIVITY,
      state: {
        activityId,
      },
    });
  };

  deleteFile = (faceSheetId) => {
    utilityService.showConfirmDialog().then(data => {
      activityService
        .deleteActivityFaceSheet(faceSheetId)
        .then((response) => {
          this.loadActivities();
        });
    })
  };

  downloadFaceSheet(facesheet) {
    FileStorageService.downloadFile(facesheet).then(data => {
      console.log(data);
    })
  }

  renderActivityList = () => {
    const { activities } = this.state;
    console.log("******** ", activities);
    return activities.map((listRow) => {
      const item = listRow.map((listItem, index) => {
        if (index < listRow.length - 1) {
          let faceSheetFile, fileName;
          if (index === 1) {
            faceSheetFile = listItem.length > 0 ? listItem.split("/") : [];
            fileName =
              faceSheetFile.length > 0
                ? faceSheetFile[faceSheetFile.length - 1]
                : "";
          }
          return (
            <td className="listing-table-column">
              {`${index === 1 ? fileName : listItem}  `}
              {index === 1 && listItem && (
                // eslint-disable-next-line
                <a onClick={() => {
                  this.downloadFaceSheet(listRow[5]);
                }} href="#" rel="noreferrer">
                  <img src={viewIcon} alt="view" />
                </a>
              )}
              {index === 1 && listItem && (
                <img
                  src={deleteIcon}
                  onClick={() => {
                    this.deleteFile(listRow[listRow.length - 1]);
                  }}
                  alt="view"
                />
              )}
            </td>
          );
        } else {
          return (
            <td className="listing-table-column">
              <img
                src={editIcon}
                onClick={() => {
                  this.edit(listRow[0]);
                }}
                alt="edit"
              />
            </td>
          );
        }
      });

      return <tr className="listing-table-rows">{item}</tr>;
    });
  };

  cancel = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
    }
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  zipCodeChanged = (value) => {
    this.setState({ zip: value.zip_code })

    if (value.state) {
      this.setState({ state: value.state.code });
    }

    if (value.city) {
      this.setState({ city: value.city.name });
    }
  }

  guarantorZipCodeChanged = (value) => {
    this.setState({ guarantorZip: value.zip_code })

    if (value.state) {
      this.setState({ guarantorState: value.state.code });
    }

    if (value.city) {
      this.setState({ guarantorCity: value.city.name });
    }
  }

  render() {
    // const patientId = this.props.location.state
    //   ? this.props.location.state.patientId
    //   : "";
    let patientId;
    if (this.props.location.state) {
      patientId = this.props.location.state.patientId;
    } else {
      patientId = "";
    }
    if (this.props.patientId) {
      patientId = this.props.patientId;
    }
    return (
      <div className="main_container">
        {this.props.PopupRequest === undefined && (
          <>
            <div
              className="col-md-3 custom-sidebar-menu left_col"
              style={{
                minHeight: "190vh",
              }}
            >
              <Sidebar activePage={this.props.activePage} />
            </div>
            <TopNavbar />
          </>
        )}
        <LoadingOverlay
          active={this.state.loading}
          spinner={
            <img
              style={{
                width: "200px",
              }}
              src={loaderImage}
              alt="loading..."
            />
          }
        >
          <div
            className={
              this.props.PopupRequest === 1 ? "" : "manage-activity-right-col"
            }
          >
            <div className="activity-row">
              <div className="page-title">
                {patientId && <h2 className="heading-custom">Edit Patient</h2>}
                {!patientId && <h2 className="heading-custom">Add Patient</h2>}
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>
                  First Name {FormValidatorService.getRequiredHTML(validationGroup, 'firstName')}
                </label>
                <input
                  type="text"
                  className={FormValidatorService.getClass(validationGroup, this.state, 'firstName')}
                  value={this.state.firstName}
                  onChange={(event) => {
                    this.setState({ firstName: event.target.value });
                  }}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>
                  Last Name {FormValidatorService.getRequiredHTML(validationGroup, 'lastName')}
                </label>
                <input
                  type="text"
                  className={FormValidatorService.getClass(validationGroup, this.state, 'lastName')}
                  value={this.state.lastName}
                  onChange={(event) => {
                    this.setState({ lastName: event.target.value });
                  }}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Middle Name</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.middleName}
                  onChange={(event) =>
                    this.setState({ middleName: event.target.value })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CustomSelect type='Gender'
                  isRequired={FormValidatorService.isRequired(validationGroup, 'gender')}
                  className={FormValidatorService.getClass(validationGroup, this.state, 'gender')}
                  value={this.state.gender}
                  onChange={(event) =>
                    this.setState({ gender: event.target.value })
                  }>
                </CustomSelect>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>
                  Phone Number (Work) {FormValidatorService.getRequiredHTML(validationGroup, 'phoneNumber')}
                </label>
                <input
                  type="text"
                  className={FormValidatorService.getClass(validationGroup, this.state, 'phoneNumber')}
                  value={this.state.phoneNumber}
                  onChange={(event) => {
                    this.handlePhoneChange(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Phone Number (Home)</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.additionalNumber}
                  onChange={(event) =>
                    this.setState({
                      additionalNumber: utilityService.formatPhone(event.target.value),
                    })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Cell Phone {FormValidatorService.getRequiredHTML(validationGroup, 'cellPhone')}</label>
                <input
                  type="text"
                  className={FormValidatorService.getClass(validationGroup, this.state, 'cellPhone')}
                  value={this.state.cellPhone}
                  onChange={(event) =>
                    this.setState({
                      cellPhone: utilityService.formatPhone(event.target.value),
                    })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Fax</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.fax}
                  onChange={(event) =>
                    this.setState({ fax: utilityService.formatPhone(event.target.value) })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>
                  Account # {FormValidatorService.getRequiredHTML(validationGroup, 'account')}
                </label>
                <input
                  type="text"
                  className={FormValidatorService.getClass(validationGroup, this.state, 'account')}
                  value={this.state.account}
                  onChange={(event) => {
                    this.setState({ account: event.target.value });
                  }}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Email Address</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>
                  DOB(MM/DD/YYYY) {FormValidatorService.getRequiredHTML(validationGroup, 'dob')}
                </label>
                <br />
                <DatePicker
                  isClearable
                  className={FormValidatorService.getClass(validationGroup, this.state, 'dob')}
                  selected={this.state.dob}
                  onChange={(date) => {
                    this.setState({ dob: date });
                  }}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Social Security number {FormValidatorService.getRequiredHTML(validationGroup, 'ssn')}</label>
                <input
                  type="text"
                  className={FormValidatorService.getClass(validationGroup, this.state, 'ssn')}
                  value={this.state.ssn}
                  onChange={(event) =>
                    this.setState({ ssn: event.target.value })
                  }
                />
              </div>





              <div className="col-md-3 mrgn-btm10">
                <label>Address Line 1</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.addressLine1}
                  onChange={(event) =>
                    this.setState({ addressLine1: event.target.value })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Address Line 2</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.addressLine2}
                  onChange={(event) =>
                    this.setState({ addressLine2: event.target.value })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <ZipCode title={'Zip Code'}
                  value={this.state.zipCode}
                  onChange={(value) => {
                    this.setState({ 'zipCode': value });
                  }}
                  onBlur={(value) => {
                    this.zipCodeChanged(value);
                  }}>
                </ZipCode>
              </div>

              <div className="col-md-3 mrgn-btm10">
                <StateSelection
                  key={this.state.state}
                  label={'State'}
                  isMulti={false}
                  selectedStateCode={this.state.state}
                  onChange={(value) => { this.setState({ state: value.value }) }}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <CitySelection
                  key={"".concat(this.state.state, this.state.city)}
                  label={'City'}
                  isMulti={false}
                  selectedStateCode={this.state.state}
                  selectedCity={this.state.city}
                  onChange={(value) => { console.log(value); this.setState({ city: value.value }) }}
                />
              </div>





              <div className="col-md-3 mrgn-btm10">
                <label>Country</label>
                <select
                  className="filter-input"
                  onChange={(event) =>
                    this.setState({ country: event.target.value })
                  }
                >
                  <option value="usa">United States</option>
                </select>
              </div>
              <div className="col-md-12 mrgn-btm10">
                <label>Notes</label>
                <textarea
                  className="code-input"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ notes: event.target.value })
                  }
                />
              </div>
              <div className="top-padding">
                <div className="page-title" onClick={this.toggleFirstInsurance}>
                  <h2 className="heading-custom">
                    +/- Primary Insurance Information
                  </h2>
                </div>
                {this.state.toggleFirstInsurance && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-md-12 mrgn-btm10">
                      <button
                        onClick={this.resetPrimary}
                        className="filter-save-button"
                      >
                        Remove Payer
                      </button>
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>
                        Billable / Non-Billable / Non-Billable Commercial
                      </label>
                      <select
                        className="filter-input"
                        onChange={(event) =>
                          this.setState({ billable: event.target.value })
                        }
                      >
                        <option value="billable">Billable</option>
                        <option value="non-billable">Non-Billable</option>
                        <option value="non-billable-commercial">
                          Non-Billable Commercial
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Primary Electronic Payer ID</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primaryPayerId}
                        onChange={(event) =>
                          this.setState({ primaryPayerId: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <CustomDropDown
                        label="Primary Insurance Name"
                        type={'insurances'}
                        isRequired={false}
                        isMulti={false}
                        selectedValue={this.state?.primaryInsurance}
                        onChange={(value) => {
                          this.setState({ primaryInsurance: value }, () =>
                            this.loadPrimaryInsuranceDetails()
                          );
                        }}>
                      </CustomDropDown>
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Master Payer ID</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primaryMasterPayerId}
                        onChange={(event) =>
                          this.setState({
                            primaryMasterPayerId: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Insurance Type Code</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primaryTypeCode}
                        onChange={(event) =>
                          this.setState({ primaryTypeCode: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Primary Insurance Phone</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primaryPhone}
                        onChange={(event) => {
                          this.handlePrimaryPhoneChange(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3 mrgn-btm10">
                      <label>Primary Insurance Fax</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primaryFax}
                        onChange={(event) =>
                          this.setState({ primaryFax: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <label>Primary Insurance Address</label>
                      <textarea
                        className="code-input"
                        value={this.state.primaryAddress}
                        onChange={(event) =>
                          this.setState({ primaryAddress: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <button
                        onClick={this.primarySubscriber}
                        className="filter-save-button"
                      >
                        Fill Form With Patient Info
                      </button>
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Subscriber First Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primarySubscriberFirstName}
                        onChange={(event) =>
                          this.setState({
                            primarySubscriberFirstName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Subscriber Last Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primarySubscriberLastName}
                        onChange={(event) =>
                          this.setState({
                            primarySubscriberLastName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Member Number</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.primaryMemberNumber}
                        onChange={(event) =>
                          this.setState({
                            primaryMemberNumber: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Group Number</label>
                      <input
                        maxLength={9}
                        type="text"
                        className="code-input"
                        value={this.state.primaryGroupNumber}
                        onChange={(event) =>
                          this.setState({
                            primaryGroupNumber: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Subscriber DOB</label>
                      <br />
                      <DatePicker
                        isClearable
                        className="filter-input"
                        selected={this.state.primaryDob}
                        onChange={(date) => this.setState({ primaryDob: date })}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="top-padding">
                <div
                  className="page-title"
                  onClick={this.toggleGuarantorInsurance}
                >
                  <h2 className="heading-custom">+/- Guarantor Information</h2>
                </div>
                {this.state.toggleGuarantor && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-md-12 mrgn-btm10">
                      <button
                        onClick={this.guarantorDetails}
                        className="filter-save-button"
                      >
                        Same as Patient
                      </button>
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Guarantor First Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.guarantorFirstName}
                        onChange={(event) =>
                          this.setState({
                            guarantorFirstName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Guarantor Last Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.guarantorLastName}
                        onChange={(event) =>
                          this.setState({
                            guarantorLastName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Address Line 1</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.guarantorAddress1}
                        onChange={(event) =>
                          this.setState({
                            guarantorAddress1: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Address Line 2</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.guarantorAddress2}
                        onChange={(event) =>
                          this.setState({
                            guarantorAddress2: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <ZipCode title={'Zip Code'}
                        value={this.state.guarantorZip}
                        onChange={(value) => {
                          this.setState({ 'guarantorZip': value });
                        }}
                        onBlur={(value) => {
                          this.guarantorZipCodeChanged(value);
                        }}>
                      </ZipCode>
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <StateSelection
                        key={this.state.guarantorState}
                        label={'State'}
                        isMulti={false}
                        selectedStateCode={this.state.guarantorState}
                        onChange={(value) => { this.setState({ guarantorState: value.value }) }}
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <CitySelection
                        key={"".concat(this.state.guarantorState, this.state.guarantorCity)}
                        label={'City'}
                        isMulti={false}
                        selectedStateCode={this.state.guarantorState}
                        selectedCity={this.state.guarantorCity}
                        onChange={(value) => { console.log(value); this.setState({ guarantorCity: value.value }) }}
                      />
                    </div>

                    <div className="col-md-6 mrgn-btm10">
                      <label>
                        Spouse or Partner's Name/Parents Name (if patient is a
                        minor)
                      </label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.guarantorSpouseName}
                        onChange={(event) =>
                          this.setState({
                            guarantorSpouseName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Spouse, Partner or Partners Phone</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.guarantorSpousePhone}
                        onChange={(event) =>
                          this.handleGuarantorPhoneChange(event.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Relationship To Patient</label>
                      <select
                        className="filter-input"
                        value={this.state.guarantorRelationship}
                        onChange={(event) =>
                          this.setState({
                            guarantorRelationship: event.target.value,
                          })
                        }
                      >
                        <option value="parent">Parent</option>
                        <option value="child">Child</option>
                        <option value="spouse">Spouse</option>
                        <option value="brother">Brother</option>
                        <option value="sister">Sister</option>
                        <option value="unknown">Unknown</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>

              <div className="top-padding">
                <div
                  className="page-title"
                  onClick={this.toggleSecondaryInsurance}
                >
                  <h2 className="heading-custom">
                    +/- Secondary Insurance Information
                  </h2>
                </div>
                {this.state.toggleSecondaryInsurance && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-md-12 mrgn-btm10">
                      <button
                        onClick={this.resetSecondary}
                        className="filter-save-button"
                      >
                        Remove Payer
                      </button>
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <CustomDropDown
                        label="Secondary Insurance Name"
                        type={'insurances'}
                        isRequired={false}
                        isMulti={false}
                        selectedValue={this.state?.secondaryInsurance}
                        onChange={(value) => {
                          this.setState({ secondaryInsurance: value }, () =>
                            this.loadSecondaryInsuranceDetails()
                          );
                        }}>
                      </CustomDropDown>
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Secondary Payer ID</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondaryPayerId}
                        onChange={(event) =>
                          this.setState({
                            secondaryPayerId: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Master Payer ID</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondaryMasterPayerId}
                        onChange={(event) =>
                          this.setState({
                            secondaryMasterPayerId: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Insurance Type Code</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondaryTypeCode}
                        onChange={(event) =>
                          this.setState({
                            secondaryTypeCode: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Secondary Insurance Phone</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondaryPhone}
                        onChange={(event) => {
                          this.handleSecondaryPhoneChange(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Secondary Insurance Fax</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondaryFax}
                        onChange={(event) =>
                          this.setState({ secondaryFax: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <label>Secondary Insurance Address</label>
                      <textarea
                        className="code-input"
                        value={this.state.secondaryAddress}
                        onChange={(event) =>
                          this.setState({
                            secondaryAddress: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <button
                        onClick={this.secondarySubscriber}
                        className="filter-save-button"
                      >
                        Fill Form With Patient Info
                      </button>
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Subscriber First Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondarySubscriberFirstName}
                        onChange={(event) =>
                          this.setState({
                            secondarySubscriberFirstName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Subscriber Last Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondarySubscriberLastName}
                        onChange={(event) =>
                          this.setState({
                            secondarySubscriberLastName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Member Number</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondaryMemberNumber}
                        onChange={(event) =>
                          this.setState({
                            secondaryMemberNumber: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Group Number</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.secondaryGroupNumber}
                        onChange={(event) =>
                          this.setState({
                            secondaryGroupNumber: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Subscriber DOB</label>
                      <br />
                      <DatePicker
                        isClearable
                        className="filter-input"
                        selected={this.state.secondaryDob}
                        onChange={(date) =>
                          this.setState({ secondaryDob: date })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="top-padding">
                <div
                  className="page-title"
                  onClick={this.toggleTertiaryInsurance}
                >
                  <h2 className="heading-custom">
                    +/- Tertiary Insurance Information
                  </h2>
                </div>
                {this.state.toggleTertiaryInsurance && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-md-12 mrgn-btm10">
                      <button
                        onClick={this.resetTertiary}
                        className="filter-save-button"
                      >
                        Remove Payer
                      </button>
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <CustomDropDown
                        label="Tertiary Insurance Name"
                        type={'insurances'}
                        isRequired={false}
                        isMulti={false}
                        selectedValue={this.state?.tertiaryInsurance}
                        onChange={(value) => {
                          this.setState({ tertiaryInsurance: value }, () =>
                            this.loadTertiaryInsuranceDetails()
                          );
                        }}>
                      </CustomDropDown>
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Tertiary Payer ID</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiaryPayerId}
                        onChange={(event) =>
                          this.setState({ tertiaryPayerId: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Master Payer ID</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiaryMasterPayerId}
                        onChange={(event) =>
                          this.setState({
                            tertiaryMasterPayerId: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Insurance Type Code</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiaryTypeCode}
                        onChange={(event) =>
                          this.setState({
                            tertiaryTypeCode: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Tertiary Insurance Phone</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiaryPhone}
                        onChange={(event) => {
                          this.handleTertiaryPhoneChange(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Tertiary Insurance Fax</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiaryFax}
                        onChange={(event) =>
                          this.setState({ tertiaryFax: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <label>Tertiary Insurance Address</label>
                      <textarea
                        className="code-input"
                        value={this.state.tertiaryAddress}
                        onChange={(event) =>
                          this.setState({ tertiaryAddress: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-12 mrgn-btm10">
                      <button
                        onClick={this.tertiarySubscriber}
                        className="filter-save-button"
                      >
                        Fill Form With Patient Info
                      </button>
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Subscriber First Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiarySubscriberFirstName}
                        onChange={(event) =>
                          this.setState({
                            tertiarySubscriberFirstName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Subscriber Last Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiarySubscriberLastName}
                        onChange={(event) =>
                          this.setState({
                            tertiarySubscriberLastName: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Member Number</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiaryMemberNumber}
                        onChange={(event) =>
                          this.setState({
                            tertiaryMemberNumber: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Group Number</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.tertiaryGroupNumber}
                        onChange={(event) =>
                          this.setState({
                            tertiaryGroupNumber: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4 mrgn-btm10">
                      <label>Subscriber DOB</label>
                      <br />
                      <DatePicker
                        isClearable
                        className="filter-input"
                        selected={this.state.tertiaryDob}
                        onChange={(date) =>
                          this.setState({ tertiaryDob: date })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="top-padding">
                <div
                  className="page-title"
                  onClick={this.toggleWorkerInsurance}
                >
                  <h2 className="heading-custom">
                    +/- Worker's Comp or Other Property & Casualty Claim Details
                    (For Event ID)
                  </h2>
                </div>
                {this.state.toggleWorker && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-md-6 mrgn-btm10">
                      <CustomSelect type='Patient Condition Related To'
                        value={this.state.workerPatientCondition}
                        className="filter-input"
                        onChange={(event) =>
                          this.setState({
                            workerPatientCondition: event.target.value,
                          })
                        }>
                      </CustomSelect>
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Employer Name</label>
                      <input
                        type="text"
                        className="code-input"
                        value={this.state.employerName}
                        onChange={(event) =>
                          this.setState({ employerName: event.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <label>Date of Injury</label>
                      <br />
                      <DatePicker
                        isClearable
                        className="filter-input"
                        selected={Date.parse(this.state.dateOfInjury)}
                        onChange={(date) =>
                          this.setState({ dateOfInjury: date })
                        }
                      />
                    </div>
                    <div className="col-md-6 mrgn-btm10">
                      <CustomSelect type='Illness Indicator'
                        className="filter-input"
                        value={this.state.illnessIndicator}
                        onChange={(event) =>
                          this.setState({
                            illnessIndicator: event.target.value,
                          })
                        }>
                      </CustomSelect>
                    </div>
                  </div>
                )}
              </div>

              {patientId && (
                <div className="top-padding">
                  <div className="table-responsive">
                    <table className="listing-table">
                      <thead className="listing-table-head">
                        <tr>{this.renderHeadColumn()}</tr>
                      </thead>
                      <tbody>{this.renderActivityList()}</tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="filter-footer">
                {patientId && (
                  <button
                    onClick={this.submitPatient}
                    className="filter-save-button"
                  >
                    Update
                  </button>
                )}
                {!patientId && (
                  <button
                    onClick={this.submitPatient}
                    className="filter-save-button"
                  >
                    Submit
                  </button>
                )}
                {this.props.PopupRequest === undefined && (
                  <button
                    onClick={this.cancel}
                    className="filter-cancel-button"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPatient,
      updatePatient,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManagePatient));
