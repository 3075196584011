import { FEEDBACK } from '../constants/actions';

const initialState = {
    feedbacks: [],
    totalFeedbacks: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case FEEDBACK.FETCH_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: action.data.data,
                totalFeedbacks: action.data.records,
            };
        default:
            return state;
    }
}