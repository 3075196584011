import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";
import {
  RECORDS_PER_PAGE,
} from "../../constants/common";
import viewIcon from "../../../assets/images/view.png";
import editIcon from "../../../assets/images/edit.png";
import { withRouter } from "react-router-dom";
import { authorizationService } from "../../services/authorization";

class AccountsListingTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
  };

  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column) => {
      return <th className="listing-table-head-column">{column}</th>;
    });

    const actionElement = (
      <th className="listing-table-head-column">{"Action"}</th>
    );

    header.splice(headColumn.length + 1, 0, actionElement);

    return header;
  };

  view = (regionId) => {
    this.props.view(regionId);
  };

  edit = (item) => {
    if (!authorizationService.isUpdateAccess())
      return;

    this.props.edit(item);
  }

  renderList = () => {
    const { listing = [] } = this.props;
    return (
      listing.length > 0 &&
      listing.map((listRow) => {
        const item = listRow.map((listItem, index) => {
          if (index < listRow.length - 1) {
            return <td onClick={() => { this.edit(listRow[listRow.length - 1]) }} className="listing-table-column">{listItem}</td>;
          } else {
            return (
              <td className="listing-table-column">
                <img
                  src={viewIcon}
                  onClick={() => {
                    this.view(listItem);
                  }}
                  alt="view"
                />
                {authorizationService.isUpdateAccess() && (
                  <img
                    src={editIcon}
                    onClick={() => {
                      this.edit(listItem);
                    }}
                    alt="edit"
                  />
                )}
              </td>
            );
          }
        });

        return <tr className="listing-table-rows" style={{ 'cursor': 'pointer' }}>{item}</tr>;
      })
    );
  };

  renderPagination = () => {
    const { totalRecords, currentPage } = this.props;

    const totalPages = Math.ceil(totalRecords / RECORDS_PER_PAGE);

    const visiblePaginationPages = 6;

    const pageIndexes = [];

    const visiblePages = visiblePaginationPages / 2;
    if (totalPages > visiblePaginationPages + 2) {
      const firstPage = Math.min(
        currentPage,
        Math.max(totalPages - visiblePages * 2, 0)
      );
      for (let i = firstPage; i < firstPage + visiblePages; i++) {
        pageIndexes.push(i);
      }

      if (currentPage + visiblePages < totalPages - visiblePages) {
        pageIndexes.push(-1);
      }

      for (let i = totalPages - visiblePages; i < totalPages; i++) {
        pageIndexes.push(i);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageIndexes.push(i);
      }
    }

    const pageLinks = pageIndexes.map((i, index) => {
      const button = (
        <button
          disabled={currentPage === i}
          key={`pagination-button-${index}`}
          onClick={() => {
            return this.props.goToPage(i);
          }}
          className="listing-pagination-button"
        >
          {i}
        </button>
      );

      const ellipsis = (
        <div
          className="listing-pagination-button"
          key={`pagination-ellipsis-${index}`}
        >
          ...
        </div>
      );
      return -1 === i ? ellipsis : button;
    });
    return (
      <div className="listing-pagination">
        <div className="inner">
          <button
            onClick={() => {
              return this.props.goToPage(1);
            }}
            disabled={1 === currentPage}
            className="listing-pagination-button"
          >
            &lt;&lt; First
          </button>
          <button
            onClick={() => {
              return this.props.goToPage(currentPage - 1);
            }}
            disabled={1 === currentPage}
            className="listing-pagination-button"
          >
            &lt; Previous
          </button>
          {pageLinks}
          <button
            onClick={() => {
              return this.props.goToPage(currentPage + 1);
            }}
            disabled={currentPage === totalPages - 1}
            className="listing-pagination-button"
          >
            Next &gt;
          </button>
          <button
            onClick={() => {
              return this.props.goToPage(totalPages - 1);
            }}
            disabled={currentPage === totalPages - 1}
            className="listing-pagination-button"
          >
            Last &gt;&gt;
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive">
            <table className="listing-table">
              <thead className="listing-table-head">
                <tr>{this.renderHeadColumn()}</tr>
              </thead>
              {this.props.isLoading && <Loader width="168%" />}
              {!this.props.isLoading && <tbody>{this.renderList()}</tbody>}
            </table>
          </div>
          {this.props.listing.length > 0 ? (
            this.renderPagination()
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "2px",
              }}
            >
              {!this.props.isLoading && this.props.listing.length === 0 && (
                <span>No Records Found</span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AccountsListingTable);
