import { LOCAL_STORAGE_KEYS } from '../constants/common';
import { AUTH } from '../constants/actions';
import { authenticationService } from '../services/authentication';

export const authenticate = email => dispatch => {
    return new Promise((resolve, reject) => {
        authenticationService
            .authentication(email)
            .then(response => {

                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                    response.data.token,
                );
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
                    JSON.stringify(response.data),
                );
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.LOGGED_IN_TIME,
                    new Date(),
                );
                localStorage.removeItem(LOCAL_STORAGE_KEYS.IMPERSONATE_EMAIL)
                dispatch({
                    type: AUTH.OFFICE_365_AUTHENTICATION,
                    data: response.data,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const checkUser = email => dispatch => {
    return new Promise((resolve, reject) => {
        authenticationService
            .authentication(email)
            .then(response => {
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.USER,
                    response.data.email,
                );
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const checkTenant = tenant => dispatch => {
    return new Promise((resolve, reject) => {
        authenticationService
            .checkClient(tenant)
            .then(response => {
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const impersonate = email => dispatch => {
    return new Promise((resolve, reject) => {
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        authenticationService
            .authentication(email)
            .then(response => {
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                    response.data.token,
                );
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
                    JSON.stringify(response.data),
                );
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.LOGGED_IN_TIME,
                    new Date(),
                );
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.IMPERSONATE_EMAIL,
                    loggedInUser.email,
                );
                dispatch({
                    type: AUTH.OFFICE_365_AUTHENTICATION,
                    data: response.data,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const register = payload => dispatch => {
    return new Promise((resolve, reject) => {
        authenticationService
            .register(payload)
            .then(response => {
                dispatch({
                    type: AUTH.REGISTRATION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};


