import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getNotifications(options, user) {
    return apiClient()
        .get(API_CONSTANTS.LIST_NOTIFICATIONS + user, options)
        .then(handleResponse)
        .catch(handleError);
}

function getNotificationsCount(user) {
    return apiClient()
        .get(API_CONSTANTS.NOTIFICATIONS_COUNT + user)
        .then(handleResponse)
        .catch(handleError);
}

function createNotification(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_NOTIFICATION, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const notificationService = {
    getNotificationsCount,
    getNotifications,
    createNotification,
}