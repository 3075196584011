import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getAssistants(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_PHYSICIAN_ASSISTANTS, options)
        .then(handleResponse)
        .catch(handleError);
}

function getRegionAssistants(region, options) {
    if (region !== undefined) {

    } else {
        region = 0;
    }
    return apiClient()
        .get(API_CONSTANTS.LIST_REGION_PHYSICIAN_ASSISTANTS + region, options)
        .then(handleResponse)
        .catch(handleError);
}

function createAssistant(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_PHYSICIAN_ASSISTANT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateAssistant(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_PHYSICIAN_ASSISTANT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function getCertificates(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_CERTIFICATES, options)
        .then(handleResponse)
        .catch(handleError);
}

function deleteAssistant(assistant) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_PHYSICIAN_ASSISTANT + assistant)
        .then(handleResponse)
        .catch(handleError);
}

function getAssistantDetail(assistant) {
    return apiClient()
        .get(API_CONSTANTS.PHYSICIAN_ASSISTANT_DETAIL + assistant)
        .then(handleResponse)
        .catch(handleError);
}

function activateAssistants(payload) {
    return apiClient()
        .post(API_CONSTANTS.PHYSICIAN_ASSISTANT_ACTIVATE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function inActivateAssistants(payload) {
    return apiClient()
        .post(API_CONSTANTS.PHYSICIAN_ASSISTANT_IN_ACTIVATE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function moveToSurgicalAssistant(payload) {
    return apiClient()
        .put(API_CONSTANTS.MOVE_TO_SURGICAL_ASSISTANT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function addDocument(payload) {
    return apiClient()
        .post(API_CONSTANTS.PHYSICIAN_ASSISTANT_ADD_DOCUMENT, payload)
        .then(handleResponse)
        .catch(handleError);
}
function uploadFile(payload) {
    return apiClient()
        .post(API_CONSTANTS.PHYSICIAN_ASSISTANT_UPLOAD_DOCUMENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteDocument(payload) {
    return apiClient()
        .post(API_CONSTANTS.PHYSICIAN_ASSISTANT_DELETE_DOCUMENT, payload)
        .then(handleResponse)
        .catch(handleError);
}


function deleteFacility(id) {
    return apiClient()
        .delete(API_CONSTANTS.PHYSICIAN_ASSISTANT_DELETE_FACILITY + id)
        .then(handleResponse)
        .catch(handleError);
}

function deleteVacciantion(id) {
    return apiClient()
        .delete(API_CONSTANTS.PHYSICIAN_ASSISTANT_DELETE_VACCINATION + id)
        .then(handleResponse)
        .catch(handleError);
}

function deleteBackgroundCheck(id) {
    return apiClient()
        .delete(API_CONSTANTS.PHYSICIAN_ASSISTANT_DELETE_BACKGROUND_CHECK + id)
        .then(handleResponse)
        .catch(handleError);
}

function deletePTO(id) {
    return apiClient()
        .delete(API_CONSTANTS.PHYSICIAN_ASSISTANT_DELETE_PTO + id)
        .then(handleResponse)
        .catch(handleError);
}

function deleteDegree(id) {
    return apiClient()
        .delete(API_CONSTANTS.PHYSICIAN_ASSISTANT_DELETE_DEGREE + id)
        .then(handleResponse)
        .catch(handleError);
}


export const physicianAssistantService = {
    getAssistants,
    getRegionAssistants,
    createAssistant,
    updateAssistant,
    getCertificates,
    deleteAssistant,
    getAssistantDetail,
    activateAssistants,
    inActivateAssistants,
    moveToSurgicalAssistant,
    uploadFile,
    deleteDocument,
    addDocument,
    deleteFacility,
    deleteVacciantion,
    deleteBackgroundCheck,
    deletePTO,
    deleteDegree
};
