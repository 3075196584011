import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getClients(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_CLIENTS, options)
        .then(handleResponse)
        .catch(handleError);
}

function deleteClient(facility) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_CLIENT + facility)
        .then(handleResponse)
        .catch(handleError);
}

function getClientDetail(facility) {
    return apiClient()
        .get(API_CONSTANTS.CLIENT_DETAIL + facility)
        .then(handleResponse)
        .catch(handleError);
}

function createClient(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_CLIENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateClient(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_CLIENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const clientService = {
    getClients,
    deleteClient,
    createClient,
    updateClient,
    getClientDetail,
}