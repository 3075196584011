import { SURGEON_ASSOCIATION } from '../constants/actions';
import { surgeonAssociationService } from '../services/surgeonAssociation';


export const fetchSurgeonAssociations = options => dispatch => {
    return new Promise((resolve, reject) => {
        surgeonAssociationService
            .getSurgeonAssociations(options)
            .then(response => {
                dispatch({
                    type: SURGEON_ASSOCIATION.FETCH_SURGEON_ASSOCIATION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const assignSurgeonAssocation = payload => dispatch => {
    return new Promise((resolve, reject) => {
        surgeonAssociationService
            .assignSurgeonAssociation(payload)
            .then(response => {
                dispatch({
                    type: SURGEON_ASSOCIATION.CREATE_SURGEON_ASSOCATION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};