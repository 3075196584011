import React from 'react';
import PropTypes from "prop-types";

import { createNotification } from "../../utils/notificationManager";
import { NOTIFICATION_TYPES } from "../../constants/common";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { createProcedure } from "../../actions/procedure";
import CustomDropDown from '../organisms/CustomDropDown';

const REQUIRED_FIELDS = {
    PROCEDURE: 'procedure',
};
class AddProcedure extends React.Component {
    static propTypes = {
        toggle: PropTypes.func.isRequired,
        createProcedure: PropTypes.func.isRequired,
        setProcedure: PropTypes.func.isRequired,
    };

    state = {
        procedure: '',
        speciality: '',
        errors: [],
    };

    validateProcedure = () => {
        const { procedure, errors } = this.state;
        let isValid = true;

        if (procedure.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.PROCEDURE] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    addProcedure = () => {
        const {
            toggle,
            createProcedure,
            setProcedure,
        } = this.props;

        if (!this.validateProcedure()) {
            return;
        }

        const request = {
            procedure_name: this.state.procedure,
            specialty_id: this.state.speciality.value,
            type: 'test',
        };
        createProcedure(request)
            .then(res => {
                setProcedure({
                    label: res.response.data.procedure_name,
                    value: parseInt(res.response.data.id)
                });
                toggle(false);
            })
    };

    render() {
        return (
            <>
                <div className="col-md-6 mrgn-btm10">
                    <label>Procedure Name <span className="required">*</span></label>
                    <input
                        type="text"
                        className={this.hasErrors(REQUIRED_FIELDS.PROCEDURE) ? 'error code-input' : 'code-input'}
                        value={this.state.procedure}
                        onChange={event => {
                            this.resetErrors(REQUIRED_FIELDS.PROCEDURE);
                            this.setState({ procedure: event.target.value })
                        }}
                    />
                </div>
                <div className="col-md-6 mrgn-btm10">
                    <CustomDropDown
                        type={'specialities'}
                        isRequired={false}
                        isMulti={false}
                        selectedValue={this.state?.speciality}
                        onChange={value => this.setState({ speciality: value })}>
                    </CustomDropDown>
                </div>
                <div style={{ borderBottom: "1px solid #ccc" }} className="col-md-12 mrgn-btm10">
                    <button
                        onClick={() => this.props.toggle(false)}
                        className="filter-cancel-button add-new-button mrgn-btm10">
                        Cancel
                    </button>
                    <button
                        onClick={this.addProcedure}
                        className="filter-save-button add-new-button mrgn-btm10">
                        Create
                    </button>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createProcedure,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(AddProcedure));