import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getDivisions(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_DIVISION, options)
        .then(handleResponse)
        .catch(handleError);
}

function getDivisionDetail(divisionid) {
    return apiClient()
        .get(API_CONSTANTS.DIVISION_DETAIL + divisionid)
        .then(handleResponse)
        .catch(handleError);
}

function createDivision(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_DIVISION, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateDivision(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_DIVISION, payload)
        .then(handleResponse)
        .catch(handleError);
}


function deleteDivision(activity) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_DIVISION + activity)
        .then(handleResponse)
        .catch(handleError);
}


export const divisionService = {
    getDivisions,
    createDivision,
    updateDivision,
    deleteDivision,
    getDivisionDetail,
}