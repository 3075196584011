import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { RevenueSchedulerService } from "../../services/revenuescheduler";
import {
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import { withRouter } from "react-router-dom";

class BillingNotes extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    eventId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  state = {
    patientId: "",
    billing_notes: "",
    files: [],
  };

  componentDidMount() {
    this.setState({ billing_notes: this.props.billingNotes });
    // alert(this.props.billingNotes);
  }
  handleMessageChange = (event) => {
    // 👇️ access textarea value
    // setMessage(event.target.value);
    this.setState({ billing_notes: event.target.value });
  };

  handleClick = () => {
    const formData = new FormData();
    formData.append("event_id", this.props.eventId);
    formData.append("billing_notes", this.state.billing_notes);
    formData.append("billing_notes_check", 1);
    RevenueSchedulerService.updateRevenueScheduler(formData)
      .then((res) => {
        if (res.data.success) {
          // this.props.history.push("billing_notes:hello");
          // this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
        } else {
          this.props.setModal(false);
          createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
          this.props.goToPage(1);
        }
      })
  };

  render() {
    const { title, isOpen, setModal } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => setModal(!isOpen)}>
          <ModalHeader toggle={() => setModal(!isOpen)}>{title}</ModalHeader>
          <ModalBody>
            <React.Fragment>
              <div className="col-md-12 mrgn-btm10">
                <textarea
                  name="billing_notes"
                  value={this.state.billing_notes}
                  onChange={(event) => this.handleMessageChange(event)}
                  rows={4}
                  cols={40}
                />
              </div>
              <div className="col-md-12 mrgn-btm10">
                <Button color="primary" onClick={() => this.handleClick()}>
                  Save
                </Button>
              </div>
            </React.Fragment>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!isOpen)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(BillingNotes);
