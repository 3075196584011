import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getRegions(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_REGIONS, options)
        .then(handleResponse)
        .catch(handleError);
}

function getRegionDetail(region) {
    return apiClient()
        .get(API_CONSTANTS.REGION_DETAIL + region)
        .then(handleResponse)
        .catch(handleError);
}

function createRegion(payload) {
    return apiClient()
        .post(API_CONSTANTS.ADD_REGION, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateRegion(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_REGION, payload)
        .then(handleResponse)
        .catch(handleError);
}


export const regionsService = {
    getRegions,
    createRegion,
    updateRegion,
    getRegionDetail,
}