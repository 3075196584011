import { GROUP } from '../constants/actions';

const initialState = {
    groups: [],
    totalGroups: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case GROUP.FETCH_GROUP_SUCCESS:
            return {
                ...state,
                groups: action.data.data,
                totalGroups: action.data.records,
            };
        case GROUP.CREATE_GROUP_SUCCESS:
            const createGroup = [ ...state.groups, action.data.data ];
            return {
                ...state,
                groups: createGroup,
            };
        case GROUP.UPDATE_GROUP_SUCCESS:
            const updateGroup = [ ...state.groups, action.data.data ];
            return {
                ...state,
                groups: updateGroup,
            }
        default:
            return state;
    }
}