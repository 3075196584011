import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router-dom";
import { utilityService } from "../../services/utility";
import { invoicingV1Service } from "../../services/invoicingv1";
class ManageInvoicePopup extends React.Component {
  static propTypes = {
    selectedBills: PropTypes.array.isRequired,
    saveChanges: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    isView: PropTypes.bool
  };

  state = {
    selectedBills: [],
    total_amount: 0,
    discount_amount: 0,
    net_amount: 0,
    assigned_amount: 0,
    remaining_amount: 0
  };

  componentDidMount() {
    if (this.props) {
      let totalAmount = 0;

      let tInvoiceDetails = [];

      this.props.selectedBills.forEach(tBill => {
        let hcpcs_code = '';
        tBill.service_lines.forEach(element => {
          if (element.cpt_code && element.cpt_code !== 'null')
            hcpcs_code += element.cpt_code + ", ";
        });
        if (hcpcs_code.length > 1)
          hcpcs_code = hcpcs_code.substring(0, hcpcs_code.length - 2);

        let insurance = '';
        if (tBill.event_patient &&
          tBill.event_patient.patient &&
          tBill.event_patient.patient.patient_insurance_data &&
          tBill.event_patient.patient.patient_insurance_data.primary)
          insurance += "P: " + tBill.event_patient.patient.patient_insurance_data.primary.description + "<br>";

        if (tBill.event_patient &&
          tBill.event_patient.patient &&
          tBill.event_patient.patient.patient_insurance_data &&
          tBill.event_patient.patient.patient_insurance_data.secondary)
          insurance += "S: " + tBill.event_patient.patient.patient_insurance_data.secondary.description;


        let pIndex = tInvoiceDetails.push({
          bill_id: tBill.old_bill_id,
          total_amount: tBill.total_bill,
          discount_percetage: 0,
          discount_value: 0,
          discount_amount: 0,
          net_amount: tBill.total_bill,
          assigned_amount: 0,
          remaining_amount: tBill.total_bill,
          patient_name: tBill.event_patient && tBill.event_patient.patient
            ? tBill.event_patient.patient.first_name
            : " " + tBill.event_patient && tBill.event_patient.patient
              ? tBill.event_patient.patient.last_name
              : " ",
          insurance: insurance,
          hcpcs_code: hcpcs_code,
          facility_name: tBill.event_patient && tBill.event_patient.facility
            ? tBill.event_patient.facility.facility_name
            : "",
          surgeon_name: tBill.event_patient && tBill.event_patient.surgeon
            ? `${tBill.event_patient.surgeon.first_name} ${tBill.event_patient.surgeon.last_name}`
            : "",
          provider: tBill.event_patient && tBill.event_patient.assistant
            ? `${tBill.event_patient.assistant.name} ${tBill.event_patient.assistant.last_name}`
            : "",
          payments: []
        });
        pIndex = pIndex - 1;

        if (tBill.event_patient && tBill.event_patient.surgeon)
          this.addInPaymentDetails(tInvoiceDetails[pIndex], 'surgeon', tBill.event_patient.surgeon.id, `${tBill.event_patient.surgeon.first_name} ${tBill.event_patient.surgeon.last_name}`);

        if (tBill.event_patient && tBill.event_patient.assistant)
          this.addInPaymentDetails(tInvoiceDetails[pIndex], 'assistant', tBill.event_patient.assistant.id, `${tBill.event_patient.assistant.name} ${tBill.event_patient.assistant.last_name}`);

        if (tBill.event_patient.patient &&
          tBill.event_patient.patient.patient_insurance_data &&
          tBill.event_patient.patient.patient_insurance_data.primary)
          this.addInPaymentDetails(tInvoiceDetails[pIndex], 'pinsurance', tBill.event_patient.patient.patient_insurance_data.primary.id, tBill.event_patient.patient.patient_insurance_data.primary.description);

        if (tBill.event_patient.patient &&
          tBill.event_patient.patient.patient_insurance_data &&
          tBill.event_patient.patient.patient_insurance_data.secondary)
          this.addInPaymentDetails(tInvoiceDetails[pIndex], 'sinsurance', tBill.event_patient.patient.patient_insurance_data.secondary.id, tBill.event_patient.patient.patient_insurance_data.secondary.description);

        if (tBill.event_patient && tBill.event_patient.facility)
          this.addInPaymentDetails(tInvoiceDetails[pIndex], 'facility', tBill.event_patient.facility.id, tBill.event_patient.facility.facility_name);

        if (tBill.event_patient && tBill.event_patient.patient) {
          this.addInPaymentDetails(tInvoiceDetails[pIndex], 'patient', tBill.event_patient.patient.id, `${tBill.event_patient.patient.first_name} ${tBill.event_patient.patient.last_name}`);
        }

        tInvoiceDetails[pIndex].payments.sort((a, b) => a.sortorder - b.sortorder);
      })

      this.setState({
        selectedBills: tInvoiceDetails,
        totalAmount: totalAmount,
        assignedAmount: 0,
        discountAmount: 0,
        discountPercentage: 0,
        discountValue: 0,
        remainingAmount: totalAmount,
        paymentDetails: this.state.paymentDetails
      }, () => {
        this.refreshPaymentInformation();
      });
    }
  }

  addInPaymentDetails = (selectedBill, type, id, label, paymentdue = 0) => {
    let sOrderArr = [];
    sOrderArr['pinsurance'] = 0;
    sOrderArr['sinsurance'] = 1;
    sOrderArr['facility'] = 2;
    sOrderArr['assistant'] = 3;
    sOrderArr['surgeon'] = 4;
    sOrderArr['patient'] = 5;

    let sortorder = sOrderArr[type];

    let typeLabelArry = [];
    typeLabelArry['pinsurance'] = 'Primary Insurance';
    typeLabelArry['sinsurance'] = 'Secondary Insurance';
    typeLabelArry['facility'] = 'Facility';
    typeLabelArry['assistant'] = 'Provider';
    typeLabelArry['surgeon'] = 'Surgeon';
    typeLabelArry['patient'] = 'Patient';

    let typelabel = typeLabelArry[type];

    let index = selectedBill.payments.findIndex(tData => tData.id === id && tData.type === type);
    if (index < 0)
      selectedBill.payments.push({
        id: id,
        type: type,
        typelabel: typelabel,
        label: label,
        sortorder: sortorder,
        paymentdue: paymentdue
      });
  }

  refreshPaymentInformation = () => {
    let tTotal = 0;
    let tDiscount = 0;
    let tAssigned = 0;
    let tRemaining = 0;
    let tNetAmount = 0;

    this.state.selectedBills.forEach(tBill => {
      let totalAmount = tBill.total_amount;
      tTotal += parseFloat(totalAmount);

      let discount = parseFloat(tBill.discount_value);
      let tDiscountPercentage = (parseFloat(tBill.discount_percetage) / 100) * totalAmount;
      discount += parseFloat(tDiscountPercentage);


      tBill.discount_amount = discount
      tBill.net_amount = totalAmount - discount

      tDiscount += parseFloat(tBill.discount_amount);
      tNetAmount += parseFloat(tBill.net_amount);

      let totalAssigned = 0;
      tBill.payments.forEach(tPayment => {
        totalAssigned += parseFloat(tPayment.paymentdue);
      })

      tBill.assigned_amount = totalAssigned;
      tBill.remaining_amount = tBill.net_amount - totalAssigned;

      tAssigned += parseFloat(tBill.assigned_amount);
      tRemaining += parseFloat(tBill.remaining_amount);
    });

    this.setState({
      'selectedBills': this.state.selectedBills,
      'total_amount': tTotal,
      'discount_amount': tDiscount,
      'net_amount': tNetAmount,
      'assigned_amount': tAssigned,
      'remaining_amount': tRemaining,
    });
  }

  saveInvoice = () => {
    if (this.state.remaining_amount !== 0) {
      utilityService.showHTMLMessage("Invoice Assigned Amount", "Please assign all of the amounts, then create invoice", "error");
      return;
    }

    const { selectedBills, total_amount, discount_amount, net_amount, assigned_amount, remaining_amount } = this.state

    let payload = {
      total_amount: total_amount,
      discount_amount: discount_amount,
      net_amount: net_amount,
      assigned_amount: assigned_amount,
      remaining_amount: remaining_amount,
      selectedBills: selectedBills,
    };

    invoicingV1Service.createInvoiceV1(payload).then(data => {
      utilityService.showHTMLMessage('Invoice', data.message);
      this.props.goToPage(1);
      this.props.setModal(!this.props.isOpen);
    })
  }

  render() {
    const { title, isOpen, setModal } = this.props;
    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => setModal(!isOpen)}
          style={{
            maxWidth: "90%",
          }}
        >
          <ModalHeader
            toggle={() => setModal(!isOpen)}
            style={{ backgroundColor: "#1b252e", color: "white" }}
          >
            {title}
          </ModalHeader>
          <ModalBody
            style={{
              backgroundColor: "#1b252e",
            }}
          >
            <React.Fragment>
              <div className="row">
                <div className="col-md-12 bordered p-0">
                  <table className="listing-table">
                    <thead className="listing-table-head">
                      <tr>
                        <th className="listing-table-head-column">Bill ID</th>
                        <th className="listing-table-head-column">Patient</th>
                        <th className="listing-table-head-column">Facility</th>
                        <th className="listing-table-head-column">Surgeon</th>
                        <th className="listing-table-head-column">Provider</th>
                        <th className="listing-table-head-column">Insurance</th>
                        <th className="listing-table-head-column">CPT Codes</th>
                        <th className="listing-table-head-column">Amount</th>
                        <th className="listing-table-head-column">Discount %</th>
                        <th className="listing-table-head-column">Discount Value</th>
                        <th className="listing-table-head-column">Discount Amount</th>
                        <th className="listing-table-head-column">Net Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.selectedBills.map((bill) => {
                        return (
                          <>
                            <tr className="listing-table-rows">
                              <td className="listing-table-column">
                                {bill.bill_id}
                              </td>
                              <td className="listing-table-column">
                                {bill.patient_name}
                              </td>
                              <td className="listing-table-column">
                                {bill.facility_name}
                              </td>
                              <td className="listing-table-column">
                                {bill.surgeon_name}
                              </td>
                              <td className="listing-table-column">
                                {bill.provider}
                              </td>
                              <td className="listing-table-column" dangerouslySetInnerHTML={{
                                __html: bill.insurance,
                              }}>
                              </td>
                              <td className="listing-table-column">
                                {bill.hcpcs_code}
                              </td>
                              <td className="listing-table-column">
                                ${bill.total_amount ? bill.total_amount : "0"}
                              </td>
                              <td className="listing-table-column">
                                {this.props.isView && (
                                  <>
                                    ${bill.discount_percetage}
                                  </>
                                )}
                                {!this.props.isView && (
                                  <input className="form-control" type="number" value={bill.discount_percetage}
                                    onBlur={() => {
                                      if (!bill.discount_percetage)
                                        bill.discount_percetage = 0
                                      this.refreshPaymentInformation()
                                    }}
                                    onChange={(event) => {
                                      bill.discount_percetage = event.target.value;
                                      this.refreshPaymentInformation()
                                    }}></input>
                                )}

                              </td>
                              <td className="listing-table-column">
                                {this.props.isView && (
                                  <>
                                    ${bill.discount_value}
                                  </>
                                )}
                                {!this.props.isView && (
                                  <input className="form-control" type="number" value={bill.discount_value}
                                    onBlur={() => {
                                      if (!bill.discount_value)
                                        bill.discount_value = 0
                                      this.refreshPaymentInformation()
                                    }}
                                    onChange={(event) => {
                                      bill.discount_value = event.target.value;
                                      this.refreshPaymentInformation()
                                    }}></input>
                                )}
                              </td>
                              <td className="listing-table-column">
                                ${bill.discount_amount ? bill.discount_amount : "0"}
                              </td>
                              <td className="listing-table-column">
                                ${bill.net_amount ? bill.net_amount : "0"}
                              </td>
                            </tr>
                            <tr className="listing-table-rows">
                              <th colSpan={9} className="listing-table-head-column"></th>
                              <th className="listing-table-head-column">Type</th>
                              <th className="listing-table-head-column">Label</th>
                              <th className="listing-table-head-column">Amount Due</th>
                            </tr>
                            {

                              bill.payments.map((payment) => {
                                return (
                                  <tr className="listing-table-rows">
                                    <td colSpan={9} className="listing-table-column"></td>
                                    <td className="listing-table-column">{payment.typelabel}</td>
                                    <td className="listing-table-column">{payment.label}</td>
                                    <td className="listing-table-column">
                                      {this.props.isView && (
                                        <>
                                          ${payment.paymentdue}
                                        </>
                                      )}
                                      {!this.props.isView && (
                                        <input className="form-control" type="number" value={payment.paymentdue}
                                          onBlur={() => {
                                            if (!payment.paymentdue)
                                              payment.paymentdue = 0
                                            this.refreshPaymentInformation()
                                          }}
                                          onChange={(event) => {
                                            payment.paymentdue = event.target.value;
                                            this.refreshPaymentInformation()
                                          }}></input>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            }
                            <tr className={bill.remaining_amount !== 0 ? 'remaining-amount-row' : ''}>
                              <td className="listing-table-column" colSpan={7} ></td>
                              <td className="listing-table-column">
                                <p><strong>Total Amount</strong></p>
                                ${bill.total_amount}
                              </td>
                              <td className="listing-table-column">
                                <p><strong>Discount Amount</strong></p>
                                ${bill.discount_amount}
                              </td>
                              <td className="listing-table-column">
                                <p><strong>Net Amount</strong></p>
                                ${bill.net_amount}
                              </td>
                              <td className="listing-table-column">
                                <p><strong>Amount Assigned</strong></p>
                                ${bill.assigned_amount}
                              </td>
                              <td className="listing-table-column">
                                <p><strong>Remaining Amount</strong></p>
                                ${bill.remaining_amount}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      <tr className={this.state.remaining_amount !== 0 ? 'remaining-amount-row' : ''}>
                        <td className="listing-table-column">
                          <h6><strong>Grand Total</strong></h6>
                        </td>
                        <td className="listing-table-column" colSpan={6} ></td>
                        <td className="listing-table-column">
                          <p><strong>Total Amount</strong></p>
                          ${this.state.total_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Discount Amount</strong></p>
                          ${this.state.discount_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Net Amount</strong></p>
                          ${this.state.net_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Amount Assigned</strong></p>
                          ${this.state.assigned_amount}
                        </td>
                        <td className="listing-table-column">
                          <p><strong>Remaining Amount</strong></p>
                          ${this.state.remaining_amount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </React.Fragment>
          </ModalBody>
          <ModalFooter
            style={{
              backgroundColor: "#1b252e",
            }}
          >
            {!this.props.isView && (
              <Button color="primary" onClick={() => this.saveInvoice()}>
                Save
              </Button>
            )}
            <Button color="secondary" onClick={() => setModal(!isOpen)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ManageInvoicePopup);
