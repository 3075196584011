import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router-dom";

class ErrorPopup extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    eventId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  state = {
    errors: "",
  };

  componentDidMount() {
    this.setState({ errors: this.props.errors });
    // alert(this.props.billingNotes);
  }
  handleMessageChange = (event) => {
    // 👇️ access textarea value
    // setMessage(event.target.value);
    this.setState({ errors: event.target.value });
  };

  render() {
    const { title, isOpen, setModal } = this.props;

    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => setModal(!isOpen)}>
          <ModalHeader toggle={() => setModal(!isOpen)}>{title}</ModalHeader>
          <ModalBody>
            <React.Fragment>
              <div className="col-md-12 mrgn-btm10">
                <span
                  style={{ color: "red" }}
                  dangerouslySetInnerHTML={{
                    __html: this.state.errors,
                  }}
                />
              </div>
            </React.Fragment>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!isOpen)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ErrorPopup);
