import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchGroups } from "../../actions/groups";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { GROUP_LISTING_COLUMNS, APP_ROUTES, RECORDS_PER_PAGE } from "../../constants/common";
import { groupService } from "../../services/groups";
import GroupListingTable from "../atom/GroupListingTable";

class GroupDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        searchByName: "",
        searched: false,
        searchedGroups: [],
        searchedTotalRecords: 0,
        pageSize: RECORDS_PER_PAGE,
    };

    componentWillMount() {
        this.setState({ loading: true });
        const options = {
            page: this.state.currentPage,
            page_size: this.state.pageSize,
        };
        this.props.fetchGroups({ params: options }).then((res) => {
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    setLoading = (flag) => {
        this.setState({ loading: flag });
    };

    setCurrentPage = (num) => {
        this.setState({ currentPage: num });
    };

    goToPage = (page) => {
        this.setLoading(true);
        this.setCurrentPage(page);

        const options = {
            page: page,
            page_size: this.state.pageSize,
        };
        this.props.fetchGroups({ params: options }).then((res) => {
            this.setLoading(false);
        });
    };

    showAdd = () => this.props.history.push(APP_ROUTES.GROUP_ADD);

    showManageGroup = (groupId) =>
        this.props.history.push({
            pathname: APP_ROUTES.GROUP_EDIT,
            state: {
                groupId,
            },
        });

    deleteGroup = (user) => {
        groupService.deleteGroup(user).then((response) => {
            this.goToPage(1);
        });
    };

    list = () => {
        const { groups = [] } = this.props;
        return groups.map((group) => {
            return [
                group.id,
                `${group.group_name ? group.group_name : ""}`.toUpperCase(),
                group.id,
            ];
        });
    };

    searchGroups = () => {
        const { searchByName } = this.state;
        let options = {};

        if (searchByName) {
            options = { ...options, search: searchByName };
        }
        options = { ...options, page_size: this.state.pageSize };

        this.setState({
            searched: true,
            loading: true,
        });
        groupService.getGroups({ params: options }).then((response) => {
            this.setState({
                searchedTotalRecords: parseInt(response.records),
                searchedGroups: response.data.map((group) => {
                    return [
                        group.id,
                        `${group.group_name ? group.group_name : ""}`.toUpperCase(),
                        group.id,
                    ];
                }),
            });
        }).finally(() => {
            this.setState({ loading: false });
        });
    };

    setPageSize = (value) => {
        this.setState({ pageSize: value }, () => {
            this.goToPage(1);
        });
    };

    resetGroupSearch = () => {
        this.setState({
            searchByName: "",
            searchedGroups: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    };

    render() {
        const { searched, searchedGroups, searchedTotalRecords } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: "755px",
                    }}
                    className="right-col"
                >
                    <div className="page-title">
                        <div className="title_left">
                            <h3
                                style={{
                                    fontFamily:
                                        "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                                    fontSize: "24px",
                                    fontWeight: "400",
                                    lineHeight: "1.471",
                                }}
                            >
                                Groups
                            </h3>
                        </div>
                    </div>
                    <div className="filter-footer-header">
                        <button
                            onClick={() => {
                                this.showAdd(true);
                            }}
                            className="filter-button"
                        >
                            Add New Group
                        </button>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: "10px",
                            }}
                            className="col-md-3 mrgn-btm10"
                        >
                            <label>Group Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={(event) => {
                                    this.setState({ searchByName: event.target.value }, () => {
                                        if (this.state.searchByName.length >= 3) {
                                            this.searchGroups();
                                        } else if (this.state.searchByName.length === 0) {
                                            this.resetGroupSearch();
                                        }
                                    });
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: "32px",
                                    width: "100px",
                                    height: "38px",
                                }}
                                onClick={this.resetGroupSearch}
                                className="filter-save-button"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <GroupListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                setPageSize={this.setPageSize}
                                pageSize={this.state.pageSize}
                                goToPage={this.goToPage}
                                edit={this.showManageGroup}
                                delete={this.deleteGroup}
                                headColumn={GROUP_LISTING_COLUMNS}
                                listing={
                                    searched && searchedGroups.length > 0
                                        ? searchedGroups
                                        : this.list()
                                }
                                totalRecords={
                                    searched && searchedTotalRecords > 0
                                        ? searchedTotalRecords
                                        : this.props.totalRecords
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        totalRecords: state.groupReducer.totalGroups,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchGroups,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GroupDashboard));
