import { CASElOG } from '../constants/actions';
import { caseLogService } from '../services/caseLog';


export const fetchCaseLogs = options => dispatch => {
    return new Promise((resolve, reject) => {
        caseLogService
            .getCaseLogs(options)
            .then(response => {
                dispatch({
                    type: CASElOG.FETCH_CASElOG_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const createCaseLog = payload => dispatch => {
    return new Promise((resolve, reject) => {
        caseLogService
            .createCaseLog(payload)
            .then(response => {
                dispatch({
                    type: CASElOG.CREATE_CASElOG_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const updateCaseLog = payload => dispatch => {
    return new Promise((resolve, reject) => {
        caseLogService
            .updateCaseLog(payload)
            .then(response => {
                dispatch({
                    type: CASElOG.UPDATE_CASElOG_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};
