import React from "react";
import { bindActionCreators } from "redux";
import { createFacility, updateFacility } from "../../actions/facility";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createNotification } from "../../utils/notificationManager";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { facilityService } from "../../services/facilities";
import StateSelection from "../organisms/StateSelection";
import CitySelection from "../organisms/CitySelection";
import ZipCode from "../organisms/ZipCode";
import { utilityService } from "../../services/utility";
import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";

const validationGroup = {
  message: "There are some validation errors:",
  fields: {
    'facilityName': { filedName: 'Facility Name', required: true, minLength: 3, maxLength: 200 },
    'placeOfService': { filedName: 'Place Of Service', required: true },
    'npi': { filedName: 'NPI', required: true },
  }
}

class ManageFacility extends React.Component {
  state = {
    errors: [],
    facilityName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    contactName: "",
    contactPhone: "",
    contactFax: "",
    contactEmail: "",
    gpo: "",
    idn: "",
    npi: "",
    internalIdentifier: "",
    salesTax: "",
    caseCapacity: "",
    confirmationEmailAddresses: "",
    type: "",
    financialClearance: "no",
    emrAccess: "NA",
    placeOfService: "",
    invoice_req: "noneed",
    additionalContacts: "",
    price: "0",
  };

  componentDidMount() {
    const facilityId = this.props.location.state
      ? this.props.location.state.facilityId
      : "";
    if (facilityId) {
      facilityService.getFacilityDetail(facilityId).then((response) => {
        this.setState({
          price: response.data.price,
          invoice_req: response.data.invoice_req,
          facilityName: response.data.facility_name,
          address1: response.data.address,
          address2: response.data.address2,
          city: response.data.city,
          state: response.data.state,
          zip: response.data.zip_code,
          contactName: response.data.contact_name,
          contactEmail: response.data.contact_email,
          contactPhone: response.data.contact_phone,
          contactFax: response.data.contact_fax,
          gpo: response.data.gpo,
          idn: response.data.idn,
          npi: response.data.npi,
          internalIdentifier: response.data.Internal_Identifier,
          salesTax: response.data.children
            ? response.data.children.tax_percent
            : "",
          caseCapacity: response.data.children
            ? response.data.children.case_capacity
            : "",
          confirmationEmailAddresses: response.data.children
            ? response.data.children.day_confirm_email
            : "",
          type: response.data.children
            ? response.data.children.facility_type
            : "",
          financialClearance: response.data.children
            ? response.data.children.financial_clearance_facility
            : "",
          emrAccess: response.data.children
            ? response.data.children.facility_emr_access
            : "",
          placeOfService: response.data.children
            ? response.data.children.facility_place_of_Service
            : "",
          additionalContacts: response.data.children
            ? response.data.children.facility_additional_contact
            : "",
        });
      });
    }
  }

  validateActivity = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  zipCodeChanged = (value) => {
    this.setState({ zip: value.zip_code })

    if (value.state) {
      this.setState({ state: value.state.code });
    }

    if (value.city) {
      this.setState({ city: value.city.name });
    }
  }

  submitFacility = () => {
    if (!this.validateActivity()) {
      return;
    }

    const { createFacility, updateFacility } = this.props;
    const facilityId = this.props.location.state
      ? this.props.location.state.facilityId
      : "";

    const {
      price,
      facilityName,
      address1,
      address2,
      city,
      state,
      zip,
      contactName,
      contactPhone,
      contactFax,
      contactEmail,
      gpo,
      idn,
      npi,
      internalIdentifier,
      salesTax,
      caseCapacity,
      confirmationEmailAddresses,
      type,
      financialClearance,
      emrAccess,
      placeOfService,
      invoice_req,
      additionalContacts,
    } = this.state;

    const request = {
      price: price,
      facility_name: facilityName,
      address: address1,
      address2: address2,
      city: city,
      state: state,
      zip_code: zip,
      contact_name: contactName,
      contact_email: contactEmail,
      contact_phone: contactPhone,
      contact_fax: contactFax,
      gpo: gpo,
      idn: idn,
      npi: npi,
      tax_percent: salesTax,
      case_capacity: caseCapacity,
      day_confirm_email: confirmationEmailAddresses,
      facility_type: type,
      financial_clearance_facility: financialClearance,
      facility_emr_access: emrAccess,
      facility_place_of_Service: placeOfService,
      invoice_req: invoice_req,
      facility_additional_contact: additionalContacts,
      Internal_Identifier: internalIdentifier,
    };
    if (facilityId) {
      const payload = { ...request, id: facilityId, api_type: "react" };

      updateFacility(payload)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          }
        })
    } else {
      createFacility(request)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          }
        })
    }
  };

  render() {
    const facilityId = this.props.location.state
      ? this.props.location.state.facilityId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {facilityId && <h2 className="heading-custom">Edit Facility</h2>}
              {!facilityId && <h2 className="heading-custom">Add Facility</h2>}
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Facility Name {FormValidatorService.getRequiredHTML(validationGroup, "facilityName")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "facilityName")}
                value={this.state.facilityName}
                onChange={(event) => {
                  this.setState({ facilityName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Address Line 1</label>
              <input
                type="text"
                className="code-input"
                value={this.state.address1}
                onChange={(event) => {
                  this.setState({ address1: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Address Line 2</label>
              <input
                type="text"
                className="code-input"
                value={this.state.address2}
                onChange={(event) => {
                  this.setState({ address2: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <ZipCode title={'Zip Code (9 digit - No spaces)'}
                value={this.state.zip}
                onChange={(value) => {
                  this.setState({ 'zip': value });
                }}
                onBlur={(value) => {
                  this.zipCodeChanged(value);
                }}>
              </ZipCode>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <StateSelection
                key={this.state.state}
                label={'State'}
                isMulti={false}
                selectedStateCode={this.state.state}
                onChange={(value) => { this.setState({ state: value.value }) }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CitySelection
                key={"".concat(this.state.state, this.state.city)}
                label={'City'}
                isMulti={false}
                selectedStateCode={this.state.state}
                selectedCity={this.state.city}
                onChange={(value) => { console.log(value); this.setState({ city: value.value }) }}
              />
            </div>


            <div className="col-md-3 mrgn-btm10">
              <label>Main Contact Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.contactName}
                onChange={(event) => {
                  this.setState({ contactName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Main Contact Phone</label>
              <input
                type="text"
                className="code-input"
                value={utilityService.formatPhone(this.state.contactPhone)}
                onChange={(event) => {
                  this.setState({ contactPhone: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Main Contact Fax</label>
              <input
                type="text"
                className="code-input"
                value={utilityService.formatPhone(this.state.contactFax)}
                onChange={(event) => {
                  this.setState({ contactFax: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Main Contact Email</label>
              <input
                type="text"
                className="code-input"
                value={this.state.contactEmail}
                onChange={(event) => {
                  this.setState({ contactEmail: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>GPO</label>
              <input
                type="text"
                className="code-input"
                value={this.state.gpo}
                onChange={(event) => {
                  this.setState({ gpo: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>IDN</label>
              <input
                type="text"
                className="code-input"
                value={this.state.idn}
                onChange={(event) => {
                  this.setState({ idn: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Facility NPI {FormValidatorService.getRequiredHTML(validationGroup, "npi")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "npi")}
                value={this.state.npi}
                onChange={(event) => {
                  this.setState({ npi: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Internal Identifier</label>
              <input
                type="text"
                className="code-input"
                value={this.state.internalIdentifier}
                onChange={(event) => {
                  this.setState({ internalIdentifier: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Sales Tax Percent</label>
              <input
                type="text"
                className="code-input"
                value={this.state.salesTax}
                onChange={(event) => {
                  this.setState({ salesTax: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Case Capacity</label>
              <input
                type="text"
                className="code-input"
                value={this.state.caseCapacity}
                onChange={(event) => {
                  this.setState({ caseCapacity: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Day Confirmation Email Addresses (Comma Separated)</label>
              <input
                type="text"
                className="code-input"
                value={this.state.confirmationEmailAddresses}
                onChange={(event) => {
                  this.setState({
                    confirmationEmailAddresses: event.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomSelect type='Facility Type'
                className="filter-input"
                value={this.state.type}
                onChange={(event) =>
                  this.setState({ type: event.target.value })
                }>
              </CustomSelect>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomSelect type='YesNo' label='Financial Clearance'
                value={this.state.financialClearance}
                className="filter-input"
                onChange={(event) =>
                  this.setState({ financialClearance: event.target.value })
                }></CustomSelect>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomSelect type='EMR Access'
                value={this.state.emrAccess}
                className="filter-input"
                onChange={(event) =>
                  this.setState({ emrAccess: event.target.value })
                }>
              </CustomSelect>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <CustomSelect type='Place Of Service'
                isRequired={FormValidatorService.isRequired(validationGroup, "placeOfService")}
                value={this.state.placeOfService}
                className={FormValidatorService.getClass(validationGroup, this.state, "placeOfService")}
                onChange={(event) =>
                  this.setState({ placeOfService: event.target.value })
                }
              >
              </CustomSelect>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Additional Contact(s)</label>
              <input
                type="text"
                className="code-input"
                value={this.state.additionalContacts}
                onChange={(event) => {
                  this.setState({ additionalContacts: event.target.value });
                }}
              />
            </div>
            {/* <div className="col-md-4 mrgn-btm10">
              <label>One Price For All Procedures {FormValidatorService.getRequiredHTML(validationGroup, "price")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "facilityName")}
                value={this.state.price}
                onChange={(event) => {
                  this.setState({
                    price: event.target.value,
                  });
                }}
              />
            </div> */}
            <div className="col-md-3 mrgn-btm10">
              <label>Invoice Procedure CPT Requirements</label>
              <select
                value={this.state.invoice_req}
                className="filter-input"
                onChange={(event) =>
                  this.setState({ invoice_req: event.target.value })
                }
              >
                <option value="noneed">No Need</option>
                <option value="procedurename">Procedure Name</option>
                <option value="cptcode">Cpt Code</option>
                <option value="both">Procedure Name & CptCode</option>
              </select>
            </div>
          </div>

          <div className="filter-footer">
            {facilityId && (
              <button
                onClick={this.submitFacility}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!facilityId && (
              <button
                onClick={this.submitFacility}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div >
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createFacility,
      updateFacility,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageFacility));
