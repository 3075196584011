import { ACTIVITY } from '../constants/actions';
import { activityService } from '../services/activities';

export const fetchActivities = options => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .getActivities(options)
            .then(response => {
                dispatch({
                    type: ACTIVITY.FETCH_ACTIVITIES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const submitActivity = payload => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .submitActivity(payload)
            .then(response => {
                dispatch({
                    type: ACTIVITY.SUBMIT_ACTIVITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateActivity = payload => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .updateActivity(payload)
            .then(response => {
                dispatch({
                    type: ACTIVITY.UPDATE_ACTIVITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const deleteActivity = options => dispatch => {
    return new Promise((resolve, reject) => {
        activityService
            .deleteActivity(options)
            .then(response => {
                dispatch({
                    type: ACTIVITY.DELETE_ACTIVITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};