import { combineReducers } from 'redux';
import authenticationReducer from './authenticationReducer';
import activityReducer from "./activityReducer";
import dashboardReducer from "./dashboardReducer";
import patientReducer from "./patientReducer";
import notificationReducer from "./notificationReducer";
import organizationReducer from "./organizationReducer";
import surgeonReducer from "./surgeonReducer";
import procedureReducer from "./procedureReducer";
import assistantReducer from "./assistantReducer";
import physicianAssistantReducer from "./physicianAssistantReducer";
import facilityReducer from "./facilityReducer";
import contractReducer from "./contractReducer";
import userReducer from "./userReducer";
import knowledgeBaseCategoryReducer from "./knowledgeBaseCategoryReducer";
import knowledgeBaseSubCategoryReducer from "./knowledgeBaseSubCategoryReducer";
import knowledgeBaseArticleReducer from "./knowledgeBaseArticleReducer";
import surgeonAssociationReducer from "./surgeonAssociationReducer";
import groupReducer from "./groupReducer";
import claimsReducer from "./claimsReducer";
import regionReducer from "./regionReducer";
import caseLogReducer from "./caseLogReducer";
import billingReducer from "./billingReducer";
import billingsReducer from "./billingsReducer";
import insuranceReducer from "./insuranceReducer";
import practiceReducer from "./practiceReducer";
import batchReducer from "./batchReducer";
import accountReducer from "./accountReducer";
import invoicingReducer from "./invoicingReducer";
import divisionReducer from "./divisionReducer";
import invoicingV1Reducer from "./invoicingV1Reducer";
import logsReducer from './logsReducer';
import clientReducer from './clientReducer';
import feedbackReducer from './feedbackReducer';

export default combineReducers({
    authenticationReducer,
    assistantReducer,
    physicianAssistantReducer,
    activityReducer,
    dashboardReducer,
    patientReducer,
    organizationReducer,
    surgeonReducer,
    procedureReducer,
    facilityReducer,
    clientReducer,
    contractReducer,
    invoicingReducer,
    divisionReducer,
    invoicingV1Reducer,
    logsReducer,
    userReducer,
    knowledgeBaseArticleReducer,
    knowledgeBaseCategoryReducer,
    knowledgeBaseSubCategoryReducer,
    groupReducer,
    claimsReducer,
    caseLogReducer,
    billingReducer,
    billingsReducer,
    insuranceReducer,
    practiceReducer,
    regionReducer,
    batchReducer,
    notificationReducer,
    feedbackReducer,
    accountReducer,
    surgeonAssociationReducer
});