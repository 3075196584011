import React from "react";
import { City, State } from 'country-state-city'
import { withRouter } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";

class CitySelection extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        isMulti: PropTypes.bool.isRequired,
        selectedStateCode: PropTypes.string.isRequired,
        selectedCity: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        cities: [],
    };

    selectedCountry = 'US';
    cities = [];

    constructor(props) {
        super(props);
        this.cancel = "";
    }

    componentDidMount() {
        this.cities = City.getCitiesOfState(this.selectedCountry, this.props.selectedStateCode);
        if (this.cities.length === 0) {
            const tstate = State.getStateByCode(this.props.selectedStateCode);
            if (tstate) {
                this.setState({ selectedCity: { label: tstate.name, value: tstate.isoCode } });
            }
        }
        else {
            const tCity = this.cities.find(tCity => tCity.name === this.props.selectedCity);
            if (tCity) {
                this.setState({ selectedCity: { label: tCity.name, value: tCity.name } });
            }
        }

    }

    loadOptions = (search, prevOptions) => {
        const convertedCities = this.cities.map((city) => ({
            'value': city.name,
            'label': city.name
        }))
        let filteredOptions;
        if (!search) {
            filteredOptions = convertedCities;
        } else {
            const searchLower = search.toLowerCase();

            filteredOptions = convertedCities.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }
        const hasMore = filteredOptions.length > prevOptions.length + 10;

        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );

        return {
            options: slicedOptions,
            hasMore
        };
    }

    render() {
        return (
            <>
                <label>{this.props.label}</label>
                <AsyncPaginate
                    placeholder={"Select " + this.props.label}
                    loadOptions={this.loadOptions}
                    isMulti={this.props.isMulti}
                    defaultOptions={[
                        {
                            value: "",
                            label: "Select " + this.props.label,
                        },
                    ]}
                    value={this.state.selectedCity}
                    onChange={(value) => this.props.onChange(value)}
                />
            </>
        )
    }
}


export default withRouter(CitySelection);
