import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

import loaderImage from "../../../assets/images/CoureMax.gif";
import LoadingOverlay from "react-loading-overlay";
import TablePagination from "../organisms/TablePagination";

export default class LogsListingTable extends React.Component {
  state = {
    fee_sum: 0,
    row_Color: "",
    row_Color_Text: "",
  };

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
    allChecked: PropTypes.bool.isRequired,
    addAllCheckedItem: PropTypes.func.isRequired,
    removeAllCheckedItem: PropTypes.func.isRequired,
  };
  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column, index) => {
      if (index === 1 || index === 2) {
        return (
          <td
            className="listing-table-head-column"
          >
            {column}
          </td>
        );
      } else {
        return <th className="listing-table-head-column">{column}</th>;
      }
    });
    return header;
  };

  renderList = () => {
    const { listing = [] } = this.props;
    return (
      listing.length > 0 &&
      listing.map((listRow) => {
        const item = listRow.map((listItem, index) => {                 //eslint-disable-line
          if (index === 0) {
            return <td className="listing-table-column">{listItem}</td>;
          } else if (index < listRow.length - 1) {
            if (index === 2 || index === 3) {
              return (
                <td
                  className="listing-table-column"
                >
                  {listItem}
                </td>
              );
            }

            return (
              <td
                className="listing-table-column"
                style={{
                  backgroundColor: this.state.row_Color,
                  color: this.state.row_Color_Text + "!important",
                  textAlign: "center",
                }}
              >
                <p data-tip={listItem}>
                  <span id={`TooltipExample`}>
                    {index === 0 || (listItem && listItem.length < 12)
                      ? listItem
                      : listItem && listItem}
                  </span>
                </p>
              </td>
            );
          }
        });

        return <tr className="listing-table-rows">{item}</tr>;
      })
    );
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive">
            <LoadingOverlay
              active={this.state.loading}
              spinner={
                <img
                  style={{
                    width: "200px",
                  }}
                  src={loaderImage}
                  alt="loading..."
                />
              }
            >
              <table className="listing-table">
                <thead className="listing-table-head">
                  <tr>{this.renderHeadColumn()}</tr>
                </thead>
                {this.props.isLoading && <Loader width="578%" />}
                {!this.props.isLoading && (
                  <tbody>
                    {this.renderList()}
                    {/* <tr
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      fontSize: 30,
                      textAlign: "center",
                    }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td>${this.state.fee_sum}</td>
                  </tr> */}
                  </tbody>
                )}
              </table>
            </LoadingOverlay>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}
