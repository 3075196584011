import React from "react";
import PropTypes from "prop-types";
import {
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import UploadDocument from "../atom/UploadDocument";
import BillingNotes from "../atom/BillingNotes";
import BillPopup from "./BillPopup";
import PatientPopup from "../atom/PatientPopup";
import EventPopup from "../atom/EventPopup";
import "../../../sass/listingtable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "../../utils/date";
import { APP_ROUTES, NOTIFICATION_TYPES } from "../../constants/common";
import { RevenueSchedulerService } from "../../services/revenuescheduler";
import { createNotification } from "../../utils/notificationManager";
import { billingService } from "../../services/billing";

import ManagePatient from "../molecules/ManagePatient";
import TablePagination from "../organisms/TablePagination";
class RevenueSchedulerListingTable extends React.Component {
  static propTypes = {
    checkedBillings: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    allChecked: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    addCheckedItem: PropTypes.func.isRequired,
    addAllCheckedItem: PropTypes.func.isRequired,
    removeAllCheckedItem: PropTypes.func.isRequired,
    removeCheckedItem: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
  };

  state = {
    billingId: "",
    bill: {},
    patient: {},
    isDialogOpen: false,
    billingNotesOpen: false,
    BillPopupOpen: false,
    patinetPopupOpen: false,
    eventPopupOpen: false,
    title: "",
    eventId: "",
    bill_Id: "",
    patientId: "",
    type: "",
    billing_notes: "",
  };
  toggleBillTable = (value) => {
    // alert(value);
    const { billingId } = this.state;
    this.setState({ loading: true });
    if (billingId === value) {
      this.setState({
        billingId: "",
        loading: false,
      });
    } else {
      this.setState({ billingId: value }, () => {
        const { billingId } = this.state;
        billingService.getEventBill(billingId).then((response) => {
          this.setState({
            bill: response.data.bill,
            patient: response.data.patient,
            loading: false,
          });
        });
      });
    }
  };

  editBill = (billId, eventId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_BILL,
      state: {
        billId,
        eventId,
      },
    });

  createBill = (eventId) =>
    // {
    //   const win = window.open(APP_ROUTES.ADD_BILL, "_blank");
    // win.focus()
    // }

    this.props.history.push({
      pathname: APP_ROUTES.ADD_BILL,
      state: {
        eventId,
      },
    });

  renderBillList = () => {
    const { billingId, bill, patient } = this.state;
    if (bill) {
      return (
        <>
          <td></td>
          <td
            style={{
              backgroundColor: "#475f87",
            }}
            className="listing-table-column"
          >
            <span
              style={{
                color: "white",
                width: "100px",
              }}
            >
              Event Bill
            </span>
          </td>
          <td className="listing-table-column">
            {bill ? bill.old_bill_id : ""}
          </td>
          <td className="listing-table-column">{billingId}</td>
          <td className="listing-table-column">{patient ? patient.id : ""}</td>
          <td className="listing-table-column">
            {patient ? patient.first_name : ""}{" "}
            {patient ? patient.last_name : ""}
          </td>
          <td className="listing-table-column">
            {bill ? formatDate(bill.service_date) : ""}
          </td>
          <td colSpan="2" className="listing-table-column">
            <button
              onClick={() => this.editBill(bill.old_bill_id, billingId)}
              className="filter-save-button"
            >
              Update Bill
            </button>
          </td>
        </>
      );
    } else {
      return (
        <td colSpan="8" className="listing-table-column">
          <button
            style={{
              width: "200px",
            }}
            onClick={() => this.createBill(billingId)}
            className="filter-save-button"
          >
            Create a Bill
          </button>
        </td>
      );
    }
  };

  renderBillingTable = () => (
    <>
      <td className="listing-table-head-column" />
      <td></td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Bill Id
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Event Id
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Patient Id
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        Patient
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
      >
        DOS
      </td>
      <td
        style={{
          color: "white",
        }}
        className="listing-table-head-column"
        colSpan="2"
      >
        Action
      </td>
    </>
  );

  setDialogOpen = (flag) => {
    this.setState({ isDialogOpen: flag });
  };
  setbillingNotesOpen = (flag) => {
    this.setState({ billingNotesOpen: flag });
  };
  setBillPopupOpen = (flag) => {
    this.setState({ BillPopupOpen: flag });
  };
  setpatinetPopupOpen = (flag) => {
    this.setState({ patinetPopupOpen: flag });
  };
  seteventPopupOpen = (flag) => {
    this.setState({ eventPopupOpen: flag });
  };
  renderHeadColumn = () => {
    const { headColumn } = this.props;
    const header = headColumn.map((column) => (
      <th
        className="listing-table-head-column"
        style={{
          minWidth: "135px",
        }}
      >
        {column}
      </th>
    ));

    const selectorElement = (
      <th className="listing-table-head-column">
        <input
          type="checkbox"
          id="check-all"
          checked={this.props.allChecked}
          onChange={(event) =>
            event.target.checked
              ? this.props.addAllCheckedItem()
              : this.props.removeAllCheckedItem()
          }
        />
      </th>
    );

    header.splice(0, 0, selectorElement);
    return header;
  };
  getFirstLetters(str) {
    const firstLetters = str
      .split(" ")
      .map((word) => word.charAt(0))
      .join("");

    return firstLetters;
  }

  handlePatient = (eventId) => {
    return (
      <>
        <ManagePatient></ManagePatient>
      </>
    );
  };
  handleSetReady(eventId, ready_to_code) {
    const formData = new FormData();
    formData.append("event_id", eventId);
    formData.append("set_ready_check", 1);
    formData.append("ready_to_code", ready_to_code);
    RevenueSchedulerService.updateRevenueScheduler(formData)
      .then((res) => {
        if (res.data.success) {
          // this.props.history.push("billing_notes:hello");
          // this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
        } else {
          // this.props.setModal(false);
          createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
          this.props.goToPage(1);
        }
      })
  }
  renderList = () => {
    const tdlineheight = {
      lineHeight: 2,
    };
    const { listing = [] } = this.props;
    const { isDialogOpen } = this.state;
    const {
      billingNotesOpen,
      BillPopupOpen,
      patinetPopupOpen,
      eventPopupOpen,
    } = this.state;

    return listing.map((listRow) => {
      const item = listRow.map((listItem, index) => {     //eslint-disable-line
        if (index === 0) {
          return (
            <td className="listing-table-column" style={tdlineheight}>
              <input
                type="checkbox"
                id="check-all"
                checked={this.props.checkedBillings.indexOf(listItem) > -1}
                onChange={(event) =>
                  event.target.checked
                    ? this.props.addCheckedItem(listItem)
                    : this.props.removeCheckedItem(listItem)
                }
              />
            </td>
          );
        } else if (index === 31) {
          let primary_id = "";
          let primary_payer_name = "";
          let secondary_payer_name = "";
          let assistant_npi = "";
          let assistant_certificate = "";
          let facility_emr_access = "";
          let patient_insurance_data_entered = "No";
          let facesheet_date = "";
          let op_report_date = "";
          let market_id = "";
          let region_name = "";
          if (listItem.patient.patient_insurance_data != undefined) {             //eslint-disable-line
            if (listItem.patient.patient_insurance_data.primary != undefined) {   //eslint-disable-line
              primary_id = listItem.patient.patient_insurance_data.primary.id;
              primary_payer_name =
                listItem.patient.patient_insurance_data.primary.description;
              patient_insurance_data_entered = "Yes";
            } else {
              primary_id = 0;
              primary_payer_name = "";
            }
            if (
              listItem.patient.patient_insurance_data.secondary != undefined    //eslint-disable-line
            ) {
              secondary_payer_name =
                listItem.patient.patient_insurance_data.description;
            } else {
              secondary_payer_name = "";
            }
          }
          if (listItem.assistant_table != undefined) {                        //eslint-disable-line
            assistant_npi = listItem.assistant_table.npi;
            if (listItem.assistant_table.certificate) {
              if (listItem.assistant_table.certificate.name != undefined) {   //eslint-disable-line
                assistant_certificate =
                  listItem.assistant_table.certificate.name;
              }
            }
          }
          //  else if (listItem.assistant != undefined) {
          //   assistant_npi = listItem.assistant_table.npi;
          // }
          else {
            assistant_npi = 0;
          }
          if (listItem.facility.children != undefined) {                    //eslint-disable-line
            facility_emr_access =
              listItem.facility.children.facility_emr_access;
          } else {
            facility_emr_access = "";
          }
          if (listItem.facesheets != undefined) {                         //eslint-disable-line
            facesheet_date = listItem.facesheets[0].created_at;
          } else {
            facesheet_date = "Not Uploaded";
          }
          if (listItem.op != undefined && listItem.op.length > 0) {     //eslint-disable-line
            op_report_date = listItem.op[0].created_at;
          } else {
            op_report_date = "Not Uploaded";
          }
          if (listItem.facility) {
            if (listItem.facility.children) {
              market_id = listItem.facility.children.market_id;
              region_name = listItem.facility.children.market_id;
            } else {
              market_id = "0";
              region_name = "";
            }
          } else {
            market_id = "0";
            region_name = "";
          }
          return (
            <React.Fragment>
              <td className="listing-table-column">
                {listItem.id}
                <button
                  className="filter-save-button btn-sm mt-2"
                  onClick={() => {
                    this.setState({
                      eventId: listItem.id,
                      eventPopupOpen: !eventPopupOpen,
                      title: "Event Detail",
                    });
                  }}
                >
                  {"Event Details"}
                </button>
              </td>
              <td className="listing-table-column">{listItem.patient.id}</td>
              <td className="listing-table-column">
                {listItem.patient.first_name}
                <button
                  className="filter-save-button btn-sm mt-2"
                  // onClick={() =>
                  //   this.handlePatient(listItem.id, listItem.patient.id)
                  // }
                  onClick={() => {
                    this.setState({
                      eventId: listItem.id,
                      patientId: listItem.patient.id,
                      patinetPopupOpen: !patinetPopupOpen,
                      title: "Patient Detail",
                    });
                  }}
                >
                  {"Patient Details"}
                </button>
              </td>
              <td className="listing-table-column">
                {listItem.patient.date_of_birth}
              </td>
              <td
                className="listing-table-column"
                style={{
                  color: listItem.has_bill != undefined ? "black" : "red",        //eslint-disable-line
                }}
              >
                {listItem.has_bill ? listItem.has_bill : "No Bill"}

                <button
                  className="filter-save-button btn-sm mt-2"
                  // onClick={() => this.toggleBillTable(listItem.id)}
                  onClick={() => {
                    this.setState({
                      eventId: listItem.id,
                      bill_Id: listItem.bill_ids,
                      BillPopupOpen: !BillPopupOpen,
                      title: "Billing Detail",
                    });
                  }}
                >
                  Billing Details
                </button>
              </td>
              <td className="listing-table-column">
                {listItem.relief_id ? listItem.relief_id : "0"}
              </td>
              <td className="listing-table-column">
                {listItem.ready_to_code ? listItem.ready_to_code : "NO"}
                <button
                  className="filter-save-button btn-sm mt-2"
                  onClick={() =>
                    this.handleSetReady(listItem.id, listItem.ready_to_code)
                  }
                >
                  {listItem.ready_to_code ? (
                    <p>Set Not Ready</p>
                  ) : (
                    <p>Set As Ready</p>
                  )}
                </button>
              </td>
              <td
                className="listing-table-column"
                style={{
                  color: listItem.has_bill != undefined ? "black" : "red",            //eslint-disable-line
                }}
              >
                {listItem.has_bill ? listItem.has_bill : ""}
                <br></br>
                Bill ID: {listItem.bill_ids ? listItem.bill_ids : ""}
              </td>

              <td className="listing-table-column">
                <p>{listItem.billing_notes}</p>
                <button
                  className="filter-save-button btn-sm mt-2"
                  onClick={() => {
                    this.setState({
                      eventId: listItem.id,
                      billingNotesOpen: !billingNotesOpen,
                      title: "Add Billing Notes",
                      billing_notes: listItem.billing_notes,
                    });
                  }}
                >
                  {listItem.billing_notes != null ? (
                    <p>See Notes</p>
                  ) : (
                    <p>Add Notes</p>
                  )}
                  {/* Add Notes */}
                </button>
              </td>

              <td className="listing-table-column">
                {listItem.assistant ? listItem.assistant.last_name : ""}
              </td>
              <td className="listing-table-column">
                {listItem.assistant ? listItem.assistant.name : ""}
              </td>
              <td className="listing-table-column">{assistant_npi}</td>
              <td className="listing-table-column">{assistant_certificate}</td>
              <td className="listing-table-column">{listItem.surgeon.id}</td>
              <td className="listing-table-column">
                {listItem.surgeon.first_name}
                {listItem.surgeon.last_name}
              </td>
              <td className="listing-table-column">{listItem.surgeon.npi}</td>
              <td className="listing-table-column" style={tdlineheight}>
                {listItem.event_notes}
              </td>

              <td className="listing-table-column">{listItem.facility.id}</td>

              <td className="listing-table-column" style={tdlineheight}>
                {listItem.facility.facility_name}
              </td>
              <td className="listing-table-column">
                {this.getFirstLetters(listItem.facility.facility_name)}
              </td>

              <td className="listing-table-column">{facility_emr_access}</td>
              <td className="listing-table-column">{market_id}</td>
              <td className="listing-table-column">{region_name}</td>
              <td className="listing-table-column">
                {listItem.event_start_date}
              </td>
              <td className="listing-table-column">
                {listItem.event_start_time}
              </td>
              <td className="listing-table-column">
                {listItem.created_by?.last_name} {listItem.created_by?.name}
              </td>
              <td className="listing-table-column">
                {listItem.event_created_at}
              </td>
              <td className="listing-table-column">{primary_id}</td>
              <td className="listing-table-column" style={tdlineheight}>
                {primary_payer_name}
              </td>
              <td className="listing-table-column">{secondary_payer_name}</td>
              <td className="listing-table-column">
                {listItem.pre_authorization_number}
              </td>
              <td className="listing-table-column">
                {patient_insurance_data_entered}
              </td>
              <td className="listing-table-column" style={tdlineheight}>
                {facesheet_date}
                <div
                  onClick={() =>
                    this.setState(
                      {
                        eventId: listItem.id,
                        type: "facesheet",
                        title: "Facesheet File",
                      },
                      () => {
                        this.setState({ isDialogOpen: !isDialogOpen });
                      }
                    )
                  }
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    size="3x"
                    color={listRow[1].file ? "#D11516" : "#475f87"}
                  />
                </div>
              </td>
              <td className="listing-table-column" style={tdlineheight}>
                {op_report_date}
              </td>
              <td className="listing-table-column" style={tdlineheight}>
                {listItem.event_notes}
              </td>
              <td className="listing-table-column">{listItem.cpt_codes}</td>
              <td className="listing-table-column">
                {listItem.diagnosis_codes}
              </td>
              <td className="listing-table-column">
                {listItem.event_type.event_type_name}
              </td>
            </React.Fragment>
          );
        }
      });
      return (
        <>
          <tr className="listing-table-rows">{item}</tr>

          {this.state.billingId === listRow[listRow.length - 1].id && (
            <tr
              style={{
                backgroundColor: "#475f87",
              }}
              className="listing-table-rows"
            >
              {this.renderBillingTable()}
            </tr>
          )}
          {this.state.billingId === listRow[listRow.length - 1].id && (
            <tr
              style={{
                backgroundColor: "#ffffff",
              }}
              className="listing-table-rows"
            >
              {this.renderBillList()}
            </tr>
          )}
        </>
      );
    });
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div
            className="table-responsive"
            style={
              {
                // height: "600px",
                // maxHeight: "600px",
                // overflowY: "scroll",
              }
            }
          >
            {this.state.isDialogOpen && (
              <UploadDocument
                goToPage={this.props.goToPage}
                title={this.state.title}
                isOpen={this.state.isDialogOpen}
                setModal={this.setDialogOpen}
                eventId={this.state.eventId}
                type={this.state.type}
              />
            )}
            {this.state.billingNotesOpen && (
              <BillingNotes
                goToPage={this.props.goToPage}
                title={this.state.title}
                isOpen={this.state.billingNotesOpen}
                setModal={this.setbillingNotesOpen}
                eventId={this.state.eventId}
                billingNotes={this.state.billing_notes}
              />
            )}
            {this.state.BillPopupOpen && (
              <BillPopup
                goToPage={this.props.goToPage}
                title={this.state.title}
                isOpen={this.state.BillPopupOpen}
                setModal={this.setBillPopupOpen}
                eventId={this.state.eventId}
                bill_Id={this.state.bill_Id}
              />
            )}
            {this.state.patinetPopupOpen && (
              <PatientPopup
                goToPage={this.props.goToPage}
                title={this.state.title}
                isOpen={this.state.patinetPopupOpen}
                setModal={this.setpatinetPopupOpen}
                eventId={this.state.eventId}
                patientId={this.state.patientId}
              />
            )}
            {this.state.eventPopupOpen && (
              <EventPopup
                goToPage={this.props.goToPage}
                title={this.state.title}
                isOpen={this.state.eventPopupOpen}
                setModal={this.seteventPopupOpen}
                eventId={this.state.eventId}
                patientId={this.state.patientId}
              />
            )}

            <table className="listing-table">
              <thead className="listing-table-head">
                <tr>{this.renderHeadColumn()}</tr>
              </thead>

              <tbody>{!this.props.isLoading && this.renderList()}</tbody>
            </table>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}

export default withRouter(RevenueSchedulerListingTable);
