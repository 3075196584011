import React from "react";
import {
  INVOICING_V1_LISTING_COLUMNS,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { fetchInvoicing } from "../../actions/invoicingv1";
import InvoicingListingV1Table from "../atom/InvoicingListingV1Table";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { formatDate } from "../../utils/date";
import InvoicingV1Filters from "../organisms/InvoicingV1Filters";
import ViewInvoicePopup from "../atom/ViewInvoicePopup";
import { utilityService } from "../../services/utility";
import { invoicingV1Service } from "../../services/invoicingv1";

class InvoicingV1Dashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    fetchInvoicing: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    fromDate: "",
    toDate: "",
    flipAssistant: false,
    pageSize: RECORDS_PER_PAGE,
    manageInvoicePopup: false,
    selectedInvoiceWithDetails: []
  };

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {
    this.setState({ invoicingv1: [], totalRecordsv1: 0 });
  }

  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };
    this.props.fetchInvoicing({ params: options }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  }
  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };
  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page < 0 ? 1 : page,
      page_size: this.state.pageSize,
    };
    this.props.fetchInvoicing({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  setToDate = (date) => {
    this.setState({ toDate: date });
  };

  setRegion = (value) => {
    this.setState({
      region: value,
      flipAssistant: !this.state.flipAssistant,
    });
  };

  setAssistant = (value) => {
    this.setState({ assistant: value });
  };

  setSurgeon = (value) => {
    this.setState({ surgeon: value });
  };
  setSearchInvoice = (value) => {
    this.setState({ searchByName: value });
  };
  setFacility = (value) => {
    this.setState({ facility: value });
  };

  setPatient = (value) => {
    this.setState({ patient: value });
  };

  setCreatedBy = (value) => {
    this.setState({ createdBy: value });
  };

  setStatus = (value, page) => {
    this.setState({ status: value }, () => {
      if (page) {
        this.goToPage(page);
      }
    });
  };

  setFlipAssistant = (value) => {
    this.setState({ flipAssistant: value });
  };
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  addFilters = (options) => {
    const {
      fromDate,
      toDate,
      region,
      assistant,
      surgeon,
      facility,
      patient,
      status,
      createdBy,
      searchByName,
    } = this.state;
    if (fromDate) {
      options = { ...options, from_date: formatDate(fromDate) };
    }
    if (toDate) {
      options = { ...options, to_date: formatDate(toDate) };
    }
    if (region && region.value) {
      options = { ...options, company_id: region.value };
    }
    if (assistant && assistant.value) {
      options = { ...options, assistant_id: assistant.value };
    }
    if (surgeon && surgeon.value) {
      options = { ...options, physician_id: surgeon.value };
    }
    if (facility && facility.value) {
      options = { ...options, facility_id: facility.value };
    }
    if (patient && patient.value) {
      options = { ...options, patient_id: patient.value };
    }
    if (status && status.value) {
      options = { ...options, status: status.value };
    }
    if (createdBy && createdBy.value) {
      options = { ...options, created_by: createdBy.value };
    }
    if (searchByName) {
      options = { ...options, invoicenumber: searchByName };
    }
    return options;
  };

  search = () => {
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };

    this.props
      .fetchInvoicing({ params: this.addFilters(options) })
      .then((res) => {
      }).finally(() => {
        this.setState({ loading: false });
      });
  };

  setInvoicePopupOpen = (flag) => {
    this.setState({ manageInvoicePopup: flag });
  };

  setSelectedInvoiceDetails = (invoice) => {
    this.setState({ selectedInvoiceWithDetails: invoice }, () => {
      this.setInvoicePopupOpen(true);
    });
  }

  startInvoice = (invoice) => {
    utilityService.showConfirmDialog("Invoice", "Do you want to start the invoice?", "info", true, '#d33', '#3085d6', 'Yes, Start Invoice').then(data => {
      let tObject = { invoice_id: invoice.id };
      invoicingV1Service.startInvoiceV1(tObject).then(temp => {
        this.goToPage(1);
      })
    })
  }

  emailInvoice = (invoice, paymententity) => {
    utilityService.showConfirmDialog("Invoice", "Do you want to send invoice to " + paymententity + "?", "info", true, '#d33', '#3085d6', 'Yes, Send Invoice').then(data => {
      let tObject = { invoice_id: invoice.id, payment_entity: paymententity };
      invoicingV1Service.sendInvoiceV1(tObject).then(response => {
        utilityService.showHTMLMessage('Invoice Email', response.message);
        this.goToPage(this.state.currentPage);
      })
    })
  }

  submitClaim = (invoice, paymententity) => {
    utilityService.showConfirmDialog("Invoice", "Do you want to submit claim to " + paymententity + "?", "info", true, '#d33', '#3085d6', 'Yes, Submit Claim').then(data => {
      let tObject = { invoice_id: invoice.id, payment_entity: paymententity };
      invoicingV1Service.submitClaimV1(tObject).then(response => {
        utilityService.showHTMLMessage('Submt claim', response.message);
        this.goToPage(this.state.currentPage);
      })
    })
  }

  render() {
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />

        <div
          style={{
            minHeight: "760px",
          }}
          className="right-col"
        >
          <InvoicingV1Filters
            goToPage={this.goToPage}
            exportEnabled={this.exportEnabled}
            search={this.search}
            setFromDate={this.setFromDate}
            setToDate={this.setToDate}
            setRegion={this.setRegion}
            setAssistant={this.setAssistant}
            setSurgeon={this.setSurgeon}
            setSearchInvoice={this.setSearchInvoice}
            setFacility={this.setFacility}
            setPatient={this.setPatient}
            setStatus={this.setStatus}
            setFlipAssistant={this.setFlipAssistant}
            setCreatedBy={this.setCreatedBy}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            region={this.state.region}
            assistant={this.state.assistant}
            surgeon={this.state.surgeon}
            facility={this.state.facility}
            patient={this.state.patient}
            status={this.state.status}
            createdBy={this.state.createdBy}
            flipAssistant={this.state.flipAssistant}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
          </div>
          <div className="row">
            <div className="activities-header">
              <InvoicingListingV1Table
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                headColumn={INVOICING_V1_LISTING_COLUMNS}
                listing={this.props.invoicingv1}
                totalRecords={this.props.totalRecordsv1}
                setSelectedInvoiceDetails={this.setSelectedInvoiceDetails}
                startInvoice={this.startInvoice}
                emailInvoice={this.emailInvoice}
                submitClaim={this.submitClaim}
                setPageSize={this.setPageSize}
                pageSize={this.state.pageSize}
              />
            </div>
          </div>
        </div>

        {this.state.manageInvoicePopup && (
          <ViewInvoicePopup
            isView={true}
            title={'View Invoice'}
            isOpen={this.state.manageInvoicePopup}
            setModal={this.setInvoicePopupOpen}
            selectedInvoice={this.state.selectedInvoiceWithDetails}>
          </ViewInvoicePopup>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoicingv1: state.invoicingV1Reducer.invoicingv1,
    totalRecordsv1: state.invoicingV1Reducer.totalInvoicingv1,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoicing,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoicingV1Dashboard));
