import { INVOICINGV1 } from '../constants/actions';
import { invoicingV1Service } from '../services/invoicingv1';

export const fetchInvoicing = options => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingV1Service
            .getInvoicing(options)
            .then(response => {
                addAggregationResponse(response);
                dispatch({
                    type: INVOICINGV1.FETCH_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const submitInvoicing = payload => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingV1Service
            .submitInvoicing(payload)
            .then(response => {
                addAggregationResponse(response);
                dispatch({
                    type: INVOICINGV1.SUBMIT_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateInvoicing = payload => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingV1Service
            .updateInvoicing(payload)
            .then(response => {
                addAggregationResponse(response);
                dispatch({
                    type: INVOICINGV1.UPDATE_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};


export const deleteActivity = options => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingV1Service
            .deleteActivity(options)
            .then(response => {
                dispatch({
                    type: INVOICINGV1.DELETE_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

function addAggregationResponse(response) {
    response.data.forEach(r => {
        let billIds = [];
        let payment = [];
        let paymentString = [];
        let payment_pinsurances = false;
        let payment_sinsurances = false;
        let payment_facility = false;
        let payment_provider = false;
        let payment_surgeon = false;
        let payment_patient = false;

        r.details.forEach(d => {
            billIds.push(d.bill_id);

            d.payments.forEach(tpayment => {
                paymentString.push(tpayment.type_label);
                payment.push({ label: tpayment.type_label, amount: parseFloat(tpayment.amount_due) });

                if (tpayment.type_label === 'Primary Insurance')
                    payment_pinsurances = true;
                else if (tpayment.type_label === 'Secondary Insurance')
                    payment_sinsurances = true;
                else if (tpayment.type_label === 'Facility')
                    payment_facility = true;
                else if (tpayment.type_label === 'Provider')
                    payment_provider = true;
                else if (tpayment.type_label === 'Surgeon')
                    payment_surgeon = true;
                else if (tpayment.type_label === 'Patient')
                    payment_patient = true;
            })
        })

        r.payment_pinsurances = payment_pinsurances;
        r.payment_sinsurances = payment_sinsurances;
        r.payment_facility = payment_facility;
        r.payment_provider = payment_provider;
        r.payment_surgeon = payment_surgeon;
        r.payment_patient = payment_patient;

        r.billIds = billIds.join(",");
        r.payments = payment;
        r.paymentString = paymentString.join(" / ");
    })
}