import { KNOWLEDGE_BASE_SUB_CATEGORY } from '../constants/actions';
import { knowledgeBaseSubCategoryService } from '../services/knowledgeBaseSubCategories';


export const fetchSubCategories = options => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseSubCategoryService
            .getSubCategories(options)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_SUB_CATEGORY.FETCH_SUB_CATEGORY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createSubCategory = payload => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseSubCategoryService
            .createSubCategory(payload)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_SUB_CATEGORY.CREATE_SUB_CATEGORY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateSubCategory = payload => dispatch => {
    return new Promise((resolve, reject) => {
        knowledgeBaseSubCategoryService
            .updateSubCategory(payload)
            .then(response => {
                dispatch({
                    type: KNOWLEDGE_BASE_SUB_CATEGORY.UPDATE_SUB_CATEGORY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
