import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getCountries() {
    return apiClient()
        .get(API_CONSTANTS.GET_COUNTRIES)
        .then(handleResponse)
        .catch(handleError);
}

function getStates(country) {
    return apiClient()
        .get(API_CONSTANTS.GET_STATES + country)
        .then(handleResponse)
        .catch(handleError);
}

function getCities(country, state) {
    return apiClient()
        .get(API_CONSTANTS.GET_COUNTRIES + country + '/' + state)
        .then(handleResponse)
        .catch(handleError);
}

function getZipCodeDetails(zipcode) {
    return apiClient()
        .get(API_CONSTANTS.GET_ZIP_DETAILS + zipcode)
        .then(handleResponse)
        .catch(handleError);
}

export const locationService = {
    getCountries,
    getStates,
    getCities,
    getZipCodeDetails
}