import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { fetchNotifications } from '../../actions/notifications';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import NotificationListingTable from "../atom/NotificationListingTable";
import { APP_ROUTES, LOCAL_STORAGE_KEYS, NOTIFICATION_LISTING_COLUMN } from "../../constants/common";
import moment from "moment";

class NotificationDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        pageSize: 10,
    };

    componentDidMount() {
        this.setState({ loading: true });
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        const options = {
            page: this.state.currentPage,
            page_size: this.state.pageSize,
        };
        this.props.fetchNotifications({ params: options }, loggedInUser.id)
            .then(res => {
                this.setState({ loading: false });
            });
    }

    setPageSize = (value) => {
        this.setState({ pageSize: value }, () => {
            this.goToPage(1);
        });
    };

    list = () => {
        const { notifications = [] } = this.props;
        return notifications.map(notification => {
            return [
                notification.title,
                moment(notification.created_at).fromNow(),
                `${notification.notif_msg} by 
                ${notification.event.user ? notification.event.user.name : ''} ${notification.event.user ? notification.event.user.last_name : ''}`,
                notification.event && notification.event.facility ? notification.event.facility.facility_name : '',
                notification.event ? moment(notification.event.event_start_date).fromNow() : '',
                notification.event ? notification.event.old_event_id : '',
            ];
        });
    };

    view = (activityId) => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_ACTIVITY,
            state: {
                activityId,
                screen: APP_ROUTES.NOTIFICATION
            }
        });
    };

    goToPage = (page) => {
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        const options = {
            page: page,
            page_size: this.state.pageSize,
        };

        this.setState({
            loading: true,
            currentPage: page,
        });
        this.props.fetchNotifications({ params: options }, loggedInUser.id)
            .then(res => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage}  />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '760px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Notifications</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <NotificationListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                headColumn={NOTIFICATION_LISTING_COLUMN}
                                listing={this.list()}
                                view={this.view}
                                totalRecords={this.props.totalRecords}
                                pageSize={this.state.pageSize}
                                setPageSize={this.setPageSize}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notificationReducer.notifications,
        totalRecords: state.notificationReducer.totalNotifications,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchNotifications,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(NotificationDashboard));