import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import loaderImage from "../../../assets/images/CoureMax.gif";
import TablePagination from "../organisms/TablePagination";

export default class FeedbackListingTable extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        currentPage: PropTypes.number.isRequired,
        goToPage: PropTypes.func.isRequired,
        headColumn: PropTypes.array.isRequired,
        listing: PropTypes.array.isRequired,
        totalRecords: PropTypes.number.isRequired,
    };

    renderHeadColumn = () => {
        const { headColumn } = this.props;

        const header = headColumn.map((column) => {
            return (
                <th className="listing-table-head-column">
                    {column}
                </th>
            );
        });

        return header;
    };

    view = activity => {
        this.props.view(activity);
    };

    renderList = () => {
        const { listing = [] } = this.props;
        return listing.length > 0 && listing.map(listRow => {
            const item = listRow.map((listItem, index) => {
                return (
                    <td onClick={() => this.view(listItem)}
                        className="listing-table-column">
                        {listItem}
                    </td>
                );
            });

            return (
                <tr className="listing-table-rows">
                    {item}
                </tr>
            );
        });
    };

    render() {
        return (
            <div className="x_panel">
                <div className="x_content">
                    <div className="table-responsive">
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner={<img
                                style={{
                                    width: '200px',
                                }}
                                src={loaderImage}
                                alt="loading..."
                            />}>
                            <table className="listing-table">
                                <thead className="listing-table-head">
                                    <tr>
                                        {this.renderHeadColumn()}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderList()}
                                </tbody>
                            </table>
                        </LoadingOverlay>
                    </div>
                    <TablePagination
                        currentPage={this.props.currentPage}
                        goToPage={this.props.goToPage}
                        setPageSize={this.props.setPageSize}
                        totalRecords={this.props.totalRecords}
                        recordsPerPage={this.props.pageSize}
                        isPageSelection={true}
                    >
                    </TablePagination>
                </div>
            </div>
        );
    }
}