import {regionsService} from "../services/regions";
import {REGIONS } from "../constants/actions";

export const fetchRegions = options => dispatch => {
    return new Promise((resolve, reject) => {
        regionsService
            .getRegions(options)
            .then(response => {
                dispatch({
                    type: REGIONS.FETCH_REGIONS_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createRegion = payload => dispatch => {
    return new Promise((resolve, reject) => {
        regionsService
            .createRegion(payload)
            .then(response => {
                dispatch({
                    type: REGIONS.CREATE_REGION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateRegion = payload => dispatch => {
    return new Promise((resolve, reject) => {
        regionsService
            .updateRegion(payload)
            .then(response => {
                dispatch({
                    type: REGIONS.UPDATE_REGION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};