export const API_CONSTANTS = {
    AUTHENTICATE: 'login',
    REGISTER: 'register',
    LIST_ACTIVITIES: 'get-activities',
    SUBMIT_ACTIVITIES: 'activity/create',
    UPDATE_ACTIVITY: 'activity/update',
    ACTIVITY_DETAIL: 'activity/',
    ACTIVITY_DETAIL_WITH_BILL_INFO: 'activity-with-bill-info/',
    GENERAL_DOWNLOAD: 'general-download',
    FACESHEET_DOWNLOAD: 'facesheet/',
    DELETE_ACTIVITY: 'activity/',
    DELETE_ACTIVITY_FACESHEET: 'facesheet/',
    EXPORT_ACTIVITIES: 'export-activities',
    EXPORT_BILLS: 'export-scan-flow',
    UPDATE_THEME: 'user-theme',
    LIST_PATIENTS: 'get-patients',
    SEARCH_PATIENT: 'search-patient',
    CREATE_PATIENT: 'patient/create',
    UPDATE_PATIENT: 'patient/update',
    DELETE_PATIENT: 'patient/',
    DELETE_MULTIPLE_PATIENTS: 'delete-multiple-patients',
    PATIENT_DETAIL: 'patient/',
    PATIENT_ACTIVITY: 'get-patient-activity/',
    LIST_SURGEONS: 'get-surgeons',
    CREATE_SURGEON: 'surgeon/create',
    UPDATE_SURGEON: 'surgeon/update',
    SURGEON_DETAIL: 'surgeon/',
    ACTIVATE_SURGEON: 'activate-surgeons',
    IN_ACTIVATE_SURGEON: 'in-activate-surgeons',
    DELETE_SURGEON: 'surgeon/',
    LIST_PROCEDURES: 'get-procedures',
    PROCEDURE_DETAIL: 'procedure/',
    CREATE_PROCEDURE: 'procedure/create',
    UPDATE_PROCEDURE: 'procedure/update',
    DELETE_PROCEDURE: 'procedure/',
    DELETE_MULTIPLE_PROCEDURE: 'delete-multiple-procedures',
    LIST_CONTRACTS: 'get-contracts',
    DELETE_CONTRACT: 'contract/',
    DELETE_ONCALL_RATE_CONTRACT: 'contract-on-call-rate/',
    DELETE_PROCEDURE_RATE_CONTRACT: 'contract-procedure-rate/',
    CONTRACT_DETAIL: 'contract/',
    CREATE_CONTRACT: 'contract/create',
    UPDATE_CONTRACT: 'contract/update',
    LIST_FACILITIES: 'get-facility',
    DELETE_FACILITY: 'facility/',
    FACILITY_DETAIL: 'facility/',
    CREATE_FACILITY: 'facility/create',
    UPDATE_FACILITY: 'facility/update',
    LIST_ASSISTANTS: 'get-assistants',
    ACTIVATE_ASSISTANTS: 'activate-assistants',
    SURGICAL_ASSISTANT_UPLOAD_DOCUMENT: 'surgical-assistant-upload-document',
    SURGICAL_ASSISTANT_DELETE_DOCUMENT: 'surgical-delete-document',
    SURGICAL_ASSISTANT_ADD_DOCUMENT: 'surgical-add-document',
    SURGICAL_ASSISTANT_DELETE_FACILITY: 'surgical-delete-facility/',
    SURGICAL_ASSISTANT_DELETE_VACCINATION: 'surgical-delete-vaccination/',
    SURGICAL_ASSISTANT_DELETE_BACKGROUND_CHECK: 'surgical-delete-background-check/',
    SURGICAL_ASSISTANT_DELETE_PTO: 'surgical-delete-pto/',
    SURGICAL_ASSISTANT_DELETE_DEGREE: 'surgical-delete-degree/',
    PHYSICIAN_ASSISTANT_UPLOAD_DOCUMENT: 'physician-assistant-upload-document',
    PHYSICIAN_ASSISTANT_DELETE_DOCUMENT: 'physician-delete-document',
    PHYSICIAN_ASSISTANT_DELETE_FACILITY: 'physician-delete-facility/',
    PHYSICIAN_ASSISTANT_DELETE_VACCINATION: 'physician-delete-vaccination/',
    PHYSICIAN_ASSISTANT_DELETE_BACKGROUND_CHECK: 'physician-delete-background-check/',
    PHYSICIAN_ASSISTANT_DELETE_PTO: 'physician-delete-pto/',
    PHYSICIAN_ASSISTANT_DELETE_DEGREE: 'physician-delete-degree/',
    PHYSICIAN_ASSISTANT_ADD_DOCUMENT: 'physician-add-document',
    SRUGEON_UPLOAD_DOCUMENT: 'surgeon-upload-document',
    SRUGEON_DELETE_DOCUMENT: 'surgeon-delete-document',
    SRUGEON_ADD_DOCUMENT: 'surgeon-add-document',
    SRUGEON_DELETE_DEGREE: 'surgeon-delete-degree/',
    MOVE_TO_PHYSICIAN_ASSISTANT: 'move-to-physician-assitant',
    MOVE_TO_SURGICAL_ASSISTANT: 'move-to-surgical-assitant',
    INACTIVATE_ASSISTANTS: 'in-activate-assistants',
    LIST_PHYSICIAN_ASSISTANTS: 'get-physician_assistants',
    ASSISTANT_DETAIL: 'assistant/',
    PHYSICIAN_ASSISTANT_DETAIL: 'physician-assistant/',
    PHYSICIAN_ASSISTANT_ACTIVATE: 'physician-assistant-activate',
    PHYSICIAN_ASSISTANT_IN_ACTIVATE: 'physician-assistant-in-activate',
    LIST_REGION_ASSISTANTS: 'region-assistants/',
    LIST_REGION_PHYSICIAN_ASSISTANTS: 'region-physician-assistants/',
    LIST_CERTIFICATES: 'get-certificates',
    CREATE_ASSISTANT: 'assistant/create',
    CREATE_PHYSICIAN_ASSISTANT: 'physician-assistant/create',
    DELETE_ASSISTANT: 'assistant/',
    DELETE_PHYSICIAN_ASSISTANT: 'physician-assistant/',
    UPDATE_ASSISTANT: 'assistant/update',
    UPDATE_PHYSICIAN_ASSISTANT: 'physician-assistant/update',
    LIST_EVENT_FLAGS: 'get-event-flags',
    LIST_EVENT_TYPES: 'get-event-type',

    GET_COUNTRIES: 'get-countries',
    GET_STATES: 'get-states/',
    GET_CITIES: 'get-cities/',
    GET_ZIP_DETAILS: 'get-zip-details/',


    CREATE_INSURANCE: 'insurance/create',
    UPDATE_INSURANCE: 'insurance/update',
    LIST_INSURANCES: 'get-insurances',
    INSURANCE_DETAIL: 'insurance/',
    DELETE_INSURANCE: 'insurance/',
    UPLOAD_INSURANCE_DOCUMENT: 'upload-insurance',
    DELETE_INSURANCE_DOCUMENT: 'delete-insurance-document/',
    DOWNLOAD_INSURANCE_DOCUMENT: 'download-insurance-document/',

    LIST_PROCEDURE_SPECIALITY: 'get-procedure-speciality',
    LIST_SPECIALITY: 'get-speciality',
    LIST_PRACTICE: 'get-practice',
    PRACTICE_DETAIL: 'practice/',
    CREATE_PRACTICE: 'practice/create',
    UPDATE_PRACTICE: 'practice/update',
    DELETE_PRACTICE: 'practice/',
    LIST_REGIONS: 'get-regions',
    ADD_REGION: 'region/create',
    UPDATE_REGION: 'region/update',
    REGION_DETAIL: 'region/',
    LIST_MODULES: 'get-modules',
    LIST_APP_MODULES: 'app-modules',
    LIST_USERS: 'users',
    CREATE_USER: 'user/create',
    UPDATE_USER: 'user/update',
    UPDATE_USER_PROFILE: 'profile/update',
    USER_DETAIL: 'user/',
    DELETE_USER: 'user/',

    LIST_KNOWLEDGE_BASE_CATEGORIES: 'knowledge-base/get-categories',
    CREATE_KNOWLEDGE_BASE_CATEGORIES: 'knowledge-base/category-create',
    UPDATE_KNOWLEDGE_BASE_CATEGORIES: 'knowledge-base/category-update',
    KNOWLEDGE_BASE_CATEGORIES_DETAIL: 'knowledge-base/get-category/',
    DELETE_KNOWLEDGE_BASE_CATEGORIES: 'knowledge-base/delete-category/',

    LIST_KNOWLEDGE_BASE_SUB_CATEGORIES: 'knowledge-base/get-sub-categories',
    CREATE_KNOWLEDGE_BASE_SUB_CATEGORIES: 'knowledge-base/sub-category-create',
    UPDATE_KNOWLEDGE_BASE_SUB_CATEGORIES: 'knowledge-base/sub-category-update',
    KNOWLEDGE_BASE_SUB_CATEGORIES_DETAIL: 'knowledge-base/get-sub-category/',
    DELETE_KNOWLEDGE_BASE_SUB_CATEGORIES: 'knowledge-base/delete-sub-category/',

    LIST_KNOWLEDGE_BASE_ARTICLES: 'knowledge-base/get-articles',
    CREATE_KNOWLEDGE_BASE_ARTICLE: 'knowledge-base/article-create',
    UPDATE_KNOWLEDGE_BASE_ARTICLE: 'knowledge-base/article-update',
    KNOWLEDGE_BASE_ARTICLE_DETAIL: 'knowledge-base/get-article/',
    DELETE_KNOWLEDGE_BASE_ARTICLE: 'knowledge-base/delete-article/',
    DOWNLOAD_KNOWLEDGE_BASE_ARTICLE: 'knowledge-base/article-download/',

    LIST_SURGEON_ASSOCIATION: 'get-surgeon-assications',
    CREATE_SURGEON_ASSOCIATION: 'surgeon-association/create',
    DELETE_SURGEON_ASSOCIATION: 'surgeon-association',

    USER_ACTIVE: 'user-active/',
    LIST_CASELOG: 'get-case-logs',
    CREATE_CASELOG: 'user/create',
    UPDATE_CASELOG: 'user/create',
    CASELOG_DETAIL: 'user/create',
    DELETE_CASELOG: 'user/create',
    LIST_SCANFLOW: 'scan-flow',
    SCANFLOW_DETAIL: 'scan-flow/',
    UPDATE_SCANFLOW: 'scan-flow/update',
    CREATE_BILL: 'event-bill/create',
    DELETE_BILLS: 'delete-bills',
    UPDATE_BILL: 'event-bill/update',
    LIST_EVENT_BILL: 'event-bill/',
    LIST_EVENT_BILLS: 'event-bills',
    UPLOAD_DOCUMENT: 'upload-document',
    LIST_CLAIMS: 'get-claims-collections-data',
    CREATE_MULTIPLE_CLAIMS: 'create-multiple-claims',
    CREATE_INVOICE: 'invoice/create',
    SEND_INVOICE: 'send-email-facility',
    GET_CPT_FEE: 'get-cpt-fee/',
    LIST_BATCH: 'batches',
    BATCH_ADD: 'batch/create',
    BATCH_UPDATE: 'batch/update',
    BATCH_DETAIL: 'batch/',
    DELETE_BATCH: 'batch/',
    POSTING_CREATE: 'payment-posting/create',
    POSTING_DETAIL: 'payment-posting/',
    POSTING_UPDATE: 'payment-posting/',
    LIST_ACCOUNT: 'get-invoices',
    INVOICE_DETAIL: 'invoice/',
    UPDATE_INVOICE: 'invoice/update',
    LIST_ORGANIZATION: 'get-organizations',
    CREATE_ORGANIZATION: 'organization/create',
    UPDATE_ORGANIZATION: 'organization/update',
    ORGANIZATION_DETAIL: 'organization/',
    DELETE_ORGANIZATION: 'organization/',
    LIST_NOTIFICATIONS: 'notifications/',
    LIST_FEEDBACKS: 'get-feedbacks',
    NOTIFICATIONS_COUNT: 'notifications/count/',
    CREATE_NOTIFICATION: 'notifications',
    SUBMIT_INVOICING: 'invoicing/create',
    CREATE_INVOICE_V1: 'invoice/createv1',
    START_INVOICE_V1: 'invoice/startinvoicev1',
    SEND_INVOICE_V1: 'invoice/sendinvoicev1',
    SUBMIT_CLAIM_V1: 'invoice/submitclaimv1',
    LIST_INVOICING: 'get-invoicing',
    LIST_INVOICINGV1: 'get-invoicingv1',
    INVOICING_DETAIL: 'invoicing/',
    DELETE_INVOICING: 'invoicing/',
    UPDATE_INVOICING: 'invoicing/update',
    CREATE_MULTIPLE_INVOICING: 'invoicing/create-multiple',
    EXPORT_INVOICING: 'export-invoicing',
    MULTIPLE_STATUS_UPDATE: 'multiple-status-update',


    LIST_DIVISION: 'get-divisions',
    DIVISION_DETAIL: 'division/',
    DELETE_DIVISION: 'division/',
    UPDATE_DIVISION: 'division/update',
    CREATE_DIVISION: 'division/create',

    LIST_REVENUESCHEDULER: 'revenue-scheduler',
    UPDATE_REVENUESCHEDULER: 'revenue-scheduler/update',
    UPDATE_CLAIM: "claims/update",
    updateClaimAll: "claims/updateAll",
    LIST_LOGS: 'get-logs',
    LIST_CLIENTS: 'get-client',
    DELETE_CLIENT: 'client/',
    CLIENT_DETAIL: 'client/',
    CREATE_CLIENT: 'client/create',
    UPDATE_CLIENT: 'client/update',
    CHECK_CLIENT: 'client/check-client',

    LIST_GROUPS: 'groups',
    DETAIL_GROUP: 'group/',
    CREATE_GROUP: 'group/create',
    UPDATE_GROUP: 'group/update',
    DELETE_GROUP: 'group/delete/',

    LIST_CONFIG_VACCINATION: 'config-get-vaccination',
}