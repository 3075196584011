import {apiClient} from "./apiClients";
import {API_CONSTANTS} from "../constants/api";
import {handleError, handleResponse} from "./GenericResponseHandler";

function getOrganizations(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_ORGANIZATION, options)
        .then(handleResponse)
        .catch(handleError);
}

function createOrganization(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_ORGANIZATION, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateOrganization(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_ORGANIZATION, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteOrganization(payload) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ORGANIZATION + payload)
        .then(handleResponse)
        .catch(handleError);
}

function getOrganizationDetail(organization) {
    return apiClient()
        .get(API_CONSTANTS.ORGANIZATION_DETAIL + organization)
        .then(handleResponse)
        .catch(handleError);
}

export const organizationService = {
    getOrganizations,
    createOrganization,
    updateOrganization,
    getOrganizationDetail,
    deleteOrganization,
}