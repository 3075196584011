import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchSurgeonAssociations } from "../../actions/surgeonAssociation";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { SURGEON_ASSOCIATION_LISTING_COLUMNS, APP_ROUTES, RECORDS_PER_PAGE } from "../../constants/common";
import SurgeonAssociationListingTable from "../atom/SurgeonAssociationListingTable";
import { surgeonAssociationService } from "../../services/surgeonAssociation";
class SurgeonAssociationDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedSurgeonAssociation: [],
    pageSize: RECORDS_PER_PAGE,
    searchedTotalRecords: 0,
  };

  componentWillMount() {
    this.setState({ loading: true });

    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };

    this.props.fetchSurgeonAssociations({ params: options }).then((res) => {
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page,
      page_size: this.state.pageSize,
    };
    this.props.fetchSurgeonAssociations({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_SURGEON_ASSOCIATION);

  deleteSurgeonAssocation = (surgeonassociation) => {
    surgeonAssociationService.deleteSurgeonAssociation(surgeonassociation).then((response) => {
      this.goToPage(1);
    });
  };

  list = () => {
    const { surgeonAssociations = [] } = this.props;
    return surgeonAssociations.map((sr) => {
      return [
        sr.id,
        sr.user?.fullname,
        sr.surgeon?.first_name,
        sr.surgeon?.last_name,
        sr.id,
      ];
    });
  };

  searchArticles = () => {
    const { searchByName } = this.state;
    let options = {};

    if (searchByName) {
      options = { ...options, search: searchByName };
    }
    options = { ...options, page_size: this.state.pageSize };

    this.setState({
      searched: true,
      loading: true,
    });

    surgeonAssociationService.getSurgeonAssociations({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedSurgeonAssociation: response.data.map((sr) => {
          return [
            sr.id,
            sr.user?.fullname,
            sr.surgeon?.first_name,
            sr.surgeon?.last_name,
            sr.id,
          ];
        }),
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };


  resetSurgeonAssociationSearch = () => {
    this.setState({
      searchByName: "",
      searchedSurgeonAssociation: [],
      searched: false,
      searchedTotalRecords: 0,
    });
  };

  render() {
    const { searched, searchedSurgeonAssociation, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "755px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Manage Surgeon Association
              </h3>
            </div>
          </div>
          <div className="filter-footer-header">
            <button
              onClick={() => {
                this.showAdd(true);
              }}
              className="filter-button"
            >
              Add Surgeon Association
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Surgeon Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value }, () => {
                    if (this.state.searchByName.length >= 3) {
                      this.searchArticles();
                    } else if (this.state.searchByName.length === 0) {
                      this.resetSurgeonAssociationSearch();
                    }
                  });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetSurgeonAssociationSearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <SurgeonAssociationListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                delete={this.deleteSurgeonAssocation}
                setPageSize={this.setPageSize}
                pageSize={this.state.pageSize}
                headColumn={SURGEON_ASSOCIATION_LISTING_COLUMNS}
                listing={
                  searched
                    ? searchedSurgeonAssociation
                    : this.list()
                }
                totalRecords={
                  searched
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    surgeonAssociations: state.surgeonAssociationReducer.surgeonAssociations,
    totalRecords: state.surgeonAssociationReducer.total,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSurgeonAssociations,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SurgeonAssociationDashboard));
