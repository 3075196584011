import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createCategory, updateCategory } from "../../actions/knowledgeBaseCategories";
import { knowledgeBaseCategoryService } from "../../services/knowledgeBaseCategories";
import { FormValidatorService } from "../../services/formvalidator";
import { utilityService } from "../../services/utility";

const validationGroup = {
  message: "There are some validation errors in Basic Information Tab:",
  fields: {
    'category': { filedName: 'Category Name', required: true, minLength: 3, maxLength: 200 },
  }
}

class ManageKnowledgeBaseCategory extends React.Component {
  state = {
    errors: [],
    category: "",
  };
  componentDidMount() {
    const categoryId = this.props.location.state
      ? this.props.location.state.categoryId
      : "";
    if (categoryId) {
      knowledgeBaseCategoryService.getCategoryDetail(categoryId).then((response) => {
        this.setState({
          category: response.data.category,
        });
      });
    }
  }

  validateKnowledgeBaseCategory = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  submitCategory = () => {
    if (!this.validateKnowledgeBaseCategory()) {
      return;
    }

    const { createCategory, updateCategory } = this.props;
    const {
      category,
    } = this.state;

    const request = {
      category: category,
    };

    const categoryId = this.props.location.state
      ? this.props.location.state.categoryId
      : "";

    if (categoryId) {
      const payload = { ...request, id: categoryId };
      updateCategory(payload)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_CATEGORIES);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
    } else {
      createCategory(request)
        .then((res) => {
          if (res.response.success) {
            this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_CATEGORIES);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
    }
  };

  render() {
    const categoryId = this.props.location.state
      ? this.props.location.state.categoryId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {categoryId && <h2 className="heading-custom">Edit Knowledge Base Category</h2>}
              {!categoryId && <h2 className="heading-custom">Add Knowledge Base Category</h2>}
            </div>
            <div className="col-md-12 mrgn-btm10">
              <label>Category {FormValidatorService.getRequiredHTML(validationGroup, "category")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "category")}
                value={this.state.category}
                onChange={(event) => {
                  this.setState({ category: event.target.value });
                }}
              />
            </div>

          </div>
          <div className="filter-footer">
            {categoryId && (
              <button onClick={this.submitCategory} className="filter-save-button">
                Update
              </button>
            )}
            {!categoryId && (
              <button onClick={this.submitCategory} className="filter-save-button">
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_CATEGORIES);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCategory,
      updateCategory,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageKnowledgeBaseCategory));
