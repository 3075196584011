import React from "react";
import { bindActionCreators } from "redux";
import { createAssistant, updateAssistant } from "../../actions/assistant";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { APP_ROUTES, NOTIFICATION_TYPES } from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import DatePicker from "react-datepicker";
import { assistantService } from "../../services/assistants";
import { formatDate } from "../../utils/date";
import StateSelection from "../organisms/StateSelection";
import CitySelection from "../organisms/CitySelection";
import FieldView from "../organisms/FieldView";
import { utilityService } from "../../services/utility";
import ZipCode from "../organisms/ZipCode";
import CustomDropDown from "../organisms/CustomDropDown";
import { FormValidatorService } from "../../services/formvalidator";
import CustomSelect from "../organisms/CustomSelect";
import { FileStorageService } from "../../services/fileStorage";
import { faUpload, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validationGroup = {
    message: "There are some validation errors in Basic Information Tab:",
    fields: {
        'name': { filedName: 'First Name', required: true, minLength: 3, maxLength: 200 },
        'lastName': { filedName: 'Last Name', required: true, minLength: 3, maxLength: 200 },
        'email': { filedName: 'Email', required: true },
        'region': { filedName: 'Region', required: true },
        'selectedDivision': { filedName: 'Division', required: true },
        'drivingLicenseState': { filedName: 'Driving License State', required: true },
        'certificate': { filedName: 'Certificate', required: true },
    }
}

const validationGroupFacility = {
    message: "There are some validation errors in Facilities Tab:",
    fields: {
        'facility_facility': { filedName: 'Facility', required: true },
        'facility_date_renewed': { filedName: 'Date Renewed', required: true },
        'facility_date_expiry': { filedName: 'Date Expiry', required: true },
        'facility_fee': { filedName: 'Fees Paid', required: true },
    }
}

const validationGroupVaccine = {
    message: "There are some validation errors in Vaccination Tab:",
    fields: {
        'vaccine_vaccine': { filedName: 'Vaccine', required: true },
        'vaccine_date_administer': { filedName: 'Date Administer', required: true },
        'vaccine_date_expiry': { filedName: 'Date Expiry', required: true },
    }
}

const validationGroupBackgroundCheck = {
    message: "There are some validation errors in Background Check Tab:",
    fields: {
        'background_check_date_performed': { filedName: 'Date Performed', required: true },
        'background_check_notes': { filedName: 'Notes', required: true },
        'background_check_feepaid': { filedName: 'Fees Paid', required: true },
    }
}

const validationGroupPTO = {
    message: "There are some validation errors in PTO Tab:",
    fields: {
        'pto_date': { filedName: 'Date', required: true },
        'pto_type': { filedName: 'Type', required: true },
        'pto_amount': { filedName: 'Amount', required: true },
    }
}

const validationGroupDegree = {
    message: "There are some validation errors in Degrees Tab:",
    fields: {
        'degree_degree': { filedName: 'Degree', required: true },
        'degree_status': { filedName: 'Status', required: true },
        'degree_aquired_date': { filedName: 'Aquired Date', required: true },
        'degree_state': { filedName: 'State', required: true },
    }
}

class ManageAssistant extends React.Component {

    state = {
        subSaveDisabled: false,
        name: '',
        lastName: '',
        email: '',
        personalEmail: '',
        phone: '',
        personalPhone: '',
        active: '',
        role: '',
        homeAddress: '',
        homeAddress2: '',
        homeAddress3: '',
        state: '',
        city: '',
        zip: '',
        practiceAddress: '',
        practiceAddress2: '',
        practiceState: '',
        practiceCity: '',
        practiceZip: '',
        primaryTaxonomy: '',
        secondaryTaxonomy: '',
        drivingLicense: '',
        drivingLicenseState: '',
        drivingLicenseIssueDate: '',
        drivingLicenseExpiryDate: '',
        certificate: {},
        licenseCertificate: '',
        certificateIssueDate: '',
        certificateExpiryDate: '',
        selectedDivision: {},
        region: {},
        npi: '',
        startDate: '',
        endDate: '',
        facilities: [],
        vaccinations: [],
        background_checks: [],
        ptos: [],
        degrees: [],
        errors: [],
        facility_attachment: [],
        facility_facility: {},
        facility_date_renewed: '',
        facility_date_expiry: '',
        vaccine_vaccine: {},
        vaccine_date_administer: '',
        vaccine_date_expiry: '',
        vaccine_attachment: [],
        background_check_date_performed: '',
        background_check_notes: '',
        background_check_feepaid: 0,
        background_check_attachment: [],
        pto_date: '',
        pto_type: '',
        pto_amount: 0,
        pto_attachment: [],
        degree_degree: '',
        degree_status: '',
        degree_aquired_date: '',
        degree_expired_date: '',
        degree_attachment: [],
        degree_state: {}
    };

    componentDidMount() {
        this.reLoadData();
    }

    reLoadData = () => {
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        const viewOnly = this.props.location.state.onlyForView ? this.props.location.state.onlyForView : false;
        if (assistantId) {
            assistantService
                .getAssistantDetail(assistantId)
                .then(response => {
                    let facilities = [];
                    let tFacility = response.data.assistant?.facilities;
                    if (tFacility) {
                        tFacility.forEach(facility => {
                            let temp = { label: facility.facility_name, value: facility.id };
                            facilities.push({
                                id: facility.pivot.id,
                                facility: temp,
                                date_renewed: formatDate(facility.pivot.renewal_date),
                                date_expiry: formatDate(facility.pivot.expiry_date),
                                fee_paid: facility.pivot.feepaid,
                                attachments: facility.pivot.attachments ? JSON.parse(facility.pivot.attachments) : []
                            })
                        })
                    }

                    let vaccinations = [];
                    let tVaccination = response.data.assistant?.vaccines;
                    if (tVaccination) {
                        tVaccination.forEach(vaccine => {
                            let temp = { label: vaccine.name, value: vaccine.id };
                            vaccinations.push({
                                id: vaccine.pivot.id,
                                vaccine: temp,
                                date_administer: formatDate(vaccine.pivot.administered_date),
                                date_expiry: formatDate(vaccine.pivot.expiry_date),
                                attachments: vaccine.pivot.attachments ? JSON.parse(vaccine.pivot.attachments) : []
                            })
                        })
                    }

                    let background = [];
                    let tBackground = response.data.assistant?.background_checks;
                    if (tBackground) {
                        tBackground.forEach(tbg => {
                            background.push({
                                id: tbg.id,
                                date_performed: formatDate(tbg.date_performed),
                                notes: tbg.notes,
                                fee_paid: tbg.fee_paid,
                                attachments: tbg.attachments ? JSON.parse(tbg.attachments) : []
                            })
                        })
                    }

                    let tPto = [];
                    let tPTOInstances = response.data.assistant?.ptos;
                    if (tPTOInstances) {
                        tPTOInstances.forEach(tPTOI => {
                            tPto.push({
                                id: tPTOI.id,
                                date: formatDate(tPTOI.date),
                                type: tPTOI.type,
                                amount: tPTOI.amount,
                                attachments: tPTOI.attachments ? JSON.parse(tPTOI.attachments) : []
                            })
                        })
                    }

                    let tDegrees = [];
                    let tDegreeInstances = response.data.assistant?.degrees;
                    if (tDegreeInstances) {
                        tDegreeInstances.forEach(tDeg => {
                            tDegrees.push({
                                date_aquired: formatDate(tDeg.date_aquired),
                                date_expired: formatDate(tDeg.date_expired),
                                degree: tDeg.degree,
                                state: tDeg.state,
                                status: tDeg.status,
                                id: tDeg.id,
                                attachments: tDeg.attachments ? JSON.parse(tDeg.attachments) : []
                            })
                        })
                    }
                    this.setState({
                        viewOnly: viewOnly,
                        degrees: tDegrees,
                        ptos: tPto,
                        background_checks: background,
                        facilities: facilities,
                        vaccinations: vaccinations,
                        name: response.data.name || '',
                        lastName: response.data.last_name || '',
                        email: response.data.assistant ? response.data.assistant.work_email : '',
                        personalEmail: response.data.assistant ? response.data.assistant.personal_email : '',
                        phone: response.data.assistant ? response.data.assistant.work_cell_phone : '',
                        personalPhone: response.data.assistant ? response.data.assistant.personal_cell_phone : '',
                        active: response.data.is_active,
                        role: response.data.type,
                        homeAddress: response.data.assistant ? response.data.assistant.practice1_address1 : '',
                        homeAddress2: response.data.assistant ? response.data.assistant.practice1_address2 : '',
                        state: response.data.assistant ? response.data.assistant.practice1_state : '',
                        city: response.data.assistant ? response.data.assistant.practice1_city : '',
                        zip: response.data.assistant ? response.data.assistant.practice1_zip : '',
                        practiceAddress: response.data.assistant ? response.data.assistant.practice2_address1 : '',
                        practiceAddress2: response.data.assistant ? response.data.assistant.practice2_address2 : '',
                        practiceState: response.data.assistant ? response.data.assistant.practice2_state : '',
                        practiceCity: response.data.assistant ? response.data.assistant.practice2_city : '',
                        practiceZip: response.data.assistant ? response.data.assistant.practice2_zip : '',
                        primaryTaxonomy: response.data.assistant ? response.data.assistant.primary_taxonomy : '',
                        secondaryTaxonomy: response.data.assistant ? response.data.assistant.secondary_taxonomy : '',
                        drivingLicense: response.data.assistant ? response.data.assistant.driving_license : '',
                        drivingLicenseState: response.data.assistant ? response.data.assistant.driving_license_state : '',
                        drivingLicenseIssueDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.driving_issue_date)) : '',
                        drivingLicenseExpiryDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.driving_expiry_date)) : '',
                        selectedDivision: {
                            label: response.data.assistant && response.data.assistant.division ? response.data.assistant.division.name : '',
                            value: response.data.assistant && response.data.assistant.division ? parseInt(response.data.assistant.division.id) : '',
                        },
                        certificate: {
                            label: response.data.assistant && response.data.assistant.certificate ? response.data.assistant.certificate.name : '',
                            value: response.data.assistant && response.data.assistant.certificate ? parseInt(response.data.assistant.certificate.id) : '',
                        },
                        licenseCertificate: response.data.assistant ? response.data.assistant.certificate_number : '',
                        certificateIssueDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.license_issue_date)) : '',
                        certificateExpiryDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.license_expiry_date)) : '',
                        region: {
                            label: response.data.assistant && response.data.assistant.region ? response.data.assistant.region.name : '',
                            value: response.data.assistant && response.data.assistant.region ? parseInt(response.data.assistant.region.company_id) : '',
                        },
                        npi: response.data.assistant ? response.data.assistant.npi : '',
                        startDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.joining_date)) : '',
                        endDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.termination_date)) : '',
                    });
                });
        }
    }

    validateAssistant = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    validateFacility = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroupFacility, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    validateVaccine = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroupVaccine, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    validatePTO = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroupPTO, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    validateBackgroundCheck = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroupBackgroundCheck, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    validateDegree = () => {
        let validationResult = FormValidatorService.isFormValid(validationGroupDegree, this.state);
        if (!validationResult.isValid) {
            utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
        }

        return validationResult.isValid;
    };

    submitVaccine = () => {
        if (!this.validateVaccine()) {
            return;
        }

        this.state.vaccinations.push({
            vaccine: this.state.vaccine_vaccine,
            date_administer: formatDate(this.state.vaccine_date_administer),
            date_expiry: formatDate(this.state.vaccine_date_expiry),
            attachments: this.state.vaccine_attachment
        });

        this.submitAssistant(false);
    }

    submitBackgroundCheck = () => {
        if (!this.validateBackgroundCheck()) {
            return;
        }
        this.state.background_checks.push({
            date_performed: formatDate(this.state.background_check_date_performed),
            notes: this.state.background_check_notes,
            fee_paid: this.state.background_check_feepaid,
            attachments: this.state.background_check_attachment
        });

        this.submitAssistant(false);
    }

    submitPTO = () => {
        if (!this.validatePTO()) {
            return;
        }

        this.state.ptos.push({
            date: formatDate(this.state.pto_date),
            type: this.state.pto_type,
            amount: this.state.pto_amount,
            attachments: this.state.pto_attachment
        });

        this.submitAssistant(false);
    }

    submitDegree = () => {
        if (!this.validateDegree()) {
            return;
        }

        this.state.degrees.push({
            degree: this.state.degree_degree,
            date_aquired: formatDate(this.state.degree_aquired_date),
            date_expired: formatDate(this.state.degree_expired_date),
            state: this.state.degree_state,
            status: this.state.degree_status,
            attachments: this.state.degree_attachment
        });

        this.submitAssistant(false);
    }

    submitFacility = () => {
        if (!this.validateFacility()) {
            return;
        }

        this.state.facilities.push({
            facility: this.state.facility_facility,
            date_renewed: formatDate(this.state.facility_date_renewed),
            date_expiry: formatDate(this.state.facility_date_expiry),
            fee_paid: this.state.facility_fee,
            attachments: this.state.facility_attachment
        });

        this.submitAssistant(false);
    }
    clearFacilityForm = () => {
        this.setState({
            'facility_attachment': [],
            'facility_facility': {},
            'facility_date_renewed': '',
            'facility_date_expiry': '',
            'facility_fee': 0,
        });
    }
    clearVaccinationForm = () => {
        this.setState({
            'vaccine_attachment': [],
            'vaccine_vaccine': {},
            'vaccine_date_administer': '',
            'vaccine_date_expiry': '',
        });
    }
    clearBackgroundCheckForm = () => {
        this.setState({
            'background_check_attachment': [],
            'background_check_date_performed': '',
            'background_check_notes': '',
            'background_check_feepaid': 0,
        });
    }

    clearPTOForm = () => {
        this.setState({
            'pto_attachment': [],
            'pto_date': '',
            'pto_type': '',
            'pto_amount': 0
        });
    }

    clearDegreeForm = () => {
        this.setState({
            'degree_attachment': [],
            'degree_degree': '',
            'degree_status': '',
            'degree_aquired_date': '',
            'degree_expired_date': '',
            'degree_state': {}
        });
    }

    downloadAttachment = (mainitem, item) => {
        FileStorageService.downloadFileFromObject(item).then(data => {
        })
    }

    deleteAttachment = (mainitem, item, type) => {
        utilityService.showConfirmDialog('Delete Attachment', 'Are you sure you want to delete this attachment', 'warning').then(data => {
            let pItem = {};
            pItem.type = type;
            pItem.id = mainitem.id;
            pItem.path = item.path;

            assistantService.deleteDocument(pItem).then(tData => {
                this.reLoadData();
            })
        })
    }

    uploadAttachment = (mainitem, type) => {
        this.setState(
            {
                'current_type': type,
                'current_id': mainitem.id,
            }
        )
        const buttonElement = document.getElementById('generic-upload-' + type);
        if (buttonElement) {
            buttonElement.click();
        }
    }

    deleteFacility = (mainitem) => {
        utilityService.showConfirmDialog('Delete Facility', 'Are you sure you want to delete this facility', 'warning').then(data => {
            assistantService.deleteFacility(mainitem.id).then(tData => {
                this.reLoadData();
            })
        })
    }

    deleteVaccination = (mainitem) => {
        utilityService.showConfirmDialog('Delete Vaccination', 'Are you sure you want to delete this vaccination', 'warning').then(data => {
            assistantService.deleteVacciantion(mainitem.id).then(tData => {
                this.reLoadData();
            })
        })
    }

    deleteBackgroundCheck = (mainitem) => {
        utilityService.showConfirmDialog('Delete Background Check', 'Are you sure you want to delete this background check', 'warning').then(data => {
            assistantService.deleteBackgroundCheck(mainitem.id).then(tData => {
                this.reLoadData();
            })
        })
    }

    deletePTO = (mainitem) => {
        utilityService.showConfirmDialog('Delete PTO', 'Are you sure you want to delete this PTO', 'warning').then(data => {
            assistantService.deletePTO(mainitem.id).then(tData => {
                this.reLoadData();
            })
        })
    }

    deleteDegree = (mainitem) => {
        utilityService.showConfirmDialog('Delete Degree', 'Are you sure you want to delete this degree', 'warning').then(data => {
            assistantService.deleteDegree(mainitem.id).then(tData => {
                this.reLoadData();
            })
        })
    }

    uploadAttachmentProcess = (event) => {
        const formData = new FormData();
        formData.append("type", this.state.current_type);
        formData.append("file", event.target.files[0]);

        assistantService.uploadFile(formData).then(tData => {
            let pItem = {};
            pItem.type = this.state.current_type;
            pItem.id = this.state.current_id;
            pItem.path = tData.data.path;
            pItem.name = tData.data.name;

            assistantService.addDocument(pItem).then(tData => {
                this.reLoadData();
            })
        })
    }

    submitAssistant = (redirectPage = true) => {
        const { createAssistant, updateAssistant } = this.props;
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        if (!this.validateAssistant()) {
            return;
        }

        const {
            name,
            lastName,
            email,
            personalEmail,
            phone,
            personalPhone,
            active,
            role,
            homeAddress,
            homeAddress2,
            homeAddress3,
            city,
            state,
            zip,
            practiceAddress,
            practiceAddress2,
            practiceState,
            practiceCity,
            practiceZip,
            primaryTaxonomy,
            secondaryTaxonomy,
            drivingLicense,
            drivingLicenseState,
            drivingLicenseIssueDate,
            drivingLicenseExpiryDate,
            certificate,
            licenseCertificate,
            certificateIssueDate,
            certificateExpiryDate,
            region,
            npi,
            startDate,
            endDate,
            facilities,
            vaccinations,
            background_checks,
            ptos,
            degrees,
            selectedDivision
        } = this.state;

        const request = {
            first_name: name,
            last_name: lastName,
            email,
            work_email: email,
            personal_email: personalEmail,
            password: '123456',
            work_cell_phone: phone,
            personal_cell_phone: personalPhone,
            is_active: active,
            type: role,
            practice1_address1: homeAddress,
            practice1_address2: homeAddress2,
            practice1_address3: homeAddress3,
            practice1_state: state,
            practice1_city: city,
            practice1_zip: zip,
            practice2_address1: practiceAddress,
            practice2_address2: practiceAddress2,
            practice2_state: practiceState,
            practice2_city: practiceCity,
            practice2_zip: practiceZip,
            driving_license: drivingLicense,
            driving_license_state: drivingLicenseState,
            driving_issue_date: formatDate(drivingLicenseIssueDate),
            driving_expiry_date: formatDate(drivingLicenseExpiryDate),
            npi,
            primary_taxonomy: primaryTaxonomy,
            secondary_taxonomy: secondaryTaxonomy,
            certificate: certificate.value,
            certificate_number: licenseCertificate,
            region: region.value,
            license_issue_date: formatDate(certificateIssueDate),
            license_expiry_date: formatDate(certificateExpiryDate),
            joining_date: formatDate(startDate),
            termination_date: formatDate(endDate),
            facilities: facilities,
            vaccinations: vaccinations,
            backgroundchecks: background_checks,
            ptos: ptos,
            degrees: degrees,
            division_id: selectedDivision.value
        };

        if (assistantId) {
            updateAssistant({ ...request, 'id': assistantId })
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        if (redirectPage) {
                            this.props.history.push({
                                pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                                state: {
                                    regionId: this.props.regionId || this.props.location.state.regionId,
                                }
                            });
                        }
                        else {
                            this.clearFacilityForm();
                            this.clearVaccinationForm();
                            this.clearPTOForm();
                            this.clearBackgroundCheckForm();
                            this.clearDegreeForm();
                            this.reLoadData();
                        }
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update assistant ${JSON.stringify(res.response.data)}`);
                    }
                })
        } else {
            createAssistant(request)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        if (redirectPage) {
                            this.props.history.push({
                                pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                                state: {
                                    regionId: this.props.regionId || this.props.location.state.regionId,
                                }
                            });
                        }
                        else {
                            this.clearFacilityForm();
                            this.clearVaccinationForm();
                            this.clearPTOForm();
                            this.clearBackgroundCheckForm();
                            this.clearDegreeForm();
                        }
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create assistant ${JSON.stringify(res.response.data)}`);
                    }
                })
        }
    };

    homeZipCodeChanged = (value) => {
        this.setState({ zip: value.zip_code })

        if (value.state) {
            this.setState({ state: value.state.code });
        }

        if (value.city) {
            this.setState({ city: value.city.name });
        }
    }

    practiceZipCodeChanged = (value) => {
        this.setState({ practiceZip: value.zip_code })

        if (value.state) {
            this.setState({ practiceState: value.state.code });
        }

        if (value.city) {
            this.setState({ practiceCity: value.city.name });
        }
    }


    render() {
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                    style={{
                        minHeight: '125vh',
                    }}>
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {assistantId &&
                                <h2 className="heading-custom">Edit Surgical Assistant</h2>
                            }
                            {!assistantId &&
                                <h2 className="heading-custom">Add Surgical Assistant</h2>
                            }
                        </div>

                        <div class='col-md-12 p-0'>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="basic-info-tab" data-toggle="tab" href="#basic-info" role="tab">Basic Information</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="facility-tab" data-toggle="tab" href="#facility" role="tab">Facilities</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="vaccination-tab" data-toggle="tab" href="#vaccination" role="tab">Vaccinations</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="background-checks-tab" data-toggle="tab" href="#background-check" role="tab">Background Checks</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="pto-tab" data-toggle="tab" href="#pto" role="tab">PTO</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="degree-tab" data-toggle="tab" href="#degree" role="tab">Degrees</a>
                                </li>
                            </ul>
                            <div class="tab-content mb-4" id="myTabContent">
                                <div class="tab-pane fade show active" id="basic-info" role="tabpanel">
                                    <div class='row'>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>First Name {FormValidatorService.getRequiredHTML(validationGroup, "name")}</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "name")}
                                                    type="text"
                                                    value={this.state.name}
                                                    onChange={event => {
                                                        this.setState({ name: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.name} />
                                            }

                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Last Name  {FormValidatorService.getRequiredHTML(validationGroup, "lastName")}</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "lastName")}
                                                    type="text"
                                                    value={this.state.lastName}
                                                    onChange={event => {
                                                        this.setState({ lastName: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.lastName} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Work Email {FormValidatorService.getRequiredHTML(validationGroup, "email")}</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "email")}
                                                    type="text"
                                                    value={this.state.email}
                                                    onChange={event => {
                                                        this.setState({ email: event.target.value })
                                                    }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.email} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Personal Email</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.personalEmail}
                                                    onChange={event => this.setState({ personalEmail: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.personalEmail} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Cell Phone</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={utilityService.formatPhone(this.state.personalPhone)}
                                                    onChange={event => this.setState({ personalPhone: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.personalPhone} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Work Phone</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={utilityService.formatPhone(this.state.phone)}
                                                    onChange={event => this.setState({ phone: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.phone} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Active</label>
                                            {!this.state.viewOnly &&
                                                <CustomSelect type='Status'
                                                    value={this.state.active}
                                                    className="filter-input"
                                                    onChange={event => this.setState({ active: event.target.value })}
                                                >
                                                </CustomSelect>
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.active ? 'Yes' : 'No'} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Role</label>
                                            {!this.state.viewOnly &&
                                                <select
                                                    value={this.state.role}
                                                    className="filter-input"
                                                    onChange={event => this.setState({ role: event.target.value })}>
                                                    <option value="">Please Select Role</option>
                                                    <option value="Surgical Assitant">Surgical Assitant</option>
                                                    <option value="Divisional Manager">Divisional Manager</option>
                                                </select>
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.role} />
                                            }
                                        </div>
                                    </div>
                                    <div className="page-title">
                                        <h2 className="heading-custom">Home Address</h2>
                                    </div>
                                    <div class='row'>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Home Address</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.homeAddress}
                                                    onChange={event => this.setState({ homeAddress: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.homeAddress} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <ZipCode title={'Zip'}
                                                    className='code-input'
                                                    value={this.state.zip}
                                                    onChange={(value) => {
                                                        this.setState({ 'zip': value });
                                                    }}
                                                    onBlur={(value) => {
                                                        this.homeZipCodeChanged(value);
                                                    }}>
                                                </ZipCode>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Zip</label>
                                                    <FieldView value={this.state.zip} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <StateSelection
                                                    key={this.state.state}
                                                    label={'State'}
                                                    isMulti={false}
                                                    selectedStateCode={this.state.state}
                                                    onChange={(value) => { this.setState({ state: value.value }) }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>State</label>
                                                    <FieldView value={this.state.state} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CitySelection
                                                    key={"".concat(this.state.state, this.state.city)}
                                                    label={'City'}
                                                    isMulti={false}
                                                    selectedStateCode={this.state.state}
                                                    selectedCity={this.state.city}
                                                    onChange={(value) => { console.log(value); this.setState({ city: value.value }) }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>City</label>
                                                    <FieldView value={this.state.city} />
                                                </>
                                            }
                                        </div>

                                    </div>
                                    <div className="page-title">
                                        <h2 className="heading-custom">Practice Address</h2>
                                    </div>
                                    <div class="row">
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Practice Address</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.practiceAddress}
                                                    onChange={event => this.setState({ practiceAddress: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.practiceAddress} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Practice Address 2</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.practiceAddress2}
                                                    onChange={event => this.setState({ practiceAddress2: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.practiceAddress2} />
                                            }
                                        </div>
                                        <div className="col-md-2 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <ZipCode title={'Zip'}
                                                    className='code-input'
                                                    value={this.state.practiceZip}
                                                    onChange={(value) => {
                                                        this.setState({ 'practiceZip': value });
                                                    }}
                                                    onBlur={(value) => {
                                                        this.practiceZipCodeChanged(value);
                                                    }}>
                                                </ZipCode>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Zip</label>
                                                    <FieldView value={this.state.practiceZip} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-2 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <StateSelection
                                                    key={this.state.practiceState}
                                                    label={'State'}
                                                    isMulti={false}
                                                    selectedStateCode={this.state.practiceState}
                                                    onChange={(value) => { this.setState({ practiceState: value.value }) }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <lable>State</lable>
                                                    <FieldView value={this.state.practiceState} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-2 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CitySelection
                                                    key={"".concat(this.state.practiceState, this.state.practiceCity)}
                                                    label={'City'}
                                                    isMulti={false}
                                                    selectedStateCode={this.state.practiceState}
                                                    selectedCity={this.state.practiceCity}
                                                    onChange={(value) => { console.log(value); this.setState({ practiceCity: value.value }) }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <lable>City</lable>
                                                    <FieldView value={this.state.practiceState} />
                                                </>
                                            }
                                        </div>

                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Primary Taxonomy</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.primaryTaxonomy}
                                                    onChange={event => this.setState({ primaryTaxonomy: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.primaryTaxonomy} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Secondary Taxonomy</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.secondaryTaxonomy}
                                                    onChange={event => this.setState({ secondaryTaxonomy: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.secondaryTaxonomy} />
                                            }
                                        </div>
                                        <div className="col-md-2 mrgn-btm10">
                                            <label>NPI #</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.npi}
                                                    onChange={event => this.setState({ npi: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.npi} />
                                            }
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Driving License</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.drivingLicense}
                                                    onChange={event => this.setState({ drivingLicense: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.drivingLicense} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <StateSelection
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "drivingLicenseState")}
                                                    key={this.state.drivingLicenseState}
                                                    label={'Driving License State'}
                                                    isMulti={false}
                                                    isRequired={FormValidatorService.isRequired(validationGroup, "drivingLicenseState")}
                                                    selectedStateCode={this.state.drivingLicenseState}
                                                    onChange={(value) => { this.setState({ drivingLicenseState: value.value }) }}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>State</label>
                                                    <FieldView value={this.state.drivingLicenseState} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Driving License Issue Date</label>
                                            {!this.state.viewOnly &&
                                                <DatePicker
                                                    isClearable
                                                    selected={this.state.drivingLicenseIssueDate}
                                                    onChange={date => this.setState({ drivingLicenseIssueDate: date })}
                                                    className="date-picker"
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={formatDate(this.state.drivingLicenseIssueDate)} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Driving License Expiry Date</label>
                                            {!this.state.viewOnly &&
                                                <DatePicker
                                                    isClearable
                                                    selected={this.state.drivingLicenseExpiryDate}
                                                    onChange={date => this.setState({ drivingLicenseExpiryDate: date })}
                                                    className="date-picker"
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={formatDate(this.state.drivingLicenseExpiryDate)} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CustomDropDown
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "certificate", '')}
                                                    type={'certificates'}
                                                    isMulti={false}
                                                    isRequired={FormValidatorService.isRequired(validationGroup, "certificate")}
                                                    selectedValue={this.state.certificate}
                                                    onChange={value => {
                                                        this.setState({ certificate: value });
                                                    }}>
                                                </CustomDropDown>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Choose a Certificate <span className="required">*</span></label>
                                                    <FieldView value={this.state.certificate?.label} />
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>License Certificate #</label>
                                            {!this.state.viewOnly &&
                                                <input
                                                    className='code-input'
                                                    type="text"
                                                    value={this.state.licenseCertificate}
                                                    onChange={event => this.setState({ licenseCertificate: event.target.value })}
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={this.state.licenseCertificate} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Certificate Issue Date</label>
                                            {!this.state.viewOnly &&
                                                <DatePicker
                                                    isClearable
                                                    selected={this.state.certificateIssueDate}
                                                    onChange={date => this.setState({ certificateIssueDate: date })}
                                                    className="date-picker"
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={formatDate(this.state.certificateIssueDate)} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Certificate Expiry Date</label>
                                            {!this.state.viewOnly &&
                                                <DatePicker
                                                    isClearable
                                                    selected={this.state.certificateExpiryDate}
                                                    onChange={date => this.setState({ certificateExpiryDate: date })}
                                                    className="date-picker"
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={formatDate(this.state.certificateExpiryDate)} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            {!this.state.viewOnly &&
                                                <CustomDropDown
                                                    className={FormValidatorService.getClass(validationGroup, this.state, "region", '')}
                                                    type={'regions'}
                                                    isMulti={false}
                                                    selectedValue={this.state.region}
                                                    isRequired={FormValidatorService.isRequired(validationGroup, "region")}
                                                    onChange={value => {
                                                        this.setState({ region: value });
                                                    }}>
                                                </CustomDropDown>
                                            }
                                            {this.state.viewOnly &&
                                                <>
                                                    <label>Region {FormValidatorService.getRequiredHTML(validationGroup, "region")}</label>
                                                    <FieldView value={this.state.region?.label} />
                                                </>
                                            }
                                        </div>

                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Start Date</label>
                                            {!this.state.viewOnly &&
                                                <DatePicker
                                                    isClearable
                                                    selected={this.state.startDate}
                                                    onChange={date => this.setState({ startDate: date })}
                                                    className="date-picker"
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={formatDate(this.state.startDate)} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <label>Termination Date</label>
                                            {!this.state.viewOnly &&
                                                <DatePicker
                                                    isClearable
                                                    selected={this.state.endDate}
                                                    onChange={date => this.setState({ endDate: date })}
                                                    className="date-picker"
                                                />
                                            }
                                            {this.state.viewOnly &&
                                                <FieldView value={formatDate(this.state.endDate)} />
                                            }
                                        </div>
                                        <div className="col-md-3 mrgn-btm10">
                                            <CustomDropDown
                                                className={FormValidatorService.getClass(validationGroup, this.state, "selectedDivision", '')}
                                                type={'divisions'}
                                                isMulti={false}
                                                isRequired={FormValidatorService.isRequired(validationGroup, "selectedDivision")}
                                                selectedValue={this.state.selectedDivision}
                                                onChange={value => {
                                                    this.setState({ selectedDivision: value });
                                                }}>
                                            </CustomDropDown>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="facility" role="tabpanel">
                                    {!this.state.viewOnly &&
                                        <div class="row">
                                            <div class='col-md-12'>
                                                <div className="page-title">
                                                    <h2 className="heading-custom">Add New Entry</h2>
                                                </div>
                                                <div class='row'>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <CustomDropDown
                                                            type={'facilities'}
                                                            isMulti={false}
                                                            all={true}
                                                            selectedValue={this.state.facility_facility}
                                                            className={FormValidatorService.getClass(validationGroupFacility, this.state, "facility_facility", '')}
                                                            isRequired={FormValidatorService.isRequired(validationGroupFacility, "facility_facility")}
                                                            onChange={value => {
                                                                this.setState({ facility_facility: value });
                                                            }}>
                                                        </CustomDropDown>
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Renewal Date {FormValidatorService.getRequiredHTML(validationGroupFacility, "facility_date_renewed")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupFacility, this.state, "facility_date_renewed")}
                                                            type="date"
                                                            value={this.state.facility_date_renewed}
                                                            onChange={event => this.setState({ facility_date_renewed: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Expiration Date {FormValidatorService.getRequiredHTML(validationGroupFacility, "facility_date_expiry")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupFacility, this.state, "facility_date_expiry")}
                                                            type="date"
                                                            value={this.state.facility_date_expiry}
                                                            onChange={event => this.setState({ facility_date_expiry: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Fees Paid {FormValidatorService.getRequiredHTML(validationGroupFacility, "facility_fee")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupFacility, this.state, "facility_fee")}
                                                            type="number"
                                                            value={this.state.facility_fee}
                                                            onChange={event => this.setState({ facility_fee: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Attachment {FormValidatorService.getRequiredHTML(validationGroupFacility, "facility_attachment")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupFacility, this.state, "facility_attachment")}
                                                            type="file"
                                                            onChange={(event) => {
                                                                this.setState({ 'subSaveDisabled': true });
                                                                const formData = new FormData();
                                                                formData.append("type", 'facility');
                                                                formData.append("file", event.target.files[0]);

                                                                assistantService.uploadFile(formData).then(tData => {
                                                                    let previousFileObject = this.state.facility_attachment;
                                                                    previousFileObject.push(tData.data);

                                                                    this.setState({ 'facility_attachment': previousFileObject });
                                                                }).finally(() => {
                                                                    this.setState({ 'subSaveDisabled': false });
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-right">
                                                    <button
                                                        onClick={this.submitFacility}
                                                        disabled={this.state.subSaveDisabled}
                                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                                        Save Facility Entry
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div className="page-title">
                                                <h2 className="heading-custom">Current Facility List</h2>
                                            </div>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr >
                                                        <th>Facility</th>
                                                        <th>Renewal Date</th>
                                                        <th>Expiration Date</th>
                                                        <th>Fees Paid</th>
                                                        <th>Attachments</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.facilities.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.facility.label}</td>
                                                                <td>{item.date_renewed}</td>
                                                                <td>{item.date_expiry}</td>
                                                                <td>{item.fee_paid}</td>
                                                                <td>
                                                                    {item.attachments.map((tItem) => {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                {tItem.name}
                                                                                <FontAwesomeIcon className="ml-2" icon={faDownload} onClick={() => { this.downloadAttachment(item, tItem) }} />
                                                                                <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteAttachment(item, tItem, 'facility') }} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    <FontAwesomeIcon className="ml-2" icon={faUpload} onClick={() => { this.uploadAttachment(item, 'facility') }}></FontAwesomeIcon>
                                                                    <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteFacility(item) }}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="vaccination" role="tabpanel">
                                    {!this.state.viewOnly &&
                                        <div class="row">
                                            <div class='col-md-12'>
                                                <div className="page-title">
                                                    <h2 className="heading-custom">Add New Entry</h2>
                                                </div>
                                                <div class='row'>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <CustomDropDown
                                                            type={'vaccinations'}
                                                            isRequired={FormValidatorService.getClass(validationGroupVaccine, "vaccine_vaccine")}
                                                            isMulti={false}
                                                            className={FormValidatorService.getClass(validationGroupVaccine, this.state, "vaccine_vaccine", '')}
                                                            selectedValue={this.state.vaccine_vaccine}
                                                            onChange={value => {
                                                                this.setState({ vaccine_vaccine: value });
                                                            }}>
                                                        </CustomDropDown>
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Date Administered {FormValidatorService.getRequiredHTML(validationGroupVaccine, "vaccine_date_administer")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupVaccine, this.state, "vaccine_date_administer")}
                                                            type="date"
                                                            value={this.state.vaccine_date_administer}
                                                            onChange={event => this.setState({ vaccine_date_administer: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Expiration Date {FormValidatorService.getRequiredHTML(validationGroupVaccine, "vaccine_date_expiry")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupVaccine, this.state, "vaccine_date_expiry")}
                                                            type="date"
                                                            value={this.state.vaccine_date_expiry}
                                                            onChange={event => this.setState({ vaccine_date_expiry: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Attachment {FormValidatorService.getRequiredHTML(validationGroupVaccine, "vaccine_attachment")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupVaccine, this.state, "vaccine_attachment")}
                                                            type="file"
                                                            onChange={(event) => {
                                                                this.setState({ 'subSaveDisabled': true });
                                                                const formData = new FormData();
                                                                formData.append("type", 'vaccine');
                                                                formData.append("file", event.target.files[0]);

                                                                assistantService.uploadFile(formData).then(tData => {
                                                                    let previousFileObject = this.state.vaccine_attachment;
                                                                    previousFileObject.push(tData.data);

                                                                    this.setState({ 'vaccine_attachment': previousFileObject });
                                                                }).finally(() => {
                                                                    this.setState({ 'subSaveDisabled': false });
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-right">
                                                    <button
                                                        onClick={this.submitVaccine}
                                                        disabled={this.state.subSaveDisabled}
                                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                                        Save Vaccine Entry
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div className="page-title">
                                                <h2 className="heading-custom">Current Vaccine List</h2>
                                            </div>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr >
                                                        <th>Vaccine</th>
                                                        <th>Date Administered</th>
                                                        <th>Expiration Date</th>
                                                        <th>Attachments</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.vaccinations.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.vaccine.label}</td>
                                                                <td>{item.date_administer}</td>
                                                                <td>{item.date_expiry}</td>
                                                                <td>
                                                                    {item.attachments.map((tItem) => {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                {tItem.name}
                                                                                <FontAwesomeIcon className="ml-2" icon={faDownload} onClick={() => { this.downloadAttachment(item, tItem) }} />
                                                                                <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteAttachment(item, tItem, 'vaccine') }} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    <FontAwesomeIcon className="ml-2" icon={faUpload} onClick={() => { this.uploadAttachment(item, 'vaccine') }}></FontAwesomeIcon>
                                                                    <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteVaccination(item) }}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="background-check" role="tabpanel">
                                    {!this.state.viewOnly &&
                                        <div class="row">
                                            <div class='col-md-12'>
                                                <div className="page-title">
                                                    <h2 className="heading-custom">Add New Entry</h2>
                                                </div>
                                                <div class='row'>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Date Performed {FormValidatorService.getRequiredHTML(validationGroupBackgroundCheck, "background_check_date_performed")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupBackgroundCheck, this.state, "background_check_date_performed")}
                                                            type="date"
                                                            value={this.state.background_check_date_performed}
                                                            onChange={event => this.setState({ background_check_date_performed: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Fees Paid {FormValidatorService.getRequiredHTML(validationGroupBackgroundCheck, "background_check_feepaid")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupBackgroundCheck, this.state, "background_check_feepaid")}
                                                            type="number"
                                                            value={this.state.background_check_feepaid}
                                                            onChange={event => this.setState({ background_check_feepaid: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Attachment {FormValidatorService.getRequiredHTML(validationGroupBackgroundCheck, "background_check_attachment")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupBackgroundCheck, this.state, "background_check_attachment")}
                                                            type="file"
                                                            onChange={(event) => {
                                                                this.setState({ 'subSaveDisabled': true });
                                                                const formData = new FormData();
                                                                formData.append("type", 'background_check');
                                                                formData.append("file", event.target.files[0]);

                                                                assistantService.uploadFile(formData).then(tData => {
                                                                    let previousFileObject = this.state.background_check_attachment;
                                                                    previousFileObject.push(tData.data);

                                                                    this.setState({ 'background_check_attachment': previousFileObject });
                                                                }).finally(() => {
                                                                    this.setState({ 'subSaveDisabled': false });
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 mrgn-btm10">
                                                        <label>Notes {FormValidatorService.getRequiredHTML(validationGroupBackgroundCheck, "background_check_notes")}</label>
                                                        <textarea
                                                            className={FormValidatorService.getClass(validationGroupBackgroundCheck, this.state, "background_check_notes")}
                                                            onChange={event => this.setState({ background_check_notes: event.target.value })}
                                                        >
                                                            {this.state.background_check_notes}
                                                        </textarea>
                                                    </div>

                                                </div>
                                                <div class="col-md-12 text-right">
                                                    <button
                                                        onClick={this.submitBackgroundCheck}
                                                        disabled={this.state.subSaveDisabled}
                                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                                        Save Background Check Entry
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div className="page-title">
                                                <h2 className="heading-custom">Current Background Check's List</h2>
                                            </div>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr >
                                                        <th>Date Performed</th>
                                                        <th>Notes</th>
                                                        <th>Fees Paid</th>
                                                        <th>Attachments</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.background_checks.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.date_performed}</td>
                                                                <td>{item.notes}</td>
                                                                <td>{item.fee_paid}</td>
                                                                <td>
                                                                    {item.attachments.map((tItem) => {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                {tItem.name}
                                                                                <FontAwesomeIcon className="ml-2" icon={faDownload} onClick={() => { this.downloadAttachment(item, tItem) }} />
                                                                                <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteAttachment(item, tItem, 'background_check') }} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    <FontAwesomeIcon className="ml-2" icon={faUpload} onClick={() => { this.uploadAttachment(item, 'background_check') }}></FontAwesomeIcon>
                                                                    <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteBackgroundCheck(item) }}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pto" role="tabpanel">
                                    {!this.state.viewOnly &&
                                        <div class="row">
                                            <div class='col-md-12'>
                                                <div className="page-title">
                                                    <h2 className="heading-custom">Add New Entry</h2>
                                                </div>
                                                <div class='row'>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Date {FormValidatorService.getRequiredHTML(validationGroupPTO, "pto_date")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupPTO, this.state, "pto_date")}
                                                            type="date"
                                                            value={this.state.pto_date}
                                                            onChange={event => this.setState({ pto_date: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Type {FormValidatorService.getRequiredHTML(validationGroupPTO, "pto_type")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupPTO, this.state, "pto_type")}
                                                            value={this.state.pto_type}
                                                            onChange={event => this.setState({ pto_type: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Amount {FormValidatorService.getRequiredHTML(validationGroupPTO, "pto_amount")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupPTO, this.state, "pto_amount")}
                                                            type="number"
                                                            value={this.state.pto_amount}
                                                            onChange={event => this.setState({ pto_amount: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Attachment {FormValidatorService.getRequiredHTML(validationGroupPTO, "pto_attachment")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupPTO, this.state, "pto_attachment")}
                                                            type="file"
                                                            onChange={(event) => {
                                                                this.setState({ 'subSaveDisabled': true });
                                                                const formData = new FormData();
                                                                formData.append("type", 'pto');
                                                                formData.append("file", event.target.files[0]);

                                                                assistantService.uploadFile(formData).then(tData => {
                                                                    let previousFileObject = this.state.pto_attachment;
                                                                    previousFileObject.push(tData.data);

                                                                    this.setState({ 'pto_attachment': previousFileObject });
                                                                }).finally(() => {
                                                                    this.setState({ 'subSaveDisabled': false });
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-right">
                                                    <button
                                                        onClick={this.submitPTO}
                                                        disabled={this.state.subSaveDisabled}
                                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                                        Save PTO Entry
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div className="page-title">
                                                <h2 className="heading-custom">Current PTO List</h2>
                                            </div>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr >
                                                        <th>Date</th>
                                                        <th>Type</th>
                                                        <th>Amount</th>
                                                        <th>Attachments</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.ptos.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.date}</td>
                                                                <td>{item.type}</td>
                                                                <td>{item.amount}</td>
                                                                <td>
                                                                    {item.attachments.map((tItem) => {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                {tItem.name}
                                                                                <FontAwesomeIcon className="ml-2" icon={faDownload} onClick={() => { this.downloadAttachment(item, tItem) }} />
                                                                                <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteAttachment(item, tItem, 'pto') }} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    <FontAwesomeIcon className="ml-2" icon={faUpload} onClick={() => { this.uploadAttachment(item, 'pto') }}></FontAwesomeIcon>
                                                                    <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deletePTO(item) }}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="degree" role="tabpanel">
                                    {!this.state.viewOnly &&
                                        <div class="row">
                                            <div class='col-md-12'>
                                                <div className="page-title">
                                                    <h2 className="heading-custom">Add New Entry</h2>
                                                </div>
                                                <div class='row'>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <CustomSelect type='Degree'
                                                            isRequired={FormValidatorService.isRequired(validationGroupDegree, "degree_degree")}
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_degree")}
                                                            value={this.state.degree_degree}
                                                            onChange={event => this.setState({ degree_degree: event.target.value })}
                                                        >
                                                        </CustomSelect>
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Date Aquired {FormValidatorService.getRequiredHTML(validationGroupDegree, "degree_aquired_date")}</label>
                                                        <input
                                                            type="date"
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_aquired_date")}
                                                            value={this.state.degree_aquired_date}
                                                            onChange={event => this.setState({ degree_aquired_date: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <label>Date Expired - If Applicable {FormValidatorService.getRequiredHTML(validationGroupDegree, "degree_expired_date")}</label>
                                                        <input
                                                            type="date"
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_expired_date")}
                                                            value={this.state.degree_expired_date}
                                                            onChange={event => this.setState({ degree_expired_date: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <StateSelection
                                                            key={this.state.degree_state}
                                                            label={'State'}
                                                            isMulti={false}
                                                            isRequired={FormValidatorService.isRequired(validationGroupDegree, "degree_state")}
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_state")}
                                                            selectedStateCode={this.state.degree_state}
                                                            onChange={(value) => { this.setState({ degree_state: value.value }) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mrgn-btm10">
                                                        <CustomSelect type='Degree Status'
                                                            isRequired={FormValidatorService.isRequired(validationGroupDegree, "degree_status")}
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_status")}
                                                            value={this.state.degree_status}
                                                            onChange={event => this.setState({ degree_status: event.target.value })}>
                                                        </CustomSelect>
                                                    </div>
                                                    <div className="col-md-4 mrgn-btm10">
                                                        <label>Attachment {FormValidatorService.getRequiredHTML(validationGroupDegree, "degree_attachment")}</label>
                                                        <input
                                                            className={FormValidatorService.getClass(validationGroupDegree, this.state, "degree_attachment")}
                                                            type="file"
                                                            onChange={(event) => {
                                                                this.setState({ 'subSaveDisabled': true });
                                                                const formData = new FormData();
                                                                formData.append("type", 'degree');
                                                                formData.append("file", event.target.files[0]);

                                                                assistantService.uploadFile(formData).then(tData => {
                                                                    let previousFileObject = this.state.degree_attachment;
                                                                    previousFileObject.push(tData.data);

                                                                    this.setState({ 'degree_attachment': previousFileObject });
                                                                }).finally(() => {
                                                                    this.setState({ 'subSaveDisabled': false });
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-right">
                                                    <button
                                                        onClick={this.submitDegree}
                                                        disabled={this.state.subSaveDisabled}
                                                        className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                                        Save Degree Entry
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div className="page-title">
                                                <h2 className="heading-custom">Current Degree List</h2>
                                            </div>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr >
                                                        <th>Degree</th>
                                                        <th>Date Aquired</th>
                                                        <th>Date Expired</th>
                                                        <th>State</th>
                                                        <th>Status</th>
                                                        <th>Attachments</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.degrees.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.degree}</td>
                                                                <td>{item.date_aquired}</td>
                                                                <td>{item.date_expired}</td>
                                                                <td>{item.state}</td>
                                                                <td>{item.status}</td>
                                                                <td>
                                                                    {item.attachments.map((tItem) => {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                {tItem.name}
                                                                                <FontAwesomeIcon className="ml-2" icon={faDownload} onClick={() => { this.downloadAttachment(item, tItem) }} />
                                                                                <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteAttachment(item, tItem, 'degree') }} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td>
                                                                    <FontAwesomeIcon className="ml-2" icon={faUpload} onClick={() => { this.uploadAttachment(item, 'degree') }}></FontAwesomeIcon>
                                                                    <FontAwesomeIcon className="ml-2" style={{ 'color': 'red' }} icon={faTrash} onClick={() => { this.deleteDegree(item) }}></FontAwesomeIcon>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.state.viewOnly &&
                            <div className="filter-footer">
                                <button
                                    onClick={this.submitAssistant}
                                    disabled={this.state.subSaveDisabled}
                                    className={this.state.subSaveDisabled ? "filter-save-button-disabled" : "filter-save-button"}>
                                    Submit
                                </button>
                                <button
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                                            state: {
                                                regionId: this.props.regionId || this.props.location.state.regionId,
                                            }
                                        })
                                    }}
                                    className="filter-cancel-button">
                                    Cancel
                                </button>
                            </div>
                        }
                        {this.state.viewOnly &&
                            <div className="filter-footer">
                                <button
                                    onClick={() => {
                                        this.props.history.push({
                                            pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                                            state: {
                                                regionId: this.props.regionId || this.props.location.state.regionId,
                                            }
                                        })
                                    }}
                                    className="filter-cancel-button">
                                    Surgical Assistants Listing
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <input type="file" id="generic-upload-facility" style={{ display: "none" }}
                    onChange={(event) => {
                        this.uploadAttachmentProcess(event);
                    }}></input>
                <input type="file" id="generic-upload-vaccine" style={{ display: "none" }}
                    onChange={(event) => {
                        this.uploadAttachmentProcess(event);
                    }}></input>
                <input type="file" id="generic-upload-background_check" style={{ display: "none" }}
                    onChange={(event) => {
                        this.uploadAttachmentProcess(event);
                    }}></input>
                <input type="file" id="generic-upload-pto" style={{ display: "none" }}
                    onChange={(event) => {
                        this.uploadAttachmentProcess(event);
                    }}></input>
                <input type="file" id="generic-upload-degree" style={{ display: "none" }}
                    onChange={(event) => {
                        this.uploadAttachmentProcess(event);
                    }}></input>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createAssistant,
            updateAssistant,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageAssistant));