import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getUserProfile(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.USER_DETAIL, options)
        .then(handleResponse)
        .catch(handleError);
}

function updateUser(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_USER_PROFILE, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const userProfileService = {
    getUserProfile,
    updateUser,
}