import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createSubCategory, updateSubCategory } from "../../actions/knowledgeBaseSubCategories";
import { knowledgeBaseSubCategoryService } from "../../services/knowledgeBaseSubCategories";
import CustomDropDown from "../organisms/CustomDropDown";

import { FormValidatorService } from "../../services/formvalidator";
import { utilityService } from "../../services/utility";

const validationGroup = {
  message: "There are some validation errors in Basic Information Tab:",
  fields: {
    'sub_category': { filedName: 'Sub Category Name', required: true, minLength: 3, maxLength: 200 },
    'selectedCategory': { filedName: 'Category', required: true },
  }
}

class ManageKnowledgeBaseCategory extends React.Component {
  state = {
    errors: [],
    sub_category: "",
    selectedCategory: {}
  };
  componentDidMount() {
    const subCategoryId = this.props.location.state
      ? this.props.location.state.subCategoryId
      : "";
    if (subCategoryId) {
      knowledgeBaseSubCategoryService.getSubCategoryDetail(subCategoryId).then((response) => {
        this.setState({
          sub_category: response.data.sub_category,
          selectedCategory: { id: response.data.category.id, label: response.data.category.category }
        });
      });
    }
  }

  validateKnowledgeBaseCategory = () => {
    let validationResult = FormValidatorService.isFormValid(validationGroup, this.state);
    if (!validationResult.isValid) {
      utilityService.showHTMLMessage('Validation Errors', validationResult.message, 'error');
    }

    return validationResult.isValid;
  };

  onChangeCategory = (item, value) => {
    this.setState({ selectedCategory: { id: item.value, label: item.label } });
  }

  submitSubCategory = () => {
    if (!this.validateKnowledgeBaseCategory()) {
      return;
    }

    const { createSubCategory, updateSubCategory } = this.props;
    const {
      selectedCategory,
      sub_category
    } = this.state;

    const request = {
      category_id: selectedCategory.id,
      sub_category: sub_category,
    };

    const subCategoryId = this.props.location.state
      ? this.props.location.state.subCategoryId
      : "";

    if (subCategoryId) {
      const payload = { ...request, id: subCategoryId };
      updateSubCategory(payload)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_SUB_CATEGORIES);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
    } else {
      createSubCategory(request)
        .then((res) => {
          if (res.response.success) {
            this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_SUB_CATEGORIES);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
    }
  };

  render() {
    const subCategoryId = this.props.location.state
      ? this.props.location.state.subCategoryId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {subCategoryId && <h2 className="heading-custom">Edit Knowledge Base Sub Category</h2>}
              {!subCategoryId && <h2 className="heading-custom">Add Knowledge Base Sub Category</h2>}
            </div>
            <div className="col-md-6" style={{ color: "black", float: "right" }}>
              <CustomDropDown
                type={'knowledgebase_categories'}
                className={FormValidatorService.getClass(validationGroup, this.state, "selectedCategory", '')}
                isRequired={FormValidatorService.isRequired(validationGroup, "selectedCategory")}
                isMulti={false}
                selectedValue={this.state?.selectedCategory}
                onChange={this.onChangeCategory}>
              </CustomDropDown>
            </div>
            <div className="col-md-6 mrgn-btm10">
              <label>Sub Category {FormValidatorService.isRequired(validationGroup, "sub_category")}</label>
              <input
                type="text"
                className={FormValidatorService.getClass(validationGroup, this.state, "sub_category")}
                value={this.state.sub_category}
                onChange={(event) => {
                  this.setState({ sub_category: event.target.value });
                }}
              />
            </div>

          </div>
          <div className="filter-footer">
            {subCategoryId && (
              <button onClick={this.submitSubCategory} className="filter-save-button">
                Update
              </button>
            )}
            {!subCategoryId && (
              <button onClick={this.submitSubCategory} className="filter-save-button">
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.KNOWLEDGE_BASE_SUB_CATEGORIES);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSubCategory,
      updateSubCategory,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageKnowledgeBaseCategory));
