import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getSubCategories(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_KNOWLEDGE_BASE_SUB_CATEGORIES, options)
        .then(handleResponse)
        .catch(handleError);
}

function createSubCategory(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_KNOWLEDGE_BASE_SUB_CATEGORIES, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateSubCategory(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_KNOWLEDGE_BASE_SUB_CATEGORIES, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteSubCategory(user) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_KNOWLEDGE_BASE_SUB_CATEGORIES + user)
        .then(handleResponse)
        .catch(handleError);
}

function getSubCategoryDetail(user) {
    return apiClient()
        .get(API_CONSTANTS.KNOWLEDGE_BASE_SUB_CATEGORIES_DETAIL + user)
        .then(handleResponse)
        .catch(handleError);
}

export const knowledgeBaseSubCategoryService = {
    getSubCategories,
    createSubCategory,
    updateSubCategory,
    deleteSubCategory,
    getSubCategoryDetail,
}