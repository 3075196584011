import { PRACTICE } from '../constants/actions';
import { practiceService } from '../services/practices';


export const fetchPractice = options => dispatch => {
    return new Promise((resolve, reject) => {
        practiceService
            .getPractices(options)
            .then(response => {
                dispatch({
                    type: PRACTICE.FETCH_PRACTICE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createPractice = payload => dispatch => {
    return new Promise((resolve, reject) => {
        practiceService
            .createPractice(payload)
            .then(response => {
                dispatch({
                    type: PRACTICE.CREATE_PRACTICE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updatePractice = payload => dispatch => {
    return new Promise((resolve, reject) => {
        practiceService
            .updatePractice(payload)
            .then(response => {
                dispatch({
                    type: PRACTICE.UPDATE_PRACTICE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
