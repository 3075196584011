import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getSurgeons(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_SURGEONS, options)
        .then(handleResponse)
        .catch(handleError);
}

function createSurgeon(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_SURGEON, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateSurgeon(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_SURGEON, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteSurgeon(patient) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_SURGEON + patient)
        .then(handleResponse)
        .catch(handleError);
}

function getSurgeonDetail(surgeon) {
    return apiClient()
        .get(API_CONSTANTS.SURGEON_DETAIL + surgeon)
        .then(handleResponse)
        .catch(handleError);
}

function activateSurgeons(payload) {
    return apiClient()
        .post(API_CONSTANTS.ACTIVATE_SURGEON, payload)
        .then(handleResponse)
        .catch(handleError);
}

function inActivateSurgeons(payload) {
    return apiClient()
        .post(API_CONSTANTS.IN_ACTIVATE_SURGEON, payload)
        .then(handleResponse)
        .catch(handleError);
}

function uploadFile(payload) {
    return apiClient()
        .post(API_CONSTANTS.SRUGEON_UPLOAD_DOCUMENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteDocument(payload) {
    return apiClient()
        .post(API_CONSTANTS.SRUGEON_DELETE_DOCUMENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function addDocument(payload) {
    return apiClient()
        .post(API_CONSTANTS.SRUGEON_ADD_DOCUMENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteDegree(id) {
    return apiClient()
        .delete(API_CONSTANTS.SRUGEON_DELETE_DEGREE + id)
        .then(handleResponse)
        .catch(handleError);
}



export const surgeonService = {
    getSurgeons,
    createSurgeon,
    updateSurgeon,
    deleteSurgeon,
    getSurgeonDetail,
    activateSurgeons,
    inActivateSurgeons,
    uploadFile,
    deleteDocument,
    addDocument,
    deleteDegree
}