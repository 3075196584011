import { claimsService } from "../services/claims";
import { CLAIM } from "../constants/actions";

export const fetchClaims = options => dispatch => {
    return new Promise((resolve, reject) => {
        claimsService
            .getClaims(options)
            .then(response => {
                dispatch({
                    type: CLAIM.FETCH_CLAIMS_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const cleanSearch = options => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: CLAIM.CLEAN_SEARCH,
        });
        resolve({});
    });
};
