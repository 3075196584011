import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getContracts(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_CONTRACTS, options)
        .then(handleResponse)
        .catch(handleError);
}

function deleteContract(contract) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_CONTRACT + contract)
        .then(handleResponse)
        .catch(handleError);
}

function deleteOnCallRate(id) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ONCALL_RATE_CONTRACT + id)
        .then(handleResponse)
        .catch(handleError);
}

function deleteProcedureRate(id) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_PROCEDURE_RATE_CONTRACT + id)
        .then(handleResponse)
        .catch(handleError);
}


function getContractDetail(contract) {
    return apiClient()
        .get(API_CONSTANTS.CONTRACT_DETAIL + contract)
        .then(handleResponse)
        .catch(handleError);
}

function createContract(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_CONTRACT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateContract(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_CONTRACT, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const contractService = {
    getContracts,
    deleteContract,
    getContractDetail,
    createContract,
    updateContract,
    deleteOnCallRate,
    deleteProcedureRate
}