import React from "react";
import {APP_ROUTES} from "../../constants/common";
import {withRouter} from "react-router-dom";

class EventCard extends React.Component {
    showManageActivity = activityId => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_ACTIVITY,
            state: {
                activityId,
                screen: APP_ROUTES.DASHBOARD,
            }
        });
    };

    getStatusColor = status => {
        switch (status) {
            case 'new':
                return 'new-event';
            case 'started':
                return 'started-event';
            case 'cancelled':
                return 'cancelled-event';
            case 'completed':
                return 'completed-event';
            default:
                return '';
        }
    };

    render() {
        const { event } = this.props;
        return (
            <div
                tabIndex="0"
                className={this.getStatusColor(event.status)}
                onClick={() => this.showManageActivity(event.id)}>
                <div
                    className="rbc-event-content"
                    title={event.title}>
                    {event.title}
                </div>
            </div>
        );
    }
}

export default withRouter(EventCard);
