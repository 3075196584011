import { LOCAL_STORAGE_KEYS } from '../constants/common';

function getURLSlug() {
    let str = window.location.href;
    if (str.indexOf('/') > 0) {
        str = str.split("/");
        str = str[str.length - 1];
    }
    if (str.indexOf('?') > 0) {
        str = str.split("?");
        str = str[0];
    }
    if(str.endsWith('-dashboard')) {
        str = str.replace('-dashboard', '');
    }
    if(str.startsWith('edit-')) {
        str = str.replace('edit-', '');
    }
    if(str.startsWith('add-')) {
        str = str.replace('add-', '');
    }
    const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
    let tModules = [];

    if (loggedInUser.groups_users) {
        loggedInUser.groups_users.forEach(group => {          //eslint-disable-line
            if (group.modules_groups) {
                let tItems = group.modules_groups.filter(t => {
                    return t.slug === str;
                })
                tItems.forEach(temp => {
                    tModules.push(temp);
                })
            }
        });
    }
    return tModules;
}



export const authorizationURLService = {
    getURLSlug,
};
