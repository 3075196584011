import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import PropTypes from "prop-types";
import TopNavbar from "../organisms/TopNavbar";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { practiceService } from "../../services/practices";
import { facilityService } from "../../services/facilities";
import DatePicker from "react-datepicker";
import { createNotification } from "../../utils/notificationManager";
import { claimsService } from "../../services/claims";
import { formatDate } from "../../utils/date";
import StateSelection from "../organisms/StateSelection";
import CitySelection from "../organisms/CitySelection";
import ZipCode from "../organisms/ZipCode";
import CustomDropDown from "../organisms/CustomDropDown";
import CustomSelect from "../organisms/CustomSelect";

const REQUIRED_FIELDS = {
  STARTDATE: "startDate",
  ENDDATE: "endDate",
  PATIENT_DOB: "patientDob",
  MEMBER_NUMBER: "insuranceMember",
  GROUP_NUMBER: "insuranceGroup",
  INSURANCE_CITY: "insuranceCity",
  INSURANCE_STATE: "insuranceState",
  INSURANCE_ZIP: "insuranceZip",
  INSURANCE_TYPE: "insuranceType",
  INSURANCE_NAME: "insuranceName",
  INSURANCE_PAYERCODE: "insurancePayercode",
  INSURANCE_FIRST_NAME: "insuranceFirstName",
  INSURANCE_LAST_NAME: "insuranceLastName",
  INSURANCE_DOB: "insuranceDob",
};

class ManageClaimDetail extends React.Component {
  static propTypes = {
    screen: PropTypes.string,
  };
  state = {
    toggleEventDetail: true,
    togglePatientDetail: false,
    toggleServiceInformation: false,
    toggleInsuranceInfo: false,
    toggleBillingProvider: false,
    toggleBillingDetail: false,
    claim: {},
    eventId: "",
    patientId: "",
    billId: "",
    surgeon: {},
    assistant: {},
    eventType: {},
    startDate: "",
    endDate: "",
    //patientId: "",
    patientFirstName: "",
    patientLastName: "",
    patientDob: "",
    patientGender: "",
    patientAddress1: "",
    patientAddress2: "",
    patientCity: "",
    patientPhone: "",
    insuranceId: "",
    insuranceType: "primary",
    insuranceName: {},
    insurancePayercode: "",
    insuranceDob: "",
    insuranceFirstName: "",
    insuranceLastName: "",
    insuranceGroup: "",
    insuranceMember: "",
    serviceLines: [],
    workerPatientCondition: "",
    employerName: "",
    dateOfInjury: "",
    illnessIndicator: "",
    practice: "",
    practicenpi: "",
    practicelastname: "",
    practicefirstname: "",
    practiceaddressline1: "",
    practiceaddressline2: "",
    practicecity: "",
    practicestate: "",
    practicezipcode: "",
    practicetaxidentification: "",
    placeOfService: "",
    facility: "",
    facilityNpi: "",
    facilityAddress: "",
    facilityCity: "",
    facilityState: "",
    facilityZip: "",
    insuranceCity: "",
    insuranceState: "",
    insuranceZip: "",
    errors: [],
  };

  componentDidMount() {
    // const claimDetails = this.props.location.state
    //   ? this.props.location.state.claimDetails
    //   : "";
    // const billId = this.props.location.state
    //   ? this.props.location.state.billId
    //   : "";
    // const eventId = this.props.location.state
    //   ? this.props.location.state.eventId
    //   : "";

    let claimDetails, eventId, bill_id;
    if (this.props.claimDetails) {
      claimDetails = this.props.claimDetails;
    } else {
      claimDetails = this.props.location.state
        ? this.props.location.state.claimDetails
        : "";
    }
    if (this.props.bill_id) {
      bill_id = this.props.bill_id;
    } else {
      bill_id = this.props.location.state
        ? this.props.location.state.billId
        : "";
    }

    if (this.props.eventId) {
      eventId = this.props.eventId;
    } else {
      eventId = this.props.location.state
        ? this.props.location.state.eventId
        : "";
    }
    claimDetails.map((claim) => {     //eslint-disable-line
      if (claim.event_id === eventId && claim.old_bill_id === bill_id) {
        this.setState(
          {
            claim: claim,
            eventId: claim.event_id,
            patientId:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.id
                : "",
            billId: claim.old_bill_id,
            surgeon: {
              label:
                claim.event_patient && claim.event_patient.surgeon
                  ? `${claim.event_patient.surgeon.first_name} ${claim.event_patient.surgeon.last_name}`
                  : "",
              value:
                claim.event_patient && claim.event_patient.surgeon
                  ? claim.event_patient.surgeon.id
                  : "",
            },
            assistant: {
              label:
                claim.event_patient && claim.event_patient.assistant
                  ? `${claim.event_patient.assistant.name} ${claim.event_patient.assistant.last_name}`
                  : "",
              value:
                claim.event_patient && claim.event_patient.assistant
                  ? claim.event_patient.assistant.id
                  : "",
            },
            eventType: {
              label:
                claim.event_patient && claim.event_patient.event_type
                  ? claim.event_patient.event_type.event_type_name
                  : "",
              value:
                claim.event_patient && claim.event_patient.event_type
                  ? claim.event_patient.event_type.id
                  : "",
            },
            startDate: claim.event_patient
              ? Date.parse(claim.event_patient.event_start_date)
              : "",
            endDate: claim.event_patient
              ? Date.parse(claim.event_patient.event_end_date)
              : "",

            patientFirstName:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.first_name
                : "",
            patientLastName:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.last_name
                : "",
            patientDob:
              claim.event_patient && claim.event_patient.patient
                ? Date.parse(claim.event_patient.patient.date_of_birth)
                : "",
            patientGender:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.gender
                : "",
            patientAddress1:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.address1
                : "",
            patientAddress2:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.address2
                : "",
            patientCity:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.city
                : "",
            patientPhone:
              claim.event_patient && claim.event_patient.patient
                ? claim.event_patient.patient.phone_number
                : "",
            workerPatientCondition:
              claim.event_patient &&
                claim.event_patient.patient &&
                claim.event_patient.patient.patient_insurance_data.workers_comp
                ? claim.event_patient.patient.patient_insurance_data
                  .workers_comp.patient_condition
                : "",
            employerName:
              claim.event_patient &&
                claim.event_patient.patient &&
                claim.event_patient.patient.patient_insurance_data.workers_comp
                ? claim.event_patient.patient.patient_insurance_data
                  .workers_comp.employer_name
                : "",
            dateOfInjury:
              claim.event_patient &&
                claim.event_patient.patient &&
                claim.event_patient.patient.patient_insurance_data.workers_comp
                ? claim.event_patient.patient.patient_insurance_data
                  .workers_comp.date_of_injury
                : "",
            illnessIndicator:
              claim.event_patient &&
                claim.event_patient.patient &&
                claim.event_patient.patient.patient_insurance_data.workers_comp
                ? claim.event_patient.patient.patient_insurance_data
                  .workers_comp.illness_indicator
                : "",
            serviceLines: claim.service_lines ? claim.service_lines : [],
            practiceid: claim.practice.id,
            practice: {
              label: claim.practice ? `${claim.practice.paytoname}` : "",
              value: claim.practice ? claim.practice.id : "",
            },
            practicenpi: claim.practice ? claim.practice.paytonpi : "",
            practicelastname: claim.practice ? claim.practice.paytoname : "",
            practicefirstname: claim.practice
              ? claim.practice.paytofirst_name
              : "",
            practiceaddressline1: claim.practice
              ? claim.practice.billing_address
              : "",
            practiceaddressline2: claim.practice
              ? claim.practice.billing_address2
              : "",
            practicecity: claim.practice ? claim.practice.paytocity : "",
            practicestate: claim.practice ? claim.practice.paytostate : "",
            practicezipcode: claim.practice ? claim.practice.paytozip : "",
            practicetaxidentification: claim.practice
              ? claim.practice.ein_ssn
              : "",
            placeOfService: claim.event_patient
              ? claim.event_patient.pos_id
              : "0",
            facility: {
              label:
                claim.event_patient && claim.event_patient.facility
                  ? `${claim.event_patient.facility.facility_name}`
                  : "",
              value:
                claim.event_patient && claim.event_patient.facility
                  ? claim.event_patient.facility.id
                  : "",
            },
            facilityNpi: claim.event_patient.facility
              ? claim.event_patient.facility.npi
              : "",
            facilityAddress: claim.event_patient.facility
              ? claim.event_patient.facility.address
              : "",
            facilityCity: claim.event_patient.facility
              ? claim.event_patient.facility.city
              : "",
            facilityState: claim.event_patient.facility
              ? claim.event_patient.facility.state
              : "",

            facilityZip: claim.event_patient.facility
              ? claim.event_patient.facility.zip_code
              : "",
          },
          () => {
            this.insuranceDetail();
          }
        );
      }
    });
  }

  insuranceDetail = () => {
    const { claim, insuranceType } = this.state;
    let insurance = {};
    if (insuranceType === "primary") {
      insurance = {
        id:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary
            ? claim.event_patient.patient.patient_insurance_data.primary.id
            : "",
        name: {
          label:
            claim.event_patient &&
              claim.event_patient.patient &&
              claim.event_patient.patient.patient_insurance_data &&
              claim.event_patient.patient.patient_insurance_data.primary
              ? claim.event_patient.patient.patient_insurance_data.primary
                .description
              : "",
          value:
            claim.event_patient &&
              claim.event_patient.patient &&
              claim.event_patient.patient.patient_insurance_data &&
              claim.event_patient.patient.patient_insurance_data.primary
              ? claim.event_patient.patient.patient_insurance_data.primary.id
              : "",
        },
        payercode:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary
            ? claim.event_patient.patient.patient_insurance_data.primary
              .payor_code
            : "",
        firstName:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .primary_subscriber.subscriber_first_name
            : "",
        lastName:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .primary_subscriber.subscriber_last_name
            : "",
        dob:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary_subscriber
            ? Date.parse(
              claim.event_patient.patient.patient_insurance_data
                .primary_subscriber.subscriber_dob
            )
            : "",
        group:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .primary_subscriber.subscriber_group_number
            : "",
        member:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .primary_subscriber.subscriber_member_number
            : "",
        insurance_city_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary
            ? claim.event_patient.patient.patient_insurance_data.primary.city
            : "",
        insurance_state_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary
            ? claim.event_patient.patient.patient_insurance_data.primary.state
            : "",
        insurance_zip_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.primary
            ? claim.event_patient.patient.patient_insurance_data.primary.zip
            : "",
      };
    } else if (insuranceType === "secondary") {
      insurance = {
        id:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.secondary
            ? claim.event_patient.patient.patient_insurance_data.secondary.id
            : "",
        name: {
          label:
            claim.event_patient &&
              claim.event_patient.patient &&
              claim.event_patient.patient.patient_insurance_data &&
              claim.event_patient.patient.patient_insurance_data.secondary
              ? claim.event_patient.patient.patient_insurance_data.secondary
                .description
              : "",
          value:
            claim.event_patient &&
              claim.event_patient.patient &&
              claim.event_patient.patient.patient_insurance_data &&
              claim.event_patient.patient.patient_insurance_data.secondary
              ? claim.event_patient.patient.patient_insurance_data.secondary.id
              : "",
        },
        payercode:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.secondary
            ? claim.event_patient.patient.patient_insurance_data.secondary
              .payor_code
            : "",
        insurance_city_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.secondary
            ? claim.event_patient.patient.patient_insurance_data.secondary.city
            : "",
        insurance_state_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.secondary
            ? claim.event_patient.patient.patient_insurance_data.secondary.state
            : "",
        insurance_zip_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.secondary
            ? claim.event_patient.patient.patient_insurance_data.secondary.zip
            : "",
        firstName:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber.subscriber_first_name
            : "",
        lastName:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber.subscriber_last_name
            : "",
        dob:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber
            ? Date.parse(
              claim.event_patient.patient.patient_insurance_data
                .secondary_subscriber.subscriber_dob
            )
            : "",
        group:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber.subscriber_group_number
            : "",
        member:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .secondary_subscriber.subscriber_member_number
            : "",
      };
    } else if (insuranceType === "tertiary") {
      insurance = {
        id:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary
            ? claim.event_patient.patient.patient_insurance_data.tertiary.id
            : "",
        name: {
          label:
            claim.event_patient &&
              claim.event_patient.patient &&
              claim.event_patient.patient.patient_insurance_data &&
              claim.event_patient.patient.patient_insurance_data.tertiary
              ? claim.event_patient.patient.patient_insurance_data.tertiary
                .description
              : "",
          value:
            claim.event_patient &&
              claim.event_patient.patient &&
              claim.event_patient.patient.patient_insurance_data &&
              claim.event_patient.patient.patient_insurance_data.tertiary
              ? claim.event_patient.patient.patient_insurance_data.tertiary.id
              : "",
        },
        payercode:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary
            ? claim.event_patient.patient.patient_insurance_data.tertiary
              .payor_code
            : "",
        insurance_city_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary
            ? claim.event_patient.patient.patient_insurance_data.tertiary.city
            : "",
        insurance_state_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary
            ? claim.event_patient.patient.patient_insurance_data.tertiary.state
            : "",
        insurance_zip_:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary
            ? claim.event_patient.patient.patient_insurance_data.tertiary.zip
            : "",
        firstName:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .tertiary_subscriber.subscriber_first_name
            : "",
        lastName:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .tertiary_subscriber.subscriber_last_name
            : "",
        dob:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
            ? Date.parse(
              claim.event_patient.patient.patient_insurance_data
                .tertiary_subscriber.subscriber_dob
            )
            : "",
        group:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .tertiary_subscriber.subscriber_group_number
            : "",
        member:
          claim.event_patient &&
            claim.event_patient.patient &&
            claim.event_patient.patient.patient_insurance_data &&
            claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
            ? claim.event_patient.patient.patient_insurance_data
              .tertiary_subscriber.subscriber_member_number
            : "",
      };
    }

    this.setState({
      insuranceId: insurance.id,
      insuranceName: insurance.name,
      insurancePayercode: insurance.payercode,
      insuranceDob: insurance.dob,
      insuranceFirstName: insurance.firstName,
      insuranceLastName: insurance.lastName,
      insuranceGroup: insurance.group,
      insuranceMember: insurance.member,
      insuranceCity: insurance.insurance_city_,
      insuranceState: insurance.insurance_state_,
      insuranceZip: insurance.insurance_zip_,
    });
  };
  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };
  toggleEventDetail = () => {
    this.setState((prevState) => ({
      toggleEventDetail: !prevState.toggleEventDetail,
    }));
  };
  toggleServiceInformation = () => {
    this.setState((prevState) => ({
      toggleServiceInformation: !prevState.toggleServiceInformation,
    }));
  };

  togglePatientDetail = () => {
    this.setState((prevState) => ({
      togglePatientDetail: !prevState.togglePatientDetail,
    }));
  };

  toggleInsuranceInfo = () => {
    this.setState((prevState) => ({
      toggleInsuranceInfo: !prevState.toggleInsuranceInfo,
    }));
  };

  toggleBillingProvider = () => {
    this.setState((prevState) => ({
      toggleBillingProvider: !prevState.toggleBillingProvider,
    }));
  };

  toggleBillingDetail = () => {
    this.setState((prevState) => ({
      toggleBillingDetail: !prevState.toggleBillingDetail,
    }));
  };
  onChangePractices = (value) => {
    this.resetErrors(REQUIRED_FIELDS.practice);

    practiceService.getPracticeDetail(value.value).then((response) => {
      this.setState({
        practicenpi: response.data.paytonpi,
        practicelastname: response.data.paytoname,
        practicefirstname: response.data.paytofirst_name,
        practiceaddressline1: response.data.billing_address,
        practiceaddressline2: response.data.billing_address2,
        practicecity: response.data.paytocity,
        practicestate: response.data.paytostate,
        practicezipcode: response.data.paytozip,
        // tax_identification: response.data.tax_identification,
        practicetaxidentification: response.data.ein_ssn,
        // clia_number: response.data.clia_number,
        // taxonomy_code: response.data.taxonomy_code,
      });
    });

    this.setState({ practice: value });
  };

  onChangeFacility = (value) => {
    this.resetErrors(REQUIRED_FIELDS.practice);

    facilityService.getFacilityDetail(value.value).then((response) => {
      this.setState({
        facilityNpi: response.data.npi,
        facilityAddress: response.data.address,
        facilityCity: response.data.city,
        facilityState: response.data.state,
        facilityZip: response.data.zip_code,
      });
    });

    this.setState({ facility: value });
  };
  onChangeSurgeon = (value) => {
    this.setState({ surgeon: value });
  };
  onChangeAssistant = (value) => {
    this.setState({ assistant: value });
  };
  setStartDate = (value) => {
    this.resetErrors(REQUIRED_FIELDS.STARTDATE);

    this.setState({ startDate: value });
  };
  setEndDate = (value) => {
    this.resetErrors(REQUIRED_FIELDS.ENDDATE);

    this.setState({ endDate: value });
  };
  setpatientDob = (value) => {
    this.resetErrors(REQUIRED_FIELDS.PATIENT_DOB);
    this.setState({ patientDob: value });
  };
  setLoading = (flag) => {
    this.setState({ loading: flag });
  };
  onChangeEventType = (value) => {
    this.setState({ eventType: value });
  };

  validateActivity = () => {
    const { startDate, endDate, patientDob, errors } = this.state;
    let isValid = true;
    if (!startDate) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.STARTDATE] });
      isValid = false;
    } else if (!endDate) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.ENDDATE] });
      isValid = false;
    } else if (!patientDob) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.PATIENT_DOB] });
      isValid = false;
    }
    if (!isValid) {
      createNotification(
        NOTIFICATION_TYPES.ERROR,
        "Please select required values"
      );
    }

    return isValid;
  };
  updateclaimData = (updatesection) => {
    if (!this.validateActivity()) {
      return;
    }
    let options;
    let eventstartDate, eventendDate, patientDob;

    if (updatesection === "activity") {
      if (formatDate(this.state.startDate) > "01/01/1970") {
        eventstartDate = formatDate(this.state.startDate);
      } else {
        eventstartDate = "";
      }
      if (formatDate(this.state.endDate) > "01/01/1970") {
        eventendDate = formatDate(this.state.endDate);
      } else {
        eventendDate = "";
      }
      options = {
        update: updatesection,
        eventId: this.state.eventId,
        surgeon: this.state.surgeon,
        assistant: this.state.assistant,
        eventType: this.state.eventType,
        eventstartDate: eventstartDate,
        eventendDate: eventendDate,
      };
    }
    if (updatesection === "patientUpdate") {
      if (formatDate(this.state.patientDob) > "01/01/1970") {
        patientDob = formatDate(this.state.patientDob);
      } else {
        patientDob = "";
      }
      options = {
        update: updatesection,
        patientId: this.state.patientId,
        patientFirstName: this.state.patientFirstName,
        patientLastName: this.state.patientLastName,
        patientDob: patientDob,
        patientGender: this.state.patientGender,
        patientAddress1: this.state.patientAddress1,
        patientAddress2: this.state.patientAddress2,
        patientCity: this.state.patientCity,
        patientPhone: this.state.patientPhone,
        workerPatientCondition: this.state.workerPatientCondition,
        employerName: this.state.employerName,
        dateOfInjury: this.state.dateOfInjury,
        illnessIndicator: this.state.illnessIndicator,
      };
    }
    if (updatesection === "patientInsurance") {
      options = {
        update: updatesection,
        insuranceId: this.state.insuranceId,
        insuranceType: this.state.insuranceType,
        insuranceName: this.state.insuranceName,
        insurancePayercode: this.state.insurancePayercode,
        insuranceCity: this.state.insuranceCity,
        insuranceState: this.state.insuranceState,
        insuranceZip: this.state.insuranceZip,
        insuranceMember: this.state.insuranceMember,
        insuranceGroup: this.state.insuranceGroup,
      };
    }
    if (updatesection === "patientPaytoinformation") {
      options = {
        update: updatesection,
        practice: this.state.practice,
        practicenpi: this.state.practicenpi,
        practicelastname: this.state.practicelastname,
        practicefirstname: this.state.practicefirstname,
        practiceaddressline1: this.state.practiceaddressline1,
        practiceaddressline2: this.state.practiceaddressline2,
        practicecity: this.state.practicecity,
        practicestate: this.state.practicestate,
        practicezipcode: this.state.practicezipcode,
        practicetaxidentification: this.state.practicetaxidentification,
      };
    }
    if (updatesection === "patientserviceinformation") {
      options = {
        update: updatesection,
        eventId: this.state.eventId,
        placeOfService: this.state.placeOfService,
        facility: this.state.facility,
        facilityNpi: this.state.facilityNpi,
        facilityAddress: this.state.facilityAddress,
        facilityCity: this.state.facilityCity,
        facilityState: this.state.facilityState,
        facilityZip: this.state.facilityZip,
      };
    }

    claimsService
      .updateClaimAll(options)
      .then((res) => {
        if (res.success) {
          createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
        }
        this.setLoading(false);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  editPatient = () => {
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_PATIENT,
      state: {
        patientId: this.state.patientId,
        screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
      },
    });
  };

  editInsurance = () => {
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_INSURANCE,
      state: {
        insId: this.state.insuranceId,
        screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
      },
    });
  };

  editBilling = () => {
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_BILL,
      state: {
        billId: this.state.billId,
        eventId: this.state.eventId,
        screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
      },
    });
  };

  renderServiceLines = () => {
    const { serviceLines } = this.state;
    return serviceLines.map((serviceLine, index) => (
      <>
        <div
          style={{
            backgroundColor: "#e0e0e0",
          }}
          className="col-md-12 mrgn-btm10"
        >
          <label
            style={{
              paddingTop: "6px",
            }}
          >{`ServiceLine ${index + 1}`}</label>
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Service Date Start</label>
          <br />
          <DatePicker
            selected={Date.parse(serviceLine.line_start_date)}
            disabled
            className="date-picker"
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Service Date End</label>
          <br />
          <DatePicker
            disabled
            selected={Date.parse(serviceLine.line_end_date)}
            className="date-picker"
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>CPT</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.hcpcs_code}
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Mod 1</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.modifier1}
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Mod 2</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.modifier2}
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Mod 3</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.modifier3}
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Mod 4</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.modifier4}
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Units</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.units}
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Total</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.line_total}
          />
        </div>
        <div className="col-md-2 mrgn-btm10">
          <label>Discount</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.line_discount}
          />
        </div>
        <div className="col-md-4 mrgn-btm10">
          <label>CPT Description</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.code_description}
          />
        </div>
        <div className="col-md-3 mrgn-btm10">
          <label>Point of Diagnosis</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.points_to_diagnosis}
          />
        </div>
        <div className="col-md-3 mrgn-btm10">
          <label>Revenue Code</label>
          <input
            disabled
            type="text"
            className="code-input"
            value={serviceLine.revenue_code}
          />
        </div>
      </>
    ));
  };

  validateClaim = () => {
    const {
      insuranceType,
      insuranceFirstName,
      insuranceLastName,
      //insuranceGroup,
      insuranceMember,
      insuranceDob,
      insuranceName,
      errors,
    } = this.state;
    let isValid = true;
    if (insuranceType.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_TYPE] });
      isValid = false;
    } else if (insuranceFirstName.length === 0) {
      this.setState({
        errors: [...errors, REQUIRED_FIELDS.INSURANCE_FIRST_NAME],
      });
      isValid = false;
    } else if (insuranceLastName.length === 0) {
      this.setState({
        errors: [...errors, REQUIRED_FIELDS.INSURANCE_FIRST_NAME],
      });
      isValid = false;
    } else if (insuranceName.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_NAME] });
      isValid = false;
    }
    // else if (insuranceGroup.length === 0) {
    //   this.setState({ errors: [...errors, REQUIRED_FIELDS.GROUP_NUMBER] });
    //   isValid = false;
    // }
    else if (insuranceMember.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.MEMBER_NUMBER] });
      isValid = false;
    } else if (insuranceDob.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_DOB] });
      isValid = false;
    }

    if (!isValid) {
      createNotification(
        NOTIFICATION_TYPES.ERROR,
        "Please select required values"
      );
    }

    return isValid;
  };

  createClaim = () => {
    if (!this.validateClaim()) {
      return;
    }
    let eventId;
    if (this.props.eventId) {
      eventId = this.props.eventId;
    } else {
      eventId = this.props.location.state
        ? this.props.location.state.eventId
        : "";
    }
    this.props.history.push({
      pathname: APP_ROUTES.CLAIM_FORM,
      state: {
        eventId,
        insuranceType: this.state.insuranceType,
      },
    });
  };

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  facilityZipCodeChanged = (value) => {
    this.setState({ facilityZip: value.zip_code })

    if (value.state) {
      this.setState({ facilityState: value.state.code });
    }

    if (value.city) {
      this.setState({ facilityCity: value.city.name });
    }
  }

  billingProviderZipCodeChanged = (value) => {
    this.setState({ practicezipcode: value.zip_code })

    if (value.state) {
      this.setState({ practicestate: value.state.code });
    }

    if (value.city) {
      this.setState({ practicecity: value.city.name });
    }
  }

  payerZipCodeChanged = (value) => {
    this.setState({ insuranceZip: value.zip_code })

    if (value.state) {
      this.setState({ insuranceState: value.state.code });
    }

    if (value.city) {
      this.setState({ insuranceCity: value.city.name });
    }
  }

  render() {
    //const { claim } = this.state;
    return (
      <div className="main_container">
        {this.props.PopupRequest === undefined && (
          <>
            <div
              className="col-md-3 custom-sidebar-menu left_col"
              style={{
                minHeight: "190vh",
              }}
            >
              <Sidebar activePage={this.props.activePage} />
            </div>
            <TopNavbar />
          </>
        )}
        <div
          className={
            this.props.PopupRequest === 1 ? "" : "manage-activity-right-col"
          }
        >
          <div className="activity-row">
            {/* Event Details */}
            <div className="top-padding">
              <div className="page-title" onClick={this.toggleEventDetail}>
                <h2 className="heading-custom">+/- Event Detail</h2>
              </div>
              {this.state.toggleEventDetail && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-4 mrgn-btm10">
                    <label>Event ID</label>
                    <input
                      type="text"
                      className="code-input"
                      value={this.state.eventId}
                      disabled
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <CustomDropDown
                      type={'surgeons'}
                      className={this.hasErrors(REQUIRED_FIELDS.SURGEON) ? "error" : ""}
                      isRequired={true}
                      isMulti={false}
                      selectedValue={this.state?.surgeon}
                      onChange={this.onChangeSurgeon}>
                    </CustomDropDown>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <CustomDropDown
                      type={'assistants'}
                      className={this.hasErrors(REQUIRED_FIELDS.ASSISTANT) ? "error" : ""}
                      isRequired={true}
                      isMulti={false}
                      selectedValue={this.state?.assistant}
                      onChange={this.onChangeAssistant}>
                    </CustomDropDown>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <CustomDropDown
                      type={'event_types'}
                      className={this.hasErrors(REQUIRED_FIELDS.EVENT_TYPE) ? "error" : ""}
                      isRequired={true}
                      isMulti={false}
                      selectedValue={this.state?.eventType}
                      onChange={this.onChangeEventType}>
                    </CustomDropDown>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>Start Date</label>
                    <DatePicker
                      isClearable
                      selected={this.state.startDate}
                      onChange={(date) => this.setStartDate(date)}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.STARTDATE)
                          ? "error date-picker"
                          : "date-picker"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>End Date</label>
                    <br />
                    <DatePicker
                      isClearable
                      selected={this.state.endDate}
                      onChange={(date) => this.setEndDate(date)}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.ENDDATE)
                          ? "error date-picker"
                          : "date-picker"
                      }
                    />
                  </div>
                  <div className="filter-footer">
                    <button
                      onClick={() => this.updateclaimData("activity")}
                      className="filter-save-button"
                    >
                      Update Event Details
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* Patient Info */}
            <div className="top-padding">
              <div className="page-title" onClick={this.togglePatientDetail}>
                <h2 className="heading-custom">+/- Patient Info</h2>
              </div>
              {this.state.togglePatientDetail && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      value={this.state.patientFirstName}
                      className="code-input"
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={this.state.patientLastName}
                      className="code-input"
                    />
                  </div>
                  <div className="col-md-3 mrgn-btm10">
                    <label>DOB</label>
                    <br />
                    <DatePicker
                      isClearable
                      selected={this.state.patientDob}
                      onChange={(date) => this.setpatientDob(date)}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PATIENT_DOB)
                          ? "error date-picker"
                          : "date-picker"
                      }
                    />
                  </div>
                  <div className="col-md-3 mrgn-btm10">
                    <label>Gender</label>
                    <input
                      type="text"
                      value={this.state.patientGender}
                      className="code-input"
                    />
                  </div>
                  <div className="col-md-3 mrgn-btm10">
                    <label>Address 1</label>
                    <input
                      type="text"
                      value={this.state.patientAddress1}
                      className="code-input"
                    />
                  </div>
                  <div className="col-md-3 mrgn-btm10">
                    <label>Address 2</label>
                    <input
                      type="text"
                      value={this.state.patientAddress2}
                      className="code-input"
                    />
                  </div>
                  <div className="col-md-3 mrgn-btm10">
                    <label>City</label>
                    <input
                      type="text"
                      value={this.state.patientCity}
                      className="code-input"
                    />
                  </div>
                  <div className="col-md-3 mrgn-btm10">
                    <label>Phone</label>
                    <input
                      type="text"
                      value={this.state.patientPhone}
                      className="code-input"
                    />
                  </div>
                  <div
                    className="col-md-6 mrgn-btm10"
                    style={{
                      display: "none",
                    }}
                  >
                    <CustomSelect type='Patient Condition Related To'
                      value={this.state.workerPatientCondition}
                      className="filter-input"
                      onChange={(event) =>
                        this.setState({
                          workerPatientCondition: event.target.value,
                        })
                      }>
                    </CustomSelect>
                  </div>
                  <div
                    className="col-md-6 mrgn-btm10"
                    style={{
                      display: "none",
                    }}
                  >
                    <label>Employer Name</label>
                    <input
                      type="text"
                      className="code-input"
                      value={this.state.employerName}
                      onChange={(event) =>
                        this.setState({ employerName: event.target.value })
                      }
                    />
                  </div>
                  <div
                    className="col-md-6 mrgn-btm10"
                    style={{
                      display: "none",
                    }}
                  >
                    <label>Date of Injury</label>
                    <br />
                    <DatePicker
                      isClearable
                      className="filter-input"
                      selected={Date.parse(this.state.dateOfInjury)}
                      onChange={(date) => this.setState({ dateOfInjury: date })}
                    />
                  </div>
                  <div
                    className="col-md-6 mrgn-btm10"
                    style={{
                      display: "none",
                    }}
                  >
                    <CustomSelect type='Illness Indicator'
                      className="filter-input"
                      value={this.state.illnessIndicator}
                      onChange={(event) =>
                        this.setState({ illnessIndicator: event.target.value })
                      }>
                    </CustomSelect>
                  </div>

                  <div className="filter-footer">
                    <button
                      onClick={() => this.updateclaimData("patientUpdate")}
                      className="filter-save-button"
                    >
                      Update Patient Details
                    </button>
                    {/* <button
                      onClick={this.editPatient}
                      className="filter-save-button"
                    >
                      Edit
                    </button> */}
                  </div>
                </div>
              )}
            </div>
            {/* Insurance Subsriber */}
            <div className="top-padding">
              <div className="page-title" onClick={this.toggleInsuranceInfo}>
                <h2 className="heading-custom">
                  +/- Insurance Subscriber Info
                </h2>
              </div>
              {this.state.toggleInsuranceInfo && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-4 mrgn-btm10">
                    <CustomSelect type='Insurance Type'
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_TYPE)
                          ? "error filter-input"
                          : "filter-input"
                      }
                      value={this.state.insuranceType}
                      onChange={(event) =>
                        this.setState(
                          { insuranceType: event.target.value },
                          () => {
                            this.insuranceDetail();
                          }
                        )
                      }
                    >
                    </CustomSelect>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Insurance Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.insuranceName.label}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_NAME)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Electronic Claims Payer ID{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.insurancePayercode}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_NAME)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <ZipCode title={'Payer Zip Code'}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_ZIP)
                          ? "error code-input"
                          : "code-input"
                      }
                      value={this.state.insuranceZip}
                      onChange={(value) => {
                        this.setState({ 'insuranceZip': value });
                      }}
                      onBlur={(value) => {
                        this.payerZipCodeChanged(value);
                      }}>
                    </ZipCode>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <StateSelection
                      key={this.state.insuranceState}
                      label={'Payer State'}
                      isMulti={false}
                      selectedStateCode={this.state.insuranceState}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_STATE)
                          ? "error code-input"
                          : "code-input"
                      }
                      onChange={(value) => { this.setState({ insuranceState: value.value }) }}
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <CitySelection
                      key={"".concat(this.state.insuranceState, this.state.insuranceCity)}
                      label={'Payer City'}
                      isMulti={false}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_CITY)
                          ? "error code-input"
                          : "code-input"
                      }
                      selectedStateCode={this.state.insuranceState}
                      selectedCity={this.state.insuranceCity}
                      onChange={(value) => { console.log(value); this.setState({ insuranceCity: value.value }) }}
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Subscriber First Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.insuranceFirstName}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_FIRST_NAME)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Subscriber Last Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.insuranceLastName}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_LAST_NAME)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Subscriber DOB <span className="required">*</span>
                    </label>
                    <br />
                    <DatePicker
                      selected={this.state.insuranceDob}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.INSURANCE_DOB)
                          ? "error date-picker"
                          : "date-picker"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Subscriber Member # <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.insuranceMember}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.MEMBER_NUMBER)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>Subscriber Group #</label>
                    <input
                      type="text"
                      value={this.state.insuranceGroup}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.GROUP_NUMBER)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>

                  <div className="filter-footer">
                    <button
                      onClick={() => this.updateclaimData("patientInsurance")}
                      className="filter-save-button"
                    >
                      Update Insurance Details
                    </button>
                    {/* <button
                      onClick={this.editInsurance}
                      className="filter-save-button"
                    >
                      Edit
                    </button> */}
                  </div>
                </div>
              )}
            </div>
            {/* Pay to infomration */}
            <div className="top-padding">
              <div className="page-title" onClick={this.toggleBillingProvider}>
                <h2 className="heading-custom">
                  +/- Billing Provider and Pay To Infomration
                </h2>
              </div>
              {this.state.toggleBillingProvider && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-4 mrgn-btm10">
                    <CustomDropDown
                      label={'Billing Provider'}
                      type={'practices'}
                      className={this.hasErrors(REQUIRED_FIELDS.PRACTICE) ? "error" : ""}
                      isRequired={true}
                      isMulti={false}
                      selectedValue={this.state?.practice}
                      onChange={this.onChangePractices}>
                    </CustomDropDown>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Billing Provider NPI <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.practicenpi}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_NPI)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Billing Provider Last Name{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.practicelastname}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_LASTNAME)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Billing Provider First Name
                      {
                        " \n (Leave blank if Billing Provider Entity/Last Name is an Organization/entity)"
                      }
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.practicefirstname}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_FIRSTNAME)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Billing Provider Address 1
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.practiceaddressline1}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_ADDRESS_1)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>
                      Billing Provider Address 2
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.practiceaddressline2}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_ADDRESS_2)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <ZipCode title={'Billing Provider Zip Code'}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_ZIPCODE)
                          ? "error code-input"
                          : "code-input"
                      }
                      value={this.state.practicezipcode}
                      onChange={(value) => {
                        this.setState({ 'practicezipcode': value });
                      }}
                      onBlur={(value) => {
                        this.billingProviderZipCodeChanged(value);
                      }}>
                    </ZipCode>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <StateSelection
                      key={this.state.practicestate}
                      label={'Billing Provider State'}
                      isMulti={false}
                      selectedStateCode={this.state.practicestate}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_STATE)
                          ? "error code-input"
                          : "code-input"
                      }
                      onChange={(value) => { this.setState({ practicestate: value.value }) }}
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <CitySelection
                      key={"".concat(this.state.practicestate, this.state.practicecity)}
                      label={'Billing Provider City'}
                      isMulti={false}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_CITY)
                          ? "error code-input"
                          : "code-input"
                      }
                      selectedStateCode={this.state.practicestate}
                      selectedCity={this.state.practicecity}
                      onChange={(value) => { console.log(value); this.setState({ practicecity: value.value }) }}
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>Billing Provider TAX</label>
                    <input
                      type="text"
                      value={this.state.practicetaxidentification}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.PRACTICE_TAX)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="filter-footer">
                    <button
                      onClick={() =>
                        this.updateclaimData("patientPaytoinformation")
                      }
                      className="filter-save-button"
                    >
                      Update Pay to information Details
                    </button>
                    {/* <button
                      onClick={this.editInsurance}
                      className="filter-save-button"
                    >
                      Edit
                    </button> */}
                  </div>
                </div>
              )}
            </div>
            {/* Service Information(Diagnosis, Procedure(S)) */}
            <div className="top-padding">
              <div
                className="page-title"
                onClick={this.toggleServiceInformation}
              >
                <h2 className="heading-custom">
                  +/- Service Information(Diagnosis, Procedure(S))
                </h2>
              </div>
              {this.state.toggleServiceInformation && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-3 mrgn-btm10">
                    <label>Place of Service</label>
                    <select
                      value={this.state.placeOfService}
                      className="filter-input"
                      onChange={(event) =>
                        this.setState({ placeOfService: event.target.value })
                      }
                    >
                      <option value="">Place of Service</option>
                      <option value="nopos">No Default POS</option>
                      <option value="11">11 Office</option>
                      <option value="21">21 Inpatient Hospital</option>
                      <option value="22">22 Outpatient Hospital</option>
                      <option value="23">23 Emergency Room - Hospital</option>
                      <option value="24">24 Ambulatory Surgical Center</option>
                    </select>
                  </div>
                  <div
                    className="page-title"
                    onClick={this.toggleServiceInformation}
                  >
                    <h2 className="heading-custom">+/- Facility Information</h2>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <CustomDropDown
                      type={'facilities'}
                      className={this.hasErrors(REQUIRED_FIELDS.FACILITY) ? "error" : ""}
                      isRequired={true}
                      isMulti={false}
                      selectedValue={this.state?.facility}
                      onChange={this.onChangeFacility}>
                    </CustomDropDown>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>Facility NPI</label>
                    <input
                      type="text"
                      value={this.state.facilityNpi}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.FACILITYNPI)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <label>Facility Street Address</label>
                    <input
                      type="text"
                      value={this.state.facilityAddress}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.FACILITY_ADDRESS)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <ZipCode title={'Facility Zip Code'}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.FACILITY_ZIP)
                          ? "error code-input"
                          : "code-input"
                      }
                      value={this.state.facilityZip}
                      onChange={(value) => {
                        this.setState({ 'facilityZip': value });
                      }}
                      onBlur={(value) => {
                        this.facilityZipCodeChanged(value);
                      }}>
                    </ZipCode>
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <StateSelection
                      key={this.state.facilityState}
                      label={'Facility State'}
                      isMulti={false}
                      selectedStateCode={this.state.facilityState}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.FACILITY_STATE)
                          ? "error code-input"
                          : "code-input"
                      }
                      onChange={(value) => { this.setState({ facilityState: value.value }) }}
                    />
                  </div>
                  <div className="col-md-4 mrgn-btm10">
                    <CitySelection
                      key={"".concat(this.state.facilityState, this.state.facilityCity)}
                      label={'Facility City'}
                      isMulti={false}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.FACILITY_CITY)
                          ? "error code-input"
                          : "code-input"
                      }
                      selectedStateCode={this.state.facilityState}
                      selectedCity={this.state.facilityCity}
                      onChange={(value) => { console.log(value); this.setState({ facilityCity: value.value }) }}
                    />
                    <label></label>
                    <input
                      type="text"
                      value={this.state.facilityCity}
                      className={
                        this.hasErrors(REQUIRED_FIELDS.FACILITY_CITY)
                          ? "error code-input"
                          : "code-input"
                      }
                    />
                  </div>


                  <div className="filter-footer">
                    <button
                      onClick={() =>
                        this.updateclaimData("patientserviceinformation")
                      }
                      className="filter-save-button"
                    >
                      Update Service Iformation Details
                    </button>
                    {/* <button
                      onClick={this.editActivity}
                      className="filter-save-button"
                    >
                      Update
                    </button> */}
                  </div>
                </div>
              )}
            </div>
            <div className="top-padding">
              <div className="page-title" onClick={this.toggleBillingDetail}>
                <h2 className="heading-custom">+/- Billing Detail</h2>
              </div>
              {this.state.toggleBillingDetail && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {this.state.serviceLines && this.renderServiceLines()}
                  <div className="filter-footer">
                    <button
                      onClick={this.editBilling}
                      className="filter-save-button"
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="top-padding">
              <div className="filter-footer">
                <button
                  type="submit"
                  onClick={this.createClaim}
                  className="scheduling-filter-button"
                >
                  Create Claim
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(withRouter(ManageClaimDetail));
