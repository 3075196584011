import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { fetchFeedbacks } from '../../actions/feedbakcs';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { FEEDBACK_LISTING_COLUMN } from "../../constants/common";
import FeedbackListingTable from "../atom/FeedbackListingTable";

class FeedbackDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        pageSize: 10,
    };

    componentDidMount() {
        this.setState({ loading: true });
        const options = {
            page: this.state.currentPage,
            page_size: this.state.pageSize,
        };
        this.props.fetchFeedbacks({ params: options })
            .then(res => {
                this.setState({ loading: false });
            });
    }

    setPageSize = (value) => {
        this.setState({ pageSize: value }, () => {
            this.goToPage(1);
        });
    };

    list = () => {
        const { feedbacks = [] } = this.props;
        return feedbacks.map(feedback => {
            return [
                feedback.id,
                feedback.created_at,
                feedback.user?.fullname,
                feedback.user?.email,
                feedback.type,
                feedback.comments,
            ];
        });
    };

    goToPage = (page) => {
        let options = {
            page: page,
            page_size: this.state.pageSize,
        };

        if (this.state.filter && this.state.filter !== '')
            options.search = this.state.filter;

        this.setState({
            loading: true,
            currentPage: page,
        });
        this.props.fetchFeedbacks({ params: options })
            .then(res => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />

                <div
                    style={{
                        minHeight: '760px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Feedbacks</h3>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: "0",
                            }}
                            className="col-md-3 mrgn-btm10"
                        >
                            <label>Filter</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.filter}
                                onChange={(event) => {
                                    this.setState({ filter: event.target.value }, () => {
                                        this.goToPage(this.state.currentPage);
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="activities-header">
                            <FeedbackListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                headColumn={FEEDBACK_LISTING_COLUMN}
                                listing={this.list()}
                                totalRecords={this.props.totalRecords}
                                pageSize={this.state.pageSize}
                                setPageSize={this.setPageSize}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        feedbacks: state.feedbackReducer.feedbacks,
        totalFeedbacks: state.feedbackReducer.totalFeedbacks,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchFeedbacks,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(FeedbackDashboard));