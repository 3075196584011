import { CONTRACT } from '../constants/actions';

const initialState = {
    contracts: [],
    totalContracts: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case CONTRACT.FETCH_CONTRACT_SUCCESS:
            return {
                ...state,
                contracts: action.data.data,
                totalContracts: action.data.records,
            };
        case CONTRACT.CREATE_CONTRACT_SUCCESS:
            const contracts = [...state.contracts, action.data.data];
            return {
                ...state,
                contracts: contracts,
            };
        case CONTRACT.UPDATE_CONTRACT_SUCCESS:
            const tcontracts = [...state.contracts, action.data.data];
            return {
                ...state,
                contracts: tcontracts,
            }
        default:
            return state;
    }
}