import {NOTIFICATION} from "../constants/actions";
import {notificationService} from "../services/notifications";

export const fetchNotifications = (options, user) => dispatch => {
    return new Promise((resolve, reject) => {
        notificationService
            .getNotifications(options, user)
            .then(response => {
                dispatch({
                    type: NOTIFICATION.FETCH_NOTIFICATION_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};