import React from 'react';
import { bindActionCreators } from "redux";
import { fetchBatches } from "../../actions/batches";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { APP_ROUTES, BATCH_LISTING_COLUMNS, PAYMENT_POSTING_COLUMNS, RECORDS_PER_PAGE } from "../../constants/common";
import { batchService } from "../../services/batch";

import BatchListingTable from "../atom/BatchListingTable.jsx";
import PaymentPostingListing from "../atom/PaymentPostingListing.jsx";
import { formatDate } from "../../utils/date";
import { authorizationService } from "../../services/authorization";

class BatchDashboard extends React.Component {

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searched: false,
        searchedBatches: [],
        searchedTotalRecords: 0,
        paymentPosting: [],
        pageSize: RECORDS_PER_PAGE,
        eventIds: [],
        batch: '',
    };

    componentDidMount() {
        this.setState({ loading: true });
        const options = {
            page: this.state.currentPage,
            page_size: this.state.pageSize,
        };
        this.props.fetchBatches({ params: options })
            .then(res => {
            }).finally(() => {
                this.setState({ loading: false });
            })
    }

    setClicked = (value) => {
        this.setState({ clicked: value });
    };

    showAdd = () => this.props.history.push({
        pathname: APP_ROUTES.BATCH_ADD,
    });

    showPosting = (batch) => this.props.history.push({
        pathname: APP_ROUTES.PAYMENT_POSTING,
        state: {
            batch,
        }
    });

    resetBatchSearch = () => {
        this.setState({
            searchByName: '',
            searchedBatches: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    edit = batchId => this.props.history.push({
        pathname: APP_ROUTES.BATCH_UPDATE,
        state: {
            batchId,
        }
    });

    editPosting = (posting, paid) => this.props.history.push({
        pathname: APP_ROUTES.UPDATE_PAYMENT_POSTING,
        state: {
            posting,
            paid,
        }
    });

    goToPage = (page) => {
        this.setLoading(true);
        this.setCurrentPage(page);

        const options = {
            page: page,
            page_size: this.state.pageSize,
            api_type: 'react'
        };
        this.props.fetchBatches({ params: options })
            .then(res => {
                this.setLoading(false);
            });
    };

    setLoading = (flag) => {
        this.setState({ loading: flag });
    };

    setCurrentPage = (num) => {
        this.setState({ currentPage: num });
    };

    delete = (batch) => {
        batchService
            .deleteBatch(batch)
            .then(response => {
                this.goToPage(1);
            });
    };

    setPageSize = (value) => {
        this.setState({ pageSize: value }, () => {
            this.goToPage(1);
        });
    };

    searchBatches = () => {
        const { searchByName } = this.state;
        let options = {};

        if (searchByName) {
            options = { ...options, search: searchByName };
        }
        options = { ...options, page_size: this.state.pageSize };

        this.setState({
            searched: true,
            loading: true
        });
        batchService
            .getBatches({ params: options })
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedBatches: response.data.map(batch => {
                        return [
                            batch.name,
                            batch.trn,
                            batch.date,
                            batch.total,
                            batch.payment_mode,
                            batch.id,
                        ];
                    })
                });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    list = () => {
        const { batches = [] } = this.props;
        return batches.map(batch => {
            return [
                batch.name,
                batch.trn,
                batch.date,
                batch.total,
                batch.payment_mode,
                batch.id,
            ];
        });
    };

    showPaymentPostingDetail = (batch) => {
        this.setState({
            loading: true,
            batch,
        });
        batchService
            .getPostingDetail(batch)
            .then(response => {
                this.setState({
                    paymentPosting: response.data.map(posting => {
                        if (this.state.eventIds.indexOf(posting.event_id) === -1) {
                            this.setState(prevState => ({
                                eventIds: [...prevState.eventIds, posting.event_id]
                            }));
                            return [
                                posting.event && formatDate(posting.event.event_start_date),
                                posting.event_id,
                                posting.event && posting.event.assistant
                                    ? `${response.data[0].event.assistant.name} ${response.data[0].event.assistant.last_name}`
                                    : '',
                                posting.patient
                                    ? `${response.data[0].patient.first_name} ${response.data[0].patient.last_name}`
                                    : '',
                                posting.event && posting.event.bill ? posting.event.bill.id : '',
                                posting.id,
                            ];
                        }
                        return [];
                    }),
                });
            }).finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        const {
            paymentPosting,
            searched,
            searchedBatches,
            searchedTotalRecords,
        } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={this.props.activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '755px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Batches</h3>
                        </div>
                    </div>
                    {authorizationService.isAddAccess() && (
                        <div className="filter-footer-header">
                            <button
                                onClick={() => { this.showAdd(true) }}
                                className="filter-button">
                                Add New Batch
                            </button>
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        <div style={{
                            paddingLeft: '0',
                        }} className="col-md-3 mrgn-btm10">
                            <label>Batch Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => {
                                    this.setState({ searchByName: event.target.value },
                                        () => {
                                            if (this.state.searchByName.length >= 3) {
                                                this.searchBatches();
                                            } else if (this.state.searchByName.length === 0) {
                                                this.resetBatchSearch();
                                            }
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetBatchSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <BatchListingTable
                                setPageSize={this.setPageSize}
                                pageSize={this.state.pageSize}
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                showPaymentPostingDetail={this.showPaymentPostingDetail}
                                showPosting={this.showPosting}
                                goToPage={this.goToPage}
                                edit={this.edit}
                                delete={this.delete}
                                headColumn={BATCH_LISTING_COLUMNS}
                                listing={searched ? searchedBatches : this.list()}
                                totalRecords={searched ? searchedTotalRecords : this.props.totalBatches}
                            />
                        </div>
                    </div>
                    {paymentPosting.length > 0 && (
                        <h3 style={{
                            fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                            fontSize: '24px',
                            fontWeight: '400',
                            lineHeight: '1.471',
                        }}>Payment Posting</h3>
                    )}
                    {paymentPosting.length > 0 && (
                        <div className="row">
                            <div className="activities-header">
                                <PaymentPostingListing
                                    isLoading={this.state.loading}
                                    editPosting={this.editPosting}
                                    headColumn={PAYMENT_POSTING_COLUMNS}
                                    listing={this.state.paymentPosting}
                                    batch={this.state.batch}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        batches: state.batchReducer.batches,
        totalBatches: state.batchReducer.totalBatches,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchBatches,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(BatchDashboard));