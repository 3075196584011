import {ACCOUNTS} from '../constants/actions';

const initialState = {
    invoices: [],
    totalInvoices: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case ACCOUNTS.FETCH_ACCOUNTS_SUCCESS:
            return {
                ...state,
                invoices: action.data.data,
                totalInvoices: action.data.records,
            };

        default:
            return state;
    }
}

