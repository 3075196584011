import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getVaccines(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_CONFIG_VACCINATION, options)
        .then(handleResponse)
        .catch(handleError);
}

export const configVaccine = {
    getVaccines,
}