import { BILLING } from "../constants/actions";

const initialState = {
    billings: [],
    totalBillings: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case BILLING.FETCH_BILLING_SUCCESS:
            return {
                ...state,
                billings: action.data.data,
                totalBillings: action.data.records,
            };
        case BILLING.UPDATE_BILLING_SUCCESS:
            const updatedBillings = [...state.billings, action.data.data];
            return {
                ...state,
                billings: updatedBillings,
            }
        default:
            return state;
    }
}