import { INVOICING } from '../constants/actions';

const initialState = {
    invoicing: [],
    totalInvoicing: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case INVOICING.FETCH_INVOICING_SUCCESS:
            return {
                ...state,
                invoicing: action.data.data,
                totalInvoicing: action.data.records,
            };
        case INVOICING.SUBMIT_INVOICING_SUCCESS:
            const invoicing = [ ...state.invoicing, action.data.data ];
            return {
                ...state,
                invoicing: invoicing,
            }
        case INVOICING.UPDATE_INVOICING_SUCCESS:
            const updatedinvoicing = [ ...state.invoicing, action.data.data ];
            return {
                ...state,
                invoicing: updatedinvoicing,
            }
        case INVOICING.DELETE_INVOICING_SUCCESS:
            const deletedinvoicing = [ ...state.invoicing, action.data.data ];
            return {
                ...state,
                invoicing: deletedinvoicing,
            }
        default:
            return state;
    }
}