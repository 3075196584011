import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function createBill(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_BILL, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteBills(payload) {
    return apiClient()
        .post(API_CONSTANTS.DELETE_BILLS, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateBill(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_BILL, payload)
        .then(handleResponse)
        .catch(handleError);
}

function getEventBills(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_EVENT_BILLS, options)
        .then(handleResponse)
        .catch(handleError);
}

function getEventBill(activity, options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_EVENT_BILL + activity, options)
        .then(handleResponse)
        .catch(handleError);
}

function getCPTFEE(cptCode) {
    return apiClient()
        .get(API_CONSTANTS.GET_CPT_FEE + cptCode)
        .then(handleResponse)
        .catch(handleError);
}

export const billingService = {
    createBill,
    updateBill,
    getEventBill,
    getCPTFEE,
    getEventBills,
    deleteBills
}