import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

import loaderImage from "../../../assets/images/CoureMax.gif";
import LoadingOverlay from "react-loading-overlay";
import { authorizationService } from "../../services/authorization";
import { utilityService } from "../../services/utility";
import TablePagination from "../organisms/TablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class InvoicingListingV1Table extends React.Component {
  state = {
    fee_sum: 0,
    row_Color: "",
    row_Color_Text: "",
  };

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
    allChecked: PropTypes.bool.isRequired,
    addAllCheckedItem: PropTypes.func.isRequired,
    removeAllCheckedItem: PropTypes.func.isRequired,
    setSelectedInvoiceDetails: PropTypes.func.isRequired,
    startInvoice: PropTypes.func.isRequired,
    emailInvoice: PropTypes.func.isRequired,
    submitClaim: PropTypes.func.isRequired
  };
  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column, index) => {
      if (index === 1 || index === 2) {
        return (
          <td
            style={{
              textAlign: "center",
            }}
            className="listing-table-head-column"
          >
            {column}
          </td>
        );
      } else {
        return <th className="listing-table-head-column">{column}</th>;
      }
    });

    // const actionElement = (
    //   <th className="listing-table-head-column">{"Action"}</th>
    // );
    const selectorElement = (
      <th className="listing-table-head-column">
      </th>
    );
    header.splice(0, 0, selectorElement);
    return header;
  };

  edit = (item) => {
    if (!authorizationService.isUpdateAccess())
      return;
    this.props.edit(item);
  }

  delete = (item) => {
    if (!authorizationService.isDeleteAccess())
      return;

    utilityService.showConfirmDialog().then(data => {
      this.props.delete(item);
    })
  };

  sumCalculate = (sum) => {
    // eslint-disable-next-line
    this.state.fee_sum = sum;
  };
  renderList = () => {
    const { listing = [] } = this.props;
    return (
      listing.length > 0 &&
      listing.map((listRow) => {
        return <tr style={{
          'cursor': 'pointer'
        }} className={parseFloat(listRow.net_amount) - parseFloat(listRow.amount_received) === 0 ? 'listing-table-rows' : 'listing-table-rows non-invoiced'}>
          <td className="listing-table-column"></td>
          <td className="listing-table-column">{utilityService.formatFrontEndDate(listRow.created_at)}</td>
          <td className="listing-table-column">{listRow.invoice_number}</td>
          <td className="listing-table-column">{listRow.billIds}</td>
          <td className="listing-table-column">{listRow.paymentString}</td>
          <td className="listing-table-column">{utilityService.formatValueInCurrency(listRow.total_amount)}</td>
          <td className="listing-table-column">{utilityService.formatValueInCurrency(listRow.discount_amount)}</td>
          <td className="listing-table-column">{utilityService.formatValueInCurrency(listRow.net_amount)}</td>
          <td className="listing-table-column">{utilityService.formatValueInCurrency(listRow.amount_received)}</td>
          <td className="listing-table-column">{utilityService.formatValueInCurrency(parseFloat(listRow.net_amount) - parseFloat(listRow.amount_received))}</td>
          <td className="listing-table-column">{listRow.is_started ? utilityService.formatFrontEndDate(listRow.invoice_start_date) : 'Not Started'}</td>
          <td className="listing-table-column">{listRow.is_started ? utilityService.formatFrontEndDate(listRow.invoice_due_date) : 'No Due Date'}</td>
          <td className="listing-table-column">
            <ol>
              {listRow.activities?.map((tActivity) => {
                return <li>{utilityService.formatFrontEndDate(tActivity.created_at)} - Bill ID: {tActivity.bill_id} - <strong>{tActivity.type}</strong> {tActivity.message}</li>
              })}
            </ol>
          </td>
          <td className="listing-table-column">
            <div class="btn-group">
              <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <FontAwesomeIcon icon="bars" />
              </button>
              <div class="dropdown-menu">
                {!listRow.is_started &&
                  <>
                    <button className="dropdown-item" onClick={() => {
                      this.props.startInvoice(listRow)
                    }}>
                      Start Invoice
                    </button>
                    <div class="dropdown-divider"></div>
                  </>
                }
                <button class="dropdown-item"
                  onClick={() => {
                    this.props.setSelectedInvoiceDetails(listRow)
                  }}>
                  View Detailed Invoice
                </button>
                <div class="dropdown-divider"></div>
                {listRow.payment_pinsurances &&
                  <button class="dropdown-item" onClick={() => {
                    this.props.submitClaim(listRow, 'Primary Insurance')
                  }}>
                    Submit Claim - Primary Insurance
                  </button>
                }
                {listRow.payment_sinsurances &&
                  <button class="dropdown-item" onClick={() => {
                    this.props.submitClaim(listRow, 'Secondary Insurance')
                  }}>
                    Submit Claim - Secondary Insurance
                  </button>
                }
                {listRow.payment_facility &&
                  <button class="dropdown-item"
                    onClick={() => {
                      this.props.emailInvoice(listRow, 'Facility')
                    }}>
                    Email Invoice - Facility
                  </button>
                }
                {listRow.payment_provider &&
                  <button class="dropdown-item"
                    onClick={() => {
                      this.props.emailInvoice(listRow, 'Provider')
                    }}>
                    Email Invoice - Provider
                  </button>
                }
                {listRow.payment_surgeon &&
                  <button class="dropdown-item" onClick={() => {
                    this.props.emailInvoice(listRow, 'Surgeon')
                  }}>
                    Email Invoice - Surgeon
                  </button>
                }
                {listRow.payment_patient &&
                  <button class="dropdown-item" onClick={() => {
                    this.props.emailInvoice(listRow, 'Patient')
                  }}>
                    Email Invoice - Patient
                  </button>
                }
              </div>
            </div>
          </td>
        </tr>;
      })
    );
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive" style={{ "min-height": "500px;" }}>
            <LoadingOverlay
              active={this.state.loading}
              spinner={
                <img
                  style={{
                    width: "200px",
                  }}
                  src={loaderImage}
                  alt="loading..."
                />
              }
            >
              <table className="listing-table">
                <thead className="listing-table-head">
                  <tr>{this.renderHeadColumn()}</tr>
                </thead>
                {this.props.isLoading && <Loader width="578%" />}
                {!this.props.isLoading && (
                  <tbody>
                    {this.renderList()}
                  </tbody>
                )}
              </table>
            </LoadingOverlay>
          </div>
          <TablePagination
            currentPage={this.props.currentPage}
            goToPage={this.props.goToPage}
            setPageSize={this.props.setPageSize}
            totalRecords={this.props.totalRecords}
            recordsPerPage={this.props.pageSize}
            isPageSelection={true}
          >
          </TablePagination>
        </div>
      </div>
    );
  }
}
